<script lang="ts" setup>
import type { PropType } from 'vue'
import { UserRoleType } from '~/services/apiClient'

const props = defineProps({
  role: { type: String as PropType<UserRoleType> },
})
</script>

<template>
  <template v-if="props.role === UserRoleType.Dispatch">
    <DispatchIcon class="align-text-bottom" />
  </template>
  <template v-else-if="props.role === UserRoleType.CustomerService">
    <CustomSupportIcon class="align-text-bottom" />
  </template>
  <template v-else-if="props.role === UserRoleType.Management">
    <ManagementIcon class="align-text-bottom" />
  </template>
  <template v-else-if="props.role === UserRoleType.SubCarrier">
    <SubCarrierIcon class="align-text-bottom" />
  </template>
  {{ props.role }}
</template>
