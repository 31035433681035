<script lang="ts" setup>
import { useReturnReadingIsStale } from '~/compositions/returnReadingIsStale'
import { useLuxonTimeAgo } from '~/compositions/timeAgo'
import { getTerminalLabel } from '~/constants'
import type { TerminalName } from '~/services/apiClient'
import { useGateScheduleReadingsLatest } from '~/stores/gateScheduleReadingsLatest'
const props = defineProps<{
  terminalName: TerminalName
  includeLegend: boolean
}>()
// Stores
const latestReadingsStore = useGateScheduleReadingsLatest()

// Computed
const reading = computed(() => {
  if (latestReadingsStore.readings_by_terminal.has(props.terminalName)) {
    return latestReadingsStore.readings_by_terminal.get(props.terminalName)!
  }
  return null
})
const readingIsStale = useReturnReadingIsStale(reading, 20)
const lastChange = computed(() => {
  if (reading.value) {
    return reading.value.first_observed
  }
  return null
})
const lastChangedDescription = useLuxonTimeAgo(lastChange)
const lastChecked = computed(() => {
  if (reading.value) {
    return reading.value.last_observed
  }
  return null
})
const lastCheckedDesc = useLuxonTimeAgo(lastChecked)
const tooltip = computed(() => {
  if (lastChangedDescription.value && reading.value) {
    return `Last checked ${lastCheckedDesc.value}. Last changed ${lastChangedDescription.value} at ${reading.value.last_changed_desc}`
  }
})
const shortLastChangedDescription = computed(() => {
  if (lastChangedDescription.value) {
    return lastChangedDescription.value.replace(' ago', '')
  }
  return ''
})
</script>

<template>
  <div>
    <div>
      <span v-tooltip="tooltip">
        <!-- Remove "Terminal:" as it was redundant, this is just a placeholder now -->
        <span v-if="includeLegend" class="legend"></span>
        {{ getTerminalLabel(terminalName) }}
      </span>
      <span
        v-if="reading && readingIsStale"
        v-tooltip="
          `WARNING: Gate Schedules last fetched ${reading?.last_observed_human_desc}`
        "
        class="ml-1"
      >
        <i-mdi:alert class="text-red-700 align-text-bottom" />
      </span>
    </div>
    <div v-tooltip="tooltip" class="text-gray-500 text-xs">
      <span v-if="includeLegend" class="legend">Last changed:</span>
      {{ shortLastChangedDescription }}
    </div>
  </div>
  <div></div>
</template>

<style lang="scss" scoped>
.legend {
  @apply text-gray-600 text-right mr-2 -ml-1;
  min-width: 82px;
  display: inline-block;
}
</style>
