import { ElMessageBox, ElNotification } from 'element-plus'
import { getTerminalLabel } from '~/constants'
import type { Appointment } from '~/models/appointments'
import { AppointmentBookingApi } from '~/services/apiClient'
import { useContainerStore } from '~/stores/containers'

const COMMON_MESSAGE_BOX_OPTIONS: {
  confirmButtonClass: string
  cancelButtonText: string
  type: 'warning'
} = {
  confirmButtonClass: 'el-button--danger',
  cancelButtonText: 'No',
  type: 'warning',
}
export function useCancelAppointments() {
  function cancelAppointment(appointment: Appointment) {
    const terminalLabel = getTerminalLabel(appointment.terminal)
    ElMessageBox.confirm(
      `Are you sure you want to cancel appointment
      at ${terminalLabel} for ${appointment.container_number}?`,
      {
        title: 'Confirm Cancel Appointment',
        confirmButtonText: 'Cancel Appointment',
        ...COMMON_MESSAGE_BOX_OPTIONS,
      }
    ).then(() => {
      const api = new AppointmentBookingApi()
      api
        .cancelAppointmentsAppointmentsBookingDelete({
          appointment_ids: [appointment.id],
        })
        .then(() => {
          ElNotification({
            title: 'Appointment Cancellation Requested',
            message: `Terminal: ${terminalLabel}
              Container: ${appointment.container_number}`,
            type: 'success',
          })
        })
    })
  }

  const containerStore = useContainerStore()

  function cancelAppointments(appointments: Appointment[]) {
    ElMessageBox.confirm(
      `Are you sure you want to cancel appointment
          for ${containerStore.numAppointedContainersForSelection} containers?`,
      {
        title: `Confirm Cancel ${containerStore.numAppointedContainersForSelection} Appointments`,
        confirmButtonText: 'Cancel Appointments',
        ...COMMON_MESSAGE_BOX_OPTIONS,
      }
    ).then(() => {
      const api = new AppointmentBookingApi()
      api
        .cancelAppointmentsAppointmentsBookingDelete({
          appointment_ids: appointments.map((appointment) => appointment.id),
        })
        .then(() => {
          ElNotification({
            title: 'Appointment Cancellation Requested',
            type: 'success',
          })
        })
    })
  }
  return { cancelAppointments, cancelAppointment }
}
