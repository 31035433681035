import { acceptHMRUpdate, defineStore } from 'pinia'
import { useUserStore } from './user'
import { BasicContainer } from '~/models/containers'
import type { LoadContainerBasicOptions } from '~/services/apiHelpers'
import { loadContainersBasic } from '~/services/apiHelpers'

export const usedBasicContainersStore = defineStore('basicContainers', () => {
  const userStore = useUserStore()
  const containers = ref([] as BasicContainer[])
  const containersLookup = ref(new Map<string, BasicContainer>())
  const loading = ref(false)
  function load(options: LoadContainerBasicOptions) {
    loading.value = true
    containers.value = []
    return loadContainersBasic(options)
      .then((resp) => {
        containers.value = resp.data.map(
          (c) => new BasicContainer(c, userStore.demo_mode)
        )
        containersLookup.value = new Map(
          containers.value.map((c) => [c.number, c])
        )
      })
      .finally(() => {
        loading.value = false
      })
  }
  function sortByOutGateTime() {
    containers.value.sort((a, b) => {
      if (a.import_out_gated_time && b.import_out_gated_time) {
        return a.import_out_gated_time > b.import_out_gated_time ? 1 : -1
      } else {
        return 0
      }
    })
  }
  return { containers, containersLookup, loading, load, sortByOutGateTime }
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(usedBasicContainersStore, import.meta.hot)
  )
}
