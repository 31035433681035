import type { MaybeRef } from '@vueuse/core'
import { DateTime } from 'luxon'
import type { Ref } from 'vue'
import type { GateScheduleReading } from '~/models/gateSchedules'
import type { ReturnRulesReading } from '~/models/returnRules'

const ONE_MINUTE = 60000
export function useReturnReadingIsStale(
  reading: MaybeRef<ReturnRulesReading | GateScheduleReading | null>,
  staleAfter: number = 10
): Ref<boolean> {
  const readingRef = ref(reading)
  const readingIsStale = ref(false)
  function updateIsStale() {
    if (readingRef.value) {
      readingIsStale.value =
        readingRef.value.last_observed <
        DateTime.now().minus({ minutes: staleAfter })
    }
  }
  watch(readingRef, updateIsStale)
  onMounted(() => {
    updateIsStale()
    setInterval(updateIsStale, ONE_MINUTE)
  })
  return readingIsStale
}
