<script lang="ts" setup>
import { ElNotification, ElTag } from 'element-plus'
import type { PropType } from 'vue'
import type { UserRoleType } from '~/services/apiClient'
import { useUsersStore } from '~/stores/users'
import UserRoleSelector from '~/components/display/UserRoleSelector.vue'
import { useUserStore } from '~/stores/user'

const props = defineProps({
  modelValue: { type: Boolean, required: true },
  userLabel: { type: String, default: 'User' },
  role: { type: String as PropType<UserRoleType> },
  extraMessages: { type: Array as PropType<string[]> },
})
const emit = defineEmits<{ (e: 'update:modelValue', value: number[]): void }>()
const addUsersDialogOpen = useVModel(props, 'modelValue', emit)
const usersStore = useUsersStore()
const userStore = useUserStore()
const rawUserEmails = ref('')
const selectedRole = ref<UserRoleType | undefined>(props.role)

const parsedUserEmails = computed(() => {
  return rawUserEmails.value
    .split(/[\s,\n<>;]+/)
    .map((s) => s.trim().toLocaleLowerCase())
    .filter((s) => s && s.includes('@') && s.includes('.'))
})

function addUsers() {
  usersStore
    .inviteUsers(parsedUserEmails.value, selectedRole.value)
    .then(() => {
      ElNotification({
        title: `Invited ${parsedUserEmails.value.length} users`,
        type: 'success',
      })
      usersStore.load()
      rawUserEmails.value = ''
      addUsersDialogOpen.value = false
    })
}
</script>

<template>
  <el-dialog
    v-model="addUsersDialogOpen"
    :title="`Invite ${userLabel}s`"
    center
  >
    <p>
      Enter an email for each {{ userLabel.toLowerCase() }} you would like to
      invite.
    </p>
    <el-input
      v-model="rawUserEmails"
      placeholder="john.do@bigtrucksco.com, other.user@bigtrucksco.com..."
      type="textarea"
      :autosize="{ minRows: 5 }"
      class="my-2"
    />
    <el-alert
      title="Emails can be separated by commas, new lines or spaces"
      type="info"
      show-icon
      :closable="false"
    />

    <template v-if="extraMessages && extraMessages.length">
      <div v-for="message in extraMessages" :key="message" class="mt-2">
        <el-alert :title="message" type="info" show-icon :closable="false" />
      </div>
    </template>

    <h3 class="text-md mt-2">Parsed emails:</h3>
    <div>
      <ElTag
        v-if="parsedUserEmails.length === 0"
        type="warning"
        class="mr-2 my-1"
      >
        No emails found, enter some above
      </ElTag>
      <ElTag
        v-for="email in parsedUserEmails"
        :key="email"
        type="success"
        class="mr-2 my-1"
      >
        {{ email }}
      </ElTag>
    </div>
    <div
      v-if="!props.role && userStore.hasManagementPermissions"
      class="mt-4 mb-2 flex items-center gap-4"
    >
      <UserRoleSelector
        v-model="selectedRole"
        :required="false"
        :allow-manager="true"
        placeholder="Select Role (optional)"
        style="max-width: 49.5%"
      />
      <Help title="About User Roles">
        <p>
          Only managers can manage other users and organization settings. Other
          roles have the same permissions but help organize users by their
          function.
        </p>
        <p>
          Users cannot select the manager role during signup unless specifically
          invited as a manager.
        </p>
      </Help>
    </div>
    <template #footer>
      <div class="flex">
        <el-button
          size="large"
          class="flex-1"
          @click="addUsersDialogOpen = false"
        >
          Cancel
        </el-button>
        <el-button
          type="primary"
          size="large"
          :disabled="parsedUserEmails.length === 0"
          class="flex-1"
          @click="addUsers"
        >
          Invite
          {{ parsedUserEmails.length }} {{ userLabel.toLowerCase() }}s
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>
