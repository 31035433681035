<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    width: number
  }>(),
  { width: 60 }
)
</script>

<template>
  <div
    v-loading="true"
    element-loading-background="rgba(0, 0, 0, 0)"
    class="inline-block align-middle"
    :style="{ width: `${props.width}px`, height: `${props.width}px` }"
  ></div>
</template>
