<script lang="ts" setup>
import type { Container } from '~/models/containers'
import { getContainerTypeDescription } from '~/constants'
import { useShippingLine } from '~/compositions/useShippingLines'
import { TerminalName } from '~/services/apiClient'
const props = defineProps<{
  container: Container
}>()
const addEmptyDialogOpen = ref(false)
const container = toRef(props, 'container')
const { getShippingLine } = useShippingLine()
const shippingLine = computed(() => {
  return getShippingLine(container.value.line)
})
const swapEmptyTooltip = computed(() => {
  return container.value.terminal === TerminalName.Wbct
    ? 'Swap empty has not been implemented for WBCT'
    : 'Swap empty in'
})
</script>

<template>
  <span>
    <span class="font-bold">
      <!-- Container in -->
      <span v-if="container.is_in_import_booked_state" class="font-bold">
        <span
          v-if="
            container.latest_appointment &&
            container.latest_appointment.linked_appointments.length > 0
          "
          class="text-gray-500"
        >
          <el-popover trigger="hover" :persistent="false" :width="220">
            <ClickToCopy
              :text-to-copy="
                container.latest_appointment.linked_appointments[0]
                  .container_number
              "
            >
              <el-button plain type="primary" class="w-full mb-2">
                <i-mdi:content-copy class="mr-1" />
                Copy container number
              </el-button>
            </ClickToCopy>
            <el-button
              v-tooltip="swapEmptyTooltip"
              :disabled="container.terminal === TerminalName.Wbct"
              plain
              type="primary"
              class="w-full"
              @click.stop="addEmptyDialogOpen = true"
            >
              <i-mdi:pencil class="mr-1" />
              Swap empty
            </el-button>
            <template #reference>
              {{
                container.latest_appointment.linked_appointments[0]
                  .container_number
              }}
            </template>
          </el-popover>
        </span>
        <span v-else>
          <el-button
            type="primary"
            size="small"
            plain
            class="min-w-112px"
            @click.stop="addEmptyDialogOpen = true"
          >
            Add empty in
          </el-button>
        </span>
        /
      </span>
      <!-- Main container number -->
      <ClickToCopy :text-to-copy="container.number" />
      <span v-if="container.is_in_empty_booked_state" class="font-bold">
        /
        <span
          v-if="container.latest_appointment?.linked_appointments.length"
          class="text-gray-500"
        >
          {{
            container.latest_appointment?.linked_appointments[0]
              .container_number
          }}
        </span>
        <span v-else class="text-gray-400 font-semibold">No load out</span>
      </span>
    </span>
    <!-- Appointment type icon -->
    <AppointmentTypeIcon
      v-if="container.is_in_booked_state"
      :container="container"
      class="mx-1"
    />
    <!-- Container info -->
    <span class="text-sm">
      <span v-if="shippingLine || container.type">
        <template v-if="!container.is_in_booked_state"> • </template>
        <span class="text-gray-400">
          <span
            v-if="shippingLine"
            v-tooltip="`${shippingLine.long_desc} (raw: ${container.raw_line})`"
            class="text-gray-400"
            >{{ shippingLine.short_desc }}</span
          ><span
            v-else-if="container.raw_line"
            v-tooltip="`This is not a shipping line Dray Dog recognizes...yet!`"
            class="text-red-900"
            >{{ container.raw_line }}</span
          >/<span
            v-if="container.type && container.type_short"
            v-tooltip="
              `${getContainerTypeDescription(container.type)} (raw: ${
                container.raw_type
              })`
            "
            class="text-gray-400"
            >{{ container.type_short }}</span
          ><span
            v-else-if="container.raw_type"
            v-tooltip="
              `This is not a container type Dray Dog recognizes...yet!`
            "
            class="text-red-900"
            >{{ container.raw_type }}</span
          >
          <span
            v-if="
              container.import_status &&
              container.import_status.wheeled &&
              container.import_status.wheeled === true
            "
            v-tooltip="
              'Container is on wheels. Source: ' +
              `${container.import_status.source}`
            "
          >
            (W)
          </span>
        </span>
      </span>
      •
      <span>
        {{ container.terminal_label }}
      </span>
      <template
        v-if="
          (container.is_in_on_vessel_state ||
            container.is_in_unavailable_state ||
            container.is_in_import_booking_state) &&
          container.vessel_name
        "
      >
        •
        <VesselName
          :container="container"
          :max-characters="15"
          :show-link-icon="false"
        />
      </template>
    </span>
    <AddEmptyDialog
      v-if="addEmptyDialogOpen && container.latest_appointment"
      :container="container"
      :appointment="container.latest_appointment"
      :show-return-rules="true"
      @close="addEmptyDialogOpen = false"
    />
  </span>
</template>
