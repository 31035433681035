<script lang="ts" setup>
import type { FormRules } from 'element-plus'
import { ElNotification } from 'element-plus'
import type { FormInstance } from 'element-plus/lib/components/form'
import { User } from '~/models/user'
import { UserRoleType, UsersApi } from '~/services/apiClient'
import { useUsersStore } from '~/stores/users'
import { useUserStore } from '~/stores/user'

const props = defineProps({
  user: { type: User, required: true },
})
const emit = defineEmits<{
  (e: 'close'): void
}>()
const usersStore = useUsersStore()
const userStore = useUserStore()
const api = new UsersApi()
const ruleFormRef = ref<FormInstance>()
const form = reactive({
  first_name: props.user.first_name,
  last_name: props.user.last_name,
  role: props.user.role,
})
const rules = reactive<FormRules>({
  first_name: [{ required: true, message: 'First name is required' }],
  last_name: [{ required: true, message: 'Last name is required' }],
  role: [{ required: true, message: 'Role is required' }],
})
const roleOptions: { label: UserRoleType }[] = [
  { label: UserRoleType.CustomerService },
  { label: UserRoleType.Dispatch },
  { label: UserRoleType.Management },
  { label: UserRoleType.Other },
]
function closeDialog() {
  emit('close')
}
function handleClose() {
  closeDialog()
}
function submitForm() {
  ruleFormRef.value?.validate((valid) => {
    if (valid) {
      api
        .updateUserInfoUsersUpdateUserInfoUserIdPatch(props.user.id, {
          first_name: form.first_name as string,
          last_name: form.last_name as string,
          role: form.role as UserRoleType,
        })
        .then((resp) => {
          ElNotification.success(resp.data.message)
          usersStore.load()
          closeDialog()
        })
    } else {
      ElNotification.error('Please fix the errors')
    }
  })
}
</script>

<template>
  <el-dialog
    :model-value="true"
    title="Edit User"
    :before-close="handleClose"
    width="20%"
  >
    <el-form ref="ruleFormRef" :model="form" :rules="rules">
      <el-form-item label="First Name:" prop="first_name">
        <el-input v-model="form.first_name" name="first_name"> </el-input>
      </el-form-item>
      <el-form-item label="Last Name:" prop="last_name">
        <el-input v-model="form.last_name" name="last_name" />
      </el-form-item>
      <el-form-item label="Role:" prop="role">
        <el-select
          v-if="userStore.hasManagementPermissions"
          v-model="form.role"
        >
          <el-option
            v-for="roleOption in roleOptions"
            :key="roleOption.label"
            :label="roleOption.label"
            :value="roleOption.label"
          />
        </el-select>
        <el-select v-else v-model="form.role" disabled> </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeDialog">Cancel</el-button>
        <el-button type="primary" @click="submitForm"> Confirm </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<style scoped>
.el-button--text {
  margin-right: 15px;
}
.el-select {
  width: 300px;
  margin-left: auto;
}
.el-input {
  width: 300px;
  margin-left: auto;
}
.dialog-footer button:first-child {
  margin-right: 10px;
}
</style>
