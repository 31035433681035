import { faker } from '@faker-js/faker/locale/en'
import { DateTime } from 'luxon'
import type {
  ContainerType,
  ContainerWatchState,
  GETWatch,
  ShippingLine,
  TerminalName,
} from '~/services/apiClient'

export class ContainerWatch {
  container_number: string
  state: ContainerWatchState
  shipping_line: ShippingLine | null
  container_type: ContainerType | null
  customer_id: number | null
  customer_name: string | null
  terminal: TerminalName | null
  import_last_free_date: DateTime | null
  constructor(watch: GETWatch, demoMode: boolean) {
    this.container_number = watch.container_number
    this.state = watch.state
    this.shipping_line = watch.shipping_line ?? null
    this.container_type = watch.container_type ?? null
    this.customer_id = null
    this.customer_name = null

    this.terminal = watch.last_related_terminal ?? null
    this.import_last_free_date = watch.import_last_free_date
      ? DateTime.fromISO(watch.import_last_free_date)
      : null
    if (watch.customer) {
      this.customer_id = watch.customer.id
      if (demoMode) {
        this.customer_name = faker.company.name()
      } else {
        this.customer_name = watch.customer.name
      }
    }
  }
}
