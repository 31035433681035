<script setup lang="ts">
import { Icon } from '@iconify/vue'
import copy from 'copy-to-clipboard'
const props = defineProps({
  tooltipText: { type: String || Icon, default: 'Click to copy' },
  textToCopy: { type: String, required: true },
})
const tooltipText = toRef(props, 'tooltipText')
const tooltipTextClicked = ref(tooltipText.value)
watch(tooltipText, () => {
  tooltipTextClicked.value = props.tooltipText
})
const copyItem = () => {
  copy(props.textToCopy, {
    message: 'Press #{key} to copy',
    format: 'text/plain',
  })
  tooltipTextClicked.value = 'Copied!'

  setTimeout(() => {
    tooltipTextClicked.value = props.tooltipText
  }, 4000)
}
</script>

<template>
  <span
    v-tooltip="{
      content: tooltipTextClicked,
      triggers: ['hover', 'focus', 'touch', 'click'],
    }"
    style="cursor: copy"
    @click.stop.prevent="copyItem"
  >
    <slot>
      {{ textToCopy }}
    </slot>
  </span>
</template>
