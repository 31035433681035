<script lang="ts" setup>
import type { WithCounts } from './types'
import LoadedContainerIcon from '~/components/icons/LoadedContainerIcon.vue'
import EmptyContainerIcon from '~/components/icons/EmptyContainerIcon.vue'
import DualsIcon from '~/components/icons/DualsIcon.vue'
const props = withDefaults(
  defineProps<{ group: WithCounts; showLabels?: boolean }>(),
  { showLabels: false }
)
const tooltip = computed(() => {
  let tooltip = `${props.group.numTransactions} appointments total`
  const subCountsDesc = []
  if (props.group.numSingleImportAppointments) {
    subCountsDesc.push(
      `${props.group.numSingleImportAppointments} imports (not dualed)`
    )
  }
  if (props.group.numDualTransactions) {
    subCountsDesc.push(`${props.group.numDualTransactions} duals`)
  }
  if (props.group.numSingleEmptyAppointments) {
    subCountsDesc.push(
      `${props.group.numSingleEmptyAppointments} single empties`
    )
  }
  if (subCountsDesc.length) {
    tooltip += `: ${subCountsDesc.join(', ')}`
  }
  return tooltip
})
</script>

<template>
  <span v-tooltip="tooltip" class="inline-block text-gray-600">
    <span v-if="group.numSingleImportAppointments" class="ml-1 count">
      <LoadedContainerIcon class="icon" />
      {{ group.numSingleImportAppointments }}
      <template v-if="showLabels">load out</template>
    </span>
    <span v-if="group.numDualTransactions" class="ml-1 count">
      <DualsIcon class="icon" />
      {{ group.numDualTransactions }}
      <template v-if="showLabels">duals</template>
    </span>
    <span v-if="group.numSingleEmptyAppointments" class="ml-1 count">
      <EmptyContainerIcon class="icon" />
      {{ group.numSingleEmptyAppointments }}
      <template v-if="showLabels">empty in</template>
    </span>
    <span class="count">
      •
      <i-mdi:calendar class="icon" />{{ props.group.numTransactions }}
      <template v-if="showLabels">transactions total</template>
    </span>
  </span>
</template>

<style scoped lang="scss">
.count {
  @apply ml-1;
}
.icon {
  @apply align-text-bottom mr-2px -mb-1px;
}
</style>
