import { DateTime } from 'luxon'
import { getPortalLabel, getTerminalLabel } from '~/constants'
import type {
  ContainerType,
  GETImportStatus,
  ImportLocation,
  LastFreeDate,
  ParsedHold,
  ShippingLine,
  TerminalName,
} from '~/services/apiClient'
import { PortalName } from '~/services/apiClient'

export class ImportStatus {
  cycle_id: number
  container_number: string
  master_bill_of_lading: string | null
  raw_data: object
  last_free_day: DateTime | null
  other_last_free_dates: Array<LastFreeDate>
  terminal: TerminalName
  ready_for_appointment: boolean
  available_for_pickup: boolean | null
  closed_area: boolean | null
  wheeled: boolean | null
  demurrage_fees: number | null
  shipping_line?: string
  container_type?: string
  parsed_shipping_line?: ShippingLine
  parsed_container_type?: ContainerType
  holds: string[]
  location: string
  parsed_location: ImportLocation
  terminal_block: string | null
  last_observed_changed: DateTime
  last_initiated_check: DateTime | null = null
  last_observed: DateTime | null = null
  extra_data: Object
  source: PortalName
  key: string
  parsed_holds: Array<ParsedHold>
  discharged_time: DateTime | null = null

  constructor(status: GETImportStatus) {
    this.cycle_id = status.id || status.cycle_id // `cycle_id` is deprecated
    this.container_number = status.container_number
    this.master_bill_of_lading = status.master_bill_of_lading ?? null
    this.raw_data = status
    this.last_free_day = status.last_free_date
      ? DateTime.fromISO(status.last_free_date)
      : null
    this.other_last_free_dates = status.other_last_free_dates ?? []
    this.terminal = status.terminal
    this.ready_for_appointment = status.ready_for_appointment
    this.available_for_pickup = status.available_for_pickup ?? null
    this.closed_area = status.closed_area ?? null
    this.wheeled = status.wheeled ?? null
    this.demurrage_fees = status.demurrage_fees ?? null
    this.shipping_line = status.shipping_line
    this.container_type = status.container_type
    this.parsed_shipping_line = status.parsed_shipping_line
    this.parsed_container_type = status.parsed_container_type
    this.holds = status.holds
    this.parsed_holds = status.parsed_holds
    this.location = status.location
    this.parsed_location = status.parsed_location
    this.terminal_block = status.terminal_block ?? null
    this.last_observed_changed = DateTime.fromISO(status.observed)
    if (status.last_observed) {
      this.last_observed = DateTime.fromISO(status.last_observed)
    }
    if (status.last_initiated_check) {
      this.last_initiated_check = DateTime.fromISO(status.last_initiated_check)
    }
    if (status.discharged_time) {
      this.discharged_time = DateTime.fromISO(status.discharged_time)
    }
    this.extra_data = status.extra_data
    this.source = status.source
    this.key = `${status.container_number}-${
      status.source
    }-${status.observed.toString()}`
  }

  get location_description(): string {
    switch (this.parsed_location) {
      case 'on_vessel':
        return 'On Vessel'
      case 'in_terminal':
        return 'Grounded'
      case 'out_gated':
        return 'Out Gated'
      case 'rail':
        return 'On Rail'
      default:
        return 'Unknown'
    }
  }

  get sourceLabel(): string {
    let sourceLabel = getPortalLabel(this.source)
    if (
      this.source === PortalName.Emodal ||
      this.source === PortalName.PortOptimizer
    ) {
      sourceLabel += ` (${getTerminalLabel(this.terminal)})`
    }
    return sourceLabel
  }

  get isStale(): boolean {
    if (this.last_observed != null && this.last_initiated_check != null) {
      return this.last_observed < this.last_initiated_check.minus({ hours: 2 })
    } else if (this.last_observed) {
      // NOTE: this is transitional as all import statuses should have last_initiated_check
      // now, even though its nullable
      return this.last_observed < DateTime.now().minus({ hours: 37 })
    } else {
      return false
    }
  }

  get staleDescription(): string | undefined {
    if (this.isStale) {
      return `Import availability data last fetched ${this.last_observed!.toRelative()}`
    }
  }
}
