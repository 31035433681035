<script lang="ts" setup>
import type { FormRules } from 'element-plus'
import { ElNotification } from 'element-plus'
import type { FormInstance } from 'element-plus/lib/components/form'
import { useCustomerStore } from '~/stores/customers'
const emit = defineEmits<{
  (e: 'close'): void
}>()
const customersStore = useCustomerStore()
const ruleFormRef = ref<FormInstance>()
const selectedCustomersIds = customersStore.selectedCustomersIds
const selectedCustomersDesc = computed(() => {
  const customerNames = customersStore.customers
    .filter((c) => selectedCustomersIds.includes(c.id))
    .map((c) => c.name)
  return (
    customerNames.slice(0, -1).join(', ') + ' and ' + customerNames.slice(-1)
  )
})
const loading = ref(false)
// name is required input string
const form = reactive({
  name: '' as string,
})
const rules = reactive<FormRules>({
  name: [{ required: true, message: 'Customer name is required' }],
})
function closeDialog() {
  emit('close')
}
function handleClose() {
  closeDialog()
}
function mergeCustomers() {
  ruleFormRef.value?.validate((valid) => {
    if (valid) {
      loading.value = true
      customersStore
        .merge(selectedCustomersIds, form.name)
        .then(() => {
          ElNotification.success('Customers Merged')
          customersStore.clearCustomerSelection()
          closeDialog()
        })
        .finally(() => {
          loading.value = false
        })
    }
  })
}
</script>

<template>
  <el-dialog
    :model-value="true"
    title="Merge Customers?"
    width="30%"
    :before-close="handleClose"
  >
    <el-alert type="warning" show-icon :closable="false">
      <strong>{{ selectedCustomersDesc }}</strong> will be turned into a single
      customer with the provided name.
      <br />
      <br />
      All containers with these customers will be updated to use the new, merged
      customer.
    </el-alert>
    <el-form
      ref="ruleFormRef"
      label-width="auto"
      :model="form"
      :rules="rules"
      class="mt-8"
    >
      <el-form-item label="New Customer Name:" prop="name">
        <el-input
          v-model="form.name"
          name="name"
          placeholder="Enter Name"
          class="float-left"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button class="mr-2" @click="closeDialog">Cancel</el-button>
        <el-button type="primary" :loading="loading" @click="mergeCustomers">
          Merge
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>
