import OpenReplay from '@openreplay/tracker'
import * as Sentry from '@sentry/vue'
import trackerAssist from '@openreplay/tracker-assist'
import { setOpenReplaySessionURLForCrispChat } from '~/services/crispChat'

export const openReplaySessionURLKeyName = 'openReplaySessionURL'

let startTrackingTime = Date.now()
const tracker = new OpenReplay({
  projectKey: '3AEaGMaU9GOFOWqvbvVB',
  // https://docs.openreplay.com/en/tutorials/crossdomain-iframe/
  crossdomain: {
    enabled: true,
    parentDomain: '*',
  },
  network: {
    capturePayload: true,
    sessionTokenHeader: 'X-OpenReplay-SessionToken',
    failuresOnly: false,
    ignoreHeaders: ['Cookie', 'Set-Cookie', 'Authorization'],
    captureInIframes: false,
  },
  obscureInputDates: false,
  obscureInputEmails: false,
  obscureTextEmails: false,
  obscureTextNumbers: false,
  obscureInputNumbers: false,
  defaultInputMode: 0,
  __DISABLE_SECURE_MODE: true,
})
// https://docs.openreplay.com/en/plugins/assist/
tracker.use(trackerAssist({}))
tracker.stop()

export function getOpenReplayTracker(): OpenReplay {
  return tracker
}
export function getOpenReplaySessionURL(): undefined | string {
  let sessionURL = tracker.getSessionURL({ withCurrentTime: true })
  if (sessionURL) {
    const trackingDurationMilliseconds = Date.now() - startTrackingTime
    sessionURL += `?jumpto=${trackingDurationMilliseconds}`
    // They messed up redirects or something at some point. This fixes it, they'll
    // probably fix at some point I would imagine
    return sessionURL.replace(
      'https://openreplay.com',
      'https://app.openreplay.com'
    )
  }
}

export function startOpenReplayTracking(): Promise<void> {
  return tracker.start().then(() => {
    startTrackingTime = Date.now()
    const sessionId = tracker.getSessionID()
    const sessionToken = tracker.getSessionToken()
    // https://docs.openreplay.com/en/integrations/sentry/
    const sessionURL = getOpenReplaySessionURL()
    if (sessionURL) {
      Sentry.setTag(openReplaySessionURLKeyName, sessionURL)
      setOpenReplaySessionURLForCrispChat(sessionURL)
    }
    if (sessionId) {
      Sentry.setTag('openReplaySessionId', sessionId)
      Sentry.setTag('openReplaySessionToken', sessionToken)
    }
  })
}
