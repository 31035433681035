<script lang="ts" setup>
import { useGetCapacitySetLabel } from '../capacity/helpers'
import type { RemainingCapacityPeriodInfo } from '~/stores/remainingCapacities'

const props = defineProps<{
  remainingCapacity: RemainingCapacityPeriodInfo
}>()
const remainingCapacity = computed(() => {
  return props.remainingCapacity.num_remaining
})
const getCapacitySetLabel = useGetCapacitySetLabel()

const numAppointments = computed(() => {
  return props.remainingCapacity.num_booked
})
const title = computed(() => {
  const setDescription =
    getCapacitySetLabel(props.remainingCapacity.set) + ' capacity'
  if (remainingCapacity.value > 0) {
    return 'You have ' + setDescription
  } else if (remainingCapacity.value === 0) {
    return 'You are at ' + setDescription
  } else {
    let msg = `You are over ${setDescription} by ${-remainingCapacity.value} appointment`
    if (-remainingCapacity.value > 1) {
      msg += 's'
    }
    return msg
  }
})
</script>

<template>
  <el-alert
    :title="title"
    :type="remainingCapacity > 0 ? 'success' : 'warning'"
    show-icon
    :closable="false"
    class="shadow mt-4"
    style="margin-bottom: 8px"
  >
    For <strong>{{ props.remainingCapacity.timeDescription }}</strong
    >, your import appointment
    {{ props.remainingCapacity.set.description }} capacity is set to
    <strong>{{ props.remainingCapacity.capacity }}</strong
    >, and you have
    <strong>{{ numAppointments }} scheduled</strong>
    import appointments
    <template v-if="remainingCapacity < 0">
      <br /><br />
      <strong>You can still book an appointment</strong>
    </template>
  </el-alert>
</template>
