<script lang="ts" setup>
import { ElMessage, ElNotification } from 'element-plus'
import type { ContainerUploadError } from '~/services/apiClient'
import { useContainerStore } from '~/stores/containers'
import { useUsersStore } from '~/stores/users'

const containerStore = useContainerStore()
const usersStore = useUsersStore()
const dialogOpen = ref(true)
const assignedUserIDs = ref([] as number[])
const customerID = ref(undefined as number | undefined)
const showContainerNumbers = ref(containerStore.numUnwatchedContainers < 5)
const errorContainers = ref([] as ContainerUploadError[])
const tagsToAdd = ref([] as string[])

function closeDialog() {
  dialogOpen.value = false
}
function watchContainers() {
  errorContainers.value = []
  containerStore
    .watchUnwatchedContainers(
      usersStore.users.filter((user) =>
        assignedUserIDs.value.includes(user.id)
      ),
      customerID.value,
      tagsToAdd.value
    )
    .then((response) => {
      if (response.data.container_numbers.length > 0) {
        ElNotification.success({
          title: `Uploaded ${response.data.container_numbers.length} containers`,
        })
      }
      if (response.data.errors.length > 0) {
        errorContainers.value = response.data.errors
        ElMessage.error({
          message: `Failed to upload ${response.data.errors.length} containers`,
          duration: 10000,
        })
      } else {
        closeDialog()
      }
    })
}
</script>

<template>
  <el-button
    v-loading="containerStore.executingWatchContainers"
    type="success"
    class="ml-2 mr-2"
    @click="dialogOpen = true"
  >
    Start tracking
    {{ containerStore.numUnwatchedContainers }}
    new container<template v-if="containerStore.numUnwatchedContainers > 1"
      >s</template
    >
  </el-button>
  <BaseDialog
    v-if="dialogOpen"
    v-model="dialogOpen"
    :title="`Start tracking ${containerStore.numUnwatchedContainers} containers?`"
    width="30%"
  >
    <p>
      {{ containerStore.numUnwatchedContainers }} of the containers you searched
      are not being tracked. Start tracking to view terminal data and allow
      appointment booking
    </p>
    <p class="mb-4 mt-4 break-normal text-xs">
      <template v-if="showContainerNumbers">
        <strong> Containers: </strong>
        {{ containerStore.searchedButUnwatchedContainers.join(', ') }}
      </template>
      <span
        v-else
        class="underline cursor-pointer"
        @click="showContainerNumbers = !showContainerNumbers"
      >
        Show container numbers
      </span>
    </p>
    <UsersCustomerAndTagsSelector
      v-model:assigned-user-i-ds="assignedUserIDs"
      v-model:customer-i-d="customerID"
      v-model:tags-to-add="tagsToAdd"
    />

    <div>
      <ul class="text-red-700">
        <strong v-if="errorContainers.length > 0">Errors:</strong>
        <li
          v-for="errorContainer in errorContainers"
          :key="errorContainer.container_number"
        >
          <b>
            {{ errorContainer.container_number }} -
            {{ errorContainer.message }}</b
          >
        </li>
      </ul>
    </div>
    <template #action-button>
      <el-button
        class="flex-1 fs-start-tracking-containers"
        type="success"
        size="large"
        @click="watchContainers"
      >
        Start Tracking
      </el-button>
    </template>
  </BaseDialog>
</template>
