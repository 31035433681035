import type { DateTime } from 'luxon'
import { defineStore } from 'pinia'
import { CameraHistoryImage } from '~/models/cameraImage'
import type { TerminalName } from '~/services/apiClient'
import { CamerasApi } from '~/services/apiClient'

const api = new CamerasApi()

export const useHistoricalCameraImagesStore = defineStore(
  'historicalCameraImages',
  {
    state: () => {
      return {
        loading: true,
        images: [] as CameraHistoryImage[],
        imagesByHour: new Map<number, CameraHistoryImage[]>(),
      }
    },
    actions: {
      load({
        terminalName,
        streamName,
        date,
      }: {
        terminalName: TerminalName
        streamName: string
        date: DateTime
      }) {
        this.loading = true
        this.images = []
        this.imagesByHour = new Map()
        api
          .getStreamHistoryCameraStreamsHistoryGet(
            terminalName,
            streamName,
            date.toISODate()
          )
          .then((resp) => {
            resp.data
              .map((img) => new CameraHistoryImage(img))
              .forEach((img) => {
                const existing = this.imagesByHour.get(img.hour_key)
                if (existing) {
                  existing.push(img)
                } else {
                  this.imagesByHour.set(img.hour_key, [img])
                }
              })
            for (const images of this.imagesByHour.values()) {
              images.sort((a, b) => a.observed.diff(b.observed).as('seconds'))
            }
            for (let hour = 0; hour < 24; hour++) {
              if (!this.imagesByHour.has(hour)) {
                this.imagesByHour.set(hour, [])
              }
            }
          })
          .finally(() => {
            this.loading = false
          })
      },
    },
  }
)
