<script lang="ts" setup>
import type { DateTime } from 'luxon'
import type { TerminalGateSchedule } from '~/models/gateSchedules'

const props = defineProps<{
  schedules: TerminalGateSchedule[]
  dates: DateTime[]
  now: DateTime
}>()
const shiftHeaders = computed(() => {
  const headers: string[] = []
  for (let i = 0; i < props.dates.length; i++) {
    headers.push('1st', '2nd', '3rd')
  }
  return headers
})
</script>

<template>
  <table stripe class="gate-schedules-table w-full">
    <thead>
      <!-- Date headers -->
      <tr class="dates-header">
        <th>Date</th>
        <th
          v-for="date in dates"
          :key="date.toISO()"
          colspan="3"
          class="even:bg-gray-50 odd:bg-white"
          :class="{ isCurrentDay: date.hasSame(now, 'day') }"
        >
          {{ date.toFormat('cccc M/dd') }}
          <ColoredBadge
            v-if="date.hasSame(now, 'day')"
            type="success"
            effect="dark"
            class="mb-1 ml-2"
          >
            Today
          </ColoredBadge>
        </th>
      </tr>
      <!-- Shift headers -->
      <tr class="shifts-header">
        <th>Shift</th>
        <th v-for="(header, index) in shiftHeaders" :key="index">
          {{ header }}
        </th>
      </tr>
    </thead>
    <tbody>
      <GateScheduleTr
        v-for="schedule in props.schedules"
        :key="schedule.terminal"
        :schedule="schedule"
        :dates="props.dates"
        :now="now"
      />
    </tbody>
  </table>
</template>

<style lang="scss">
.gate-schedules-table {
  tr.shifts-header {
    th {
      text-align: center;
      @apply text-sm text-gray-500 font-semibold;
      border: 1px solid #d3dce6;
    }
  }
  tr.dates-header {
    th {
      text-align: center;
      @apply text-sm text-gray-500 font-semibold py-2;
    }
  }
  table {
    border: 1px solid #d3dce6;
  }
  tr {
    padding: 0.5rem;
    text-align: center;
  }

  tr:nth-child(even) {
    @apply bg-gray-50;
  }
  tr:nth-child(odd) {
    background-color: #fff;
  }

  .isCurrentDay {
    @apply bg-gray-100;
  }
}
</style>
