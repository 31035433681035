<script lang="ts" setup>
import type { ContainerType } from '~/services/apiClient'
import { useContainerTypesStore } from '~/stores/containerTypes'

const props = withDefaults(
  defineProps<{
    modelValue: ContainerType | undefined
    clearable?: boolean
    disabled?: boolean
  }>(),
  { clearable: false, disabled: false }
)
const emit = defineEmits<{
  (e: 'update:modelValue', value: string | undefined): void
}>()
const selectedContainerType = useVModel(props, 'modelValue', emit)
const containerTypeStore = useContainerTypesStore()

onMounted(containerTypeStore.loadContainerTypes)
</script>

<template>
  <el-select
    v-model="selectedContainerType"
    :loading="containerTypeStore.loading"
    filterable
    :clearable="clearable"
    :disabled="disabled"
    size="large"
    style="min-width: 160px"
  >
    <el-option
      v-for="option in containerTypeStore.container_types"
      :key="option.type"
      :label="option.description"
      :value="option.type"
    />
  </el-select>
</template>
