import { DateTime } from 'luxon'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { useReturnRulesReadingsLatest } from './returnRulesReadingsLatest'
import type { SoketiEvent } from '~/compositions/configureSoketi'
import { useSoketi } from '#compositions/configureSoketi'
import useStartOfToday from '~/compositions/useStartOfToday'
import type { CachedTerminalReturnRulesInterface } from '~/models/returnRules'
import {
  ReturnRule,
  SpecificReturnRule,
  makeShiftKey,
} from '~/models/returnRules'
import type {
  ContainerType,
  EmptyReturnRulesCheckedEvent,
  EmptyReturnRulesReadingEvent,
  EmptyReturnRulesUpdatedEvent,
  ShippingLine,
  TerminalName,
  TerminalReadingRules,
} from '~/services/apiClient'
import {
  EmptyReturnRulesApi,
  EventName,
  Shift,
  WebsocketChannel,
} from '~/services/apiClient'

export interface CategoryKey {
  terminal: TerminalName
  shippingLine: ShippingLine
  containerType: ContainerType
}
export interface CategoryKeyWithTimestamps extends CategoryKey {
  lastChecked: DateTime
  lastChanged: DateTime
}
type ShiftLookup = Map<string, ReturnRule>

type RulesLookup = Map<
  TerminalName,
  Map<ShippingLine, Map<ContainerType, ShiftLookup>>
>
type ExpandedRulesLookup = Map<
  ShippingLine,
  Map<ContainerType, Map<TerminalName, SpecificReturnRule[]>>
>
export function useReturnRulesLatest(syncOnInitialization = true) {
  const useStore = defineStore('empty-return-rules-latest', () => {
    const loadingPromise = ref(undefined as Promise<void> | undefined)
    // Persistent store
    const rulesByTerminalCache = useLocalStorage<
      CachedTerminalReturnRulesInterface[]
    >('empty_return_rules_by_terminal', [], {
      serializer: {
        read: (
          value: string | undefined
        ): CachedTerminalReturnRulesInterface[] => {
          if (value) {
            try {
              const rules: CachedTerminalReturnRulesInterface[] =
                JSON.parse(value)
              // Filter out values without rules. This will fix things for any customers
              // who were stuck in a "bad" state, see DEV-52
              return rules.filter((rules) => rules.rules)
            } catch (error) {
              console.error(
                'Error parsing empty return rules from local storage:',
                error
              )
              return []
            }
          }
          return []
        },
        write: (value: CachedTerminalReturnRulesInterface[]): string => {
          return JSON.stringify(value)
        },
      },
    })
    // Data structures derived from `rules` computed property and maintained by the
    // `updateRefs` method + a watcher
    const rulesLookup = ref(new Map() as RulesLookup)
    const expandedRules = ref(new Map() as ExpandedRulesLookup)
    const categoryKeys = ref([] as CategoryKeyWithTimestamps[])
    const notClosedCategoryKeys = ref([] as CategoryKeyWithTimestamps[])
    const latestReturnRuleDate = ref(DateTime.now())
    const startOfToday = useStartOfToday()

    // Computed properties
    const datesWithRules = computed(() => {
      const dates = []
      const maxDate = DateTime.max(
        latestReturnRuleDate.value,
        // Ensure we display at least three days of rules. Relevant when the rules
        // used to compute latestReturnRuleDate were not date-based
        startOfToday.value.plus({ days: 3 })
      )
      let date = startOfToday.value
      while (date < maxDate) {
        dates.push(date)
        date = date.plus({ days: 1 })
      }
      return dates
    })
    const rules = computed((): ReturnRule[] => {
      const rulesByKey = new Map<string, ReturnRule>()
      for (const terminalRules of rulesByTerminalCache.value) {
        if (terminalRules.rules) {
          for (const rawRule of terminalRules.rules) {
            // Just ignore rules that don't have a mapped shipping line or container type
            if (!rawRule.parsed_shipping_line) {
              // console.warn(
              //   `Empty return rule has no parsed shipping line (raw shipping line: ${rawRule.shipping_line}):`,
              //   rawRule
              // )
              continue
            }
            if (!rawRule.parsed_container_type) {
              // console.warn(
              //   `Empty return rule has no parsed container type (raw containerType: ${rawRule.container_type}):`,
              //   rawRule
              // )
              continue
            }

            const rule = new ReturnRule(rawRule)
            rulesByKey.set(rule.key, rule)
          }
        }
      }
      return Array.from(rulesByKey.values())
    })
    const loading = computed(() => !!loadingPromise.value)

    // Watches
    watch(rules, updateRefs)

    // Functions
    function updateRules(terminalRuleSets: TerminalReadingRules[]) {
      // Take rules from cache, turn into a map
      const byTerminal = new Map<
        TerminalName,
        CachedTerminalReturnRulesInterface
      >()
      for (const rules of rulesByTerminalCache.value) {
        byTerminal.set(rules.terminal, rules)
      }
      // Add new rules to map, verifying that they are indeed newer
      for (const potentiallyCachedRules of terminalRuleSets) {
        // This would be the case if the API says "what you have in cache is good"
        // rules.cache_valid should be `true` for this case
        if (!potentiallyCachedRules.rules) {
          // Make sure we pull the latest "last observed" time so our rules don't show
          // as stale
          updateRulesLastChecked({
            terminal: potentiallyCachedRules.terminal,
            checked_time: DateTime.fromISO(
              potentiallyCachedRules.reading_last_observed
            ),
            group_first_observed: DateTime.fromISO(
              potentiallyCachedRules.reading_first_observed
            ),
          })
          continue
        }
        const rules =
          potentiallyCachedRules as CachedTerminalReturnRulesInterface
        const existingRules = byTerminal.get(rules.terminal)
        if (
          !existingRules ||
          existingRules.reading_first_observed < rules.reading_first_observed ||
          existingRules.reading_last_parsed < rules.reading_last_parsed
        ) {
          byTerminal.set(rules.terminal, rules)
        } else {
          updateRulesLastChecked({
            terminal: rules.terminal,
            checked_time: DateTime.fromISO(rules.reading_last_observed),
            group_first_observed: DateTime.fromISO(
              rules.reading_first_observed
            ),
          })
        }
      }
      // Set rules back in cache
      rulesByTerminalCache.value = Array.from(byTerminal.values())
    }

    function updateRulesLastChecked({
      terminal,
      checked_time,
      group_first_observed,
    }: {
      terminal: TerminalName
      checked_time: DateTime
      group_first_observed: DateTime
    }): void {
      for (const terminalRules of rulesByTerminalCache.value) {
        if (terminalRules.terminal !== terminal) continue
        if (!terminalRules.rules) continue
        const firstObserved = DateTime.fromISO(
          terminalRules.reading_first_observed
        )
        if (!firstObserved.equals(group_first_observed)) continue
        const isoCheckedTime = checked_time.toISO()
        terminalRules.reading_last_observed = isoCheckedTime
        for (const rule of terminalRules.rules) {
          rule.group_last_observed = isoCheckedTime
        }
      }
    }

    function updateRefs(rules: ReturnRule[]) {
      // Gather vars
      const lookup = new Map() as RulesLookup
      const ruleCategoryKeys = ref([] as CategoryKeyWithTimestamps[])
      const seenCategoryKeys = new Set<string>()
      const newNotClosedCategoryKeys = [] as CategoryKeyWithTimestamps[]
      const seenNotClosedCategoryKeys = new Set<string>()
      let maxDate = DateTime.local()
      // Iterate over rules
      for (const rule of rules) {
        // we don't care about rules before today
        if (
          rule.date &&
          rule.date.startOf('day') < DateTime.local().startOf('day')
        ) {
          continue
        }
        // Build lookup tree
        if (!lookup.has(rule.terminal)) {
          lookup.set(rule.terminal, new Map())
        }
        let byTerminal = lookup.get(rule.terminal)
        if (!byTerminal) {
          byTerminal = new Map()
          lookup.set(rule.terminal, byTerminal)
        }
        let byShippingLine = byTerminal.get(rule.shipping_line)
        if (!byShippingLine) {
          byShippingLine = new Map()
          byTerminal.set(rule.shipping_line, byShippingLine)
        }
        let byContainerType = byShippingLine.get(rule.container_type)
        if (!byContainerType) {
          byContainerType = new Map()
          byShippingLine.set(rule.container_type, byContainerType)
        }
        // Set rule
        byContainerType.set(rule.shift_key, rule)
        const category = {
          shippingLine: rule.shipping_line,
          containerType: rule.container_type,
          terminal: rule.terminal,
          lastChecked: rule.last_observed,
          lastChanged: rule.first_observed,
        }
        if (!seenCategoryKeys.has(rule.categoryKey)) {
          ruleCategoryKeys.value.push(category)
          seenCategoryKeys.add(rule.categoryKey)
        }
        if (!rule.closed && !seenNotClosedCategoryKeys.has(rule.categoryKey)) {
          newNotClosedCategoryKeys.push(category)
          seenNotClosedCategoryKeys.add(rule.categoryKey)
        }
        if (rule.date) {
          maxDate = DateTime.max(maxDate, rule.date)
        }
      }
      ruleCategoryKeys.value.sort((a, b) => {
        if (a.shippingLine < b.shippingLine) return -1
        if (a.shippingLine > b.shippingLine) return 1
        if (a.terminal < b.terminal) return -1
        if (a.terminal > b.terminal) return 1
        if (a.containerType < b.containerType) return -1
        if (a.containerType > b.containerType) return 1
        return 0
      })
      rulesLookup.value = lookup
      latestReturnRuleDate.value = maxDate
      expandedRules.value = expandRules(lookup)
      categoryKeys.value = ruleCategoryKeys.value
      notClosedCategoryKeys.value = newNotClosedCategoryKeys
    }
    /**
     * Expand rules into one rule per date+shift, per terminal/line/type
     * The way we receive rules they can be "global" or by date or by shift an addition
     * to for specific shifts, which is a bit difficult to work with
     * @param lookup The rules lookup
     * @returns The expanded rules lookup
     */
    function expandRules(lookup: RulesLookup): ExpandedRulesLookup {
      const expandedLookup = new Map() as ExpandedRulesLookup
      for (const [terminal, byShippingLine] of lookup.entries()) {
        for (const [
          shippingLine,
          byContainerType,
        ] of byShippingLine.entries()) {
          let expandedByContainerType = expandedLookup.get(shippingLine)
          if (!expandedByContainerType) {
            expandedByContainerType = new Map()
            expandedLookup.set(shippingLine, expandedByContainerType)
          }
          for (const containerType of byContainerType.keys()) {
            let expandedByTerminal = expandedByContainerType.get(containerType)
            if (!expandedByTerminal) {
              expandedByTerminal = new Map()
              expandedByContainerType.set(containerType, expandedByTerminal)
            }
            const terminalRules: SpecificReturnRule[] = []
            expandedByTerminal.set(terminal, terminalRules)
            for (const date of datesWithRules.value) {
              for (const shift of [Shift.First, Shift.Second]) {
                const rule = lookupRule(
                  shippingLine,
                  containerType,
                  terminal,
                  date,
                  shift
                )
                const specificRule = new SpecificReturnRule(
                  date,
                  shift,
                  terminal,
                  shippingLine,
                  containerType,
                  rule
                )
                terminalRules.push(specificRule)
              }
            }
          }
        }
      }
      return expandedLookup
    }

    function syncRules(): Promise<void> {
      const readings_in_cache = rulesByTerminalCache.value
        // Make extra sure that what we send to the server actually has rules
        .filter((rule) => !!rule.rules)
        .map((rule) => ({
          terminal: rule.terminal,
          reading_last_parsed: rule.reading_last_parsed,
          reading_first_observed: rule.reading_first_observed,
        }))
      const api = new EmptyReturnRulesApi()
      const promise = api
        .getLatestRulesIfChangedEmptyReturnRulesRulesLatestIfChangedPost({
          // Let the API know what we already have locally in cache
          readings_in_cache,
        })
        .then((resp) => {
          return updateRules(resp.data)
        })
        .finally(() => {
          loadingPromise.value = undefined
        })
      loadingPromise.value = promise
      return promise
    }

    function getRuleForShift(
      byShift: Map<string, ReturnRule>,
      date: DateTime,
      shift: Shift
    ): ReturnRule | null {
      // By date+shift
      let shiftKey = makeShiftKey(date, shift)
      let rule = byShift.get(shiftKey)
      if (rule) return rule
      // By no date or shift
      shiftKey = makeShiftKey(null, null)
      rule = byShift.get(shiftKey)
      if (rule) return rule
      // By shift
      shiftKey = makeShiftKey(null, shift)
      rule = byShift.get(shiftKey)
      if (rule) return rule
      // By date
      // Not sure this case happens right now, but leaving in case we have a terminal
      // provide rules by date without shift
      shiftKey = makeShiftKey(date, null)
      rule = byShift.get(shiftKey)
      return rule ?? null
    }

    function lookupRule(
      shippingLine: ShippingLine,
      containerType: ContainerType,
      terminal: TerminalName,
      date: DateTime,
      shift: Shift
    ): ReturnRule | null {
      const byShippingLine = rulesLookup.value.get(terminal)
      if (!byShippingLine) return null
      const byContainerType = byShippingLine.get(shippingLine)
      if (!byContainerType) return null
      const byShift = byContainerType.get(containerType)
      if (!byShift) return null
      return getRuleForShift(byShift, date, shift)
    }

    function lookupRulesForTerminal(
      terminal: TerminalName,
      date: DateTime,
      shift: Shift
    ): ReturnRule[] {
      const byShippingLine = rulesLookup.value.get(terminal)
      if (!byShippingLine) return []
      const rules: ReturnRule[] = []
      for (const byContainerType of byShippingLine.values()) {
        for (const byShift of byContainerType.values()) {
          const rule = getRuleForShift(byShift, date, shift)
          if (rule) rules.push(rule)
        }
      }
      return rules
    }
    const soketi = useSoketi()
    if (syncOnInitialization) syncRules()
    function soketiEventToAPIFormat(
      event: EmptyReturnRulesUpdatedEvent
    ): TerminalReadingRules {
      return {
        terminal: event.terminal,
        reading_first_observed: event.reading_first_observed,
        reading_last_parsed: event.reading_last_parsed,
        // Kinda a hack, should probably add a reading_last_observed to the event format
        reading_last_observed: event.rules[0].group_last_observed,
        rules: event.rules,
        // Doesn't really apply here, we know this is not a cached value
        cache_valid: false,
      }
    }
    soketi.bindOnReconnect(syncRules)
    function rulesUpdatedEventHandler(event: SoketiEvent) {
      const update_event = event as EmptyReturnRulesUpdatedEvent
      updateRules([soketiEventToAPIFormat(update_event)])
    }
    soketi.bind(
      WebsocketChannel.PrivateEmptyReturnRules,
      EventName.EmptyReturnRulesUpdated,
      rulesUpdatedEventHandler
    )
    function rulesCheckedEventHandler(event: SoketiEvent) {
      const check_event = event as EmptyReturnRulesCheckedEvent
      const returnRulesReadingsLatestStore = useReturnRulesReadingsLatest()
      updateRulesLastChecked({
        checked_time: DateTime.fromISO(check_event.check_time),
        group_first_observed: DateTime.fromISO(check_event.first_observed),
        terminal: event.terminal,
      })
      // update the reading
      returnRulesReadingsLatestStore.updateObservedTimesFromSoketi(check_event)
    }

    soketi.bind(
      WebsocketChannel.PrivateEmptyReturnRules,
      EventName.EmptyReturnRulesReadingUpdated,
      newReadingHandler
    )
    function newReadingHandler(event: SoketiEvent) {
      const readingEvent = event as EmptyReturnRulesReadingEvent
      const reading = readingEvent.reading
      const returnRulesReadingsLatestStore = useReturnRulesReadingsLatest()
      returnRulesReadingsLatestStore.setReading(reading)
    }

    soketi.bind(
      WebsocketChannel.PrivateEmptyReturnRuleChecks,
      EventName.EmptyReturnRulesChecked,
      rulesCheckedEventHandler
    )
    const forTesting = {
      rules,
      rulesLookup,
      updateRules,
      updateRulesLastChecked,
      syncRules,
      rulesByTerminalCache,
    }
    return {
      loading,
      loadingPromise,
      datesWithRules,
      categoryKeys,
      notClosedCategoryKeys,
      expandedRules,
      lookupRule,
      lookupRulesForTerminal,
      // "Internal" methods and data structures are exported for testing purposes ONLY
      forTesting,
    }
  })
  if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useStore, import.meta.hot))
  }
  return useStore()
}
