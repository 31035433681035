<script lang="ts" setup>
import type { Container } from '~/models/containers'
const props = defineProps<{
  container: Container
}>()
</script>

<template>
  <AutoBookButton
    v-if="props.container.auto_book_request_status"
    :containers="[container]"
    class="inline cursor-pointer"
  >
    <AutoBookIcon
      :auto-book-request-status="container.auto_book_request_status"
      class="align-text-bottom"
    />
  </AutoBookButton>
</template>
