<script lang="ts" setup>
import { Icon } from '@iconify/vue'
import startCase from 'lodash-es/startCase'
import type { Appointment } from '~/models/appointments'
import type { Container } from '~/models/containers'

const props = defineProps<{
  container: Container
}>()
const container = toRef(props, 'container')
function getStateDescription(container: Container): string {
  if (container.is_in_booked_state) {
    if (container.latest_appointment?.status === 'scheduled') {
      return 'Appointed'
    }
    return startCase(container.latest_appointment?.status || 'Appointed')
  } else {
    return container.stateDescription
  }
}
const stateDescription = computed(() => getStateDescription(container.value))
function appointmentTooltip(appointment: Appointment | undefined): string {
  if (!appointment) return ''
  if (appointment.requested_by) {
    return (
      `Booked on Dray Dog by ${appointment.requested_by.first_name} ` +
      `${appointment.requested_by.last_name}`
    )
  } else {
    return `Booked on ${appointment.portal_name} (off Dray Dog) by unknown user`
  }
}
</script>

<template>
  <span
    v-tooltip="{
      disabled: !container.booked_appointment,
      content: appointmentTooltip(container.latest_appointment!),
    }"
  >
    {{ stateDescription }}
    <Icon
      v-if="container.status"
      :icon="container.status.icon"
      class="align-text-bottom mb-1px"
    />
  </span>
</template>
