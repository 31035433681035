import { DateTime } from 'luxon'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { AppointmentsApi } from '~/services/apiClient'
import { useUserStore } from '~/stores/user'

export const useAppointmentsRefreshRequestsStore = defineStore(
  'appointments-refresh-requests',
  () => {
    const lastRefresh = useLocalStorage<DateTime | null>(
      'last-appointments-refresh',
      null,
      {
        serializer: {
          read: (value: string): DateTime | null => {
            if (value) {
              return DateTime.fromISO(value)
            }
            return null
          },

          write: (value: DateTime | null): string => {
            if (value) {
              return value.toISO() || ''
            }
            return ''
          },
        },
      }
    )

    function triggerRefresh() {
      const userStore = useUserStore()
      if (!userStore.isUasOrg) {
        // only refresh appointments for UAS orgs
        return
      }
      // if the last refresh was within the last 20 minutes, don't refresh
      if (
        lastRefresh.value &&
        lastRefresh.value.diffNow('minutes').minutes < 20
      ) {
        return
      }
      // update last refresh time
      lastRefresh.value = DateTime.now()
      const api = new AppointmentsApi()
      api.refreshAppointmentsAppointmentsRefreshPost().catch(
        // catch 429 response codes
        (error) => {
          if (error.response?.status === 429) {
            console.warn('Too many requests refreshing appointments')
          }
        }
      )
    }

    if (import.meta.hot) {
      import.meta.hot.accept(
        acceptHMRUpdate(useAppointmentsRefreshRequestsStore, import.meta.hot)
      )
    }

    return {
      triggerRefresh,
    }
  }
)
