<template>
  <router-link class="text-md" :to="{ name: 'cameras-home' }">
    <slot>
      <el-button type="primary" v-bind="$attrs">
        <slot name="default">
          <i-eva:arrow-ios-back-fill class="align-bottom -ml-2 mr-1" />
          <i-healthicons:ui-menu-grid class="align-bottom mr-1 mh-2" />
          All Terminals
        </slot>
      </el-button>
    </slot>
  </router-link>
</template>
