<script lang="ts" setup>
import { useGetCapacitySetLabel } from './helpers'
import type { CapacitySet } from '~/models/capacitySets'
import { useCapacitiesStore } from '~/stores/capacities'

const props = defineProps({
  capacitySet: { type: Object as PropType<CapacitySet>, required: true },
})
const getCapacitySetLabel = useGetCapacitySetLabel()

const capacitiesStore = useCapacitiesStore()
const isActive = computed(() => {
  return (
    capacitiesStore.activeCapacitySet?.customerID ===
      props.capacitySet.customerID &&
    capacitiesStore.activeCapacitySet?.containerTag ===
      props.capacitySet.containerTag
  )
})
const label = computed(() => {
  return getCapacitySetLabel(props.capacitySet)
})
</script>

<template>
  <div class="mx-1 inline-block">
    <div class="text-xs text-gray-500 text-left mb-2px h-16px">
      <template v-if="isActive"> Viewing: </template>
    </div>
    <el-button
      v-tooltip="`View only ${label} capacity`"
      :plain="!isActive"
      :type="isActive ? 'primary' : 'default'"
      @click="capacitiesStore.setActiveCapacitySet(capacitySet)"
    >
      {{ label }} Capacity
    </el-button>
  </div>
</template>
