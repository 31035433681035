<script setup lang="ts">
import useTruckDescription from '~/compositions/useTruckDescription'

const props = defineProps<{
  licensePlate: string | undefined
}>()

let truckDescription = toRef(props.licensePlate)
if (props.licensePlate) {
  const licensePlate = toRef(props.licensePlate)
  const truckId = ref(undefined)
  truckDescription = useTruckDescription(truckId, licensePlate)
}
</script>

<template>
  <el-form-item label="Existing License plate:">
    {{ truckDescription || 'No Plate' }}
  </el-form-item>
</template>
