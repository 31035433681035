import { faker } from '@faker-js/faker/locale/en'
import type { SavedCustomer as GetCustomers } from '~/services/apiClient/api'

export default class Customer {
  id: number
  name: string

  constructor(raw: GetCustomers, demoMode: boolean) {
    if (demoMode) {
      this.name = faker.company.name()
    } else {
      this.name = raw.name
    }
    this.id = raw.id
  }
}
