import type { MaybeRef } from '@vueuse/core'
import type { DateTime } from 'luxon'
import type { Ref } from 'vue'

export function useLuxonTimeAgo(
  dt: MaybeRef<DateTime | null>
): Ref<string | undefined> {
  const time = ref(dt)
  const description = ref()
  const millisInSecond = 1000
  const updateDescIntervalTimer: Ref<NodeJS.Timeout | null> = ref(null)
  function clearTimer() {
    if (updateDescIntervalTimer.value) {
      clearInterval(updateDescIntervalTimer.value)
    }
  }

  onUnmounted(clearTimer)
  watch(
    time,
    (newTime) => {
      if (newTime) {
        description.value = newTime.toRelative()
        updateCheckFrequency(newTime)
      } else if (updateDescIntervalTimer.value) {
        clearTimeout(updateDescIntervalTimer.value)
      }
    },
    { immediate: true }
  )
  function updateCheckFrequency(time: DateTime) {
    const secondsAgo = -time.diffNow().as('seconds')
    clearTimer()
    let checkFrequency = millisInSecond
    if (secondsAgo > 60 * 60) {
      // Over one hour
      checkFrequency = 5 * 60 * millisInSecond
    } else if (secondsAgo > 60) {
      // Over one minute
      checkFrequency = 30 * millisInSecond
    }
    updateDescIntervalTimer.value = setInterval(() => {
      description.value = time.toRelative()
    }, checkFrequency)
  }
  return description
}
