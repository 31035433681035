import type { LinkedAppointment } from '~/services/apiClient'

export function useMakeDualTooltip() {
  function makeDualTooltip(linkedAppointments: LinkedAppointment[]): string {
    return (
      'As part of a dual with ' +
      linkedAppointments
        .map((linkedAppointment) => {
          return linkedAppointment.container_number
        })
        .join(' / ')
    )
  }
  return { makeDualTooltip }
}
