<script lang="ts" setup>
import type { GETEmptyReturnRule } from '~/services/apiClient'
import { ReceivingRule } from '~/services/apiClient'

const props = defineProps({
  rule: {
    type: Object as PropType<GETEmptyReturnRule>,
    required: true,
  },
})

const ruleDesc = computed(() => {
  switch (props.rule.rule) {
    case ReceivingRule.Closed:
      return 'Closed'
    case ReceivingRule.Open:
      return 'Open'
    case ReceivingRule.DualsOnly:
      return 'Duals'
    case ReceivingRule.Exempt:
      return 'Exempt'
  }
})
const textColorClass = computed((): string => {
  switch (props.rule.rule) {
    case ReceivingRule.Closed:
      return 'bg-red-500'
    case ReceivingRule.Open:
      return 'bg-green-500'
    case ReceivingRule.DualsOnly:
      return 'bg-yellow-500'
    case ReceivingRule.Exempt:
      return 'bg-blue-500'
  }
})
</script>

<template>
  <div
    :class="textColorClass"
    class="bg-opacity-50 receiving-rule-cell text-gray-700"
  >
    {{ ruleDesc }}
  </div>
</template>

<style lang="scss" scoped>
.receiving-rule-cell {
  padding: 0.2rem 0.1rem;
}
th {
  @apply bg-light-700;
}
</style>
