import { ContainerWatch } from './containerWatch'
import { Appointment } from '~/models/appointments'
import type { GETAppointmentWithContainer } from '~/services/apiClient'

export class AppointmentWithContainerInfo {
  appointment: Appointment
  container_number: string
  watch: ContainerWatch

  constructor(appointment: GETAppointmentWithContainer, demoMode: boolean) {
    this.appointment = new Appointment(appointment.appointment, demoMode)
    this.container_number = appointment.appointment.container_number
    this.watch = new ContainerWatch(appointment.watch, demoMode)
  }
}
