<script lang="ts" setup>
import type { Container } from '~/models/containers'

const props = defineProps<{
  container: Container
}>()

const appointmentType = computed(() => {
  if (
    props.container.is_in_booked_state &&
    props.container.latest_appointment?.linked_appointments?.length
  ) {
    return 'dual'
  }
  if (props.container.is_in_import_booked_state) {
    return 'import_out'
  }
  if (props.container.is_in_empty_booked_state) {
    return 'empty_in'
  }
  return null
})

const tooltipText = computed(() => {
  switch (appointmentType.value) {
    case 'import_out':
      return 'Load out'
    case 'empty_in':
      return 'Empty in'
    case 'dual':
      return 'Dual transaction'
    default:
      return ''
  }
})
</script>

<template>
  <span v-tooltip="tooltipText" class="text-md">
    <DualsIcon v-if="appointmentType === 'dual'" class="icon" />
    <EmptyContainerIcon
      v-else-if="appointmentType === 'empty_in'"
      class="icon"
    />
    <LoadedContainerIcon
      v-else-if="appointmentType === 'import_out'"
      class="icon"
    />
  </span>
</template>

<style scoped>
.icon {
  @apply align-middle text-blue-gray-900 mb-2px;
}
</style>
