import { defineStore } from 'pinia'
import { DateTime } from 'luxon'
import { useGateScheduleReadingsHistory } from './gateScheduleReadingHistory'
import { ReturnRulesReading } from '~/models/returnRules'
import {
  type GETGateScheduleReading,
  type GateScheduleCheckedEvent,
  GateSchedulesApi,
  type TerminalName,
} from '~/services/apiClient'
import type { GateScheduleReading } from '~/models/gateSchedules'

export const useGateScheduleReadingsLatest = defineStore(
  'gate-schedules-readings-latest',
  {
    state: () => {
      return {
        readings_by_terminal: new Map() as Map<
          TerminalName,
          GateScheduleReading
        >,
        loading: false,
      }
    },
    actions: {
      load() {
        this.readings_by_terminal = new Map()
        this.loading = true
        const api = new GateSchedulesApi()
        return api
          .getLatestReadingsGateSchedulesReadingsLatestGet()
          .then((resp) =>
            resp.data.forEach((reading) => this.setReading(reading))
          )
          .finally(() => {
            this.loading = false
          })
      },

      setReading(reading: GETGateScheduleReading) {
        const mappedReading = new ReturnRulesReading(reading)
        this.readings_by_terminal.set(mappedReading.terminal, mappedReading)
        const gateScheduleReadingsHistoryStore =
          useGateScheduleReadingsHistory()
        if (
          gateScheduleReadingsHistoryStore.readings.length > 0 &&
          mappedReading.terminal ===
            gateScheduleReadingsHistoryStore.readings[0].terminal &&
          mappedReading.first_observed >
            gateScheduleReadingsHistoryStore.readings[0].first_observed
        ) {
          gateScheduleReadingsHistoryStore.readings.unshift(mappedReading)
        }
      },
      updateObservedTimesFromSoketi(checkEvent: GateScheduleCheckedEvent) {
        const gateScheduleReadingsHistoryStore =
          useGateScheduleReadingsHistory()
        const lastObserved = DateTime.fromISO(checkEvent.check_time)
        const firstObserved = DateTime.fromISO(checkEvent.first_observed)
        const existingReading = this.readings_by_terminal.get(
          checkEvent.terminal
        )
        if (existingReading) {
          existingReading.last_observed = lastObserved
        }
        if (
          gateScheduleReadingsHistoryStore.readings.length > 0 &&
          checkEvent.terminal ===
            gateScheduleReadingsHistoryStore.readings[0].terminal &&
          firstObserved.equals(
            gateScheduleReadingsHistoryStore.readings[0].first_observed
          )
        ) {
          gateScheduleReadingsHistoryStore.readings[0].last_observed =
            lastObserved
        }
      },
    },
  }
)
