<script setup lang="ts">
import { ElTag } from 'element-plus'
import { AppointmentStatus } from '~/services/apiClient'

const props = defineProps<{
  status: AppointmentStatus
}>()

const statusType = computed(
  (): 'primary' | 'success' | 'warning' | 'info' | 'danger' => {
    switch (props.status) {
      case AppointmentStatus.Scheduled:
        return 'primary'
      case AppointmentStatus.InProgress:
        return 'warning'
      case AppointmentStatus.Completed:
        return 'success'
      case AppointmentStatus.Cancelled:
        return 'info'
      case AppointmentStatus.Missed:
      case AppointmentStatus.SchedulingError:
      case AppointmentStatus.Aborted:
        return 'danger'
    }
  }
)
const statusText = computed(() => {
  switch (props.status) {
    case AppointmentStatus.Scheduled:
      return 'Scheduled'
    case AppointmentStatus.InProgress:
      return 'In Progress'
    case AppointmentStatus.Completed:
      return 'Completed'
    case AppointmentStatus.Cancelled:
      return 'Cancelled'
    case AppointmentStatus.Missed:
      return 'Missed'
    case AppointmentStatus.SchedulingError:
      return 'Error'
    case AppointmentStatus.Aborted:
      return 'Aborted'
  }
})
</script>

<template>
  <ElTag
    :type="statusType"
    round
    disable-transitions
    effect="plain"
    :class="{ scheduled: props.status === AppointmentStatus.Scheduled }"
  >
    {{ statusText }}
  </ElTag>
</template>

<style scoped lang="scss">
$background-color: #fefcff;
$text-color: #d38af2;

.scheduled {
  background-color: $background-color !important;
  color: $text-color;
  border-color: $text-color;
}
</style>
