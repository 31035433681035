<script setup lang="ts">
import CamerasGrid from '~/components/CamerasGrid.vue'

useHead({
  title: 'Dray Dog - LA/LB Cameras',
})
</script>

<template>
  <div>
    <CamerasGrid />
  </div>
</template>

<route lang="yaml">
meta:
  layout: no_sidebar
name: cameras-home
</route>
