<script lang="ts" setup>
import { UserRoleType } from '~/services/apiClient'
import { useUsersStore } from '~/stores/users'
const usersStore = useUsersStore()

onMounted(usersStore.load)
const inviteSubCarriersDialogOpen = ref(false)
</script>

<template>
  <el-button
    type="primary"
    class="mb-2"
    @click="inviteSubCarriersDialogOpen = true"
  >
    Invite Sub-Carriers
  </el-button>
  <UsersTable
    v-loading="usersStore.loading"
    :users="usersStore.subCarriers"
    empty-text="No sub-carriers have been invited"
  />
  <InviteUsersDialog
    v-model="inviteSubCarriersDialogOpen"
    user-label="Sub-Carrier"
    :role="UserRoleType.SubCarrier"
    :extra-messages="[
      'Sub-carriers can book/cancel/reschedule/auto book containers that you have ' +
        'already added to Dray Dog. They may not add containers or view containers ' +
        'except by explicit container search or if the containers are assigned to them',
    ]"
  />
</template>
