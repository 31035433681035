<script lang="ts" setup>
import LFDDisplay from './LFDDisplay.vue'
import type { Container } from '~/models/containers'

const props = defineProps<{
  container: Container
}>()
const last_free_day = computed(() => props.container.last_free_day || null)
const lfdDesc = computed(() => {
  if (props.container.lfdInfo) {
    return props.container.lfdInfo
  } else {
    return undefined
  }
})
</script>

<template>
  <LFDDisplay
    :last-free-date="last_free_day"
    :info="lfdDesc"
    :unique-date-count="props.container.uniqueDateCount"
  />
</template>
