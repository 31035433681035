import type { WithCounts } from './types'

export function appointmentCountDescription(group: WithCounts): string {
  const appointmentCount = group.numTransactions
  let singleEmptiesMessage = ''
  if (group.numSingleEmptyAppointments) {
    let emptiesNoun = 'empties'
    if (group.numSingleEmptyAppointments === 1) {
      emptiesNoun = 'empty'
    }
    singleEmptiesMessage = `(${group.numSingleEmptyAppointments} ${emptiesNoun})`
  }
  let verb = 'are'
  let noun = 'appointments'
  let adjective = 'total'
  if (appointmentCount === 1) {
    verb = 'is'
    noun = 'appointment'
    adjective = ''
  }
  return `There ${verb} ${appointmentCount} ${adjective} ${noun} ${singleEmptiesMessage}`
}
