<script lang="ts" setup>
import type { Container } from '~/models/containers'
import { formatDTMilitary } from '~/utils'
const props = defineProps<{
  container: Container
}>()
const container = toRef(props, 'container')
</script>

<template>
  <span class="text-sm">
    <!-- Load out gated -->
    <span
      v-if="
        (container.is_in_out_gated_state ||
          container.is_in_empty_returned_state) &&
        container.import_out_gated_time
      "
      v-tooltip="`(L)oad out-gate time`"
    >
      L: {{ formatDTMilitary(container.import_out_gated_time) }}
      <template
        v-if="
          container.out_gated_days !== null &&
          !container.is_in_empty_returned_state
        "
      >
        •
        <OutGatedDaysDesc
          v-tooltip="`Out-gated ${container.out_gated_days} days ago`"
          :num-days-out-gated="container.out_gated_days"
        />
        ago
      </template>
      <span
        v-if="
          container.is_in_empty_returned_state && container.empty_in_gated_time
        "
      >
        <i-mdi:arrow-right class="align-text-bottom mr-1" />
      </span>
    </span>
    <!-- Empty in-gated (returned) -->
    <span
      v-if="
        container.is_in_empty_returned_state && container.empty_in_gated_time
      "
    >
      <span v-tooltip="`(E)mpty in-gate time`">
        E: {{ formatDTMilitary(container.empty_in_gated_time) }}
      </span>
      <template v-if="props.container.out_gated_days !== null">
        <OutGatedDaysDesc
          v-tooltip="
            `Container was out-gated for ${container.out_gated_days} days`
          "
          :num-days-out-gated="props.container.out_gated_days"
          class="ml-1"
        />
      </template>
    </span>

    <AppointmentTimeDisplay
      v-if="container.booked_appointment"
      :appointment="container.booked_appointment"
    />
    <template v-if="container.booked_appointment">
      • #<ClickToCopy
        :text-to-copy="container.booked_appointment.display_terminal_reference"
      />
    </template>
  </span>
</template>
