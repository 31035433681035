<script lang="ts" setup>
import type { PropType } from 'vue'
import type { Container } from '~/models/containers'
import { truncate } from '~/utils'

const props = defineProps({
  container: { type: Object as PropType<Container>, required: true },
  alwaysShowIcon: { type: Boolean, default: false },
})
const spanRef = ref()
const hover = useElementHover(spanRef)
const editing = ref(false)
const truncatedCustomerName = computed(() => {
  if (!props.container.customer_name) return undefined
  return truncate(props.container.customer_name, 20)
})
const tooltip = computed(() => {
  if (props.container.customer_id) {
    return `Customer is ${props.container.customer_name}. Click to edit`
  } else {
    return `Click to add customer`
  }
})
</script>

<template>
  <span
    ref="spanRef"
    v-tooltip="tooltip"
    class="cursor-pointer hover:border hover:rounded-3px hover:border-dashed hover:border-gray-700"
    @click="editing = true"
  >
    <span
      v-bind="$attrs"
      :class="{
        'text-gray-400': props.container.assigned_users.length === 0,
      }"
    >
      {{ truncatedCustomerName }}
      <i-mdi:pencil
        v-if="alwaysShowIcon || hover"
        class="align-text-bottom mb-1px"
        style="font-size: 0.9em"
      />
    </span>
  </span>
  <EditAssignedCustomerDialog
    v-if="editing"
    v-model="editing"
    :containers="[props.container]"
  />
</template>
