import type { DateTime } from 'luxon'
import type { AppointmentStatus } from '~/services/apiClient'

export class BucketFilter {
  bucket_start: DateTime
  bucket_end: DateTime
  status: AppointmentStatus
  x_index: number

  constructor(
    bucket_start: DateTime,
    bucket_end: DateTime,
    status: AppointmentStatus,
    x_index: number
  ) {
    this.status = status
    this.bucket_start = bucket_start
    this.bucket_end = bucket_end
    this.x_index = x_index
  }
}
