// Summer is the Bao Bun of my life 🥟
import { ElNotification } from 'element-plus'
import { DateTime } from 'luxon'
import { defineStore } from 'pinia'
import { useUserStore } from './user'
import type { CapacitySetIdentifiers, DayOfWeek } from '~/models/capacitySets'
import {
  CapacityBucket,
  CapacitySet,
  makeCapacitySetFromAPI,
} from '~/models/capacitySets'
import type { GETCapacitySet } from '~/services/apiClient'
import { AppointmentCapacitiesApi } from '~/services/apiClient'

export const useCapacitiesStore = defineStore('capacities', () => {
  // Other stores

  const userStore = useUserStore()
  // Refs
  const fleetSet = ref(null as null | CapacitySet)
  const customerSets = ref(new Map() as Map<number, CapacitySet>)
  const tagSets = ref(new Map() as Map<string, CapacitySet>)
  const allSets = ref(new Map() as Map<string, CapacitySet>)
  const activeCapacitySetKey = ref(null as null | string)
  const activeCapacitySetSavePointJSON = ref(null as null | string)
  const saving = ref(false)
  const loading = ref(false)
  const loadedFromAPI = ref(false)

  const activeCapacitySet = computed((): CapacitySet | null => {
    if (activeCapacitySetKey.value) {
      return allSets.value.get(activeCapacitySetKey.value) ?? null
    }
    return null
  })

  // Functions
  function loadBucketsIfNotLoaded() {
    // No Need to load if we've loaded in this same session. Websockets will keep
    // us up to date. Also, these are unlikely to change often
    if (loadedFromAPI.value) return Promise.resolve()
    loading.value = true
    const api = new AppointmentCapacitiesApi()
    return api
      .getAppointmentCapacitySetsAppointmentsCapacitiesSetsGet()
      .then((resp) => {
        setCapacitySetsFromAPI(resp.data.capacity_sets)
      })
      .finally(() => {
        loading.value = false
      })
  }
  function setCapacitySetsFromAPI(capacitySets: GETCapacitySet[]) {
    for (const apiCapacitySet of capacitySets) {
      // Deletion case (not sure how or why this is used)
      if (apiCapacitySet.buckets.length === 0) {
        if (apiCapacitySet.customer) {
          customerSets.value.delete(apiCapacitySet.customer.id)
        } else if (apiCapacitySet.container_tag) {
          tagSets.value.delete(apiCapacitySet.container_tag)
        } else {
          fleetSet.value = null
        }
        continue
      }
      // Update set case
      const set = makeCapacitySetFromAPI(apiCapacitySet, userStore.demo_mode)
      allSets.value.set(set.key, set)
      setCapacitySet(set)
    }
    if (
      customerSets.value.size > 0 ||
      tagSets.value.size > 0 ||
      fleetSet.value
    ) {
      selectDefaultCapacitySet()
      markCurrentBucketsAsSaved()
    }
    loadedFromAPI.value = true
  }
  function setCapacitySet(set: CapacitySet) {
    allSets.value.set(set.key, set)
    if (set.customerID) {
      customerSets.value.set(set.customerID, set)
    } else if (set.containerTag) {
      tagSets.value.set(set.containerTag, set)
    } else {
      fleetSet.value = set
    }
  }
  function selectDefaultCapacitySet() {
    if (!activeCapacitySet.value) {
      const globalCapacity = fleetSet.value
      if (globalCapacity) {
        setActiveCapacitySet(globalCapacity)
      } else if (customerSets.value.size > 0) {
        // select the first one
        const firstCustomerSet = customerSets.value.values().next().value!
        setActiveCapacitySet(firstCustomerSet)
      }
    }
  }
  function setActiveCapacitySet(capacitySet: CapacitySet) {
    if (allSets.value.has(capacitySet.key)) {
      activeCapacitySetKey.value = capacitySet.key
    } else {
      throw new Error(`Capacity set ${capacitySet.key} not found`)
    }
    markCurrentBucketsAsSaved()
  }
  function markCurrentBucketsAsSaved() {
    if (!activeCapacitySet.value) {
      console.warn('No active capacity set')
      return
    }
    activeCapacitySetSavePointJSON.value = JSON.stringify(
      activeCapacitySet.value
    )
  }
  function saveCapacitySet(capacitySet: CapacitySet) {
    const api = new AppointmentCapacitiesApi()
    saving.value = true
    const APIFormattedBuckets = capacitySet.buckets.map((b) => {
      return {
        day_of_week: b.dayOfWeek,
        hour_of_day: b.hourOfDay,
        max_appointments: b.maxAppointments,
      }
    })
    return api
      .saveAppointmentCapacitiesAppointmentsCapacitiesPost({
        timezone: DateTime.now().toFormat('z'),
        buckets: APIFormattedBuckets,
        customer_id: capacitySet.customerID || undefined,
        container_tag: capacitySet.containerTag || undefined,
        independent_capacity: capacitySet.dedicated,
      })
      .then(() => {
        ElNotification.success({ title: 'Capacities saved' })
      })
      .finally(() => {
        saving.value = false
      })
  }
  function deleteCapacitySet(set: CapacitySetIdentifiers) {
    saving.value = true
    const api = new AppointmentCapacitiesApi()
    return api
      .deleteCapacitySetAppointmentsCapacitiesSetDelete({
        customer_id: set.customer?.id || undefined,
        container_tag: set.containerTag || undefined,
      })
      .then(() => {
        ElNotification.success({ title: 'Capacities Updated' })
        if (set.customer) {
          customerSets.value.delete(set.customer.id)
        } else if (set.containerTag) {
          tagSets.value.delete(set.containerTag)
        } else {
          fleetSet.value = null
        }
        activeCapacitySetKey.value = null
      })
      .finally(() => {
        selectDefaultCapacitySet()
        markCurrentBucketsAsSaved()
        saving.value = false
      })
  }

  function activateCapacityManagement() {
    regenerateBuckets(120, { customer: null, containerTag: null }, false)
  }
  function getBucketsForDay(day: DayOfWeek) {
    // Acts on the active capacity set
    const capacitySet = activeCapacitySet.value
    if (!capacitySet) throw new Error(`No active capacity set to duplicate`)
    return capacitySet.buckets.filter(
      (bucket) => bucket.dayOfWeekDescription === day
    )
  }
  function duplicateBuckets(sourceDay: DayOfWeek, targetDayNames: DayOfWeek[]) {
    // Acts on the active capacity set
    const targetDayNamesSet = new Set(targetDayNames)
    if (targetDayNamesSet.has(sourceDay))
      throw new Error(`Cannot duplicate to self`)
    const sourceDayBuckets = getBucketsForDay(sourceDay)
    for (const targetDay of targetDayNames) {
      const targetBuckets = getBucketsForDay(targetDay)
      for (const targetBucket of targetBuckets) {
        const dayOfWeek = targetBucket.dayOfWeekDescription
        if (targetDayNamesSet.has(dayOfWeek)) {
          // This is a target day
          const sourceBucket = sourceDayBuckets.find((sourceBucket) => {
            const match = sourceBucket.hourOfDay === targetBucket.hourOfDay
            return match
          })
          if (sourceBucket === undefined) {
            throw new Error(
              `No source bucket with hour of day ${targetBucket.hourOfDay} not found in` +
                ` ${sourceDayBuckets.length} source buckets. This should never happen`
            )
          }
          targetBucket.maxAppointments = sourceBucket.maxAppointments
        }
      }
    }
  }
  function regenerateBuckets(
    bucketMinutes: number,
    setIdentifiers: CapacitySetIdentifiers,
    dedicated: boolean
  ) {
    const buckets = generateEmpty(
      bucketMinutes,
      setIdentifiers,
      dedicated,
      userStore.demo_mode
    )
    const capacitySet = new CapacitySet(
      buckets,
      setIdentifiers.customer,
      setIdentifiers.containerTag,
      dedicated
    )
    setCapacitySet(capacitySet)
    setActiveCapacitySet(capacitySet)
  }
  // Computed properties
  const activeCapacitySetModified = computed(() => {
    if (!activeCapacitySet.value) {
      return false
    } else if (activeCapacitySetSavePointJSON.value === null) {
      return false
    } else {
      return (
        JSON.stringify(activeCapacitySet.value) !==
        activeCapacitySetSavePointJSON.value
      )
    }
  })
  function getCapacitySets(customerID: number | null, containerTags: string[]) {
    const capacitySets = []
    let customerCapacitySet
    if (customerID) {
      customerCapacitySet = customerSets.value.get(customerID)
      if (customerCapacitySet) {
        capacitySets.push(customerCapacitySet)
      }
    }
    if (
      fleetSet.value &&
      // If we have a dedicated customer set, fleet capacity does not apply
      !(customerCapacitySet && customerCapacitySet.dedicated)
    ) {
      capacitySets.push(fleetSet.value)
    }
    for (const containerTag of containerTags) {
      const tagCapacitySet = tagSets.value.get(containerTag)
      if (tagCapacitySet) {
        capacitySets.push(tagCapacitySet)
      }
    }
    return capacitySets
  }
  function getBucketsForTime(
    time: DateTime,
    customerID: number | null,
    containerTags: string[]
  ): CapacityBucket[] {
    const capacitySets = getCapacitySets(customerID, containerTags)
    return capacitySets.map((capacitySet) => capacitySet.getBucket(time))
  }

  const setup = computed((): boolean => {
    return !!(
      fleetSet.value ||
      customerSets.value.size > 0 ||
      (tagSets.value.size > 0 && !loading.value)
    )
  })
  const dedicatedCapacitySetIds = computed(() => {
    const ids: number[] = []
    for (const capacitySet of customerSets.value.values()) {
      if (capacitySet.dedicated) {
        if (capacitySet.customerID === null) {
          throw new Error('Global capacity set cannot be dedicated')
        }
        ids.push(capacitySet.customerID)
      }
    }
    return ids
  })
  const dedicatedCapacitySetTags = computed(() => {
    const tags: string[] = []
    for (const capacitySet of tagSets.value.values()) {
      if (capacitySet.dedicated) {
        if (capacitySet.containerTag === null) {
          throw new Error('Global capacity set cannot be dedicated')
        }
        tags.push(capacitySet.containerTag)
      }
    }
    return tags
  })
  return {
    // Refs
    fleetSet,
    customerSets,
    tagSets,
    loading,
    activeCapacitySet,
    saving,
    // Functions
    regenerateBuckets,
    duplicateBuckets,
    loadBucketsIfNotLoaded,
    saveCapacitySet,
    deleteCapacitySet,
    activateCapacityManagement,
    setCapacitySetsFromAPI,
    setActiveCapacitySet,
    getBucketsForTime,
    getBucketsForDay,
    // Computed properties
    activeCapacitySetModified,
    setup,
    dedicatedCapacitySetIds,
    dedicatedCapacitySetTags,
  }
})

function generateEmpty(
  bucketMinutes: number,
  setIdentifiers: CapacitySetIdentifiers,
  dedicated: boolean,
  demoMode: boolean
): CapacityBucket[] {
  const buckets: CapacityBucket[] = []
  let currentMinuteOfWeekStart = 0
  while (currentMinuteOfWeekStart < 7 * 24 * 60) {
    buckets.push(
      new CapacityBucket(
        {
          startMinuteOfWeek: currentMinuteOfWeekStart,
          bucketMinutes,
          customer: setIdentifiers.customer,
          containerTag: setIdentifiers.containerTag,
          dedicated,
        },
        demoMode
      )
    )
    currentMinuteOfWeekStart = currentMinuteOfWeekStart + bucketMinutes
  }
  return buckets
}
