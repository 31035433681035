<script lang="ts" setup>
import { ClickOutside as vClickOutside } from 'element-plus'
import type { SlotSelectedEvent } from './events'
import type { Bucket } from '~/logic/appointmentBucketing'
import type { Appointment } from '~/models/appointments'
import { useAppointmentCountStoreForAvailability } from '~/stores/appointmentCounts'
import { useRemainingCapacitiesStore } from '~/stores/remainingCapacities'

const props = defineProps<{
  bucket: Bucket
  description: string
  bookedAppointment: Appointment | null
  showHideAppt: boolean | undefined
}>()

const emit = defineEmits({
  book: (event: SlotSelectedEvent) => true,
})

const slotBucket = toRef(props, 'bucket')
const description = toRef(props, 'description')

const apptCountStore = useAppointmentCountStoreForAvailability()
const remainingCapacitiesStore = useRemainingCapacitiesStore()
const numExistingAppts = computed(() => {
  return (
    apptCountStore.getCountForRange(
      slotBucket.value.bucketStart,
      slotBucket.value.bucketEnd
    ) || 0
  )
})
const remainingCapacities = computed((): number[] => {
  if (!remainingCapacitiesStore.loaded) return []
  const capacities: number[] = []
  slotBucket.value.slots
    .filter((slot) => slot.has_availability)
    .forEach((slot) => {
      const capacity = remainingCapacitiesStore.getRemainingCapacityForTime(
        slot.window_start
      )
      if (capacity !== null) {
        capacities.push(capacity)
      }
    })
  return capacities
})

const capacitiesDefined = computed(() => remainingCapacities.value.length > 0)

const hasCapacity = computed(() => {
  return remainingCapacities.value.some(
    (capacity) => capacity !== undefined && capacity && capacity > 0
  )
})
const hasSomeTimesAtOrOverCapacity = computed(() => {
  return remainingCapacities.value.some(
    (capacity) => capacity !== undefined && capacity && capacity <= 0
  )
})
const noRemainingCapacity = computed(() => {
  return remainingCapacities.value.every(
    (capacity) => capacity !== undefined && capacity && capacity <= 0
  )
})
const bucketButtonType = computed(() => {
  if (slotBucket.value.hasAvailability) {
    if (capacitiesDefined.value) {
      if (hasCapacity.value) {
        if (hasSomeTimesAtOrOverCapacity.value) {
          return 'warning'
        } else {
          return 'success'
        }
      } else {
        return 'danger'
      }
    } else {
      return 'primary'
    }
  } else {
    return 'info'
  }
})
const tooltipMessage = computed(() => {
  if (props.bucket.hasBookedAppointment) {
    return 'Appointment already booked for this slot. Click to cancel or reschedule'
  } else if (slotBucket.value.hasAvailability) {
    let slotCountDesc = ''
    if (slotBucket.value.numSlotsIsLowerBound) {
      slotCountDesc = `At least ${slotBucket.value.numSlots} slots available`
    } else {
      slotCountDesc = `${slotBucket.value.numSlots} slots available`
    }
    if (capacitiesDefined.value) {
      let capacityDesc = ''
      if (hasCapacity.value && !hasSomeTimesAtOrOverCapacity.value) {
        capacityDesc = 'and your company has capacity'
      } else if (
        hasSomeTimesAtOrOverCapacity.value &&
        !noRemainingCapacity.value
      ) {
        capacityDesc = 'but some times are over capacity'
      } else {
        capacityDesc = "but your company doesn't have capacity"
      }
      return `${slotCountDesc}, ${capacityDesc}. Click to view times`
    } else {
      return `${slotCountDesc}. Click to view times`
    }
  }
  return 'No availability for this time'
})
// Popover is only created once needed, to reduce the number of Vue components
const popoverCreated = ref(false)
const popoverActive = ref(false)
const buttonRef = ref()
const capacityRef = ref()
const onClickOutside = () => {
  popoverActive.value = false
}
function openPopover() {
  popoverCreated.value = true
  popoverActive.value = true
}
</script>

<template>
  <!-- Available slots count -->
  <div class="mt-2">
    <el-button
      ref="buttonRef"
      v-tooltip="tooltipMessage"
      v-click-outside="onClickOutside"
      round
      :type="bucketButtonType"
      size="default"
      :plain="!props.bucket.hasBookedAppointment"
      :disabled="
        !slotBucket.hasAvailability && !props.bucket.hasBookedAppointment
      "
      style="min-width: 40px; width: 90%"
      class="text-sm fs-view-appointment-slot-bucket-options mb-2"
      @click="openPopover"
    >
      <template v-if="bucket.hasBookedAppointment">
        <i-mdi:check class="text-lg" />
      </template>
      <template v-else>
        <span style="font-weight: bold">
          {{ slotBucket.numSlots }}
        </span>
        <template v-if="slotBucket.numSlotsIsLowerBound">+</template>
      </template>
    </el-button>
  </div>
  <!-- Existing appointments already booked -->
  <div
    v-if="showHideAppt"
    ref="capacityRef"
    v-tooltip="
      `Across all terminals, you have ${numExistingAppts} existing import appointments at this time`
    "
    :class="[
      numExistingAppts > 0
        ? 'text-xs text-center text-gray-400 min-h-4 h-6 opacity-50 mb-3 existing-app'
        : 'text-xs text-center text-gray-400 min-h-4 h-6 opacity-50  ',
    ]"
  >
    <span
      v-if="numExistingAppts > 0"
      data-testid="existing-appointments-count"
      >{{ numExistingAppts }}</span
    >
  </div>
  <!-- Slot selection -->
  <el-popover
    v-if="popoverCreated"
    :virtual-ref="buttonRef"
    virtual-triggering
    placement="bottom"
    :width="340"
    :visible="popoverActive"
    trigger="click"
    @hide="popoverActive = false"
    @show="popoverActive = true"
  >
    <div>
      <div class="font-bold text-center mb-0">
        Available Slots ({{ description }})
      </div>
      <div v-for="slot in slotBucket.slots" :key="slot.window_start.toISO()">
        <AppointmentSlotButton
          :availability-slot="slot"
          :booked-appointment="props.bookedAppointment"
          @book="emit('book', $event)"
        />
      </div>
    </div>
  </el-popover>
</template>

<style scoped>
.existing-app {
  background-color: #f3f3f3;
  padding: 2px 4px;
  border-radius: 6px;
  width: 24px;
  margin: auto;
  border: 1px solid black;
  color: black;
  /* font-weight: bold; */
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
