<script lang="ts" setup>
const props = defineProps({
  remainingCapacity: { type: Number, required: false },
  showIcon: { type: Boolean, required: false, default: true },
})
</script>

<template>
  <div class="text-xs text-orange-600 opacity-50">
    <template
      v-if="
        props.remainingCapacity !== undefined && props.remainingCapacity <= 0
      "
    >
      <i-mdi:alert v-if="showIcon" class="align-text-bottom" />
      <template v-if="remainingCapacity === 0"> You are at capacity </template>
      <template v-else>
        You are over capacity by
        {{ Math.abs(props.remainingCapacity) }} appointment<template
          v-if="Math.abs(props.remainingCapacity) > 1"
          >s</template
        >
      </template>
    </template>
  </div>
</template>
