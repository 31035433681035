import type { install as floatingVueInstall } from 'floating-vue'
import FloatingVue from 'floating-vue'
import type { App } from 'vue'
import type { UserModule } from '~/types'

type FloatingVueConfig = Parameters<typeof floatingVueInstall>[1]
const config: FloatingVueConfig = {
  // See: https://floating-vue.starpad.dev/guide/config.html#default-values
  themes: {
    tooltip: {
      placement: 'bottom',
      delay: {
        show: 500,
        hide: 0,
      },
    },
  },
}
export function setupTooltips(app: App) {
  app.use(FloatingVue, config)
}
export const install: UserModule = ({ app }) => {
  setupTooltips(app)
}
