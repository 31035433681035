<script lang="ts" setup>
const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  width: {
    type: Number,
    default: 400,
  },
})
</script>

<template>
  <div>
    <el-popover
      placement="top-start"
      :width="width"
      trigger="hover"
      effect="dark"
      content="this is content, this is content, this is content"
    >
      <template #reference>
        <i-mdi:help-circle class="text-lg align-middle" v-bind="$attrs" />
      </template>
      <template #default>
        <div class="help-text">
          <h3 class="font-bold text-lg text-center mb-2 break-normal">
            {{ props.title }}
          </h3>
          <div class="indent-sm break-normal text-left">
            <slot />
          </div>
        </div>
      </template>
    </el-popover>
  </div>
</template>

<style lang="scss">
.help-text {
  p {
    margin-bottom: 10px;
  }
  li {
    list-style: disc;
    list-style-position: inside;
  }
  a {
    @apply underline;
  }
}
</style>
