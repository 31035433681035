import { defineStore } from 'pinia'
import { useUserStore } from './user'
import Customer from '~/models/customers'

import type {
  BodyDeleteCustomersCustomersDelete,
  BodyMergeCustomersCustomersMergePost,
  SavedCustomer,
} from '~/services/apiClient'
import { CustomersApi } from '~/services/apiClient/'
const api = new CustomersApi()

export const useCustomerStore = defineStore('customers', () => {
  const customers = ref([] as Customer[])
  const loadingPromise = ref(null as Promise<void> | null)
  const loaded = ref(false)
  const selectionKey = ref(0)
  const selectedCustomers = ref(new Map() as Map<number, boolean>)
  function loadIfNotLoaded(): Promise<void> {
    if (loadingPromise.value) {
      return loadingPromise.value
    } else if (!loaded.value) {
      return load()
    } else {
      return Promise.resolve()
    }
  }
  function load(query?: string): Promise<void> {
    const userStore = useUserStore()
    loadingPromise.value = api
      .getCustomersCustomersGet(query)
      .then((resp) => {
        customers.value = resp.data.map(
          (b) => new Customer(b, userStore.demo_mode)
        )
        loaded.value = true
      })
      .finally(() => (loadingPromise.value = null))
    return loadingPromise.value
  }
  function create(name: string): Promise<SavedCustomer[]> {
    return api.addCustomersCustomersPost([{ name }]).then((resp) => {
      customers.value.push(...resp.data)
      return resp.data
    })
  }
  function deleteCustomer(id: number): Promise<void> {
    const body: BodyDeleteCustomersCustomersDelete = {
      ids: [id],
    }
    return api.deleteCustomersCustomersDelete(body).then(() => {
      customers.value = customers.value.filter((customer) => customer.id !== id)
    })
  }
  function update(customer: SavedCustomer): Promise<void> {
    return api.updateCustomerCustomersUpdatePost(customer).then((resp) => {
      customers.value = customers.value.map((c) => {
        if (c.id === resp.data.id) {
          return new Customer(resp.data, false)
        }
        return c
      })
    })
  }
  function merge(customersIds: number[], new_name: string): Promise<void> {
    // type as customers
    const customersToMerge: Customer[] = customers.value.filter((c) =>
      customersIds.includes(c.id)
    )
    // turn into a saved customer
    const savedCustomers: SavedCustomer[] = customersToMerge.map((c) => {
      return {
        id: c.id,
        name: c.name,
      }
    })
    const body: BodyMergeCustomersCustomersMergePost = {
      customers: savedCustomers,
      new_name,
    }
    return api.mergeCustomersCustomersMergePost(body).then((resp) => {
      // delete the ones we merged
      customers.value = customers.value.filter(
        (c) => !customersIds.includes(c.id)
      )
      // add the new one
      customers.value.push(new Customer(resp.data, false))
    })
  }
  function clearCustomerSelection() {
    for (const customer of customers.value) {
      selectedCustomers.value.set(customer.id, false)
    }
    selectionKey.value += 1
  }
  function toggleCustomerSelection(customerId: number) {
    const selected = selectedCustomers.value.get(customerId)
    selectedCustomers.value.set(customerId, !selected)
    selectionKey.value += 1
  }
  function findByNameSimilarity(customerName: string): Customer | undefined {
    const cleanedCustomerName = cleanCustomerName(customerName)
    return customers.value.find((customer) => {
      const cleanedName = cleanCustomerName(customer.name)
      return (
        cleanedName.includes(cleanedCustomerName) ||
        cleanedCustomerName.includes(cleanedName)
      )
    })
  }
  // Computed properties
  const loading = computed((): boolean => {
    return !!loadingPromise.value
  })
  const getByCustomerID = computed(() => {
    return (id: number) => {
      return customers.value.find((customer) => customer.id === id)
    }
  })
  const isCustomerSelected = computed(() => {
    return (id: number): boolean => {
      return selectedCustomers.value.get(id) || false
    }
  })
  const numSelectedCustomers = computed((): number => {
    let count = 0
    for (const isSelected of selectedCustomers.value.values()) {
      if (isSelected) {
        count += 1
      }
    }
    return count
  })
  const selectedCustomersIds = computed((): number[] => {
    const customerIds = []
    for (const [customerId, isSelected] of selectedCustomers.value.entries()) {
      if (isSelected) {
        customerIds.push(customerId)
      }
    }
    return customerIds
  })
  return {
    // Refs
    customers,
    loadingPromise,
    loaded,
    selectionKey,
    selectedCustomers,
    // Methods
    loadIfNotLoaded,
    load,
    create,
    deleteCustomer,
    update,
    merge,
    clearCustomerSelection,
    toggleCustomerSelection,
    findByNameSimilarity,
    // Computed properties
    loading,
    getByCustomerID,
    isCustomerSelected,
    numSelectedCustomers,
    selectedCustomersIds,
  }
})

function cleanCustomerName(name: string): string {
  return name.trim().toUpperCase().replace(/\s/g, '')
}
