<script setup lang="ts">
import type { IconType } from '../emptyReturnRules/types'
import { EIconType } from '../emptyReturnRules/types'

const props = defineProps({
  type: {
    type: String as () => IconType | null | undefined,
  },
})

const iconType = computed(() => {
  return props.type
})
</script>

<template>
  <div class="grid place-items-center">
    <!-- {{ props }} -->
    <IconClosed v-if="iconType === EIconType.Closed" />
    <IconOpen v-if="iconType === EIconType.Open" />
    <IconDuals v-if="iconType === EIconType.DualsOnly" />
    <IconExempt v-if="iconType === EIconType.Exempt" />
    <IconQuestion v-if="!iconType" />
  </div>
</template>

<style scoped></style>
