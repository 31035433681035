<script setup lang="ts">
import EmptyContainerIcon from '~/components/icons/EmptyContainerIcon.vue'
import LoadedContainerIcon from '~/components/icons/LoadedContainerIcon.vue'
import type { Appointment } from '~/models/appointments'

const props = defineProps<{
  appointment: Appointment
}>()
</script>

<template>
  <LoadedContainerIcon v-if="props.appointment.loaded" />
  <EmptyContainerIcon v-else />
</template>
