import { startCase } from 'lodash-es'
import { DateTime } from 'luxon'
import { getTerminalLabel } from '~/constants'
import type {
  GETCameraHistoryImage,
  GETCameraImage,
  TerminalName,
} from '~/services/apiClient'

export class CameraImage {
  observed: DateTime
  url: string
  source_url: string
  terminal: TerminalName
  streamName: string
  key: string
  constructor(img: GETCameraImage) {
    this.observed = DateTime.fromISO(img.observed)
    this.url = img.image_url
    this.terminal = img.terminal
    this.streamName = img.stream_name
    this.key = `${img.terminal}_${img.stream_name}`
    this.source_url = img.source_url
  }

  get largeImageURL(): string {
    return this.url.replace('-thumbnail', '')
  }

  get streamNameDesc(): string {
    return startCase(this.streamName)
  }

  get terminalDesc(): string {
    return getTerminalLabel(this.terminal)
  }
}

export class CameraHistoryImage extends CameraImage {
  bucket_time: DateTime
  hour_key: number
  raw: GETCameraHistoryImage
  constructor(img: GETCameraHistoryImage) {
    super(img)
    this.bucket_time = DateTime.fromISO(img.bucket_time)
    this.hour_key = this.bucket_time.hour
    this.raw = img
  }
}
