<script lang="ts" setup>
const props = defineProps({
  routeName: {
    type: String,
    required: true,
  },
})
</script>

<template>
  <router-link
    :to="{ name: props.routeName }"
    class="text-xl self-center hover:underline mr-6"
  >
    <slot></slot>
  </router-link>
</template>
