<script lang="ts" setup>
import type { Container } from '~/models/containers'
const props = defineProps<{
  container: Container
  isRowHovered: boolean
}>()
const keepActionButtonsOpen = ref(false)
</script>

<template>
  <span
    v-if="isRowHovered || keepActionButtonsOpen"
    class="action-buttons relative"
  >
    <div class="absolute right-0 flex items-start">
      <div v-if="props.container.is_in_import_booking_state">
        <el-button
          v-tooltip="`Click to open booking window for ${container.number}`"
          type="primary"
          size="small"
          plain
        >
          <i-mdi:calendar-plus class="mr-1" />
          Book
        </el-button>
      </div>
      <AutoBookButton
        v-if="container.auto_booking_applies"
        :containers="[container]"
        size="small"
        @click="keepActionButtonsOpen = true"
        @close="keepActionButtonsOpen = false"
      />
      <div v-if="container.has_active_appointment">
        <el-button
          v-tooltip="`Click to open reschedule window`"
          type="primary"
          size="small"
          plain
        >
          <i-mdi:calendar-arrow-right class="mr-1" />
          Reschedule
        </el-button>
      </div>
      <CancelAppointmentButton
        v-if="container.has_active_appointment"
        v-tooltip="`Click to cancel appointment`"
        :appointment="container.booked_appointment!"
        class="block"
      />
    </div>
  </span>
</template>
