<script lang="ts" setup>
import { useCapacitiesStore } from '~/stores/capacities'

useHead({
  title: 'Dray Dog - Capacity',
})

const capacitiesStore = useCapacitiesStore()
</script>

<template>
  <Card class="py-4 px-8">
    <div v-show="capacitiesStore.setup">
      <CapacityManager />
    </div>
    <div
      v-if="capacitiesStore.loading"
      v-loading="true"
      class="min-h-100"
    ></div>
    <div
      v-else-if="capacitiesStore.setup === false"
      class="text-center max-w-800px mx-auto"
    >
      <h1 class="text-3xl mt-6">
        Introducing: <strong>Capacity Management</strong>
      </h1>
      <div class="mt-8">
        <p class="text-left">
          Dray Dog Capacity Management lets you specify the quantity of
          appointments you want for different times, and let auto booking book
          appointments to match. <br /><br />In other words: capacity management
          adds appointment count limits to auto booking
        </p>

        <el-button
          type="success"
          class="my-8 text-lg text-center test-enable-capacity-management"
          size="large"
          data-testid="enable-capacity-management"
          @click="capacitiesStore.activateCapacityManagement"
        >
          Enable Capacity Management
        </el-button>
        <div class="text-left my-6">
          There are a few important things to note when using capacity
          management:
          <ul class="list-disc ml-10 mt-6">
            <li>
              When enabled, auto booking will not book more than the specified
              capacity for a given time
              <ul class="list-disc ml-6">
                <li>
                  Make sure you define capacity for all times where you want
                  appointments.
                  <strong>Don't "half" configure your capacity</strong>
                </li>
              </ul>
            </li>
            <li>
              You still must enable auto booking for the containers you want
              booked
              <ul class="list-disc ml-6">
                <li>This is a tool for further guiding auto booking</li>
              </ul>
            </li>
            <li>
              Auto booking will never book over the defined limit for a given
              bucket.
              <ul class="list-disc ml-6">
                <li>It does not try to "generally" match your capacity.</li>
                <li>
                  You may want to set higher capacities and cancel some
                  appointments as a result
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </Card>
</template>

<route lang="yaml">
meta:
  auth: true
name: capacity
</route>
