import { acceptHMRUpdate, defineStore } from 'pinia'
import globalAxios from 'axios'
import * as Sentry from '@sentry/browser'
import { isInternalUser } from '~/constants'
import { reloadPage } from '~/utils'
import type {
  GETOrganization,
  GETToken,
  GETUserInfo,
  SimpleUser,
} from '~/services/apiClient'
import {
  OrganizationSubscription,
  UserRoleType,
  UsersApi,
} from '~/services/apiClient'
import { UserInfo } from '~/models/userInfo'
import { Organization } from '~/models/organization'
import { clearChatData, configureUserWithCrispChat } from '~/services/crispChat'
import { startOpenReplayTracking } from '~/observability/openReplay'

function clearLocalStorageExcept(keepKeys: Set<string>) {
  const allKeys = Object.keys(localStorage)
  allKeys.forEach((key) => {
    if (!keepKeys.has(key)) {
      localStorage.removeItem(key)
    }
  })
}

const AUTH_HEADER_KEY = 'auth_headers_by_org_id'
const EXPORT_IN_TRINIUM_MODE_KEY = 'export_in_trinium_mode'
const EXPORT_ALL_APPOINTMENTS_KEY = 'export_all_appointments'
const USE_OWN_CHASSIS_KEY = 'use_own_chassis'
const DEMO_MODE_KEY = 'demo_mode'
const TEST_MODE_KEY = 'test_mode'
const ALL_USER_DATA_KEYS = new Set([
  AUTH_HEADER_KEY,
  EXPORT_IN_TRINIUM_MODE_KEY,
  EXPORT_ALL_APPOINTMENTS_KEY,
  USE_OWN_CHASSIS_KEY,
  DEMO_MODE_KEY,
  TEST_MODE_KEY,
])

export const useUserStore = defineStore('user', () => {
  const authHeader = useLocalStorage<null | string>('auth_header', null)
  const authHeadersByOrgID = useLocalStorage<Record<string, string>>(
    AUTH_HEADER_KEY,
    {}
  )
  const exportInTriniumMode = useLocalStorage<boolean>(
    EXPORT_IN_TRINIUM_MODE_KEY,
    false
  )
  const exportAllAppointments = useLocalStorage<boolean>(
    EXPORT_ALL_APPOINTMENTS_KEY,
    false
  )
  const orgNotificationsChannel = ref<string>('')
  const userNotificationsChannel = ref<string>('')

  const useOwnChassis = useLocalStorage<boolean>(USE_OWN_CHASSIS_KEY, false)
  const loading = ref(false)
  const feature_flags = ref(new Set<string>())
  const demo_mode = useLocalStorage<boolean>(DEMO_MODE_KEY, false, {})
  const testMode = useLocalStorage<boolean>(TEST_MODE_KEY, false)
  const userInfo = ref(null as null | UserInfo)

  // Computed properties
  const userEmail = computed(() => {
    return userInfo.value?.email ?? null
  })
  const is_internal_user = computed(() => {
    if (userEmail.value) {
      return isInternalUser(userEmail.value)
    } else {
      return false
    }
  })

  // User prefs
  const showHideImportAppointments: Ref<boolean | undefined> = ref(true)

  // Actions
  function setAuthHeader(header: string) {
    globalAxios.defaults.headers.common.Authorization = header
    authHeader.value = header
  }

  function addAuthHeader(tokenInfo: GETToken) {
    const authHeaderVal = makeAuthHeader(tokenInfo)
    authHeadersByOrgID.value[tokenInfo.organization_id] = authHeaderVal
  }

  function loadInfo(): Promise<UserInfo> {
    const api = new UsersApi()

    return api.getCurrentUserInfoUsersCurrentInfoGet().then((resp) => {
      return updateUser(resp.data)
    })
  }
  function updateUser(user: GETUserInfo): UserInfo {
    userInfo.value = new UserInfo(user, demo_mode.value)
    feature_flags.value = new Set<string>(userInfo.value.feature_flags)
    orgNotificationsChannel.value = `private-organization-notifications-${userInfo.value.current_organization.id}`
    userNotificationsChannel.value = `private-user-notifications-${userInfo.value.current_organization.id}-${userInfo.value.id}`
    return userInfo.value
  }

  function loadInfoIfNeeded(): Promise<UserInfo> {
    // TODO: Cache in local storage? That would kind of invalidate our live updates
    // for feature flags
    if (userInfo.value === null) {
      return loadInfo()
    } else {
      return Promise.resolve(userInfo.value)
    }
  }

  function updateOrg(orgUpdate: GETOrganization) {
    if (userInfo.value) {
      if (userInfo.value.current_organization.id === orgUpdate.id) {
        userInfo.value.current_organization = new Organization(orgUpdate)
      }
      for (const [index, org] of userInfo.value.organizations.entries()) {
        if (org.id === orgUpdate.id) {
          userInfo.value.organizations[index] = org
        }
      }
    }
  }

  async function switchToOrg(orgID: number): Promise<void> {
    // unbind from the channels
    // unbindFromUserChannels()
    if (!authHeadersByOrgID.value[orgID]) {
      const api = new UsersApi()
      const resp = await api.getTokenViaTokenUsersTokenViaTokenPost({
        organization_id: orgID,
      })
      authHeadersByOrgID.value[orgID] = makeAuthHeader(resp.data)
    }
    clearLocalStorageExcept(ALL_USER_DATA_KEYS)
    setAuthHeader(authHeadersByOrgID.value[orgID])
    await loadInfo()
    reloadPage()
  }

  async function login(tokenResp: GETToken) {
    const authHeader = makeAuthHeader(tokenResp)
    setAuthHeader(authHeader)
    demo_mode.value = false
    await loadInfo()
  }
  async function loginUsingPortOptimizerToken(token: string): Promise<void> {
    const api = new UsersApi()
    return await api
      .getTokenViaPortOptimizerTokenUsersTokenViaPortOptimizerTokenPost({
        token,
      })
      .then((resp) => {
        return login(resp.data)
      })
  }
  function clearAllUserData() {
    authHeader.value = null
    userInfo.value = null
    localStorage.clear()
    feature_flags.value = new Set<string>()
  }

  function logout() {
    globalAxios.defaults.headers.common.Authorization = null
    clearAllUserData()
    clearChatData()
    // @ts-expect-error
    if (window.posthog) {
      // @ts-expect-error
      posthog.reset()
    }
  }

  async function identifyUserWithServices() {
    if (!userInfo.value) return
    const email = userInfo.value?.email
    if (import.meta.env.DEV) {
      return
    }
    configureUserWithCrispChat(userInfo.value)
    if (isInternalUser(email)) {
      return
    }
    const openReplayModule = await import('~/observability/openReplay')
    const openReplay = openReplayModule.getOpenReplayTracker()
    openReplay.setUserID(email)
    startOpenReplayTracking().then(() => {
      openReplay.setUserID(email)
    })
    // Identify in PostHog. PostHog is not available on localhost
    // @ts-expect-error
    if (window.posthog) {
      // Delay is to give PostHog time to initialize
      setTimeout(() => {
        // @ts-expect-error
        posthog.identify(email, {
          properties: {
            email,
          },
        })
      }, 2000)
    } else {
      // eslint-disable-next-line no-console
      console.log('PostHog not available')
    }
    // Identify in Sentry (error tracking)
    Sentry.setUser({ email })
  }

  function setShowHideImportAppt(value: boolean | undefined) {
    showHideImportAppointments.value = value
  }

  // Getters
  const loggedIn = computed(() => {
    return authHeader.value !== null
  })

  const token = computed((): string | undefined => {
    if (authHeader.value) {
      return authHeader.value.split(' ')[1]
    }
    return undefined
  })
  const currentRole = computed((): UserRoleType | null => {
    return userInfo.value?.current_organization.role ?? null
  })
  const isSubCarrier = computed((): boolean => {
    return currentRole.value === UserRoleType.SubCarrier
  })
  const hasManagementPermissions = computed((): boolean => {
    return (
      currentRole.value === UserRoleType.Management ||
      (userEmail.value !== null && isInternalUser(userEmail.value))
    )
  })
  const loggedInUser = computed((): SimpleUser | null => {
    if (userInfo.value) {
      return {
        id: userInfo.value.id,
        first_name: userInfo.value.first_name,
        last_name: userInfo.value.last_name,
      }
    }
    return null
  })

  const isTerminalOrg = computed((): boolean => {
    if (userInfo.value) {
      // if its a terminal subscription
      const terminal_org =
        userInfo.value.current_organization.subscriptions.includes(
          OrganizationSubscription.Terminal
        )
      return terminal_org
    }
    return false
  })

  const isUasOrg = computed((): boolean => {
    if (userInfo.value) {
      // if its a UAS subscription
      const uas_org =
        userInfo.value.current_organization.subscriptions.includes(
          OrganizationSubscription.Uas
        )
      return uas_org
    }
    return false
  })
  const currentOrg = computed((): Organization | undefined => {
    return userInfo.value?.current_organization
  })

  const getShowHideImportAppt = computed((): boolean | undefined => {
    return showHideImportAppointments.value
  })

  return {
    // Refs
    userEmail,
    authHeader,
    exportInTriniumMode,
    exportAllAppointments,
    useOwnChassis,
    loading,
    feature_flags,
    demo_mode,
    testMode,
    userInfo,
    is_internal_user,
    showHideImportAppointments,
    orgNotificationsChannel,
    userNotificationsChannel,
    // Actions
    addAuthHeader,
    switchToOrg,
    loadInfoIfNeeded,
    login,
    logout,
    identifyUserWithTrackers: identifyUserWithServices,
    loadInfo,
    updateUser,
    updateOrg,
    setShowHideImportAppt,
    loginUsingPortOptimizerToken,
    // Computed properties
    loggedIn,
    token,
    isSubCarrier,
    hasManagementPermissions,
    loggedInUser,
    currentOrg,
    getShowHideImportAppt,
    isTerminalOrg,
    isUasOrg,
  }
})

function makeAuthHeader(tokenResp: GETToken): string {
  return `${tokenResp.token_type} ${tokenResp.access_token}`
}

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
