<script lang="ts" setup>
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useContainerTagsStore } from '~/stores/containerTags'
import { useContainerStore } from '~/stores/containers'

interface IRemovableTag {
  name: string
  type: 'success' | 'warning' | 'info' | 'danger'
}

const props = defineProps<{
  containerNumbers: string[]
}>()
const emit = defineEmits<{ (e: 'close'): void }>()
/** Init stores */
const containerTagsStore = useContainerTagsStore()
const containerStore = useContainerStore()

/** Refs */
const dialogModel: Ref<boolean> = ref(true)
const inputValue: Ref<string> = ref('')
const tagsToAdd: Ref<string[]> = ref([])
const activeTabName = ref('addTags') // Serves as default tab selected
const { tags: definedTags } = storeToRefs(containerTagsStore)
const selectedContainerTags: Ref<{ name: string }[]> = ref([])
const tagsToRemove: Ref<IRemovableTag[]> = ref([])

/** METHODS */
function onClickRemoveTag(tagName: string) {
  selectedContainerTags.value = selectedContainerTags.value.filter(
    (el) => el.name !== tagName
  )

  tagsToRemove.value.push({
    name: tagName,
    type: 'danger',
  })
}

function onClickCancelRemoveTag(tagName: string) {
  tagsToRemove.value = tagsToRemove.value.filter((el) => el.name !== tagName)

  selectedContainerTags.value.push({
    name: tagName,
  })
}

const onClickInputConfirm = (tagName: string) => {
  if (tagName) {
    tagsToAdd.value.push(tagName)
  }
}

// TODO: update tags arg
function tagContainers() {
  containerTagsStore.tagContainers(props.containerNumbers, tagsToAdd.value)
  emit('close')
}
// TODO: update tags arg
function untagContainers() {
  const arrayTagsToRemove = tagsToRemove.value.map((i) => i.name)
  containerTagsStore.untagContainers(props.containerNumbers, arrayTagsToRemove)
  emit('close')
}

/** COMPUTED */
const suggestedTags = computed(() => {
  const containerTags: string[] = tagsToAdd.value

  const remainingTags = definedTags.value.filter(
    (item) => !containerTags.includes(item)
  )

  return remainingTags
})

/** LIFECYCLE HOOKS */
onMounted(async () => {
  /**
   * Tags to be removed and tags to be added have different formats
   *
   * to display tags in the remove Tags Pane the tag has this format { name: string }
   * to display tags in Add Tags Pane the tags are a simple array ['tag 1', 'tag 2']
   */

  // Get tags from selected containers in Store remove ducplicates
  const tagsOnSelectedContainers = containerStore.selectedContainerObjects
    .map((c) => c.tags)
    .reduce((acc, val) => {
      val.forEach((tag) => {
        if (!acc.includes(tag)) {
          acc.push(tag)
        }
      })
      return acc
    }, [])

  // Assign to Tags to Add
  tagsToAdd.value = tagsOnSelectedContainers

  // Assign to Tags to Remove
  selectedContainerTags.value = tagsOnSelectedContainers.map((item) => {
    return { name: item }
  })
})

/** Confirmation modal */
// TODO: refactor into own component in progress
const open = () => {
  ElMessageBox.confirm(
    'This will permamently delete the tags from the selected containers. Continue?',
    'Warning',
    {
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
      type: 'warning',
    }
  )
    .then(() => {
      untagContainers()
      ElMessage({
        type: 'success',
        message: 'Delete completed',
      })
    })
    .catch(() => {
      ElMessage({
        type: 'info',
        message: 'Delete canceled',
      })
    })
}
</script>

<template>
  <div>
    <BaseDialog
      v-model="dialogModel"
      title="Add or remove tags"
      width="40%"
      :close-on-click-modal="false"
      @close="emit('close')"
    >
      <el-tabs v-model="activeTabName" class="demo-tabs">
        <!-- ADD TAGS TAB -->
        <el-tab-pane label="Add tags" name="addTags">
          <!-- Element Plus tags input -->
          <el-form-item class="mt-2">
            <TagsSelector
              v-model="tagsToAdd"
              placeholder="Select tags to add"
              class="w-full"
            />
          </el-form-item>

          <!-- Recently used tags -->
          <div>
            <div v-if="inputValue.length < 1" class="mt-4">
              <h1 style="font-size: medium" class="mb-2">Suggested tags:</h1>

              <div class="flex gap-2 mt-2">
                <el-tag
                  v-for="tag in suggestedTags"
                  :key="tag"
                  class="pointer"
                  :disable-transitions="false"
                  @click="onClickInputConfirm(tag)"
                >
                  {{ tag }}
                </el-tag>
              </div>
            </div>
          </div>
        </el-tab-pane>

        <!-- REMOVE TAGS TAB -->
        <el-tab-pane label="Remove tags" name="removeTags" class="mb-6">
          <div>
            <h1 style="font-size: medium" class="mb-2 mt-4">
              Tags from selected containers
            </h1>
          </div>
          <div class="flex gap-2 mb-6">
            <el-tag
              v-for="tag in selectedContainerTags"
              :key="tag.name"
              closable
              @close="onClickRemoveTag(tag.name)"
            >
              {{ tag.name }}
            </el-tag>
          </div>

          <!-- Tags to be removed -->
          <div>
            <h1 style="font-size: medium" class="mb-2">Tags to remove</h1>
          </div>
          <div class="flex gap-2">
            <el-tag
              v-for="tag in tagsToRemove"
              :key="tag.name"
              closable
              :type="tag.type"
              @close="onClickCancelRemoveTag(tag.name)"
            >
              {{ tag.name }}
            </el-tag>
          </div>
        </el-tab-pane>
      </el-tabs>

      <template #action-button>
        <el-button
          v-if="activeTabName === 'removeTags'"
          size="large"
          type="danger"
          :disabled="!tagsToRemove.length"
          @click="open"
          >Remove tags
        </el-button>
        <el-button
          v-else
          size="large"
          type="primary"
          :disabled="!tagsToAdd.length"
          @click="tagContainers"
          >Add tags
        </el-button>
      </template>
    </BaseDialog>
  </div>
</template>

<style scoped>
.pointer {
  cursor: pointer;
}

.demo-tabs > .el-tabs__content {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}
</style>
