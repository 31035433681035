<script lang="ts" setup>
import BaseDialog from './BaseDialog.vue'
import type { GETTruck } from '~/services/apiClient'
import { useTrucksStore } from '~/stores/trucks'
import { standardizeLicensePlate } from '~/utils'

const props = defineProps<{
  visible: boolean
  licensePlatePrefill?: string
}>()

const emit = defineEmits<{
  (e: 'update:visible', value: boolean): void
  (e: 'truckAdded', savedTruck: GETTruck): void
}>()

const trucksStore = useTrucksStore()

const formRef = ref()
const licensePlateInputRef = ref()
const form = ref({
  licensePlate: props.licensePlatePrefill || '',
  truckName: '',
})

const dialogVisible = computed({
  get: () => props.visible,
  set: (value) => emit('update:visible', value),
})

const standardizedLicensePlate = computed(() =>
  standardizeLicensePlate(form.value.licensePlate)
)

onMounted(() => {
  nextTick(() => {
    licensePlateInputRef.value?.focus()
    licensePlateInputRef.value?.select()
  })
})

const rules = {
  licensePlate: [
    {
      required: true,
      message: 'License plate is required',
      trigger: 'blur',
    },
    {
      validator: (
        rule: any,
        value: string,
        callback: (error?: Error) => void
      ) => {
        if (!standardizeLicensePlate(value)) {
          callback(new Error('Please enter a valid license plate'))
        } else {
          callback()
        }
      },
      trigger: 'blur',
    },
  ],
}

async function validateForm(): Promise<boolean> {
  if (!formRef.value) return false
  try {
    await formRef.value.validate()
    return true
  } catch (error) {
    return false
  }
}

async function handleAddTruck() {
  if (!(await validateForm())) return

  if (!standardizedLicensePlate.value) {
    throw new Error('Invalid license plate')
  }

  const savedTrucks = await trucksStore.saveNewTruck({
    licensePlate: standardizedLicensePlate.value,
    name: form.value.truckName || undefined,
  })
  const savedTruck = savedTrucks[0]
  emit('truckAdded', savedTruck)
  dialogVisible.value = false
  form.value.licensePlate = ''
  form.value.truckName = ''
}
</script>

<template>
  <BaseDialog
    v-model="dialogVisible"
    title="Add New Truck"
    width="400px"
    :close-on-click-modal="false"
  >
    <el-form ref="formRef" :model="form" :rules="rules" label-position="top">
      <el-form-item label="License Plate:" prop="licensePlate">
        <el-input
          ref="licensePlateInputRef"
          v-model="form.licensePlate"
          placeholder="Enter license plate"
        />
        <div
          v-if="
            standardizedLicensePlate &&
            standardizedLicensePlate !== form.licensePlate
          "
          class="text-warning mt-1"
        >
          <span class="text-xs text-gray-500">
            <span class="text-orange-500">NOTE:</span> License plate will be
            saved as "{{ standardizedLicensePlate }}"
          </span>
        </div>
      </el-form-item>
      <el-form-item label="Truck Name:">
        <el-input
          v-model="form.truckName"
          placeholder="Enter truck name (optional)"
        />
      </el-form-item>
    </el-form>

    <template #action-button>
      <el-button type="primary" size="large" @click="handleAddTruck">
        Save
      </el-button>
    </template>
  </BaseDialog>
</template>
