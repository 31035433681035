<script lang="ts" setup>
import type {
  GETGateScheduleEntryResponse,
  TerminalName,
} from '~/services/apiClient'
import { GateSchedulesApi } from '~/services/apiClient'
const props = defineProps({
  terminal: {
    type: String as PropType<TerminalName>,
    required: true,
  },
  readingFirstObserved: {
    type: String,
    required: true,
  },
})

const { readingFirstObserved, terminal } = toRefs(props)

const loading = ref(true)
const entriesByDateShift = ref(
  new Map<string, Map<string, GETGateScheduleEntryResponse[]>>() // date -> shift -> entries
)
const activeTab = ref('')
const numRules = ref(0)

function clearRules() {
  entriesByDateShift.value = new Map()
}

function loadEntries() {
  loading.value = true
  const api = new GateSchedulesApi()
  api
    .getEntriesByTerminalGateSchedulesEntriesByTerminalTerminalGet(
      props.terminal,
      //   ISO in UTC
      readingFirstObserved.value
    )
    .then((resp) => {
      clearRules()
      numRules.value = resp.data.entries.length
      resp.data.entries.forEach((entry) => {
        let date_title = ''
        let shift_title = ''
        if (entry.date) {
          date_title = entry.date
        }
        if (entry.shift) {
          shift_title =
            shift_title +
            (shift_title ? ' ' : '') +
            `${entry.shift.charAt(0).toUpperCase()}${entry.shift.slice(1)} Shift`
        }
        if (!entriesByDateShift.value.has(date_title)) {
          entriesByDateShift.value.set(date_title, new Map())
        }
        if (!entriesByDateShift.value.get(date_title)!.has(shift_title)) {
          entriesByDateShift.value.get(date_title)!.set(shift_title, [])
        }
        entriesByDateShift.value.get(date_title)!.get(shift_title)!.push(entry)
      })
      activeTab.value = Array.from(entriesByDateShift.value.keys())[0]
    })
    .catch((err) => {
      clearRules()
      numRules.value = 0
      throw err
    })
    .finally(() => {
      loading.value = false
    })
}
onMounted(loadEntries)
watch([terminal], () => {
  clearRules()
  loadEntries()
})
watch([readingFirstObserved], loadEntries)
</script>

<template>
  <div v-loading="loading" class="min-h-20vh">
    <div
      v-if="numRules === 0"
      class="flex items-center justify-center h-20vh text-gray-500"
    >
      <template v-if="!loading">
        <i-mdi:alert class="mr-2" />
        We have not parsed this reading yet
      </template>
    </div>
    <div
      v-for="date in entriesByDateShift.keys()"
      v-else
      :key="date"
      class="px-4"
    >
      <h2 class="text-lg font-bold text-center mb-1">{{ date }}</h2>
      <table class="w-full text-center mb-3">
        <thead>
          <tr>
            <th class="text-center w-1/2">Shift</th>
            <th class="text-center w-1/2">Status</th>
          </tr>
        </thead>
        <tbody>
          <!-- left header is shift, td is status -->
          <tr
            v-for="[shift, entries] in entriesByDateShift.get(date)"
            :key="shift"
          >
            <td class="text-center">{{ shift }}</td>
            <td
              v-for="entry in entries"
              :key="entry.shift"
              class="text-center flex items-center justify-center"
            >
              {{ entry.status }}
              <span
                v-if="entry.notes && entry.notes.length > 0"
                v-tooltip="entry.notes.join(', ')"
              >
                <i-mdi:information-circle />
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style lang="scss" scoped>
td,
th {
  border: 1px solid gray;
}
// Add left padding to first td or th in each row
tr td:first-child,
tr th:first-child {
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  @apply bg-light-500;
}
td {
  padding: 0px;
}
th {
  @apply bg-light-700;
}
</style>
