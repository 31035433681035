import { ElMessageBox, ElNotification } from 'element-plus'
import type { Container } from '~/models/containers'
import { AppointmentBookingApi, TerminalName } from '~/services/apiClient'

export function useEditChassis() {
  function editChassis(container: Container) {
    if (container.terminal !== TerminalName.Apm) {
      return
    }
    if (!container.latest_appointment) {
      return
    }
    const latestAppointmentId = container.latest_appointment.id
    let changeTo: string | null = null
    let ownChassis: boolean | null = null
    if (
      container.latest_appointment?.extra_data &&
      'own_chassis' in container.latest_appointment.extra_data
    ) {
      ownChassis = container.latest_appointment.extra_data
        .own_chassis as boolean
      if (container.latest_appointment.extra_data.own_chassis) {
        // set to pool
        changeTo = 'Pool'
      } else {
        // set to Own
        changeTo = 'Own'
      }
    }
    if (!changeTo) {
      return
    }
    if (ownChassis === null) {
      return
    }
    ElMessageBox.confirm(
      'Change chassis ownership to ' + changeTo + '?',
      'Update Chassis Ownership',
      {
        confirmButtonText: 'Update',
        cancelButtonText: 'Cancel',
        type: 'warning',
      }
    )
      .then(() => {
        const api = new AppointmentBookingApi()
        api
          .editChassisAppointmentsBookingEditChassisPost({
            appointment_id: latestAppointmentId,
            own_chassis: !ownChassis,
          })
          .then(() => {
            ElNotification.success({
              title: 'Chassis update initiated',
            })
          })
          .catch(() => {
            ElNotification.error({
              title: 'Chassis update failed',
            })
          })
      })
      .catch(() => {
        // do nothing
      })
  }
  return {
    editChassis,
  }
}
