<script lang="ts" setup>
import { EMPTY_RETURN_RULE_SOURCES } from '~/components/emptyReturnRules/terminals'
import type { TerminalName } from '~/services/apiClient'
import { useReturnRulesReadingsLatest } from '~/stores/returnRulesReadingsLatest'

const props = defineProps<{ modelValue: TerminalName | undefined }>()
const emit = defineEmits<{ 'update:modelValue': [TerminalName] }>()

const latestReturnRuleReadingsStore = useReturnRulesReadingsLatest()

function loadLatestReadings() {
  latestReturnRuleReadingsStore.load()
}
onMounted(loadLatestReadings)
</script>

<template>
  <el-radio-group
    :model-value="props.modelValue"
    size="large"
    class="ml-2 empty-return-rules-tabs"
  >
    <el-radio-button
      v-for="(rulesSource, index) in EMPTY_RETURN_RULE_SOURCES"
      :key="rulesSource.terminal"
      :value="rulesSource.terminal"
      @click="emit('update:modelValue', rulesSource.terminal)"
    >
      <ReturnRulesTerminalTab
        :terminal-name="rulesSource.terminal"
        :include-legend="index === 0"
      />
    </el-radio-button>
  </el-radio-group>
</template>

<style lang="scss">
.empty-return-rules-tabs .el-radio-button__inner {
  padding: 4.5px 15px;
  height: 41px;
}
</style>
