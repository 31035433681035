<script lang="ts" setup>
import { useMakeLineAndTypeDescription } from '~/compositions/useMakeLineAndTypeDescription'
import { getTerminalLabel } from '~/constants'
import type { BasicContainer } from '~/models/containers'

const props = defineProps<{
  container: BasicContainer
}>()
const { makeLineAndTypeDescription } = useMakeLineAndTypeDescription()
</script>

<template>
  <div class="flex justify-between items-center">
    <!-- Left -->
    <div>
      <span class="container-number">
        {{ props.container.number }}
      </span>
      <span class="text-xs text-gray-400">
        • {{ makeLineAndTypeDescription(container) }}
      </span>
    </div>
    <!-- Right -->
    <div class="ml-2">
      <span>
        <template v-if="container.customer_name">
          {{ container.customer_name }}
        </template>
        <span
          v-if="container.import_out_gated_time"
          class="text-xs text-gray-400"
        >
          <template v-if="container.customer_name">•</template>
          out-gated
          {{ container.import_out_gated_time.toRelative() }}
          <span v-if="container.last_related_terminal">
            from
            <!-- In effect, this is always the "out gating" terminal if the container is
            in an "out gated" like state without an active appointment -->
            {{ getTerminalLabel(container.last_related_terminal) }}
          </span>
        </span>
      </span>
    </div>
  </div>
</template>

<style scoped>
.container-number {
  min-width: 102px;
  display: inline-block;
  text-align: right;
}
</style>
