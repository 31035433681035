<script lang="ts" setup>
import type { DayOfWeek } from '~/models/capacitySets'
import { useCapacitiesStore } from '~/stores/capacities'

const props = defineProps<{
  targetDay: DayOfWeek
}>()
const emit = defineEmits<{
  (e: 'close'): void
}>()

const capacitiesStore = useCapacitiesStore()

const daySelections = ref([] as DayOfWeek[])

const dayHasCapacitiesSet = computed((): boolean => {
  const dayBuckets = capacitiesStore.getBucketsForDay(props.targetDay)
  let hasCapacitySet = false
  for (const bucket of dayBuckets) {
    if (bucket.maxAppointments > 0) {
      hasCapacitySet = true
      break
    }
  }
  return hasCapacitySet
})
const allDays = computed(() => {
  const days = new Set<string>()
  for (const bucket of capacitiesStore.activeCapacitySet!.buckets) {
    days.add(bucket.dayOfWeekDescription)
  }
  return Array.from(days)
})

function close() {
  emit('close')
}

function duplicate() {
  capacitiesStore.duplicateBuckets(props.targetDay, daySelections.value)
  close()
}
</script>

<template>
  <el-dialog
    :model-value="true"
    :title="`Duplicate Capacity from ${props.targetDay}`"
    width="30%"
    center
    @close="close"
  >
    <p>
      Select the days you'd like to duplicate
      <em>{{ props.targetDay }}'s</em> capacity limits to be copied to:
    </p>
    <el-alert
      v-if="!dayHasCapacitiesSet"
      type="warning"
      :closable="false"
      show-icon
      class="my-2"
    >
      <template #title>
        {{ props.targetDay }} does not have any capacity limits set
      </template>
    </el-alert>
    <el-form>
      <el-checkbox-group v-model="daySelections">
        <div v-for="day of allDays" :key="day" class="my-2">
          <el-checkbox
            :disabled="props.targetDay === day"
            :label="props.targetDay === day ? `${day} (source)` : day"
            border
            class="w-full"
          />
        </div>
      </el-checkbox-group>
    </el-form>

    <template #footer>
      <div class="flex">
        <el-button class="flex-1" @click="close">Cancel</el-button>
        <el-button class="flex-1" type="primary" @click="duplicate">
          Duplicate
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>
