import { DateTime } from 'luxon'
import globalAxios from 'axios'
import type {
  ContainerType,
  GETTimeBucketAppointmentStatusCount,
  ShippingLine,
  TerminalName,
  TransactionDirection,
} from './apiClient'
import {
  AppointmentsApi,
  ContainerWatchState,
  ContainersApi,
  SortOptions,
} from './apiClient'
import type { ContainerFilters } from '~/compositions/containerFilters'
import type { Container } from '~/models/containers'

export function setAuthorizationHeader(header: string) {
  globalAxios.defaults.headers.common.Authorization = header
}

export interface LoadContainerOptions extends ContainerFilters {
  abortController?: AbortController
  pageSize: number
  page: number
  offset?: number
  preloadSize?: number
}

function selectSortOption(
  state: ContainerWatchState | undefined
): SortOptions | undefined {
  switch (state) {
    case ContainerWatchState.ImportTrackingOnVessel:
    case ContainerWatchState.ImportTracking:
    case ContainerWatchState.ImportApptBooking:
      return SortOptions.LastFreeDay
    case ContainerWatchState.ImportApptBooked:
      return SortOptions.AppointmentTime // Factors in both import and empty appointments
    case ContainerWatchState.ImportPickedUp:
    case ContainerWatchState.ImportEmptyApptBooked:
      return SortOptions.ImportOutGatedTime
    case ContainerWatchState.ImportEmptyReturned:
      return SortOptions.EmptyInGatedTime
    default:
      return undefined
  }
}

function byLFD(a: Container, b: Container) {
  if (!a.last_free_day && !b.last_free_day) return byContainerNumber(a, b)
  if (!a.last_free_day) return 1
  if (!b.last_free_day) return -1
  if (a.last_free_day < b.last_free_day) return -1
  if (a.last_free_day > b.last_free_day) return 1
  return byContainerNumber(a, b)
}

function byOutGatedTimeDescending(a: Container, b: Container) {
  if (!a.import_out_gated_time && !b.import_out_gated_time)
    return byContainerNumber(a, b)
  if (!a.import_out_gated_time) return 1
  if (!b.import_out_gated_time) return -1
  if (a.import_out_gated_time > b.import_out_gated_time) return -1
  if (a.import_out_gated_time < b.import_out_gated_time) return 1
  return byContainerNumber(a, b)
}

function byEmptyInGatedTimeDescending(a: Container, b: Container) {
  if (!a.empty_in_gated_time && !b.empty_in_gated_time)
    return byContainerNumber(a, b)
  if (!a.empty_in_gated_time) return 1
  if (!b.empty_in_gated_time) return -1
  if (a.empty_in_gated_time > b.empty_in_gated_time) return -1
  if (a.empty_in_gated_time < b.empty_in_gated_time) return 1
  return byContainerNumber(a, b)
}
function byContainerNumber(a: Container, b: Container) {
  return a.number.localeCompare(b.number)
}

export function getSortFunctionForState(
  state: ContainerWatchState | undefined
): ((a: Container, b: Container) => number) | undefined {
  const sortOption = selectSortOption(state)
  switch (sortOption) {
    case SortOptions.LastFreeDay:
      return byLFD
    case SortOptions.ImportOutGatedTime:
      return byOutGatedTimeDescending
    case SortOptions.EmptyInGatedTime:
      return byEmptyInGatedTimeDescending
    default:
      return undefined
  }
}

export function loadContainers({
  terminal,
  container_numbers,
  customer_id,
  auto_book_on,
  auto_book_off,
  assigned_user_ids,
  container_types,
  shipping_lines,
  states,
  page,
  tags,
  vessels,
  pageSize,
  offset,
  updated_in_last_minutes,
  abortController,
}: LoadContainerOptions) {
  const api = new ContainersApi()
  let sortBy: SortOptions | undefined
  if (states && states?.length > 0) {
    sortBy = selectSortOption(states[0])
  }
  let sortOrder: 'ascending' | 'descending' = 'ascending'
  if (
    sortBy === SortOptions.ImportOutGatedTime ||
    sortBy === SortOptions.EmptyInGatedTime
  ) {
    sortOrder = 'descending'
  }
  return api.getContainersContainersGet(
    sortBy,
    sortOrder,
    page,
    pageSize,
    offset,
    terminal,
    states,
    undefined,
    undefined,
    vessels,
    customer_id,
    auto_book_on,
    auto_book_off,
    container_types,
    shipping_lines,
    updated_in_last_minutes,
    tags,
    container_numbers,
    assigned_user_ids,
    { signal: abortController?.signal }
  )
}

export interface LoadContainerBasicOptions {
  containers?: string
  states?: ContainerWatchState[]
  containerTypes?: ContainerType[]
  shippingLines?: ShippingLine[]
  importOutGatedAfter?: DateTime
  importOutGatedBefore?: DateTime
  limit?: number
  updatedInLastMinutes?: number
  tags?: string[]
  vessels?: string[]
}

export function loadContainersBasic({
  containers,
  states,
  containerTypes,
  shippingLines,
  importOutGatedAfter,
  importOutGatedBefore,
  limit,
  updatedInLastMinutes,
  tags,
  vessels,
}: LoadContainerBasicOptions) {
  const api = new ContainersApi()
  return api.getContainersBasicContainersBasicGet(
    containerTypes,
    shippingLines,
    importOutGatedAfter ? importOutGatedAfter.toISO() : undefined,
    importOutGatedBefore ? importOutGatedBefore.toISO() : undefined,
    limit,
    states,
    undefined,
    undefined,
    vessels,
    undefined,
    undefined,
    undefined,
    updatedInLastMinutes,
    tags,
    containers,
    undefined
  )
}

export interface LoadAppointmentCountsParams {
  time_bucket_hours: number
  after: DateTime
  before?: DateTime
  terminal_name?: TerminalName
  direction?: TransactionDirection
  loaded?: boolean
  customer_id?: number
  exclude_customer_ids?: string
  excludeContainerTags?: string[]
  containerTags?: string[]
}

export async function loadAppointmentCounts(
  params: LoadAppointmentCountsParams
): Promise<GETTimeBucketAppointmentStatusCount[]> {
  const {
    time_bucket_hours,
    after,
    before,
    terminal_name,
    direction,
    loaded,
    customer_id,
    exclude_customer_ids,
    excludeContainerTags,
    containerTags,
  } = params

  const api = new AppointmentsApi()
  const tz = DateTime.now().toFormat('z')
  const resp = await api.getAppointmentCountsAppointmentsCountsGet(
    time_bucket_hours,
    after.toUTC().toISO(),
    before?.toUTC().toISO(),
    terminal_name,
    direction,
    loaded,
    customer_id,
    containerTags,
    exclude_customer_ids,
    excludeContainerTags,
    tz
  )

  resp.data.sort((a, b) =>
    a.time_bucket_start > b.time_bucket_start
      ? 1
      : b.time_bucket_start > a.time_bucket_start
        ? -1
        : 0
  )

  return resp.data
}
