<script lang="ts" setup></script>

<template>
  <div class="flex">
    <!-- Left Pane -->
    <div class="mr-3px basis-1/2">
      <slot name="left" />
    </div>
    <!-- Right Pane -->
    <div class="ml-3px basis-1/2" style="min-height: 20vh">
      <slot name="right" />
    </div>
  </div>
</template>
