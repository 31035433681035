<script setup lang="ts">
import ContainerInfoTd from '~~/components/ContainerInfoTd.vue'
import { useGlobalSlotsDisplayConfig } from '~~/compositions/loadSlots'

const props = defineProps<{
  container: string
}>()

const datesToShow = useGlobalSlotsDisplayConfig().datesToShow
const headerColspan = computed(() => {
  return 1
})

const mainColspan = computed(() => {
  return datesToShow.value.length * 2 - headerColspan.value + 1
})
</script>

<template>
  <tr :ariaLabel="props.container">
    <ContainerInfoTd
      :import-status="undefined"
      :terminal="undefined"
      :appointment-window="null"
      :container-number="props.container"
      :colspan="headerColspan"
    />
    <td
      :colspan="mainColspan"
      class="text-center text-red-700 bg-yellow-50"
      style="height: 60px"
    >
      Not Found
    </td>
  </tr>
</template>
