<script lang="ts" setup>
import { useRouteQuery } from '@vueuse/router'
import { ElMessage, ElNotification } from 'element-plus'
import type { Ref } from 'vue'
import { UsersApi } from '~/services/apiClient'

useHead({
  title: 'Dray Dog - Reset Passwords',
})

const token = useRouteQuery('token') as Ref<string>
const newPassword = ref('')
const confirmNewPassword = ref('')
const router = useRouter()
function onReset() {
  if (!newPassword.value) {
    ElMessage.warning('Please enter a password')
    return
  }
  if (newPassword.value.length < 6) {
    ElMessage.warning('Password must be at least 6 characters')
    return
  }
  if (newPassword.value !== confirmNewPassword.value) {
    ElMessage.warning('Password confirmation does not match password')
    return
  }
  const api = new UsersApi()
  api
    .resetPasswordUsersResetPasswordPost({
      token: token.value,
      password: newPassword.value,
    })
    .then((resp) => {
      ElNotification.success(resp.data.message)
      router.push({ name: 'login' })
    })
}
</script>

<template>
  <CenteredCard>
    <template #title> Reset Password </template>
    <el-form-item label="New Password:">
      <el-input v-model="newPassword" type="password"></el-input>
    </el-form-item>
    <el-form-item label="Confirm New Password:">
      <el-input v-model="confirmNewPassword" type="password"></el-input>
    </el-form-item>
    <el-button type="primary" class="w-full mt-2" @click="onReset">
      Reset Password
    </el-button>
  </CenteredCard>
</template>

<route lang="yaml">
meta:
  layout: no_sidebar
</route>
