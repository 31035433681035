import { DateTime } from 'luxon'

export default function useStartOfToday() {
  const startOfToday = ref(DateTime.local().startOf('day'))
  const ONE_HOUR_IN_MS = 1_000 * 60 * 60
  //   Update the start of the day once per hour
  setInterval(() => {
    startOfToday.value = DateTime.local().startOf('day')
  }, ONE_HOUR_IN_MS)

  return startOfToday
}
