import { acceptHMRUpdate, defineStore } from 'pinia'
import type { GETShippingLine, ShippingLine } from '~/services/apiClient'
import { ContainersApi } from '~/services/apiClient'

export const useShippingLinesStore = defineStore('shippingLines', () => {
  const shipping_lines = ref([] as GETShippingLine[])
  const shipping_line_lookup = ref(new Map<ShippingLine, GETShippingLine>())
  const loading = ref(false)
  function loadShippingLinesCached() {
    if (shipping_lines.value.length === 0 && !loading.value) {
      loadShippingLines()
    }
  }
  function loadShippingLines() {
    loading.value = true
    const api = new ContainersApi()
    api.getShippingLinesContainersShippingLinesGet().then((resp) => {
      shipping_lines.value = resp.data
      shipping_line_lookup.value = new Map(
        resp.data.map((sl) => [sl.shipping_line, sl])
      )
      loading.value = false
    })
  }

  function getShippingLineDescription(line: ShippingLine): string {
    const sl = shipping_line_lookup.value.get(line)
    if (sl) {
      return sl.long_desc
    }
    return line
  }

  return {
    shipping_lines,
    shipping_line_lookup,
    loading,
    loadShippingLines,
    loadShippingLinesCached,
    getShippingLineDescription,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useShippingLinesStore, import.meta.hot)
  )
}
