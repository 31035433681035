<script lang="ts" setup>
import type { FormRules } from 'element-plus'
import { ElNotification } from 'element-plus'
import { CAPACITY_SET_DIALOG_MAX_WIDTH } from './constants'
import { useCapacitiesStore } from '~/stores/capacities'
import { useContainerTagsStore } from '~/stores/containerTags'
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
})

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
  (e: 'close'): void
}>()
const dialogOpen = useVModel(props, 'modelValue', emit)
const capacitiesStore = useCapacitiesStore()
const tagsStore = useContainerTagsStore()
const loading = ref(false)
const form = reactive({
  tag: undefined as unknown as string | undefined,
  dedicated: false,
})

const availableTags = computed(() => {
  return tagsStore.tags.filter((tag) => {
    return !capacitiesStore.tagSets.has(tag)
  })
})
const rules = reactive<FormRules>({
  tag: [{ required: true, message: 'Please Select a Tag' }],
})

function submitForm() {
  const tag = form.tag as string
  if (tag === undefined) {
    return
  }
  capacitiesStore.regenerateBuckets(
    120,
    { customer: null, containerTag: tag },
    form.dedicated
  )
  ElNotification.success({ title: 'Capacities Generated for ' + tag })
  dialogOpen.value = false
}
</script>

<template>
  <BaseDialog
    v-if="dialogOpen"
    v-model="dialogOpen"
    title="Add Tag Capacity Set"
    :max-width="CAPACITY_SET_DIALOG_MAX_WIDTH"
    @close="emit('close')"
  >
    <el-form
      ref="ruleFormRef"
      :model="form"
      :rules="rules"
      label-position="top"
    >
      <el-form-item label="Tag:" prop="customerId">
        <el-select
          v-model="form.tag"
          placeholder="Select a Tag"
          style="margin-left: 0px"
          :loading="loading"
          clearable
          no-data-text="No Tags Available"
        >
          <template #prefix>
            <i-mdi:tag />
          </template>
          <el-option
            v-for="tag in availableTags"
            :key="tag"
            :label="tag"
            :value="tag"
          />
        </el-select>
      </el-form-item>
      <el-form-item prop="dedicated">
        <el-switch v-model="form.dedicated" active-text="Dedicated" />
      </el-form-item>
    </el-form>

    <el-alert title="" type="info" effect="dark" show-icon>
      <p>
        A tag capacity set limits the number of appointments booked for
        containers with a given tag. This is useful for controlling the number
        of appointments for certain types of loads such as "Over Weight" or
        "Long Haul"
      </p>
      <br />
      <p>
        By default tag-based capacity sets are a constraint
        <strong>in addition to</strong> Fleet Capacity (i.e. both apply). If you
        select the "Dedicated" option, the capacity set is
        <strong>separate from</strong> Fleet Capacity. This means that any
        container with a tag that has a tag-based capacity set defined will not
        be subject to and will not "use" your Fleet Capacity.
      </p>
    </el-alert>
    <template #action-button>
      <el-button
        size="large"
        type="success"
        :disabled="!form.tag"
        @click="submitForm()"
      >
        Add Tag Capacity
      </el-button>
    </template>
  </BaseDialog>
</template>

<style scoped>
.el-button--text {
  margin-right: 15px;
}
.el-select {
  width: 300px;
  margin-left: auto;
}
.el-input {
  width: 300px;
  margin-left: auto;
}
.dialog-footer button:first-child {
  margin-right: 10px;
}
</style>
