<script setup lang="ts">
import {
  LA_TERMINALS,
  TERMINALS_REQUIRING_APPOINTMENTS,
  getTerminalLabel,
} from '~/constants'
import type { TerminalName } from '~/services/apiClient'
const props = defineProps<{
  showOnlyLaTerminals?: boolean
}>()

const terminals = defineModel<TerminalName[]>()

const options = computed(() => {
  const terminals = Array.from(
    props.showOnlyLaTerminals ? LA_TERMINALS : TERMINALS_REQUIRING_APPOINTMENTS
  )
  terminals.sort((a, b) => a.localeCompare(b))
  return terminals
})
</script>

<template>
  <el-select
    v-model="terminals"
    multiple
    filterable
    clearable
    collapse-tags
    collapse-tags-tooltip
    :max-collapse-tags="2"
    placeholder="Terminal"
    aria-label="Terminal"
  >
    <el-option
      v-for="terminal of options"
      :key="terminal"
      :label="getTerminalLabel(terminal)"
      :value="terminal"
    />
  </el-select>
</template>
