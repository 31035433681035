import { faker } from '@faker-js/faker/locale/en'
import { Organization } from './organization'
import type {
  GETOrganization,
  GETUserInfo,
  UserPreferences,
} from '~/services/apiClient'

export class UserInfo {
  id: number
  first_name: string
  last_name: string
  email: string
  current_organization: Organization
  organizations: GETOrganization[]
  feature_flags: string[]
  preferences: UserPreferences

  constructor(userInfo: GETUserInfo, demoMode: boolean) {
    this.id = userInfo.id
    if (demoMode) {
      userInfo.current_organization.name = faker.company.name()
      userInfo.current_organization.scac = faker.string.alpha(4).toUpperCase()
      userInfo.organizations.forEach((org) => {
        org.name = faker.company.name()
        org.scac = faker.string.alpha(4).toUpperCase()
      })
    }
    this.first_name = userInfo.first_name
    this.last_name = userInfo.last_name
    this.email = userInfo.email
    this.current_organization = new Organization(userInfo.current_organization)
    this.organizations = userInfo.organizations
    this.feature_flags = userInfo.feature_flags
    this.preferences = userInfo.preferences
  }

  get full_name(): string {
    return `${this.first_name} ${this.last_name}`
  }
}
