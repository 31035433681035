<script setup lang="ts">
import { useUserStore } from '~/stores/user'

useHead({
  title: 'Dray Dog',
})
const userStore = useUserStore()

const router = useRouter()
onMounted(() => {
  if (userStore.loggedIn) {
    router.push({
      name: 'containers',
    })
  } else {
    router.push({ name: 'cameras-home' })
  }
})
</script>

<template>
  <h1 class="text-xl">Home</h1>
</template>

<route lang="yaml">
meta:
  layout: no_sidebar
name: home
</route>
