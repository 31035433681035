<script lang="ts" setup>
import type { DateTime } from 'luxon'
import type {
  GateScheduleEntry,
  TerminalGateSchedule,
} from '~/models/gateSchedules'
import { gateScheduleEntryKey } from '~/models/gateSchedules'
import { Shift } from '~/services/apiClient'

const props = defineProps<{
  schedule: TerminalGateSchedule
  dates: DateTime[]
  now: DateTime
}>()

const entriesByDateShift = computed((): Map<string, GateScheduleEntry> => {
  const map = new Map<string, GateScheduleEntry>()
  props.schedule.entries.forEach((entry) => {
    map.set(entry.key, entry)
  })
  return map
})

interface ShiftInfo {
  date: DateTime
  shift: Shift
  key: string
}
const shiftKeys = computed((): ShiftInfo[] => {
  const shifts: ShiftInfo[] = []
  for (const date of props.dates) {
    for (const shift of [Shift.First, Shift.Second, Shift.Third]) {
      const key = gateScheduleEntryKey(date, shift)
      shifts.push({ date, shift, key })
    }
  }
  return shifts
})
</script>

<template>
  <tr class="gate-schedule-row">
    <th style="white-space: nowrap; padding-left: 4px; padding-right: 4px">
      {{ props.schedule.terminalLabel }}
    </th>
    <GateScheduleTd
      v-for="shift in shiftKeys"
      :key="shift.key"
      :entry="entriesByDateShift.get(shift.key)"
      :schedule="props.schedule"
      :class="{
        isCurrentDay: shift.date.hasSame(props.now, 'day'),
      }"
    />
  </tr>
</template>

<style lang="scss">
.gate-schedule-row {
  button {
    margin: 2px;
  }
}
</style>
