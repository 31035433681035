<script lang="ts" setup>
import type { DateTime } from 'luxon'
import type { SlotSelectedEvent } from '../appointmentSlots/events'
import { CELL_POPOVER_WIDTH } from './constants'
import {
  type ReturnCategory,
  type ReturnRule,
  returnOptionKey,
} from '~/models/returnRules'
import type {
  ContainerType,
  Shift,
  ShippingLine,
  TerminalName,
} from '~/services/apiClient'
import { useEmptyAppointmentSlotsStore } from '~/stores/emptyAppointmentSlots'
import type { EmptyInAppointmentSlot } from '~/models/appointmentSlot'
import { shiftFromTimeAndShift } from '~/logic/shifts'
import type { AppointmentWithContainerInfo } from '~/models/appointmentWithContainerInfo'
import useAppointmentsForReturnRules from '~/compositions/useAppointmentsForReturnRules'
const props = defineProps<{
  returnRule: ReturnRule | null
  terminal: TerminalName
  shippingLine: ShippingLine
  containerType: ContainerType
  date: DateTime
  shift: Shift
  singleEmptyAppointmentsLookup: Map<
    TerminalName,
    Map<string, AppointmentWithContainerInfo[]>
  >
  searchedContainerNumber: string | null
}>()

// Refs
const bookingSlot = ref(null as null | EmptyInAppointmentSlot)
const addEmptyDialogTargetOpportunity =
  ref<AppointmentWithContainerInfo | null>(null)
const infoDialogOpen = ref(false)

// Stores
const emptyAppointmentSlotsStore = useEmptyAppointmentSlotsStore()

// Compositions
const {
  numDualOpportunities,
  existingDualAppointmentsLookup,
  getDualOpportunitiesForShift,
} = useAppointmentsForReturnRules()

// Computed
const closed = computed(() => {
  return props.returnRule?.closed ?? true
})
const shiftDesc = computed(() => {
  return shiftFromTimeAndShift(props.date, props.shift)
})

const bookedSingleEmptyAppointments = computed(() => {
  // console.log(props.appointmentsLookup)
  // console.log(`terminal: ${props.terminal}, shift: ${shiftDesc.value}`)
  return (
    props.singleEmptyAppointmentsLookup
      .get(props.terminal)
      ?.get(shiftDesc.value) || []
  )
})
const category = computed(() => {
  return {
    containerType: props.containerType,
    shippingLine: props.shippingLine,
    terminal: props.terminal,
  } as ReturnCategory
})
const slotReading = computed(() => {
  return emptyAppointmentSlotsStore.getSlotReading(category.value)
})
const availableShiftSlots = computed(() => {
  const slots = [] as EmptyInAppointmentSlot[]
  if (!slotReading.value) return slots
  for (const slot of slotReading.value.slots) {
    if (slot.shift_description === shiftDesc.value) {
      slots.push(slot)
    }
  }
  return slots
})
const errorLoadingSlots = computed(() => {
  return emptyAppointmentSlotsStore.getErrors(category.value)?.length
})

const loadingSlots = computed(() => {
  if (!props.returnRule) return false
  return emptyAppointmentSlotsStore.isLoading(props.returnRule.category)
})

const numAvailableSingleEmptySlots = computed((): number | null => {
  if (loadingSlots.value || errorLoadingSlots.value) return null
  let count = 0
  for (const slot of availableShiftSlots.value) {
    count += slot.num_appointments_available || 1
  }
  if (closed.value && count === 0) {
    return null
  } else {
    return count
  }
})

const dualOpportunities = computed(() => {
  if (numDualOpportunities.value > 0) {
    return getDualOpportunitiesForShift(props.terminal, props.date, props.shift)
  }
  return []
})
const existingDuals = computed(() => {
  const key = returnOptionKey({
    terminal: props.terminal,
    shift: props.shift,
    shiftDate: props.date,
  })
  return existingDualAppointmentsLookup.value.get(key) ?? []
})

// Functions

function startBooking(event: SlotSelectedEvent) {
  bookingSlot.value = event.slot as EmptyInAppointmentSlot
}
function startAddEmpty(opportunity: AppointmentWithContainerInfo) {
  addEmptyDialogTargetOpportunity.value = opportunity
}
const tdRef = ref()
</script>

<template>
  <!-- Actual cell -->
  <ReturnRulesTableTd
    ref="tdRef"
    :terminal="props.terminal"
    :return-rule="props.returnRule"
    :num-single-opportunities="numAvailableSingleEmptySlots"
    :num-dual-opportunities="dualOpportunities.length"
    :num-booked-singles="bookedSingleEmptyAppointments.length"
    :num-booked-duals="existingDuals.length"
  />
  <el-popover
    virtual-triggering
    :virtual-ref="tdRef"
    :width="CELL_POPOVER_WIDTH"
    trigger="click"
    :hide-after="0"
    transition="el-zoom-in-top"
    :persistent="false"
    class="break-normal"
  >
    <ReturnRulesPopoverBody
      :slots="availableShiftSlots"
      :slots-reading="slotReading"
      :return-rule="props.returnRule || null"
      :shift-single-empty-appointments="bookedSingleEmptyAppointments"
      :existing-duals="existingDuals"
      :dual-opportunities="dualOpportunities"
      :date="props.date"
      :shift="props.shift"
      @view-return-rules-screenshot="infoDialogOpen = true"
      @book="startBooking"
      @add-empty="startAddEmpty"
    />
  </el-popover>
  <EmptyBookingDialog
    v-if="bookingSlot"
    :booking-slot="bookingSlot"
    :model-value="true"
    :shipping-line="props.shippingLine"
    :return-rule="props.returnRule"
    :container-type="props.containerType"
    :terminal="props.terminal"
    :prefill-container-number="props.searchedContainerNumber"
    @close="bookingSlot = null"
  />

  <AddEmptyDialog
    v-if="addEmptyDialogTargetOpportunity"
    :container="addEmptyDialogTargetOpportunity.watch"
    :appointment="addEmptyDialogTargetOpportunity.appointment"
    :shipping-line="props.shippingLine"
    :container-type="props.containerType"
    :prefill-container-number="props.searchedContainerNumber"
    :show-return-rules="true"
    @close="addEmptyDialogTargetOpportunity = null"
  />
  <ReturnRuleInfoModal
    v-if="infoDialogOpen && returnRule"
    :model-value="infoDialogOpen"
    :return-rule="returnRule"
    @close="infoDialogOpen = false"
  />
</template>
