<script lang="ts" setup>
import { DateTime } from 'luxon'
import type { PropType } from 'vue'
import type { TerminalName } from '~/services/apiClient'
import { useHistoricalCameraImagesStore } from '~/stores/historicalCameraImages'

const props = defineProps({
  terminalName: { type: String as PropType<TerminalName>, required: true },
  streamName: { type: String, required: true },
})
const { terminalName, streamName } = toRefs(props)
const cameraHistoryStore = useHistoricalCameraImagesStore()
const date = ref(new Date())
const zone = Intl.DateTimeFormat().resolvedOptions().timeZone
const luxonDate = computed(() => DateTime.fromJSDate(date.value, { zone }))
function loadImages() {
  cameraHistoryStore.load({
    date: luxonDate.value,
    terminalName: terminalName.value,
    streamName: streamName.value,
  })
}
onMounted(loadImages)
watch([date, terminalName, streamName], loadImages)
const disabledDate = (time: Date) => {
  const earliestLoadedFromBeforeSwitchTo90DayRetention = DateTime.local(
    2022,
    5,
    23
  )
  const earliestRolling = DateTime.local().minus({ days: 90 })
  let earliest
  if (earliestRolling < earliestLoadedFromBeforeSwitchTo90DayRetention) {
    earliest = earliestLoadedFromBeforeSwitchTo90DayRetention
  } else {
    earliest = earliestRolling
  }
  return time.getTime() > Date.now() || time < earliest.toJSDate()
}
const shortcuts = [
  {
    text: 'Today',
    value: new Date(),
  },
  {
    text: 'Yesterday',
    value: () => {
      const date = new Date()
      date.setTime(date.getTime() - 3600 * 1000 * 24)
      return date
    },
  },
]
</script>

<template>
  <div v-loading="cameraHistoryStore.loading" class="affix-container">
    <h1 class="text-3xl mr-4 mb-2 font-semibold">
      <i-mdi:history class="align-text-bottom" />
      History
    </h1>
    <el-affix :offset="-4" target=".affix-container">
      <div class="mt-1 mb-1 py-2 flex bg-warm-gray-100">
        <el-button
          type="primary"
          :disabled="disabledDate(luxonDate.minus({ days: 1 }).toJSDate())"
          @click="date = luxonDate.minus({ days: 1 }).toJSDate()"
        >
          <i-eva:arrow-ios-back-fill class="align-bottom -ml-2 mr-1" />
          {{ luxonDate.minus({ days: 1 }).toFormat('ccc, LLL d') }}
        </el-button>
        <div class="mx-4">
          <el-date-picker
            v-model="date"
            type="date"
            placeholder="Pick a day"
            :disabled-date="disabledDate"
            :shortcuts="shortcuts"
            format="ddd, MMM D"
          />
        </div>
        <el-button
          type="primary"
          :disabled="disabledDate(luxonDate.plus({ days: 1 }).toJSDate())"
          @click="date = luxonDate.plus({ days: 1 }).toJSDate()"
        >
          {{ luxonDate.plus({ days: 1 }).toFormat('ccc, LLL d') }}
          <i-eva:arrow-ios-forward-fill class="align-bottom ml-1 -mr-2" />
        </el-button>
        <slot></slot>
      </div>
    </el-affix>

    <div
      v-for="[hourKey, images] in cameraHistoryStore.imagesByHour.entries()"
      :key="hourKey"
      v-loading="cameraHistoryStore.loading"
      class="max-w-full -mx-1"
    >
      <CameraHistoryRow
        :images="images"
        :terminal-name="terminalName"
        :stream-name="streamName"
        :start-time="luxonDate.set({ hour: hourKey, minute: 0, second: 0 })"
      />
    </div>

    <el-button
      type="primary"
      :disabled="disabledDate(luxonDate.minus({ days: 1 }).toJSDate())"
      class="w-full mt-2"
      @click="date = luxonDate.minus({ days: 1 }).toJSDate()"
    >
      <i-eva:arrow-ios-back-fill class="align-bottom -ml-2 mr-1" />
      View images for previous day ({{
        luxonDate.minus({ days: 1 }).toFormat('ccc, LLL d')
      }})
    </el-button>
  </div>
</template>
