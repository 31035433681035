<script lang="ts" setup>
import type { GETConnection } from '~/services/apiClient'

const props = defineProps<{ connection: GETConnection }>()
</script>

<template>
  <SFTPConnection
    :connection="props.connection"
    tms-name="TranData"
    :documentation-link="undefined"
  />
</template>
