<script lang="ts" setup>
import type {
  ContainerType,
  GETEmptyReturnRule,
  ShippingLine,
  TerminalName,
} from '~/services/apiClient'
import { EmptyReturnRulesApi } from '~/services/apiClient'

const props = defineProps({
  terminal: {
    type: String as PropType<TerminalName>,
    required: true,
  },
  readingFirstObserved: {
    type: String,
    required: true,
  },
})

const { readingFirstObserved, terminal } = toRefs(props)

const loading = ref(true)
const rulesByShiftAndSSL = ref(
  new Map<string, Map<ShippingLine, Map<ContainerType, GETEmptyReturnRule>>>()
)
const containerTypes = ref(new Set<ContainerType>())
const activeTab = ref('')
const numRules = ref(0)

const containerTypesSorted = computed(() => {
  const arr = Array.from(containerTypes.value)
  arr.sort()
  return arr
})

function clearRules() {
  rulesByShiftAndSSL.value = new Map()
  containerTypes.value = new Set()
}

function loadRules() {
  loading.value = true
  const api = new EmptyReturnRulesApi()
  api
    .getRulesByTerminalEmptyReturnRulesRulesByTerminalTerminalGet(
      props.terminal,
      //   ISO in UTC
      readingFirstObserved.value
    )
    .then((resp) => {
      clearRules()
      numRules.value = resp.data.length
      resp.data.forEach((rule) => {
        let shift_title = ''
        if (rule.date) {
          shift_title = rule.date
        }
        if (rule.shift) {
          shift_title =
            shift_title +
            (shift_title ? ' ' : '') +
            `${rule.shift.charAt(0).toUpperCase()}${rule.shift.slice(1)} Shift`
        }
        const ssl = rule.parsed_shipping_line
        const containerType = rule.parsed_container_type

        if (!ssl || !containerType) return // TODO: Want about these somewhere?
        containerTypes.value.add(containerType)
        if (!rulesByShiftAndSSL.value.has(shift_title)) {
          rulesByShiftAndSSL.value.set(shift_title, new Map())
        }
        const rulesBySSL = rulesByShiftAndSSL.value.get(shift_title)!
        if (rulesBySSL.has(ssl)) {
          rulesBySSL.get(ssl)!.set(containerType, rule)
        } else {
          const newMap = new Map<ContainerType, GETEmptyReturnRule>()
          newMap.set(containerType, rule)
          rulesBySSL.set(ssl, newMap)
        }
      })
      activeTab.value = Array.from(rulesByShiftAndSSL.value.keys())[0]
    })
    .catch((err) => {
      clearRules()
      numRules.value = 0
      throw err
    })
    .finally(() => {
      loading.value = false
    })
}
onMounted(loadRules)
watch([terminal], () => {
  clearRules()
  loadRules()
})
watch([readingFirstObserved], loadRules)
</script>

<template>
  <div v-loading="loading" class="min-h-20vh">
    <div
      v-if="numRules === 0"
      class="flex items-center justify-center h-20vh text-gray-500"
    >
      <template v-if="!loading">
        <i-mdi:alert class="mr-2" />
        We have not parsed this reading yet
      </template>
    </div>
    <div
      v-for="[shiftName, rulesBySSL] in rulesByShiftAndSSL.entries()"
      v-else
      :key="shiftName"
      class="px-4"
    >
      <h2 class="text-lg font-bold text-center mb-1">{{ shiftName }}</h2>
      <table class="w-full text-center mb-3">
        <thead>
          <tr>
            <th class="text-left">SSL</th>
            <th
              v-for="containerType in containerTypesSorted"
              :key="containerType"
            >
              {{ containerType }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="[shippingLine, rulesByContainerType] in rulesBySSL.entries()"
            :key="shippingLine"
          >
            <td class="text-left">
              <ShippingLineLink :shipping-line="shippingLine" />
            </td>
            <td
              v-for="containerType in containerTypesSorted"
              :key="containerType"
            >
              <ReturnRuleCell
                v-if="rulesByContainerType.has(containerType)"
                :rule="rulesByContainerType.get(containerType)!"
              />
              <template v-else> - </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style lang="scss" scoped>
td,
th {
  border: 1px solid gray;
}
// Add left padding to first td or th in each row
tr td:first-child,
tr th:first-child {
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  @apply bg-light-500;
}
td {
  padding: 0px;
}
th {
  @apply bg-light-700;
}
</style>
