<script lang="ts" setup>
import type { PropType } from 'vue'
import { useUsersStore } from '~/stores/users'
import { useUserStore } from '~/stores/user'
const props = defineProps({
  modelValue: { type: Array as PropType<number[]>, required: true },
})
const emit = defineEmits<{ (e: 'update:modelValue', value: number[]): void }>()
const assignedUserIDs = useVModel(props, 'modelValue', emit)
const usersStore = useUsersStore()
const userStore = useUserStore()

function selectLoggedInUser() {
  assignedUserIDs.value = usersStore.users
    .filter((user) => user.email === userStore.userEmail)
    .map((user) => user.id)
}
onMounted(() => {
  usersStore.load().then(selectLoggedInUser)
})
</script>

<template>
  <div>
    <el-form-item class="flex flex-col justify-start content-start">
      <p class="font-medium text-md">Assign containers to</p>
      <UserSelector v-model="assignedUserIDs" class="w-full" />
    </el-form-item>
  </div>
</template>
