<script setup lang="ts">
import { type ShiftGroup } from '~/models/groupedAppointments'
import type { WithCounts } from '~/components/display/containers/types'
import AppointmentCounts from '~/components/display/containers/AppointmentCounts.vue'

const props = defineProps<{
  shiftGroup: ShiftGroup
}>()
const counts = computed((): WithCounts => {
  return {
    numTransactions: props.shiftGroup.countTotal,
    numSingleEmptyAppointments: props.shiftGroup.countEmptyIn,
    numSingleImportAppointments: props.shiftGroup.countLoadOut,
    numDualTransactions: 0, // TODO
  }
})
</script>

<template>
  <div class="-ml-1 flex justify-between w-full">
    <!-- Left -->
    <div class="font-bold">
      {{ props.shiftGroup.date.toFormat('EEE M/d') }}
      •
      {{ shiftGroup.shortShiftName }} Shift
    </div>
    <!-- Right -->
    <div>
      <AppointmentCounts :group="counts" :show-labels="true" />
    </div>
  </div>
</template>
