<script lang="ts" setup>
const props = defineProps<{
  numDaysOutGated: number
}>()

const colorBadgeType = computed(() => {
  if (props.numDaysOutGated < 5) return 'success'
  else if (props.numDaysOutGated < 8) return 'warning'
  else return 'danger'
})
</script>

<template>
  <span>
    <ColoredBadge :type="colorBadgeType">
      {{ numDaysOutGated }} day{{ numDaysOutGated > 1 ? 's' : '' }}
    </ColoredBadge>
  </span>
</template>
