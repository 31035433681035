import type {
  AutoBookRequestPreset,
  GETOrgConfig,
  GETOrganization,
  OrganizationSubscription,
  UserRoleType,
} from '~/services/apiClient'
import { TerminalName } from '~/services/apiClient'

export class OrgConfig {
  // terminals?: TerminalOrgConfig[]
  terminals_to_not_prefill: Set<TerminalName>
  terminals_to_automatically_set_plate: Set<TerminalName>
  autoBookPresets: AutoBookRequestPreset[]
  constructor(config: GETOrgConfig | undefined) {
    const terminals = config?.terminals || []
    this.autoBookPresets = config?.auto_book_presets || []
    this.terminals_to_not_prefill = new Set()
    this.terminals_to_automatically_set_plate = new Set()
    if (config?.prefill_apm_dummy_license_plates === false) {
      this.terminals_to_not_prefill.add(TerminalName.Apm)
    }
    for (const terminalConfig of terminals) {
      if (terminalConfig.prefill_license_plates === false) {
        this.terminals_to_not_prefill.add(terminalConfig.terminal)
      }
      if (
        terminalConfig.set_unused_license_plate_if_no_plate_set_as_appointment_approaches ===
        true
      ) {
        this.terminals_to_automatically_set_plate.add(terminalConfig.terminal)
      }
    }
  }

  shouldPrefillLicensePlate(terminal: TerminalName): boolean {
    return !this.terminals_to_not_prefill.has(terminal)
  }

  shouldSetPlateIfNotSet(terminal: TerminalName): boolean {
    return this.terminals_to_automatically_set_plate.has(terminal)
  }

  toggleShouldPrefillLicensePlate(terminal: TerminalName): void {
    if (this.terminals_to_not_prefill.has(terminal)) {
      this.terminals_to_not_prefill.delete(terminal)
      // turn off automatic plate setting
      if (this.terminals_to_automatically_set_plate.has(terminal)) {
        this.terminals_to_automatically_set_plate.delete(terminal)
      }
    } else {
      this.terminals_to_not_prefill.add(terminal)
    }
  }

  toggleShouldSetPlateIfNotSet(terminal: TerminalName): void {
    if (this.terminals_to_automatically_set_plate.has(terminal)) {
      this.terminals_to_automatically_set_plate.delete(terminal)
    } else {
      this.terminals_to_automatically_set_plate.add(terminal)
      // turn off the prefill license plate setting
      if (!this.terminals_to_not_prefill.has(terminal)) {
        this.terminals_to_not_prefill.add(terminal)
      }
    }
  }

  toAPIFormat(): GETOrgConfig {
    return {
      prefill_apm_dummy_license_plates: !this.terminals_to_not_prefill.has(
        TerminalName.Apm
      ),
      terminals: Object.values(TerminalName)
        .map((terminal) => ({
          terminal,
          prefill_license_plates: !this.terminals_to_not_prefill.has(terminal),
          set_unused_license_plate_if_no_plate_set_as_appointment_approaches:
            this.terminals_to_automatically_set_plate.has(terminal),
        }))
        .filter((terminal) => terminal.prefill_license_plates === false),
      auto_book_presets: this.autoBookPresets,
    }
  }
}

export class Organization {
  id: number
  name: string
  scac: string | null
  role?: UserRoleType
  config: OrgConfig
  subscriptions: OrganizationSubscription[]
  constructor(org: GETOrganization) {
    this.id = org.id
    this.name = org.name
    this.scac = org.scac || null
    this.role = org.role
    this.config = new OrgConfig(org.config)
    this.subscriptions = org.subscriptions
  }

  get description(): string {
    return `${this.name} (${this.scac})`
  }
}
