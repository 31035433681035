<script setup lang="ts">
import CameraImage from './CameraImage.vue'
import { useLiveCameraStreams } from '~/compositions/useLiveCameraStreams'
function title(name: string) {
  return name.replace('_', ' ').toLocaleUpperCase()
}

const { images } = useLiveCameraStreams()
</script>

<template>
  <div
    class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-full gap-x-4 gap-y-2 p-0 pt-0"
  >
    <div
      v-for="img in images.values()"
      :key="img.streamName"
      class="flex"
      style="max-height: 28vh; min-height: 240px"
    >
      <TerminalCamerasLink :img="img" class="flex-1">
        <div class="flex flex-col h-full">
          <div class="text-dark-900 pb-1 flex justify-between items-end">
            <span class="text-md">{{ title(img.terminal) }}</span>
            <span class="text-xs mb-1px text-blue-gray-500 underline">
              <TerminalCamerasLink :img="img" anchor="#camera-history">
                <i-mdi:history
                  class="align-text-bottom mb-1px"
                  style="max-height: 1em"
                />
                View history
              </TerminalCamerasLink>
            </span>
          </div>
          <CameraImage :img="img" class="h-full w-full" />
        </div>
      </TerminalCamerasLink>
    </div>
  </div>
</template>
