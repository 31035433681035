<script lang="ts" setup>
import ColoredBadge from '../display/ColoredBadge.vue'
import type { ContainerBadgeInfo } from '~/models/containers'

const props = defineProps<{
  badge: ContainerBadgeInfo
}>()

function onClick() {
  if (props.badge.link) {
    const url = props.badge.link
    window.open(url, '_blank')
  }
}
</script>

<template>
  <ColoredBadge
    :key="props.badge.message"
    v-tooltip="props.badge.tooltip"
    :type="props.badge.type"
    class="mr-2"
    @click="onClick"
  >
    {{ props.badge.message }}
  </ColoredBadge>
</template>
