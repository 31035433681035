import { type ContainerFilters, useContainerFilters } from './containerFilters'
import { useVesselCountsStore } from '~/stores/vesselCounts'

export function useVesselCounts() {
  const { allFilters } = useContainerFilters()
  const vesselCountsStore = useVesselCountsStore()

  const filtersWithoutVessel = computed((): ContainerFilters => {
    return {
      ...allFilters.value,
      vessels: undefined,
    }
  })
  watch(filtersWithoutVessel, (newValue, oldValue) => {
    if (JSON.stringify(oldValue) === JSON.stringify(newValue)) {
      return // No change - no need to reload
    }
    loadVesselCounts()
  })
  onMounted(loadVesselCounts)
  function loadVesselCounts() {
    vesselCountsStore.load(filtersWithoutVessel.value)
  }
  const countByName = computed((): Map<string, number> => {
    return vesselCountsStore.countByName
  })
  return {
    countByName,
  }
}
