<script lang="ts" setup>
import type { GateScheduleReading } from '~/models/gateSchedules'
import type { ReturnRulesReading } from '~/models/returnRules'
const props = defineProps<{
  loadingHistory: boolean
  readingsHistory: GateScheduleReading[] | ReturnRulesReading[]
  selectedReading: GateScheduleReading | ReturnRulesReading | null
}>()
const emit = defineEmits<{
  'reading-clicked': [reading: GateScheduleReading | ReturnRulesReading]
}>()
</script>

<template>
  <div v-loading="loadingHistory" class="overflow-y-scroll p-4px">
    <el-timeline v-if="readingsHistory.length > 0" class="pr-3">
      <el-timeline-item
        v-for="(reading, index) in readingsHistory"
        :key="reading.last_changed_desc"
        color="#3f9eff"
        class="cursor-pointer"
        :class="{
          selected: props.selectedReading?.first_observed.equals(
            reading.first_observed
          ),
        }"
        @click="emit('reading-clicked', reading)"
      >
        <div class="version p-1">
          <div
            v-tooltip="
              reading.last_parsed_time
                ? ''
                : 'We have not parsed this reading yet'
            "
            :class="{ 'text-red-500': reading.last_parsed_time === null }"
          >
            <template v-if="index === 0">
              Since {{ reading.last_changed_desc }} (current rules)
            </template>
            <template v-else>
              {{ reading.last_changed_desc }} to
              {{ reading.last_observed_desc }}
            </template>
          </div>
          <div class="text-gray-500 text-xs mb-1">
            <!-- TODO: Add back live-updates of latest reading checked -->
            <!-- <template v-if="index === 0">
              Last checked {{ latestReadingLastCheckedDesc }}
              <br />
            </template> -->
            That's {{ reading.last_changed_human_desc }} for
            <span
              v-if="reading.first_observed.equals(reading.last_observed)"
              v-tooltip="
                `We only saw this reading once, so it may have been a fluke.`
              "
              class="text-red-700 font-semibold"
            >
              {{ reading.observed_for_desc }}
            </span>
            <template v-else>
              {{ reading.observed_for_desc }}
            </template>
          </div>
          <div class="flex mt-2">
            <el-button class="flex-1 mr-1 w-32">
              <i-mdi:eye class="align-middle mr-1" /> View
            </el-button>
            <a
              :href="reading.screenshot_download_link"
              class="flex-1"
              @click.stop=""
            >
              <el-button class="mr-0 w-32">
                <i-mdi:download class="align-middle" />
                Download
              </el-button>
            </a>
          </div>
        </div>
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<style scoped lang="scss">
.selected .version {
  background-color: #ffdba4;
  border-radius: 3px;
  border: 1px solid rgb(168, 168, 168);
}
</style>
