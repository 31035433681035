import type { DateTime } from 'luxon'
import type { Ref } from 'vue'
import { scaleLinear, scaleTime } from 'd3-scale'
import { toJSDate } from '~/utils'

export function useScales({
  start,
  end,
  apptsScaleMax,
  width,
  height,
}: {
  start: Ref<DateTime>
  end: Ref<DateTime>
  apptsScaleMax: Ref<number>
  width: Ref<number>
  height: Ref<number>
}) {
  start = ref(start)
  end = ref(end)
  width = ref(width)
  const timeScale = computed(() =>
    scaleTime()
      .range([0, width.value])
      .domain([toJSDate(start.value), toJSDate(end.value)])
  )
  const apptsScale = computed(() =>
    scaleLinear()
      .range([0, height.value])
      .domain([apptsScaleMax.value + 2, 0])
  )

  function getXForTime(time: DateTime) {
    return timeScale.value(toJSDate(time))
  }
  function getYForAppointments(numAppointments: number) {
    return apptsScale.value(numAppointments)
  }
  return { timeScale, apptsScale, getXForTime, getYForAppointments }
}
