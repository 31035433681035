<script lang="ts" setup>
import type { PropType } from 'vue'
import type { Container } from '~/models/containers'
import { truncate } from '~/utils'
import { useSimpleUserLabel } from '~/compositions/useSimpleUserLabel'

const props = defineProps({
  container: { type: Object as PropType<Container>, required: true },
  alwaysShowIcon: { type: Boolean, default: false },
})
const spanRef = ref()
const hover = useElementHover(spanRef)
const editing = ref(false)
const simpleUserLabel = useSimpleUserLabel()
const assignedUsersDescription = computed(() => {
  const users = props.container.assigned_users
  if (users.length === 0) return 'Unassigned'
  let desc = ''
  for (const user of users) {
    if (desc) desc += ', '
    desc += simpleUserLabel(user)
  }
  return desc
})
const tooltip = computed(() => {
  if (props.container.assigned_users.length === 0) {
    return 'Click to assign'
  } else {
    return `Assigned to ${assignedUsersDescription.value}. Click to edit`
  }
})
const truncatedAssignedUsersDesc = computed(() => {
  if (!assignedUsersDescription.value) return undefined
  return truncate(assignedUsersDescription.value, 44)
})
</script>

<template>
  <span
    ref="spanRef"
    v-tooltip="tooltip"
    class="cursor-pointer px-1 hover:border hover:rounded-3px hover:border-dashed hover:border-gray-700"
    @click="editing = true"
  >
    <span
      v-if="props.container.assigned_users"
      v-bind="$attrs"
      :class="{
        'text-gray-400': props.container.assigned_users.length === 0,
      }"
    >
      {{ truncatedAssignedUsersDesc }}
      <i-mdi:pencil
        v-if="alwaysShowIcon || hover"
        class="align-text-bottom mb-1px"
        style="font-size: 0.9em"
      />
    </span>
  </span>
  <EditAssignedUsersDialog
    v-if="editing"
    v-model="editing"
    :container="props.container"
  />
</template>
