<template>
  <el-card
    shadow="always"
    :body-style="{ padding: '20px' }"
    class="my-auto relative top-20vh max-w-440px mx-auto"
  >
    <template #header>
      <h1 class="text-center text-xl"><slot name="title"></slot></h1>
    </template>
    <slot />
  </el-card>
</template>
