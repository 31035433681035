<script lang="ts" setup>
import type { ShippingLine } from '~/services/apiClient'
import { useShippingLine } from '~/compositions/useShippingLines'

const props = defineProps({
  shippingLine: {
    type: String as PropType<ShippingLine>,
    required: true,
  },
})

const { getShippingLine } = useShippingLine()

const shippingLineInfo = computed(() => getShippingLine(props.shippingLine))
</script>

<template>
  <a
    v-if="shippingLineInfo"
    v-tooltip="
      `${shippingLineInfo.long_desc}. Click to visit ${shippingLineInfo.url} in a new tab`
    "
    :href="shippingLineInfo.url"
    target="_blank"
  >
    {{ shippingLineInfo.short_desc }}
  </a>
  <span v-else>{{ props.shippingLine }}</span>
</template>
