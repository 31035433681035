<script lang="ts" setup>
import { ElTag } from 'element-plus'
import { useElementSize } from '@vueuse/core'
import type { Container, ContainerBadgeInfo } from '~/models/containers'
const props = defineProps<{
  container: Container
}>()
const MIN_BADGE_WIDTH = 25
const displayBadges = ref([] as ContainerBadgeInfo[])
const overflowBadges = ref([] as ContainerBadgeInfo[])
const holder = ref(null)
const { width } = useElementSize(holder)

watchEffect(() => {
  const newBadges = []
  const newOverflowBadges = []
  let usedLength = 0
  const maxWidth = width.value - 3 // A little fudge factor
  for (const badge of props.container.badges) {
    const badgeLength = MIN_BADGE_WIDTH + badge.message.length * 7
    if (usedLength + badgeLength < maxWidth) {
      newBadges.push(badge)
      usedLength += badgeLength
    } else {
      newOverflowBadges.push(badge)
    }
  }
  displayBadges.value = newBadges
  overflowBadges.value = newOverflowBadges
})
</script>

<template>
  <div ref="holder" class="badge-holder w-full">
    <ContainerBadge
      v-for="badge in displayBadges"
      :key="badge.message"
      :badge="badge"
    />
    <el-popover
      v-if="overflowBadges.length"
      trigger="hover"
      :persistent="false"
      :width="200"
    >
      <ContainerBadge
        v-for="badge in overflowBadges"
        :key="badge.message"
        :badge="badge"
        class="my-1 mr-2"
      />

      <template #reference>
        <ElTag size="small" type="info" plain>
          +{{ overflowBadges.length }}
        </ElTag>
      </template>
    </el-popover>
  </div>
</template>

<style lang="scss" scoped>
.badge-holder {
  min-height: 24px;
}
</style>
