import { DateTime } from 'luxon'
import type {
  ContainerType,
  GETLineImportStatus,
  LineStatus,
  PortalName,
  ShippingLine,
  TerminalName,
} from '~/services/apiClient'

export class LineImportStatus {
  id: number
  container_number: string
  status: string
  parsed_status: LineStatus
  terminal: TerminalName
  last_free_day: DateTime | null
  source: PortalName
  observed: DateTime
  last_observed: DateTime
  parsed_shipping_line: ShippingLine | null
  parsed_container_type: ContainerType | null

  constructor(status: GETLineImportStatus) {
    this.id = status.id
    this.container_number = status.container_number
    this.status = status.status
    this.parsed_status = status.parsed_status
    this.terminal = status.terminal
    this.last_free_day = status.last_free_date
      ? DateTime.fromISO(status.last_free_date)
      : null
    this.source = status.source
    this.observed = DateTime.fromISO(status.observed)
    this.last_observed = DateTime.fromISO(status.last_observed)
    this.parsed_shipping_line = status.parsed_shipping_line ?? null
    this.parsed_container_type = status.parsed_container_type ?? null
  }
}
