<script lang="ts" setup>
import { useLuxonTimeAgo } from '~/compositions/timeAgo'
import { getTerminalLabel } from '~/constants'
import type { ReturnRule } from '~/models/returnRules'

const props = withDefaults(
  defineProps<{
    modelValue: boolean
    returnRule: ReturnRule
    appendToBody?: boolean
  }>(),
  { appendToBody: false }
)
const emit = defineEmits<{
  (e: 'close'): void
  (e: 'update:modelValue', value: boolean): void
}>()
function close() {
  emit('update:modelValue', false)
  emit('close')
}
const readingLastChanged = computed(() => {
  return props.returnRule.first_observed
})
const readingLastChecked = computed(() => {
  return props.returnRule.last_observed
})
const readingLastChangedDescription = useLuxonTimeAgo(readingLastChanged)
const readingLastCheckedDescription = useLuxonTimeAgo(readingLastChecked)
</script>

<template>
  <BaseDialog
    :model-value="props.modelValue"
    :proportional-width="0.8"
    :append-to-body="props.appendToBody"
    :max-width="1200"
    cancel-button-text="Close"
    :title="`${getTerminalLabel(returnRule.terminal)} Empty Return Rule Info`"
    @close="close"
  >
    <div class="text-center">
      <div class="flex justify-center">
        <div class="text-left mb-12">
          <div class="info">
            <span>
              {{ returnRule.terminal_label }} last updated these rules:
            </span>
            <strong>{{ readingLastChangedDescription }}</strong>
            (last checked {{ readingLastCheckedDescription }})
          </div>
          <div class="info">
            <span> Shipping Line as seen in source: </span>
            <strong>{{ returnRule.raw_shipping_line }}</strong>
          </div>
          <div class="info">
            <span> Container Type as seen in source: </span>
            <strong>{{ returnRule.raw_container_type }}</strong>
          </div>
        </div>
      </div>

      <img :src="returnRule.screenshot_url" class="mx-auto" />
    </div>
    <template #action-button>
      <a :href="returnRule.screenshot_download_link" class="flex-1">
        <el-button type="primary" size="large" class="w-full ml-2">
          <i-mdi:download />
          Download Screenshot
        </el-button>
      </a>
    </template>
  </BaseDialog>
</template>

<style scoped lang="scss">
.info {
  span {
    min-width: 240px;
    @apply text-right inline-block mr-2;
  }
}
</style>
