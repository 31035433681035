<script setup lang="ts">
import { DateTime } from 'luxon'
import type { PropType } from 'vue'
import type { CameraImage } from '~/models/cameraImage'

const props = defineProps({
  img: { type: Object as PropType<CameraImage>, required: true },
  large: { type: Boolean, default: false },
  preview: { type: Boolean, default: false },
})
const { img } = toRefs(props)
const currentTime = ref(DateTime.local())
onMounted(() => {
  setInterval(() => {
    currentTime.value = DateTime.local()
  }, 1000)
})

const title = computed(() => {
  let msg = `Observed at ${img.value.observed.toFormat(
    'H:mm'
  )} on ${img.value.observed.toFormat('ccc, LLL M/d')}
  (${img.value.observed.toRelative(currentTime.value)} ago)`
  if (props.preview) {
    msg += '. Click to enlarge'
  }
  return msg
})
</script>

<template>
  <div class="rounded-3px overflow-hidden">
    <el-image
      v-tooltip="title"
      v-bind="$attrs"
      :src="props.large ? img.largeImageURL : img.url"
      :alt="`Camera outside ${img.terminal} terminal`"
      loading="lazy"
      :preview-src-list="props.preview ? [img.largeImageURL] : undefined"
      :hide-on-click-modal="true"
      class="shadow-2xl"
    >
      <template #placeholder>
        <el-skeleton :loading="true" animated>
          <template #template>
            <el-skeleton-item variant="image" class="min-h-160px" />
          </template>
        </el-skeleton>
      </template>
    </el-image>
  </div>
</template>
