<script lang="ts" setup>
import type { ReturnRulesReading } from '~/models/returnRules'
import { useReturnRulesHistory } from '~/stores/returnRuleReadingsHistory'
const props = defineProps<{
  selectedReading: ReturnRulesReading | null
}>()
const emit = defineEmits<{
  'reading-clicked': [reading: ReturnRulesReading]
}>()
const emptyReturnRulesHistoryStore = useReturnRulesHistory()
const readingsHistory = toRef(emptyReturnRulesHistoryStore, 'readings')
const loadingHistory = toRef(emptyReturnRulesHistoryStore, 'loading')
</script>

<template>
  <BaseReadingHistory
    :selected-reading="props.selectedReading"
    :readings-history="readingsHistory"
    :loading-history="loadingHistory"
    @reading-clicked="emit('reading-clicked', $event)"
  ></BaseReadingHistory>
</template>
