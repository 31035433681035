<script lang="ts" setup>
import { getTerminalLabel } from '~/constants'
import type { TerminalGateSchedule } from '~/models/gateSchedules'

const props = defineProps<{
  modelValue: boolean
  gateSchedule: TerminalGateSchedule
}>()

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'update:modelValue', value: boolean): void
}>()
function close() {
  emit('update:modelValue', false)
  emit('close')
}
</script>

<template>
  <BaseDialog
    :model-value="props.modelValue"
    :proportional-width="0.8"
    :max-width="1200"
    cancel-button-text="Close"
    :title="`${getTerminalLabel(gateSchedule.terminal)} Gate Schedule Screenshot`"
    @close="close"
  >
    <div class="text-center">
      <img :src="gateSchedule.screenshot_url" class="mx-auto" />
    </div>
    <template #action-button>
      <a :href="gateSchedule.screenshot_download_link" class="flex-1">
        <el-button type="primary" size="large" class="w-full ml-2">
          <i-mdi:download />
          Download Screenshot
        </el-button>
      </a>
    </template>
  </BaseDialog>
</template>

<style scoped lang="scss">
.info {
  span {
    min-width: 240px;
    @apply text-right inline-block mr-2;
  }
}
</style>
