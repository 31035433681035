<script lang="ts" setup>
import type { AppointmentWithContainerInfo } from '~/models/appointmentWithContainerInfo'

const props = defineProps<{
  appointmentsWithContainers: AppointmentWithContainerInfo[]
  showTerminal: boolean
  showCancelButton: boolean
}>()
</script>

<template>
  <el-popover
    v-if="props.appointmentsWithContainers.length > 0"
    placement="bottom"
    width="500"
    :hide-after="0"
  >
    <template #reference>
      <el-button
        type="primary"
        size="default"
        class="mt-1 relative w-full"
        style="padding-right: 20px"
      >
        {{ appointmentsWithContainers[0].container_number }}

        ({{
          appointmentsWithContainers[0].appointment.display_terminal_reference
        }})
        <template v-if="appointmentsWithContainers.length > 1">
          +{{ appointmentsWithContainers.length - 1 }} </template
        ><i-mdi:chevron-down class="absolute" style="right: 0px" />
      </el-button>
    </template>
    <template #default>
      <BookedEmptyAppointmentsTable
        :appointments-with-containers="appointmentsWithContainers"
        :show-terminal="showTerminal"
        :show-cancel-button="showCancelButton"
        class="w-full"
      />
    </template>
  </el-popover>
</template>
