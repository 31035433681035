import type { DateTime } from 'luxon'
import { Shift } from '~/services/apiClient'

export function shiftFromTimeAndShift(date: DateTime, shift: Shift): string {
  const dateStr = date.toFormat('ccc M/d')
  const shiftDesc = shiftNameFromShift(shift)
  return `${dateStr} - ${shiftDesc}`
}
export function shiftNameFromShift(shift: Shift): string {
  let shiftDesc: string
  switch (shift) {
    case Shift.First:
      shiftDesc = '1st'
      break
    // For the main app, I had made the decision to combine 2nd and 3rd shifts
    case Shift.Second:
    case Shift.Third:
      shiftDesc = '2nd'
      break
  }
  return shiftDesc + ' shift'
}

export function shortShiftName(shift: Shift): string {
  switch (shift) {
    case Shift.First:
      return '1st'
    case Shift.Second:
      return '2nd'
    case Shift.Third:
      return '3rd'
  }
}

const FIRST_SHIFT_START_HOUR = 6
const SECOND_SHIFT_START_HOUR = 17
const THIRD_SHIFT_START_HOUR = 3

export function shiftFromTime(dt: DateTime): [DateTime, Shift] {
  const shiftStart = shiftStartFromTime(dt)
  const shift = shiftFromHour(shiftStart.hour)
  let shiftDate: DateTime
  if (shift === Shift.Third) {
    shiftDate = shiftStart.minus({ days: 1 })
  } else {
    shiftDate = shiftStart
  }
  return [shiftDate, shift]
}
export function shiftFromHour(hour: number): Shift {
  let shift: Shift
  if (hour === FIRST_SHIFT_START_HOUR) {
    shift = Shift.First
  } else if (hour === SECOND_SHIFT_START_HOUR) {
    shift = Shift.Second
  } else if (hour === THIRD_SHIFT_START_HOUR) {
    shift = Shift.Third
  } else {
    throw new Error(`Invalid shift start hour: ${hour}`)
  }
  return shift
}
export function shiftNameFromTime(dt: DateTime): string {
  const [shiftDate, shift] = shiftFromTime(dt)
  return shiftFromTimeAndShift(shiftDate, shift)
}

export function shiftStartFromTime(dt: DateTime): DateTime {
  // Returns "real" time of shift start, so third shift will start on the next day
  let shiftStart: DateTime
  const startHour = getShiftStartHour(dt)
  if (dt.hour < THIRD_SHIFT_START_HOUR) {
    // Adjust for overnight shifts
    shiftStart = dt
      .minus({ days: 1 })
      .set({ hour: startHour, minute: 0, second: 0 })
  } else {
    shiftStart = dt.set({ hour: startHour, minute: 0, second: 0 })
  }
  return shiftStart
}

function getShiftStartHour(dt: DateTime): number {
  if (dt.hour >= FIRST_SHIFT_START_HOUR && dt.hour < SECOND_SHIFT_START_HOUR) {
    return FIRST_SHIFT_START_HOUR
  } else if (
    dt.hour >= SECOND_SHIFT_START_HOUR ||
    dt.hour < THIRD_SHIFT_START_HOUR
  ) {
    return SECOND_SHIFT_START_HOUR
  } else {
    return THIRD_SHIFT_START_HOUR
  }
}

export function shiftToInteger(shift: Shift): number {
  switch (shift) {
    case Shift.First:
      return 1
    case Shift.Second:
      return 2
    case Shift.Third:
      return 3
  }
}

export function sortableShiftKey(shiftDate: DateTime, shift: Shift): string {
  const shiftInt = shiftToInteger(shift)
  return `${shiftDate.toFormat('yyyy-MM-dd')}--${shiftInt}`
}

export function shiftWindowStart(shiftDate: DateTime, shift: Shift): DateTime {
  switch (shift) {
    case Shift.First:
      return shiftDate.set({ hour: FIRST_SHIFT_START_HOUR })
    case Shift.Second:
      return shiftDate.set({ hour: SECOND_SHIFT_START_HOUR })
    case Shift.Third:
      return shiftDate.plus({ days: 1 }).set({ hour: THIRD_SHIFT_START_HOUR })
  }
}

export function shiftWindowEnd(shiftDate: DateTime, shift: Shift): DateTime {
  switch (shift) {
    case Shift.First:
      return shiftDate.set({ hour: SECOND_SHIFT_START_HOUR })
    case Shift.Second:
      return shiftDate.plus({ days: 1 }).set({ hour: THIRD_SHIFT_START_HOUR })
    case Shift.Third:
      return shiftDate.plus({ days: 1 }).set({ hour: FIRST_SHIFT_START_HOUR })
  }
}
