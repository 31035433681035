export function makeTimeLabel(minutes: number): string {
  const timeLabels: { [key: number]: string } = {
    15: '15 Minutes',
    30: '30 Minutes',
    60: '1 Hour',
    120: '2 Hours',
    240: '4 Hours',
    480: '8 Hours',
    720: '12 Hours',
    1440: '1 Day',
  }

  if (timeLabels[minutes]) {
    return timeLabels[minutes]
  } else {
    return `${minutes} Minutes`
  }
}
