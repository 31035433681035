<script lang="ts" setup>
import startCase from 'lodash-es/startCase'
import { DateTime } from 'luxon'
import { Icon } from '@iconify/vue'
import { GateTransaction } from '~/models/gateTransaction'

const props = defineProps({
  gateTransaction: { type: GateTransaction, required: true },
})
const gateTransaction = toRef(props, 'gateTransaction')

function formatTime(dt: DateTime) {
  return dt.toLocaleString(DateTime.DATETIME_MED)
}
const icon = computed((): string => {
  switch (gateTransaction.value.direction) {
    case 'inbound':
      return 'ion:enter-outline'
    case 'outbound':
      return 'ion:exit-outline'
    default:
      throw new Error(`Invalid direction ${gateTransaction.value.direction}`)
  }
})
</script>

<template>
  <DetailHeader>
    <Icon :icon="icon" class="align-middle" height="20" />
    Latest {{ startCase(gateTransaction.direction) }} Gate Transaction
  </DetailHeader>
  <el-descriptions border class="flex-1" :column="1">
    <el-descriptions-item label="Time">
      {{ formatTime(gateTransaction.time) }}
    </el-descriptions-item>
    <el-descriptions-item label="Loaded">
      {{ startCase(gateTransaction.loaded.toString()) }}
    </el-descriptions-item>
    <el-descriptions-item label="Terminal">
      {{ gateTransaction.terminalName }}
    </el-descriptions-item>
    <el-descriptions-item label="Drivers License #">
      {{ gateTransaction.drivers_license_number || 'N/A' }}
    </el-descriptions-item>
    <el-descriptions-item label="Truck License Plate #">
      {{ gateTransaction.truck_license_plate || 'N/A' }}
    </el-descriptions-item>
    <el-descriptions-item label="Truck RFID #">
      {{ gateTransaction.truck_rfid || 'N/A' }}
    </el-descriptions-item>
    <el-descriptions-item label="Chassis #">
      {{ gateTransaction.chassis_number || 'N/A' }}
    </el-descriptions-item>
    <template v-if="gateTransaction.extra_data">
      <el-descriptions-item
        v-for="(value, fieldName) in gateTransaction.extra_data"
        :key="fieldName"
        :label="startCase(fieldName.toLowerCase().replace('_', ' '))"
      >
        {{ value ?? 'N/A' }}
      </el-descriptions-item>
    </template>
  </el-descriptions>
</template>
