<script lang="ts" setup>
import type { DateTime } from 'luxon'
import type { SlotSelectedEvent } from '../appointmentSlots/events'
import type { TimeSlot } from './types'
import type {
  EmptyInAppointmentSlot,
  EmptyInAppointmentSlotReading,
} from '~/models/appointmentSlot'
import type { ReturnRule } from '~/models/returnRules'
import type { Shift } from '~/services/apiClient'
import { useEmptyAppointmentSlotsStore } from '~/stores/emptyAppointmentSlots'
import { useLuxonTimeAgo } from '~/compositions/timeAgo'
import type { AppointmentWithContainerInfo } from '~/models/appointmentWithContainerInfo'
import { useUserStore } from '~/stores/user'

const props = defineProps<{
  returnRule: ReturnRule | null
  slotsReading: EmptyInAppointmentSlotReading | undefined
  slots: EmptyInAppointmentSlot[]
  shiftSingleEmptyAppointments: AppointmentWithContainerInfo[]
  dualOpportunities: AppointmentWithContainerInfo[]
  existingDuals: AppointmentWithContainerInfo[]
  date: DateTime
  shift: Shift
}>()
const emit = defineEmits({
  viewReturnRulesScreenshot: () => true,
  addEmpty: (opportunity: AppointmentWithContainerInfo) => true,
  book: (event: SlotSelectedEvent) => true,
})

const userStore = useUserStore()
const slotsLastLoaded = computed(() => {
  return props.slotsReading?.observed || null
})
const slotsLastLoadedDescription = useLuxonTimeAgo(slotsLastLoaded)

const emptyAppointmentSlotsStore = useEmptyAppointmentSlotsStore()

const loadingSlots = computed(() => {
  if (!props.returnRule) return false
  return emptyAppointmentSlotsStore.isLoading(props.returnRule.category)
})

const timeSlots = computed(() => {
  const byTime = new Map<string, TimeSlot>()
  // Add available single empty slots
  for (const slot of props.slots) {
    const time = slot.window_start.toISO()
    byTime.set(time, {
      window_start: slot.window_start,
      window_end: slot.window_end,
      slot,
      existingSingleEmptyAppointments: [],
      dualOpportunities: [],
      existingDualEmptyAppointments: [],
    })
  }
  // Add booked single empty appointments
  for (const appointmentInfo of props.shiftSingleEmptyAppointments) {
    const appointment = appointmentInfo.appointment
    const time = appointment.window_start.toISO()
    if (!byTime.has(time)) {
      byTime.set(time, {
        window_start: appointment.window_start,
        window_end: appointment.window_end,
        slot: undefined,
        existingSingleEmptyAppointments: [],
        dualOpportunities: [],
        existingDualEmptyAppointments: [],
      })
    }
    const slot = byTime.get(time)!
    slot.existingSingleEmptyAppointments.push(appointmentInfo)
  }
  // Add dual opportunities
  for (const dualOpportunity of props.dualOpportunities) {
    const time = dualOpportunity.appointment.window_start.toISO()
    if (!byTime.has(time)) {
      byTime.set(time, {
        window_start: dualOpportunity.appointment.window_start,
        window_end: dualOpportunity.appointment.window_end,
        slot: undefined,
        existingSingleEmptyAppointments: [],
        dualOpportunities: [],
        existingDualEmptyAppointments: [],
      })
    }
    const slot = byTime.get(time)!
    slot.dualOpportunities.push(dualOpportunity)
  }

  // Add existing duals
  for (const existingDual of props.existingDuals) {
    const time = existingDual.appointment.window_start.toISO()
    if (!byTime.has(time)) {
      byTime.set(time, {
        window_start: existingDual.appointment.window_start,
        window_end: existingDual.appointment.window_end,
        slot: undefined,
        existingSingleEmptyAppointments: [],
        dualOpportunities: [],
        existingDualEmptyAppointments: [],
      })
    }
    const slot = byTime.get(time)!
    slot.existingDualEmptyAppointments.push(existingDual)
  }

  // Package and sort
  const timeSlotsArray = Array.from(byTime.values())
  timeSlotsArray.sort(
    (a, b) => a.window_start.diff(b.window_start).milliseconds
  )
  return timeSlotsArray
})
</script>

<template>
  <div class="break-normal bg-white p-0">
    <!-- Return rules -->
    <ReturnRulesCellCard
      :return-rule="returnRule"
      @view-return-rules-screenshot="emit('viewReturnRulesScreenshot')"
    />

    <!-- Available appointments + booked appointments -->
    <table v-if="timeSlots.length > 0" class="mt-4">
      <thead>
        <tr>
          <th></th>
          <th colspan="2">
            <i-mdi:calendar-plus class="align-text-bottom" />
            Available:
          </th>
          <th colspan="2">
            <i-mdi:calendar-check class="align-text-bottom" />
            Booked:
          </th>
        </tr>
        <tr>
          <th></th>
          <th class="main-column">
            Singles
            <EmptyReturnIcon class="align-text-bottom -mb-1px" />
          </th>
          <th class="main-column">
            Duals
            <DualsIcon class="align-text-bottom -mb-1px" />
            <Help title="Dual opportunities" class="inline-block ml-1">
              <p>
                A "dual opportunity" is an import (outbound+loaded) appointment
                with a compatible container type that does not have an inbound
                container attached to it yet
              </p>
              <p>
                You should verify that the dual is valid (including chassis)
                before booking
              </p>
            </Help>
          </th>
          <th class="main-column">
            Singles
            <EmptyReturnIcon class="align-text-bottom -mb-1px" />
          </th>
          <th class="main-column">
            Duals
            <DualsIcon class="align-text-bottom -mb-1px" />
          </th>
        </tr>
      </thead>
      <tbody>
        <ReturnRulesPopoverBodyTr
          v-for="slot in timeSlots"
          :key="slot.window_start.toISO()"
          :time-slot="slot"
          class="mb-1"
          @book="emit('book', $event)"
          @add-empty="emit('addEmpty', $event)"
        />
      </tbody>
    </table>
    <template
      v-else-if="returnRule && !returnRule.closed && !userStore.isTerminalOrg"
    >
      <hr class="mt-4" />
      <div class="mt-4 text-lg">
        <div v-if="loadingSlots">Loading appointments...</div>
        <div v-else-if="timeSlots.length === 0" class="text-red-800">
          No appointments available
        </div>
      </div>
    </template>
    <!-- Readings last read info -->
    <div v-if="slotsLastLoadedDescription" class="text-gray-400 text-xs mt-4">
      Available appointment times last checked {{ slotsLastLoadedDescription }}
    </div>
  </div>
</template>

<style scoped lang="scss">
table {
  @apply w-full;
  thead {
    tr {
      @apply border-b;
    }
    th {
      @apply py-1;
    }
    tr {
      th {
        @apply font-semibold text-gray-800;
      }
    }
  }
  .main-column {
    width: 160px;
  }
}
</style>
