import { DateTime, Duration } from 'luxon'
import type { Appointment } from '~/models/appointments'
import type { AppointmentWithContainerInfo } from '~/models/appointmentWithContainerInfo'
import type { LinkedAppointment } from '~/services/apiClient'
import { AppointmentStatus, TransactionDirection } from '~/services/apiClient'
import { AppointmentTransaction } from '~/models/groupedAppointments'

export function groupAppointmentsIntoTransactions(
  appointments: AppointmentWithContainerInfo[]
): AppointmentTransaction[] {
  const relevantAppointments =
    limitToReleventTransactionsPerContainerAndMoveType(appointments)
  const groups = groupAppointmentsIntoLinkedGroups(relevantAppointments)
  return groups.map(createTransactionFromGroup)
}

function groupAppointmentsIntoLinkedGroups(
  appointments: AppointmentWithContainerInfo[]
): AppointmentWithContainerInfo[][] {
  // Create maps to store appointments and their connections
  const appointmentsByKey = new Map<string, AppointmentWithContainerInfo>()
  const adjacency = new Map<string, Set<string>>()

  // Populate the maps with appointments and their linked appointment --s
  for (const apptInfo of appointments) {
    const apptKey = makeAppointmentKey(apptInfo.appointment)
    appointmentsByKey.set(apptKey, apptInfo)
    adjacency.set(apptKey, new Set())

    const linkedKeys =
      apptInfo.appointment.linked_appointments.map(makeAppointmentKey)
    for (const linkedKey of linkedKeys) {
      // Add bidirectional links between appointments
      adjacency.get(apptKey)!.add(linkedKey)
      if (!adjacency.has(linkedKey)) {
        adjacency.set(linkedKey, new Set())
      }
      adjacency.get(linkedKey)!.add(apptKey)
    }
  }

  // Use depth-first search to group linked appointments
  const visited = new Set<string>()
  const groups: AppointmentWithContainerInfo[][] = []

  for (const key of adjacency.keys()) {
    if (!visited.has(key)) {
      const group: AppointmentWithContainerInfo[] = []
      const stack = [key]

      // Perform depth-first search
      while (stack.length > 0) {
        const currKey = stack.pop()!
        if (!visited.has(currKey)) {
          visited.add(currKey)
          const apptInfo = appointmentsByKey.get(currKey)
          if (apptInfo) {
            group.push(apptInfo)
          }
          // Add unvisited neighbors to the stack
          for (const neighbor of adjacency.get(currKey)!) {
            if (!visited.has(neighbor)) {
              stack.push(neighbor)
            }
          }
        }
      }

      // Add the completed group to the list of groups
      groups.push(group)
    }
  }

  return groups
}

export function createTransactionFromGroup(
  group: AppointmentWithContainerInfo[]
): AppointmentTransaction {
  const transaction = new AppointmentTransaction()
  const [inboundAppointments, outboundAppointments] =
    splitInboundOutbound(group)

  transaction.inbound_appointment =
    selectBestInboundAppointment(inboundAppointments)
  transaction.outbound_appointment =
    selectBestOutboundAppointment(outboundAppointments)

  return transaction
}

function splitInboundOutbound(
  group: AppointmentWithContainerInfo[]
): [AppointmentWithContainerInfo[], AppointmentWithContainerInfo[]] {
  const inbound: AppointmentWithContainerInfo[] = []
  const outbound: AppointmentWithContainerInfo[] = []

  for (const apptInfo of group) {
    if (apptInfo.appointment.direction === TransactionDirection.Inbound) {
      inbound.push(apptInfo)
    } else if (
      apptInfo.appointment.direction === TransactionDirection.Outbound
    ) {
      outbound.push(apptInfo)
    }
  }

  return [inbound, outbound]
}

function selectBestInboundAppointment(
  inboundAppointments: AppointmentWithContainerInfo[]
): AppointmentWithContainerInfo | undefined {
  // TODO: Implement logic to select the best inbound appointment
  // For now, we'll just return the first one if it exists
  return inboundAppointments[0]
}

function selectBestOutboundAppointment(
  outboundAppointments: AppointmentWithContainerInfo[]
): AppointmentWithContainerInfo | undefined {
  // TODO: Implement logic to select the best outbound appointment
  // For now, we'll just return the first one if it exists
  return outboundAppointments[0]
}

function makeAppointmentKey(
  appointment: Appointment | LinkedAppointment
): string {
  return `${appointment.container_number}!!${appointment.terminal_reference}`
}

function limitToReleventTransactionsPerContainerAndMoveType(
  appointments: AppointmentWithContainerInfo[]
): AppointmentWithContainerInfo[] {
  const map = new Map<string, AppointmentWithContainerInfo[]>()
  // Group by container number + move type
  for (const appointment of appointments) {
    // TODO: Have this be controlled at a higher level/with a parameter, but for now
    // this is easiest
    if (
      appointment.appointment.status !== AppointmentStatus.Scheduled &&
      appointment.appointment.status !== AppointmentStatus.InProgress &&
      appointment.appointment.status !== AppointmentStatus.Completed
    ) {
      continue
    }
    const key = `${appointment.appointment.container_number}-${appointment.appointment.moveType}`
    if (!map.has(key)) {
      map.set(key, [appointment])
    } else {
      map.get(key)!.push(appointment)
    }
  }
  const results: AppointmentWithContainerInfo[] = []
  // Select best appointment for each group
  for (const appointments of map.values()) {
    results.push(selectBestAppointment(appointments))
  }
  return results
}
function selectBestAppointment(
  appointments: AppointmentWithContainerInfo[]
): AppointmentWithContainerInfo {
  appointments.sort((a, b) => {
    return appointmentScore(b.appointment) - appointmentScore(a.appointment)
  })
  return appointments[0]
}

const STALE_APPOINTMENT_THRESHOLD = Duration.fromObject({ hours: 2 })

function isStaleAppointment(appointment: Appointment): boolean {
  const windowEnd =
    appointment.window_end || appointment.window_start.plus({ hours: 1 })
  const windowEndStaleThreshold = DateTime.now().minus(
    STALE_APPOINTMENT_THRESHOLD
  )

  return (
    (appointment.status === AppointmentStatus.Scheduled ||
      appointment.status === AppointmentStatus.InProgress) &&
    windowEnd < windowEndStaleThreshold
  )
}

function isActiveAppointment(appointment: Appointment): boolean {
  /**
   * "Active" means stuff that could still be used or is being used.
   * Completed, cancelled, and stale scheduled appointments are not active.
   */
  return (
    !isStaleAppointment(appointment) &&
    (appointment.status === AppointmentStatus.Scheduled ||
      appointment.status === AppointmentStatus.InProgress)
  )
}

function appointmentScore(appointment: Appointment): number {
  const now = DateTime.now()
  let dt =
    appointment.created_at_terminal ||
    appointment.last_observed ||
    now.minus({ days: 1 }) // Should never be used, but just in case
  const isActive = isActiveAppointment(appointment)

  if (
    appointment.status !== AppointmentStatus.Scheduled &&
    appointment.status !== AppointmentStatus.Completed
  ) {
    dt = dt.minus({ hours: 12 })
  }

  // Create a numeric score that combines the active status and datetime
  // Active appointments get a higher base score
  const baseScore = isActive ? 1000000000000 : 0

  // Add the timestamp in milliseconds to the base score
  // This ensures that more recent appointments within the same active/inactive group are ranked higher
  const timeScore = dt.toMillis() - now.toMillis()

  return baseScore + timeScore
}
