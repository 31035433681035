<script setup lang="ts">
import ClickToCopy from '~/components/ClickToCopy.vue'

const props = defineProps<{
  appointmentReference: string
}>()
</script>

<template>
  <div class="w-full truncate-text">
    <ClickToCopy
      :text-to-copy="props.appointmentReference"
      tooltip-text="Click to copy appointment number"
    />
  </div>
</template>

<style scoped>
.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
</style>
