<script setup lang="ts">
import type { AppointmentWithContainerInfo } from '~/models/appointmentWithContainerInfo'
const props = defineProps<{
  appointment: AppointmentWithContainerInfo | undefined | null
}>()
const shiftRealDateMismatch = computed(() => {
  return (
    props.appointment?.appointment.window_start.toISODate() !==
    props.appointment?.appointment.shiftDate.toISODate()
  )
})
const dateTooltip = computed(() => {
  if (shiftRealDateMismatch.value) {
    const shiftDate = props.appointment?.appointment.shiftDate.toFormat('M/d')
    const realDate = props.appointment?.appointment.window_start.toFormat('M/d')
    return `Shift date is ${shiftDate} but "real" date is ${realDate}`
  }
  return null
})
</script>

<template>
  <div v-if="props.appointment">
    <span v-tooltip="dateTooltip" class="text-gray-500 mr-1">
      {{ props.appointment.appointment.window_start.toFormat('M/d') }}
    </span>
    <span class="appt-window">
      {{ props.appointment.appointment.window_description }}</span
    >
  </div>
  <div v-else></div>
</template>

<style scoped lang="scss">
.appt-window {
  @apply inline-block mr-1;
  min-width: 90px;
}
</style>
