import type { ChannelAuthorizationCallback } from 'pusher-js'
import Pusher from 'pusher-js'
import * as Sentry from '@sentry/browser'
import { UsersApi } from './apiClient'

export function connectToSoketi(): Pusher {
  // https://www.npmjs.com/package/pusher-js
  const client = new Pusher('draydog', {
    wsHost: 'ws.draydog.com',
    wsPort: 443,
    wssPort: 443,
    cluster: 'not_used', // Not used but typing requires it
    forceTLS: true,
    // This needs to be both ws and wss of the connection will have state "failed." Per
    // the Pusher docs, they way to force TLS is to set forceTLS to true but still have
    // ws and wss in the enabledTransports array.
    enabledTransports: ['ws', 'wss'],
    disableStats: true, // These would be sent to pusher.com
    // authEndpoint: 'https://api.draydog.com/pusher/auth',
    channelAuthorization: {
      // https://pusher.com/docs/channels/using_channels/connection/#userauthcustomhandler-1319001424
      customHandler: (
        info: { channelName: string; socketId: string },
        callback: ChannelAuthorizationCallback
      ) => {
        // I was going to throw some caching in here, but it runs out that auth tokens
        // are tied to socketIDs so it doesn't really work to cache them
        const usersAPI = new UsersApi()
        usersAPI
          .getTokenForPusherUsersTokenForPusherPost(
            info.channelName,
            info.socketId
          )
          .then((resp) => {
            // resp.data is not typed because we are just passing through what Pusher
            // sends
            callback(null, { auth: resp.data.auth })
          })
      },
    },
    // pongTimeout: 10000,
    // unavailableTimeout: 20000,
  })
  // https://pusher.com/docs/channels/using_channels/connection/#available-states
  if (client.connection.state === 'failed') {
    Sentry.captureMessage('Pusher connection failed, possibly misconfigured')
  }
  // client.connection.bind_global((eventName, data) => {
  //   console.log('global Soketi connection event:', eventName, data)
  // })
  return client
}
