<script lang="ts" setup>
import startCase from 'lodash-es/startCase'
import { Appointment } from '~/models/appointments'
const props = defineProps({
  appointment: {
    type: Appointment,
    required: true,
  },
})
const appointment = toRef(props, 'appointment')
</script>

<template>
  <ObjectChangeTimesDisplay :object="appointment" />
  <el-descriptions border class="flex-1" :column="1">
    <el-descriptions-item label="Container Number">
      <ClickToCopy :text-to-copy="appointment.container_number" />
    </el-descriptions-item>
    <el-descriptions-item label="Time">
      <AppointmentTimeDisplay :appointment="appointment">
        {{ appointment.window_date }},
        {{ appointment.window_description }}</AppointmentTimeDisplay
      >
    </el-descriptions-item>
    <el-descriptions-item label="License Plate">
      {{ appointment.truck_license_plate_number ?? 'N/A' }}
    </el-descriptions-item>
    <el-descriptions-item label="Terminal Appt Number">
      <span v-if="appointment.display_terminal_reference"
        ><ClickToCopy :text-to-copy="appointment.display_terminal_reference" />
      </span>
      <span v-else>
        {{ 'N/A' }}
      </span>
    </el-descriptions-item>
    <el-descriptions-item label="Booked By">
      <span v-if="appointment.booked_by">
        {{ appointment.booked_by }}
      </span>
      <span v-else> Unknown (booked off Dray Dog) </span>
    </el-descriptions-item>
    <template v-if="appointment.extra_data">
      <el-descriptions-item
        v-for="(value, fieldName) in appointment.extra_data"
        :key="fieldName"
        :label="startCase(fieldName.toLowerCase().replace('_', ' '))"
      >
        <template v-if="fieldName.toLowerCase() === 'dual_empty_placeholder'">
          YES
        </template>
        <template v-else>
          {{ value ?? 'N/A' }}
        </template>
      </el-descriptions-item>
    </template>
  </el-descriptions>
</template>
