<script lang="ts"></script>

<template>
  <h1>book appointments - coming soon</h1>
</template>

<route lang="yaml">
meta:
  auth: true
name: book-appointments
</route>
