import { acceptHMRUpdate, defineStore } from 'pinia'
import { ContainersApi } from '~/services/apiClient'
import type { ContainerFilters } from '~/compositions/containerFilters'

export const useVesselCountsStore = defineStore('vessel-counts', () => {
  const countByName = ref(new Map() as Map<string, number>)
  const abortController = ref(null as AbortController | null)
  const lastLoadArgsJSON = ref(null as string | null)

  function load(filters: ContainerFilters) {
    if (lastLoadArgsJSON.value === JSON.stringify(filters)) {
      // If what we have in the store was loaded with these args, there's no need to
      // re-load
      return
    }
    if (abortController.value) {
      abortController.value.abort()
    }
    const newAbortController = new AbortController()
    abortController.value = newAbortController
    lastLoadArgsJSON.value = null
    const api = new ContainersApi()
    return api
      .getVesselCountsByTerminalContainersVesselCountsGet(
        filters.terminal,
        filters.states,
        undefined,
        undefined,
        filters.customer_id,
        filters.auto_book_on,
        filters.auto_book_off,
        filters.container_types,
        filters.shipping_lines,
        filters.updated_in_last_minutes,
        filters.tags,
        filters.container_numbers,
        filters.assigned_user_ids,
        { signal: abortController.value.signal }
      )
      .then((resp) => {
        countByName.value = new Map(resp.data.map((x) => [x.name, x.count]))
        lastLoadArgsJSON.value = JSON.stringify(filters)
      })
      .finally(() => {
        if (newAbortController === abortController.value) {
          abortController.value = null
        }
      })
  }
  return {
    load,
    countByName,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useVesselCountsStore, import.meta.hot))
}
