import { DateTime } from 'luxon'
import type { GETPierPass, TerminalName } from '~/services/apiClient'

export class PierPass {
  container_number: string
  terminals: Set<TerminalName>
  last_observed_change: DateTime
  fee_paid: boolean
  has_hold: boolean
  claimed_by_other_company: boolean

  constructor(pierPass: GETPierPass) {
    this.container_number = pierPass.container_number
    this.last_observed_change = DateTime.fromISO(pierPass.observed)
    this.terminals = new Set(pierPass.terminals)
    this.has_hold = pierPass.has_hold
    this.fee_paid = pierPass.fee_paid
    this.claimed_by_other_company = pierPass.claimed_by_other_company
  }
}

export class CleanTruckFee extends PierPass {}
