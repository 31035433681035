import { DateTime } from 'luxon'
import { getTerminalLabel } from '~/constants'
import type {
  BaseGateTransaction as GETGateTransaction,
  TerminalName,
  TransactionDirection,
} from '~/services/apiClient'
import { formatDT, moveDescription } from '~/utils'

export class GateTransaction {
  time: DateTime
  direction: TransactionDirection
  loaded: boolean
  terminal: TerminalName
  chassis_number?: string
  truck_rfid?: string
  drivers_license_number?: string
  truck_license_plate?: string
  extra_data: Object | undefined
  constructor(gate_trx: GETGateTransaction) {
    this.time = DateTime.fromISO(gate_trx.time)
    this.direction = gate_trx.direction
    this.loaded = gate_trx.loaded
    this.terminal = gate_trx.terminal
    this.chassis_number = gate_trx.chassis_number
    this.truck_rfid = gate_trx.truck_rfid_number
    this.drivers_license_number = gate_trx.drivers_license_number
    this.truck_license_plate = gate_trx.truck_license_plate_number
    this.extra_data = gate_trx.extra_data
  }

  get type(): string {
    return moveDescription(this.direction, this.loaded)
  }

  get formattedTime(): string {
    return formatDT(this.time)
  }

  get terminalName(): string {
    return getTerminalLabel(this.terminal)
  }
}
