<script lang="ts" setup>
import { ElMessage, ElNotification } from 'element-plus'
import type { PropType } from 'vue'
import type { Container } from '~/models/containers'
import type {
  ContainerWatchState,
  POSTContainerWatch,
} from '~/services/apiClient'
import { useContainerStore } from '~/stores/containers'

const props = defineProps({
  containers: { type: Object as PropType<Container[]>, required: true },
  modelValue: { type: Boolean, required: true },
})
const emit = defineEmits(['close', 'update:modelValue'])

const containerStore = useContainerStore()

const customerID = ref(undefined as number | undefined)
const saving = ref(false)
function saveContainers() {
  saving.value = true
  const watches: POSTContainerWatch[] = []
  props.containers.forEach((container) => {
    watches.push({
      container_number: container.number,
      customer_id: customerID.value,
    })
  })
  let cycle_state = undefined as ContainerWatchState | undefined
  if (props.containers.length === 1) {
    cycle_state = props.containers[0].cycle_state
  }
  containerStore
    .watchContainers(watches, [], customerID.value, cycle_state)
    .then((response) => {
      if (response.data.container_numbers.length > 0) {
        ElNotification.success({
          title: `Updated ${response.data.container_numbers[0]} Customer`,
        })
      } else if (response.data.errors.length > 0) {
        ElMessage.error({
          message: `Failed to update ${response.data.errors[0].container_number}`,
          duration: 10000,
        })
      } else {
        ElNotification.error({
          title: `Failed to update ${response.data.errors[0].container_number}`,
        })
      }
    })
    .then(() => {
      close()
    })
    .finally(() => {
      saving.value = false
    })
}
const title = computed(() => {
  if (props.containers.length === 1) {
    return `Edit Customer for ${props.containers[0].number}`
  } else {
    return `Edit Customer for ${props.containers.length} Containers`
  }
})
function close() {
  emit('close')
  emit('update:modelValue', false)
}
</script>

<template>
  <el-dialog
    :model-value="props.modelValue"
    :title="title"
    width="30%"
    :append-to-body="true"
    @update:model-value="emit('update:modelValue')"
    @close="emit('close')"
  >
    <CustomerSelectOrCreate v-model="customerID" :optional="false" />
    <template #footer>
      <div class="flex">
        <el-button class="flex-1" @click="close">Cancel</el-button>
        <el-button
          class="flex-1"
          type="primary"
          :loading="saving"
          :disabled="customerID === undefined"
          @click="saveContainers"
        >
          Save
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>
