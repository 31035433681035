import VueVirtualScroller from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

import type { UserModule } from '~/types'

export const install: UserModule = ({ app }) => {
  app.use(VueVirtualScroller, {
    itemsLimit: 5000,
  })
}
