import { faker } from '@faker-js/faker/locale/en'

import type { GETUser, UserRoleType } from '~/services/apiClient'

export class User implements GETUser {
  id: number
  email: string
  first_name?: string
  last_name?: string
  label: string
  role?: UserRoleType
  pending: boolean
  is_active_user: boolean

  constructor(user: GETUser, demoMode: boolean) {
    this.id = user.id
    if (demoMode) {
      if (!user.email.endsWith('@draydog.com')) {
        this.email = faker.internet.email()
      } else {
        this.email = faker.internet.email()
      }

      this.first_name = faker.person.firstName()
      this.last_name = faker.person.lastName()
    } else {
      this.email = user.email
      this.first_name = user.first_name
      this.last_name = user.last_name
    }
    this.label = userLabel(user)
    this.role = user.role
    this.pending = user.pending
    this.is_active_user = user.is_active_user
  }
}

function userLabel(user: GETUser) {
  let label = user.email
  if (user.first_name) {
    label = user.first_name
  }
  if (user.last_name) {
    label += ' ' + user.last_name
  }
  return label
}
