import type { AxiosError } from 'axios'
import { ElNotification } from 'element-plus'
import * as Sentry from '@sentry/vue'
import { useUserStore } from './stores/user'

export function handleAPIError(error: AxiosError<any>) {
  updateSentryContexts(error)
  // TODO: Handle other types of errors, like network errors
  let msg = 'An error occurred'
  if (error.code === 'ERR_CANCELED') {
    return Promise.reject(error) // Cancelled by internal handler, not an issue
  }
  if (error.response?.status === 429) {
    // do nothing, don't need to see these errors
    return Promise.reject(error)
  }
  if (error.response?.status === 422) {
    try {
      msg = error.response.data.detail
        .map((detail: any) => detail.msg)
        .join(', ')
    } catch (e) {
      Sentry.captureException(e)
      msg = 'An error occurred due to invalid input'
    }
  } else if (error.response?.data?.message) {
    msg = error.response.data.message
  } else if (error.response) {
    msg = `${error.response.status} ${
      error.message
    } - ${error.response.statusText.slice(0, 40)}`
  } else {
    msg = error.message
  }
  if (error.response?.status === 401) {
    if (!error.response.config.headers.Authorization) {
      Sentry.captureException(
        'Received 401 on a request that did not have the Authorization header set'
      )
    }
    const userStore = useUserStore()
    userStore.logout()
    return Promise.resolve()
  }
  ElNotification({
    title: 'Error',
    message: msg,
    type: 'error',
  })
  return Promise.reject(error)
}

function updateSentryContexts(error: AxiosError<any>) {
  let sentryTransactionName = 'Axios API Error'
  if (error.response && error.response.config.url) {
    try {
      const path = error.response.config.url
        .replace('https://api.draydog.com', '')
        .split('?')[0]
      const method = error.response.config.method!.toUpperCase()
      sentryTransactionName = `${method} ${path}`
      Sentry.setContext('request', {
        url: error.response.config.url,
        body: error.response.config.data,
      })
      if (error.response.data) {
        Sentry.setContext('response', {
          body: JSON.stringify(error.response.data),
        })
      }
    } catch (e) {
      Sentry.captureException(e)
    }
  }
  Sentry.setTag('transaction', sentryTransactionName)
}
