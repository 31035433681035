<script lang="ts" setup>
import { useContainerTagsStore } from '~/stores/containerTags'

const props = withDefaults(
  defineProps<{
    /** The Tags Array  */
    modelValue: string[]
    placeholder?: string
  }>(),
  {
    placeholder: 'Select tags',
  }
)
const emit = defineEmits<{
  /** Emit Tags Array back to parent  */
  (e: 'update:modelValue', value: string | undefined): void
  (e: 'close', value: string | undefined): void
}>()
const selectedTags = useVModel(props, 'modelValue', emit)
const containerTagsStore = useContainerTagsStore()

type SelectRefElement = HTMLElement | null
const selectRef: Ref<SelectRefElement | null> = ref(null)
interface InputEvent extends Event {
  target: HTMLInputElement
}

const clearMultiSelectInput = async (event: InputEvent) => {
  event.target.value = '' // Clear the input

  // Attempt to close the dropdown by blurring the input field if possible
  if (selectRef.value) {
    // @ts-expect-error
    selectRef.value.query = '' // This helps to reset the internal state or query
    // @ts-expect-error
    selectRef.value.handleClose()
  }

  await nextTick() // give DOM time to update

  if (selectRef.value) {
    // @ts-expect-error
    const input = selectRef.value.$el.querySelector('input')

    // this will allow to trigger the dopdown with definedTags
    if (input) {
      input.blur()
      input.focus()
    }
  }
}

const onKeyupEnterAddTag = async (event: InputEvent) => {
  if (event) {
    const inputValue = event.target.value

    if (inputValue) {
      selectedTags.value.push(inputValue)
    }
    await clearMultiSelectInput(event)
  }
}

const onClickClose = (tagName: string) => {
  emit('close', tagName)
}
</script>

<template>
  <el-select
    ref="selectRef"
    v-model="selectedTags"
    multiple
    :placeholder="props.placeholder"
    allow-create
    filterable
    size="large"
    @keyup.enter="onKeyupEnterAddTag"
    @remove-tag="onClickClose"
  >
    <el-option
      v-for="tag in containerTagsStore.tags"
      :key="tag"
      :label="tag"
      :value="tag"
    />
  </el-select>
</template>
