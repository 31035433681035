<script lang="ts" setup>
import { DateTime } from 'luxon'
import { useWeekAppointmentCounts } from './useAppointmentCounts'

const props = defineProps<{
  startTime: DateTime
  timeBucketMinutes: number
  getXForTime: Function
  getYForAppointments: Function
  customerId: number | undefined
  containerTag: string | undefined
  excludeCustomerIds?: number[]
  excludeContainerTags?: string[]
}>()
const {
  customerId,
  containerTag,
  excludeContainerTags,
  excludeCustomerIds,
  timeBucketMinutes,
} = toRefs(props)

const excludeCustomerIdsStr = computed(() =>
  props.excludeCustomerIds ? props.excludeCustomerIds.join(',') : undefined
)
const {
  counts,
  loading,
  load: loadAppointmentCounts,
} = useWeekAppointmentCounts({
  weekStart: props.startTime,
  time_bucket_minutes: timeBucketMinutes,
})
// Computed
const time_bucket_hours = computed(() =>
  Math.ceil(props.timeBucketMinutes / 60)
)
const bucketWidth = computed(() => {
  const now = DateTime.now()
  return (
    props.getXForTime(now.plus({ hours: time_bucket_hours.value })) -
    props.getXForTime(now)
  )
})
function callLoadAppointmentCounts() {
  loadAppointmentCounts({
    customerId: props.customerId,
    containerTag: props.containerTag,
    excludeCustomerIDs: excludeCustomerIdsStr.value,
    excludeTags: props.excludeContainerTags,
  })
}
onMounted(callLoadAppointmentCounts)
watch(
  [
    time_bucket_hours,
    customerId,
    containerTag,
    excludeContainerTags,
    excludeCustomerIds,
  ],
  callLoadAppointmentCounts
)
</script>

<template>
  <g v-if="!loading" id="historical-appointment-counts">
    <rect
      v-for="count in counts"
      :key="count.time"
      :x="getXForTime(DateTime.fromMillis(count.time))"
      :y="getYForAppointments(count.count)"
      :width="bucketWidth"
      :height="getYForAppointments(0) - getYForAppointments(count.count)"
      class="appointments-count-bar fill-blue-300 opacity-70"
    />
  </g>
</template>

<style lang="scss" scoped>
.appointments-count-bar {
  //   opacity: 0.5;
  pointer-events: none;
}
</style>
