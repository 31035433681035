<script setup lang="ts">
import { Plus } from '@element-plus/icons-vue'
import SwapEmptyDialog from '../dialogs/SwapEmptyDialog.vue'
import AddEmptyDialog from '../dialogs/AddEmptyDialog.vue'
import MoveTypeIcon from './MoveTypeIcon.vue'
import DualsIcon from '~/components/icons/DualsIcon.vue'
import ClickToCopy from '~/components/ClickToCopy.vue'
import type { Appointment } from '~/models/appointments'
import type { AppointmentTransaction } from '~/models/groupedAppointments'
import { AppointmentStatus } from '~/services/apiClient'
import { useUserStore } from '~/stores/user'
const props = defineProps<{
  appointment: Appointment | undefined
  isDual: boolean
  transaction: AppointmentTransaction
  rowIsHovered: boolean
}>()

const swapEmptyDialogOpen = ref(false)
const addEmptyDialogOpen = ref(false)

function openSwapEmptyDialog() {
  swapEmptyDialogOpen.value = true
}
const userStore = useUserStore()
const showReturnRules = computed(() => {
  return !userStore.isUasOrg
})
const showSwapEmptyButton = computed(() => {
  return (
    props.appointment &&
    props.appointment.is_empty_in &&
    props.rowIsHovered &&
    props.isDual
  )
})
function openAddEmptyDialog() {
  addEmptyDialogOpen.value = true
}
const showAddEmptyButton = computed(() => {
  return (
    props.appointment === undefined &&
    props.transaction.primary_appointment?.appointment.is_import_out &&
    props.transaction.primary_appointment?.appointment.status ===
      AppointmentStatus.Scheduled
  )
})

const importAppointment = computed(() => {
  if (props.transaction.primary_appointment?.appointment.is_import_out) {
    return props.transaction.primary_appointment?.appointment
  }
})
</script>

<template>
  <div v-if="props.appointment" class="relative w-full">
    <MoveTypeIcon :appointment="props.appointment" class="container-icon" />
    <ClickToCopy :text-to-copy="props.appointment.container_number" />
    <el-button
      v-if="showSwapEmptyButton"
      v-tooltip="`Swap empty in container number`"
      size="small"
      circle
      type="primary"
      class="mx-1"
      style="position: absolute; top: 0px; right: -12px; z-index: 10"
      @click="openSwapEmptyDialog"
    >
      <i-mdi:pencil />
    </el-button>
    <DualsIcon v-if="props.isDual" class="duals-icon" />
  </div>
  <div v-else class="relative w-full">
    <ElButton
      v-if="showAddEmptyButton"
      v-tooltip="`Add empty`"
      type="primary"
      size="small"
      plain
      :icon="Plus"
      class="w-full"
      @click="openAddEmptyDialog"
    >
      Add Empty
    </ElButton>
  </div>

  <SwapEmptyDialog
    v-if="swapEmptyDialogOpen && props.appointment"
    :appointment="props.appointment"
    :show-return-rules="showReturnRules"
    @close="swapEmptyDialogOpen = false"
  />

  <AddEmptyDialog
    v-if="addEmptyDialogOpen && showAddEmptyButton && importAppointment"
    :container="props.transaction.primary_appointment.watch"
    :appointment="importAppointment"
    :show-return-rules="showReturnRules"
    @close="addEmptyDialogOpen = false"
  />
</template>

<style scoped lang="scss">
svg {
  @apply align-text-bottom;
}
.container-icon {
  margin-right: 2px;
}
.duals-icon {
  right: -8px;
  margin-top: 2px;
  position: absolute;
}
</style>
