<script setup lang="ts">
import { Icon } from '@iconify/vue'
import { useShippingLinesStore } from '~/stores/shippingLines'
import { useVesselETAsStore } from '~/stores/vesselETAs'

import type { Container } from '~/models/containers'
import { getContainerTypeDescription } from '~/constants'
import { formatDate } from '~/utils'

const props = defineProps<{
  container: Container
}>()
const container = toRef(props, 'container')
const shippingLineStore = useShippingLinesStore()
const vesselETAStore = useVesselETAsStore()

const shippingLine = computed(() => {
  if (container.value.line) {
    return shippingLineStore.shipping_line_lookup.get(container.value.line)
  }
})
const vesselETA = computed(() => {
  if (container.value.matched_vessel && !vesselETAStore.loading) {
    return vesselETAStore.getVesselETA(
      container.value.matched_vessel.id,
      container.value.terminal
    )
  } else {
    return null
  }
})
</script>

<template>
  <div class="flex justify-between">
    <!-- Left -->
    <div>
      <h1 class="text-left text-xl">
        <span class="font-bold">
          <ClickToCopy :text-to-copy="container.number"
        /></span>
        <span>
          <span v-if="container.tms_reference">
            • <ClickToCopy :text-to-copy="container.tms_reference"
          /></span>
          <span v-if="shippingLine || container.type">
            •
            <span class="text-gray-500">
              <span
                v-if="shippingLine"
                v-tooltip="
                  `${shippingLine.long_desc} (raw: ${container.raw_line})`
                "
                >{{ shippingLine.short_desc }}</span
              ><span v-else-if="container.raw_line">{{
                container.raw_line
              }}</span
              >/<span
                v-if="container.type && container.type_short"
                v-tooltip="
                  `${getContainerTypeDescription(container.type)} (raw: ${
                    container.raw_type
                  })`
                "
                >{{ container.type_short }}</span
              ><span v-else-if="container.raw_type">{{
                container.raw_type
              }}</span>
              <span
                v-if="
                  container.import_status &&
                  container.import_status.wheeled &&
                  container.import_status.wheeled === true
                "
                v-tooltip="
                  'Container is on wheels. Source: ' +
                  `${container.import_status.source}`
                "
              >
                (W)
              </span>
            </span>
          </span>
        </span>

        •
        <span class="font-semibold">
          {{ container.terminal_label }}
        </span>
      </h1>
      <h2 v-if="container.import_status" class="text-lg text-gray-700">
        Location:
        {{ container.import_status?.location_description || 'Unknown' }}
      </h2>
      <h2 class="text-sm text-gray-700">
        Customer:
        <AssignedCustomer :container="container" :always-show-icon="true" />
      </h2>

      <h3 class="text-sm text-gray-700">
        Assigned to:
        <AssignedUsers :container="container" :always-show-icon="true" />
      </h3>
      <h3 v-if="container.added_by_user" class="text-xs text-gray-500">
        Added by: {{ container.added_by_user.first_name }}
        {{ container.added_by_user.last_name }}
      </h3>

      <div>
        <ReportContainerIssueButton
          :container-number="container.number"
          :terminal="container.terminal"
          class="mt-2"
        />
      </div>
    </div>
    <!-- Right -->
    <div class="text-right">
      <h2 class="text-xl">
        Status:
        <span class="font-semibold mr-1">
          {{ container.stateDescription }}
        </span>
        <Icon
          v-if="container.status"
          :icon="container.status.icon"
          class="align-text-bottom mb-2px"
        />
      </h2>

      <h2
        v-if="
          container.vessel_name &&
          (container.is_in_on_vessel_state ||
            container.is_in_unavailable_state ||
            container.is_in_import_booking_state ||
            container.is_in_import_booked_state)
        "
        class="text-lg text-gray-700"
      >
        <VesselName :container="container" /><span
          v-if="vesselETA"
          v-tooltip="`ETA from '${vesselETA.portalLabel}'`"
          class="text-gray-400 mr-2"
          >,
          <span class="text-gray-500">ETA: </span>
          <span class="text-gray-700">{{ formatDate(vesselETA.eta) }}</span>
        </span>
        <VesselIcon class="align-text-bottom mb-2px text-sm ml-1" />
      </h2>
      <h3
        v-if="container.import_status && container.lfdIsRelevant"
        class="text-ld text-gray-700"
      >
        LFD:
        <LFD :container="container" :import-status="container.import_status" />
      </h3>
      <h3 v-if="container.badges.length">
        <ColoredBadge
          v-for="hold in container.badges"
          :key="hold.message"
          v-tooltip="hold.tooltip"
          :type="hold.type"
          class="ml-2"
        >
          {{ hold.message }}
        </ColoredBadge>
      </h3>
      <h3 v-else class="text-gray-400 text-md">
        <ColoredBadge type="info">No holds</ColoredBadge>
      </h3>
    </div>
  </div>
</template>
