<script setup lang="ts">
import { useRouteQuery } from '@vueuse/router'
import { parseContainerNumbers } from '~/utils'
const props = withDefaults(
  defineProps<{
    size?: 'large' | 'default' | 'small'
  }>(),
  { size: 'default' }
)

const inputText = useRouteQuery<string>('container_numbers', '')

const searchContainers = defineModel<string[]>({
  required: true,
  default: () => [],
})
watch(
  inputText,
  () => {
    const newContainers = parseContainerNumbers(inputText.value)
    // We only want to emit a new event if the list of containers has changed
    if (!areListsEqual(newContainers, searchContainers.value)) {
      searchContainers.value = newContainers
    }
  },
  { immediate: true }
)
function areListsEqual(list1: string[], list2: string[]): boolean {
  if (list1.length !== list2.length) {
    return false
  }

  return list1.every(
    (element: string, index: number) => element === list2[index]
  )
}
</script>

<template>
  <div class="flex">
    <el-input
      v-model="inputText"
      placeholder="Search container numbers"
      clearable
      :size="props.size"
    >
      <template #suffix>
        <i-mdi:search />
      </template>
    </el-input>
  </div>
</template>

<style scoped></style>
