import { ElMessageBox, ElNotification } from 'element-plus'
import Customer from '~/models/customers'
import { useCustomerStore } from '~/stores/customers'
import { useUserStore } from '~/stores/user'

export function useCreateCustomer() {
  const customersStore = useCustomerStore()
  const userStore = useUserStore()
  function createCustomer(): Promise<Customer | undefined | void> {
    return ElMessageBox.prompt('Name:', 'Create New Customer', {
      confirmButtonText: 'Create',
      cancelButtonText: 'Cancel',
      inputPattern: /\S+/,
      inputErrorMessage: 'Enter a Customer Name',
    }).then((event) => {
      const customerName = event.value
      const customerWithSimilarName =
        customersStore.findByNameSimilarity(customerName)
      if (customerWithSimilarName) {
        return ElMessageBox.confirm(
          `There is a customer '${customerWithSimilarName.name}' which is similar to '${customerName}'. Are you sure you want to create '${customerName}'?`,
          {
            title: `Customer with similar name found`,
            type: 'warning',
            cancelButtonText: `Cancel`,
            confirmButtonText: 'Yes, Create Anyway',
          }
        ).then(() => {
          return _createCustomer(event.value)
        })
      } else {
        return _createCustomer(event.value)
      }
    })
  }
  function _createCustomer(name: string) {
    return customersStore.create(name).then((newCustomer) => {
      ElNotification.success('Customer Created')
      return new Customer(newCustomer[0], userStore.demo_mode)
    })
  }
  return { createCustomer }
}
