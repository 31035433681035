<script setup lang="ts">
import { type ShiftGroup } from '~/models/groupedAppointments'
import EmptyContainerIcon from '~/components/icons/EmptyContainerIcon.vue'
import LoadedContainerIcon from '~/components/icons/LoadedContainerIcon.vue'

const props = defineProps<{
  shiftGroup: ShiftGroup
  isFirstVisibleShift: boolean
  selected: boolean
}>()

const emit = defineEmits({
  shiftClicked: (shiftGroup: ShiftGroup) => true,
})

const hasNoAppointments = computed(() => {
  return props.shiftGroup.countTotal === 0
})
function onClick() {
  if (hasNoAppointments.value) return
  emit('shiftClicked', props.shiftGroup)
}
const tooltip = computed(() => {
  if (hasNoAppointments.value) {
    return `No appointments for this shift`
  } else {
    const typeCounts = []
    if (props.shiftGroup.countEmptyIn > 0) {
      typeCounts.push(`${props.shiftGroup.countEmptyIn} empty in`)
    }
    if (props.shiftGroup.countLoadOut > 0) {
      typeCounts.push(`${props.shiftGroup.countLoadOut} load out`)
    }
    const typeCountString = typeCounts.join(', ')
    return `${props.shiftGroup.countTotal} appointments (${typeCountString})`
  }
})
</script>

<template>
  <div
    v-tooltip="tooltip"
    class="shift-option"
    :class="{
      'has-no-appointments': hasNoAppointments,
      selected,
      'is-first-visible-shift': isFirstVisibleShift,
    }"
    @click="onClick"
  >
    <div style="min-width: 40px">
      <span class="text-gray-800 text-lg">
        {{ props.shiftGroup.shortShiftName }}
      </span>
    </div>
    <div class="appointment-counts">
      <div v-if="props.shiftGroup.countLoadOut > 0" class="count-group">
        <LoadedContainerIcon />
        {{ props.shiftGroup.countLoadOut }}
      </div>
      <div v-if="props.shiftGroup.countEmptyIn > 0" class="count-group">
        <EmptyContainerIcon />
        {{ props.shiftGroup.countEmptyIn }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.shift-option {
  @apply border-1 px-1 pb-1 pt-1px rounded-4px cursor-pointer;
  margin: 2px;
  &.has-no-appointments {
    @apply bg-gray-100 text-gray-500 cursor-not-allowed;
    opacity: 0.6;
  }
  &.is-first-visible-shift {
    @apply border-blue-gray-400 bg-blue-gray-300;
  }
  &.selected {
    @apply border-blue-700 bg-blue-100;
    border-width: 2px;
    margin: 0px;
  }
  .count-group {
    @apply mr-1 flex flex-nowrap;
    &:last-child {
      margin-right: 0;
    }
    svg {
      @apply align-text-bottom;
      margin-top: 1px;

      margin-right: 2px;
    }
  }
  .appointment-counts {
    @apply text-xs flex text-gray-600 flex-row;
    min-width: 0;
  }
}
</style>
