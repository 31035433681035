<script lang="ts" setup>
import type { DateTime } from 'luxon'
import { useLuxonTimeAgo } from '~/compositions/timeAgo'
import { formatDT } from '~/utils'

interface ObjectWithTimes {
  last_observed: DateTime | null
  last_observed_changed: DateTime
}

const props = withDefaults(
  defineProps<{
    object: ObjectWithTimes
    lastCheckedLabel?: string
  }>(),
  { lastCheckedLabel: 'Last checked' }
)
const lastObserved = toRef(props.object, 'last_observed')
const lastObservedChanged = toRef(props.object, 'last_observed_changed')
const lastCheckedDesc = useLuxonTimeAgo(lastObserved)
const lastChangedDesc = useLuxonTimeAgo(lastObservedChanged)
</script>

<template>
  <span class="text-xs mb-1 text-gray-600">
    <span v-if="props.object.last_observed && object.last_observed !== null">
      <span v-tooltip="`Last checked at ${formatDT(object.last_observed)}`">
        {{ lastCheckedLabel }}:
        {{ lastCheckedDesc }}
      </span>
    </span>

    <span class="mx-1">-</span>
    <span
      v-tooltip="
        `Last observed changed at ${formatDT(object.last_observed_changed)}`
      "
    >
      Last changed:
      {{ lastChangedDesc }}
    </span>
  </span>
</template>
