<script lang="ts" setup>
import type { PropType } from 'vue'
import { AutoBookRequestStatus } from '~/services/apiClient'

const props = defineProps({
  autoBookRequestStatus: { type: String as PropType<AutoBookRequestStatus> },
})

const iconColor = computed(() => {
  switch (props.autoBookRequestStatus) {
    case AutoBookRequestStatus.Requested:
      return '#67C23A'
    case AutoBookRequestStatus.Failed:
      return 'red'
    case AutoBookRequestStatus.Cancelled:
      return 'gray'
    case AutoBookRequestStatus.InProgress:
      return '#fab937' // yellow
    case AutoBookRequestStatus.Complete:
      return '#3f9eff'
    default:
      throw new Error(
        `Unknown AutoBookRequestStatus: ${props.autoBookRequestStatus}`
      )
  }
})
const tooltip = computed(() => {
  switch (props.autoBookRequestStatus) {
    case AutoBookRequestStatus.Requested:
      return 'Auto-book requested and active. Click to view settings'
    case AutoBookRequestStatus.Failed:
      return 'Auto-book failed. Click to view settings/history and reactivate if desired'
    case AutoBookRequestStatus.Cancelled:
      return 'Auto-book cancelled. Click to view history and re-activate if desired'
    case AutoBookRequestStatus.InProgress:
      return 'Auto-book in progress. Click to view settings'
    case AutoBookRequestStatus.Complete:
      return 'Auto-book successfully booked an appointment'
    default:
      throw new Error(
        `Unknown AutoBookRequestStatus: ${props.autoBookRequestStatus}`
      )
  }
})
</script>

<template>
  <RobotIcon v-tooltip="tooltip" :color="iconColor" v-bind="$attrs" />
</template>
