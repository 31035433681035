import { useTrucksStore } from '~/stores/trucks'

export default function useTruckDescription(
  truckId: Ref<number | null | undefined>,
  licensePlate: Ref<string | null | undefined>
) {
  const truckStore = useTrucksStore()
  const description = ref<string>(updateIfNeededAndGetDescription())
  function getDescription() {
    if (licensePlate.value) {
      return truckStore.getTruckDescription(
        truckId.value ?? null,
        licensePlate.value
      )
    }
    return ''
  }
  function updateIfNeededAndGetDescription() {
    truckStore.loadIfNotLoadedSinceReconnect().then(() => {
      description.value = getDescription()
    })
    return getDescription()
  }
  watch([truckId, licensePlate], () => {
    description.value = updateIfNeededAndGetDescription()
  })
  return description
}
