<script setup lang="ts">
import type { Container } from '~/models/containers'

const props = withDefaults(
  defineProps<{
    container: Container
    maxCharacters?: number
    showLinkIcon?: boolean
  }>(),
  { showLinkIcon: true }
)

const truncatedVesselName = computed(() => {
  if (!props.container.vessel_name) return null
  if (
    props.maxCharacters &&
    props.container.vessel_name.length > props.maxCharacters - 3
  ) {
    return props.container.vessel_name.slice(0, props.maxCharacters - 3) + '...'
  } else {
    return props.container.vessel_name
  }
})
</script>

<template>
  <a
    :href="
      props.container.matched_vessel
        ? `https://www.vesselfinder.com/vessels/details/${props.container.matched_vessel.imo_number}`
        : undefined
    "
    target="_blank"
    class="text-gray-400"
    :class="props.container.matched_vessel ? 'hover:underline' : ''"
  >
    <i-mdi:external-link
      v-if="props.showLinkIcon && props.container.matched_vessel"
      class="align-text-bottom"
    />
    <span
      v-tooltip="
        `Vessel is '${container.vessel_name}'${container.matched_vessel ? '' : '. WARNING: We have not matched this to a vessel yet so it cannot be used in filters'}`
      "
      class="text-gray-400"
    >
      <span v-if="!props.container.matched_vessel">
        <i-mdi:alert data-testid="alert-icon" class="align-text-bottom" />
      </span>
      {{ truncatedVesselName }}
    </span>
  </a>
</template>
