<script lang="ts" setup>
import { RecycleScroller } from 'vue-virtual-scroller'
import type { Container } from '~/models/containers'

const props = defineProps<{
  containers: Container[]
}>()
</script>

<template>
  <RecycleScroller
    v-slot="{ item: container, index }"
    :items="props.containers"
    size-field="rowHeight"
    key-field="key"
    page-mode
    :buffer="600"
    class="h-full"
  >
    <ContainerRow
      :container="container"
      :class="{ 'border-t-0': index === 0 }"
    />
  </RecycleScroller>
</template>
