import type { SimpleUser } from '~/services/apiClient'
import { useUserStore } from '~/stores/user'

export function useSimpleUserLabel() {
  const userStore = useUserStore()
  function simpleUserLabel(user: SimpleUser) {
    let label = `User ${user.id}`
    if (user.first_name) {
      label = user.first_name
    }
    if (user.last_name) {
      label += ' ' + user.last_name
    }
    if (user.id === userStore.loggedInUser?.id) {
      label = `Me (${label})`
    }
    return label
  }
  return simpleUserLabel
}
