<script lang="ts" setup>
import { Container } from '~/models/containers'

const props = defineProps({ container: { type: Container, required: true } })
const allRelevantGateTransactions = computed(() => {
  const transactions = []
  if (props.container.outbound_gate_transaction) {
    transactions.push(props.container.outbound_gate_transaction)
  }
  if (props.container.inbound_gate_transaction) {
    transactions.push(props.container.inbound_gate_transaction)
  }
  return transactions
})
</script>

<template>
  <el-table :data="allRelevantGateTransactions" stripe class="w-full">
    <el-table-column prop="terminalName" label="Terminal" />
    <el-table-column prop="type" label="Type" />
    <el-table-column prop="formattedTime" label="Time" />
    <el-table-column prop="chassis_number" label="Chassis" />
  </el-table>
</template>
