<script lang="ts" setup>
import { DateTime } from 'luxon'
import type { PropType } from 'vue'
import { CameraHistoryImage } from '~/models/cameraImage'
import type { TerminalName } from '~/services/apiClient'

const props = defineProps({
  images: { type: Array as PropType<CameraHistoryImage[]>, required: true },
  terminalName: { type: String as PropType<TerminalName>, required: true },
  streamName: { type: String, required: true },
  startTime: { type: Object as PropType<DateTime>, required: true },
})
const missingImageURL = 'https://api.draydog.com/static/tv_static.jpg'
const imagesByMinutes = computed(() => {
  const imagesByMinutes = new Map()
  for (const image of props.images) {
    imagesByMinutes.set(image.bucket_time.minute, image)
  }
  return imagesByMinutes
})
const rowTimeMinutes = [0, 10, 20, 30, 40, 50]
const images = computed(() => {
  const images = []
  for (const timeMinute of rowTimeMinutes) {
    const image = imagesByMinutes.value.get(timeMinute)
    if (image) {
      images.push(image)
    } else {
      const time = props.startTime.set({ minute: timeMinute })
      const isoTime = time.toISO()
      if (time > DateTime.now().minus({ minutes: 10 })) {
        continue
      }
      images.push(
        new CameraHistoryImage({
          bucket_time: isoTime,
          image_url: missingImageURL,
          terminal: props.terminalName,
          stream_name: props.streamName,
          source_url: '',
          observed: isoTime,
        })
      )
    }
  }
  return images
})
</script>

<template>
  <div class="flex">
    <div v-for="img in images" :key="img.url" class="w-1/6 px-1">
      <div
        class="text-xs text-dark-900"
        :class="{
          'text-xl': img.bucket_time.minute === 0,
          'font-semibold': img.bucket_time.minute === 0,
          'mt-3': img.bucket_time.minute !== 0,
        }"
      >
        {{ img.bucket_time.toFormat('H:mm') }}
      </div>
      <CameraImage :img="img" :preview="true" class="w-full" />
    </div>
  </div>
</template>
