<script lang="ts" setup>
import { useRouteQuery } from '@vueuse/router'
import type { FormInstance, FormRules } from 'element-plus'
import { ContainerType, ShippingLine } from '~/services/apiClient'
import { isValidContainerType, isValidShippingLine } from '~/constants'
import { parseContainerNumbers } from '~/utils'
import { loadContainersBasic } from '~/services/apiHelpers'

const shippingLine = defineModel<ShippingLine | undefined>('shippingLine')
const containerType = defineModel<ContainerType | undefined>('containerType')
const searchedContainerNumber = defineModel<string | null>(
  'searchedContainerNumber'
)

const shippingLineQuery = useRouteQuery<string | undefined>(
  'shipping_line',
  ShippingLine.Cmdu
)
const containerTypeQuery = useRouteQuery<string | undefined>(
  'container_type',
  ContainerType._40Hc
)
const form = reactiveComputed(() => ({
  containerNumber: searchedContainerNumber.value,
}))
const warningMessage = ref<string | undefined>()
interface FormInterface {
  containerNumber: string
}
const ruleFormRef = ref<FormInstance>()
const formRules = reactive<FormRules<FormInterface>>({
  containerNumber: [
    {
      trigger: 'change',
      validator: (rule, value: string | undefined, callback) => {
        if (value) {
          const containerNumber = parseContainerNumbers(value)
          if (containerNumber.length === 1) {
            return callback()
          } else {
            return callback(new Error('Invalid container number'))
          }
        }
        callback()
      },
    },
  ],
})

onMounted(() => {
  if (shippingLineQuery.value) {
    updateShippingLine(shippingLineQuery.value)
  }
  if (containerTypeQuery.value) {
    updateContainerType(containerTypeQuery.value)
  }
})
function displayContainerWarning(
  value: string,
  callback?: () => void,
  durationMilliseconds: number = 6000
) {
  warningMessage.value = value
  setTimeout(() => {
    warningMessage.value = undefined
    callback?.()
  }, durationMilliseconds)
}
function updateShippingLine(value: string | undefined) {
  if (!isValidShippingLine(value)) {
    value = undefined
  }
  if (value !== shippingLineQuery.value) {
    shippingLineQuery.value = value
  }
  if (value !== shippingLine.value) {
    shippingLine.value = value
  }
}

function updateContainerType(value: string | undefined) {
  if (!isValidContainerType(value)) {
    value = undefined
  }
  if (value !== containerTypeQuery.value) {
    containerTypeQuery.value = value
  }
  if (value !== containerType.value) {
    containerType.value = value
  }
}
function updateContainerNumber(value: string | undefined) {
  if (value) {
    // Don't search until we have a valid container number
    const parsedContainers = parseContainerNumbers(value)
    if (parsedContainers.length === 1) {
      loadContainersBasic({ containers: parsedContainers[0] }).then((resp) => {
        if (resp.data.length === 1) {
          updateContainerType(resp.data[0].container_type)
          updateShippingLine(resp.data[0].shipping_line)
        } else if (resp.data.length === 0) {
          shippingLine.value = undefined
          containerType.value = undefined
          displayContainerWarning('Not found, select line+type', () => {
            searchedContainerNumber.value = undefined
          })
        } else if (resp.data.length > 1) {
          throw new Error(
            `Multiple containers found for ${parsedContainers[0]}, this is unexpected`
          )
        }
      })
    }
  }
}
</script>

<template>
  <el-form
    ref="ruleFormRef"
    label-position="top"
    :rules="formRules"
    :model="form"
  >
    <div class="flex">
      <el-form-item
        label="Container:"
        :error="warningMessage"
        prop="containerNumber"
      >
        <el-input
          v-model="searchedContainerNumber"
          clearable
          placeholder="Ex: CSQU3054383"
          style="width: 160px"
          size="large"
          @update:model-value="updateContainerNumber"
        />
      </el-form-item>
      <div class="text-sm text-gray-500 align-middle self-center ml-2 mt-3">
        or
      </div>
      <el-form-item label="Shipping Line:" class="ml-2" prop="shippingLine">
        <ShippingLineSelector
          :model-value="shippingLine"
          :clearable="true"
          :disabled="shippingLine && !!searchedContainerNumber"
          @update:model-value="updateShippingLine"
        />
      </el-form-item>
      <el-form-item label="Container Type:" class="ml-2" prop="containerType">
        <ContainerTypeSelector
          :model-value="containerType"
          :clearable="true"
          :disabled="containerType && !!searchedContainerNumber"
          @update:model-value="updateContainerType"
        />
      </el-form-item>
    </div>
  </el-form>
</template>
