<script lang="ts" setup>
useHead({
  title: 'Dray Dog - Dashboard',
})
</script>

<template>
  <Card class="p-2 px-4">
    <AppointmentsByStatusGraph />
  </Card>
</template>

<route lang="yaml">
meta:
  auth: true
name: dashboard
</route>
