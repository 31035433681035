<script lang="ts" setup>
import startCase from 'lodash-es/startCase'
import type { DateTime } from 'luxon'
import { useAppointmentStore } from '~/stores/appointments'
import { appointmentStatusColor } from '~/utils'
import { useMakeDualTooltip } from '~/compositions/createDualToolTip'

const props = defineProps({
  containerNumber: { type: String, required: true },
  // Allows filtering out old appointments
  after: { type: Object as () => DateTime, required: false },
})
const appointmentStore = useAppointmentStore()
onMounted(() => {
  appointmentStore.load({
    container_numbers: [props.containerNumber],
    after: props.after,
  })
})
const { makeDualTooltip } = useMakeDualTooltip()
</script>

<template>
  <el-table
    v-loading="appointmentStore.loading"
    :data="appointmentStore.appointments"
    stripe
    class="w-full break-words"
    empty-text="No appointments"
  >
    <el-table-column prop="window_date" label="Date • Time" width="212">
      <template #default="{ row }">
        {{ row.window_date }}
        •
        {{ row.window_description }}
      </template>
    </el-table-column>
    <el-table-column prop="status" label="Terminal • Status" width="160">
      <template #default="{ row }">
        {{ row.terminal_name }} •
        <span
          :style="{ color: appointmentStatusColor(row.status) }"
          class="text-white"
        >
          {{ startCase(row.status) }}
        </span>
      </template>
    </el-table-column>
    <el-table-column prop="type" label="Type" width="110">
      <template #default="{ row }">
        <span
          v-if="row.linked_appointments.length"
          v-tooltip="makeDualTooltip(row.linked_appointments)"
        >
          {{ row.type }}
          <DualsIcon class="align-middle" />
        </span>
        <template v-else>{{ row.type }}</template>
      </template>
    </el-table-column>
    <el-table-column prop="created_at_terminal_fmtd" label="Created">
      <template #default="{ row }">
        <div class="text-gray-400">
          {{ row.created_at_terminal_fmtd }}
        </div>
      </template>
    </el-table-column>
    <el-table-column prop="booked_by" label="Booking User">
      <template #default="{ row }">
        <div
          v-if="row.booked_by"
          v-tooltip="`Booked by ${row.booked_by}`"
          class="text-gray-400"
        >
          {{ row.booked_by }}
        </div>
        <div
          v-else
          v-tooltip="
            `Booking user is unknown because appointment was not booked on Dray Dog`
          "
        >
          Unknown
        </div>
      </template></el-table-column
    >
  </el-table>
</template>
