<script lang="ts" setup>
import { ElMessageBox, ElNotification } from 'element-plus'
import type Customer from '~/models/customers'
import { useCustomerStore } from '~/stores/customers'
const props = defineProps({
  customers: { type: Array as PropType<Customer[]>, required: true },
})
const customersStore = useCustomerStore()
const editingCustomer = ref(null as null | Customer)

function deleteCustomer(customer: Customer) {
  ElMessageBox.confirm(
    `${customer?.name} will be removed from any containers. You might want to consider "merging" instead`,
    {
      title: `Delete ${customer?.name}?`,
      confirmButtonText: `Delete ${customer?.name}`,
      cancelButtonText: 'Cancel',
      type: 'warning',
      confirmButtonClass: 'el-button--danger',
    }
  ).then(() =>
    customersStore.deleteCustomer(customer.id).then(() => {
      customersStore.load()
      ElNotification.success(`Customer ${customer?.name} deleted`)
    })
  )
}
</script>

<template>
  <div>
    <el-table :data="props.customers">
      <!-- Checkbox -->
      <el-table-column width="55">
        <template #default="{ row }">
          <div class="pr-2 flex items-start -mt-1">
            <el-checkbox
              :key="customersStore.selectionKey"
              :checked="customersStore.isCustomerSelected(row.id)"
              @click.prevent.stop="
                () => customersStore.toggleCustomerSelection(row.id)
              "
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="Customer">
        <template #default="{ row }">
          {{ row.name }}
        </template>
      </el-table-column>
      <el-table-column label="Actions">
        <template #default="{ row }">
          <ElButton
            v-tooltip="'Edit Customer Name'"
            type="success"
            size="small"
            class="edit-button"
            @click="editingCustomer = row"
          >
            <el-icon size="medium">
              <i-material-symbols:edit-document
            /></el-icon>
          </ElButton>
          <ElButton
            v-tooltip="'Remove Customer'"
            type="danger"
            size="small"
            class="ml-1 delete-button"
            @click="deleteCustomer(row)"
          >
            <el-icon size="medium">
              <i-material-symbols:delete-forever-outline />
            </el-icon>
          </ElButton>
        </template>
      </el-table-column>
    </el-table>
    <EditCustomerDialog
      v-if="editingCustomer"
      :customer="editingCustomer"
      @close="editingCustomer = null"
    />
  </div>
</template>
