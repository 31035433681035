import type { DateTime } from 'luxon'
import { useCustomerStore } from '~/stores/customers'

interface HasCustomerIDAndDedicated {
  customerID: number | null
  containerTag: string | null
  dedicated: boolean
}

export function useGetCapacitySetLabel() {
  const customerStore = useCustomerStore()
  onMounted(customerStore.loadIfNotLoaded)
  function getCapacitySetLabel(obj: HasCustomerIDAndDedicated): string {
    if (obj.containerTag) {
      return obj.containerTag + (obj.dedicated ? ' Dedicated' : '')
    } else if (obj.customerID) {
      const customer = customerStore.getByCustomerID(obj.customerID)
      let customerName = 'Unknown'
      if (customer) {
        customerName = customer.name
      }
      return `${customerName} ${obj.dedicated ? 'Dedicated' : 'Receiving'}`
    } else {
      return 'Fleet'
    }
  }
  return getCapacitySetLabel
}

export function convertMinuteOfWeekToTime(
  minuteOfWeek: number,
  timeInTargetWeek: DateTime
): DateTime {
  const startOfWeek = timeInTargetWeek.startOf('week').minus({ days: 1 })
  // Adjust for short/long weeks due to daylight savings
  const endOfWeek = timeInTargetWeek.endOf('week').minus({ days: 1 })
  const hoursInWeek = Math.round(endOfWeek.diff(startOfWeek, 'hours').hours)
  const extraHoursInWeek = hoursInWeek - 168
  return startOfWeek.plus({
    minutes: minuteOfWeek,
    hours: extraHoursInWeek,
  })
}
