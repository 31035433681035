import posthog from 'posthog-js'
import type { UserModule } from '~/types'

export const install: UserModule = ({ router, app }) => {
  if (process.env.NODE_ENV === 'development') {
    return
  }
  // https://posthog.com/docs/libraries/vue-js
  const postHogInstance = posthog.init(
    'phc_NiJbllxJuXC5wPu5eLPQVi1clI9UBuBtpsqz6mLo9mZ',
    // https://posthog.com/docs/libraries/js#config
    {
      api_host: 'https://app.posthog.com',
      disable_session_recording: true,
      opt_in_site_apps: true,
      // We'll just use for pageview analytics below
      autocapture: false,
      capture_pageview: false,
      capture_pageleave: false,
    }
  )
  app.config.globalProperties.$posthog = postHogInstance
  // @ts-expect-error
  window.posthog = postHogInstance
  router.afterEach((to, from) => {
    if (to.path !== from.path) {
      nextTick(() => {
        posthog.capture('$pageview', {
          $current_url: to.fullPath,
        })
      })
    }
  })
}
