<script lang="ts" setup>
import { DateTime } from 'luxon'
import type { PropType } from 'vue'
import { type CapacityBucket, getMinuteOfWeek } from '~/models/capacitySets'

const props = defineProps({
  x: { type: Number, required: true },
  y: { type: Number, required: true },
  bucketWidth: { type: Number, required: true },
  bucketWidthMinutes: { type: Number, required: true },
  bucket: { type: Object as PropType<CapacityBucket>, required: true },
  appointmentCount: { type: Number, required: true },
})

const tensedPossessiveVerb = computed(() => {
  const currentMinuteOfWeek = getMinuteOfWeek(DateTime.now())
  if (props.bucket.startMinuteOfWeek < currentMinuteOfWeek) {
    return 'had'
  } else {
    return 'have'
  }
})

const centerX = computed(() => props.x + props.bucketWidth / 2)
const backgroundWidth = ref(90)
const backgroundHeight = ref(34)
const group = ref()
</script>

<template>
  <g ref="group" class="capacity-buttons">
    <!-- Background rectangle -->
    <rect
      :x="centerX - backgroundWidth / 2"
      :y="props.y - 8"
      :width="backgroundWidth"
      :height="backgroundHeight"
      class="fill-gray-100"
    />
    <g class="labels">
      <text :x="centerX" :y="y" text-anchor="middle" class="time-label">
        {{ props.bucket.timeDescription }}
      </text>
      <text :x="centerX" :y="y + 12" text-anchor="middle" class="font-bold">
        {{ bucket.maxAppointments }} appts. max
      </text>
      <text
        :x="centerX"
        :y="y + 22"
        text-anchor="middle"
        style="font-size: 0.9em"
      >
        You {{ tensedPossessiveVerb }} {{ props.appointmentCount }} appts.
      </text>
    </g>
  </g>
</template>

<style scoped lang="scss">
.capacity-buttons {
  rect.button-hover-zone {
    fill: rgb(185, 185, 185);
  }
}
.labels {
  pointer-events: none;
  font-size: 7px;
  .time-label {
    font-size: 8px;
  }
}
</style>
