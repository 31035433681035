<script lang="ts" setup>
import { CAPACITY_SET_DIALOG_MAX_WIDTH } from './constants'
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
})
const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
  (e: 'close'): void
}>()
const addCustomerCapacityDialogOpen = ref(false)
const addTagCapacityDialogOpen = ref(false)

const mainDialogOpen = useVModel(props, 'modelValue', emit)

function openCustomerDialog() {
  addCustomerCapacityDialogOpen.value = true
  mainDialogOpen.value = false
}
function openTagDialog() {
  addTagCapacityDialogOpen.value = true
  mainDialogOpen.value = false
}
</script>

<template>
  <div class="add-capacity-set-dialog">
    <BaseDialog
      v-if="mainDialogOpen"
      v-model="mainDialogOpen"
      title="Which type of capacity set would you like to add?"
      :max-width="CAPACITY_SET_DIALOG_MAX_WIDTH"
    >
      <div>
        <el-button type="primary" class="w-full" @click="openCustomerDialog">
          Customer
        </el-button>
        <p>
          Customer capacity settings are useful when you want to limit the
          appointments booked for a particular customer
        </p>
      </div>
      <div>
        <el-button type="primary" class="w-full mt-3" @click="openTagDialog">
          Tag
        </el-button>
        <p>
          Tag-based capacity settings are a more general/flexible option for
          setting capacity limits on groups of containers. You'll need to make
          sure to tag your containers appropriately for this to be useful.
        </p>
      </div>
    </BaseDialog>
    <AddCustomerCapacityDialog
      v-if="addCustomerCapacityDialogOpen"
      v-model="addCustomerCapacityDialogOpen"
    />
    <AddTagCapacitySetDialog
      v-if="addTagCapacityDialogOpen"
      v-model="addTagCapacityDialogOpen"
    />
  </div>
</template>

<style scoped lang="scss">
.add-capacity-set-dialog {
  p {
    margin-top: 8px;
    margin-bottom: 16px;
  }
}
</style>
