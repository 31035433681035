<script lang="ts" setup>
import type { ShiftGroup } from './types'
import { appointmentCountDescription } from './utils'
import AppointmentCounts from './AppointmentCounts.vue'
const props = defineProps<{
  shiftGroup: ShiftGroup
}>()

const tooltip = computed(() => {
  return `${appointmentCountDescription(props.shiftGroup)} for this shift`
})
</script>

<template>
  <BaseAppointmentHeader :tooltip="tooltip" :is-sub-header="false">
    <template #left>
      {{ shiftGroup.shiftName }}
    </template>
    <template #right>
      <ColoredBadge
        v-if="shiftGroup.overCapacityDescriptions.length"
        v-tooltip="
          `Times over capacity: ${shiftGroup.overCapacityDescriptions.join(
            ', '
          )}`
        "
        type="danger"
        effect="dark"
      >
        <i-mdi:alert class="align-text-bottom" />
        Has times over capacity
      </ColoredBadge>
      <AppointmentCounts :group="shiftGroup" />
    </template>
  </BaseAppointmentHeader>
</template>

\
