<script lang="ts" setup>
import { Icon } from '@iconify/vue'
import startCase from 'lodash-es/startCase'
import { ContainerChangeGroup } from '~/models/containerChangeGroup'
import { ContainerEventType } from '~/services/apiClient'

import {
  ADD_APPT_ICON,
  EDIT_APPT_ICON,
  INGATE_ICON,
  LOADED_CONTAINER_ICON,
  OUTGATE_ICON,
} from '~/constants'

const props = defineProps({
  changeGroup: {
    type: ContainerChangeGroup,
    required: true,
  },
  showRawChanges: {
    type: Boolean,
    default: true,
  },
})
const changes = computed(() => {
  return props.changeGroup.changes.filter(
    (change) => change.from_raw_data === false
  )
})
const rawChanges = computed(() => {
  return props.changeGroup.changes.filter((change) => change.from_raw_data)
})
const rawChangesTreeData = computed(() => {
  return rawChanges.value.map((change) => {
    return {
      label: change.desc,
    }
  })
})
function pickIcon(eventType: ContainerEventType): string {
  switch (eventType) {
    case ContainerEventType.AppointmentChanged:
      return EDIT_APPT_ICON
    case ContainerEventType.AppointmentCreated:
      return ADD_APPT_ICON
    case ContainerEventType.ImportStatusFound:
      return LOADED_CONTAINER_ICON
    case ContainerEventType.ImportStatusChanged:
      return LOADED_CONTAINER_ICON
    case ContainerEventType.InGated:
      return OUTGATE_ICON
    case ContainerEventType.OutGated:
      return INGATE_ICON
    case ContainerEventType.AutoBookCancelled:
      return 'icon-park-outline:robot-one'
    case ContainerEventType.AutoBookTurnedOn:
      return 'icon-park-outline:robot-one'
    case ContainerEventType.AutoBookUpdated:
      return 'mdi:autorenew'
    case ContainerEventType.AppointmentRequested:
      return 'mdi:calendar-clock'
    case ContainerEventType.EmptyAppointmentRequested:
      return 'mdi:calendar-clock'
    case ContainerEventType.AppointmentCancellationRequested:
      return 'mdi:calendar-remove'
    case ContainerEventType.AppointmentRescheduleRequested:
      return 'mdi:calendar-refresh'
    case ContainerEventType.AppointmentAddEmptyRequested:
      return 'tabler:arrows-right-left'
    case ContainerEventType.AppointmentEditLicensePlateRequested:
      return 'mdi:truck'
    case ContainerEventType.AppointmentEditChassisRequested:
      return 'mdi:truck-flatbed'
    case ContainerEventType.AppointmentCheckInRequested:
      return 'material-symbols:check-circle-outline'
    case ContainerEventType.WatchCreated:
      return 'mdi:eye-plus'
    case ContainerEventType.ContainerArchived:
      return 'mdi:archive'
    default:
      return 'akar-icons:question'
  }
}

function changeTitle(eventType: ContainerEventType): string {
  switch (eventType) {
    case ContainerEventType.AutoBookCancelled:
      return 'Auto Book Deactivated'
    case ContainerEventType.AutoBookTurnedOn:
      return 'Auto Book Activated'
    default:
      return startCase(eventType)
  }
}
</script>

<template>
  <el-timeline-item
    color="#3f9eff"
    :timestamp="changeGroup.observed_and_source_desc"
  >
    <h3 class="font-semibold">
      <Icon
        :icon="pickIcon(changeGroup.type)"
        class="align-middle"
        height="18"
      />
      {{ changeTitle(changeGroup.type) }}
    </h3>
    <ul class="list-disc pl-4">
      <li v-for="change in changes" :key="change.desc">
        {{ change.desc }}
      </li>
    </ul>
    <el-tree
      v-if="rawChanges.length > 0 && showRawChanges"
      :data="[{ label: 'Raw Changes', children: rawChangesTreeData }]"
    ></el-tree>
  </el-timeline-item>
</template>
