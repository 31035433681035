<script lang="ts" setup>
import { ElMessage } from 'element-plus'
import type { PropType } from 'vue'
import type { Container } from '~/models/containers'

const props = defineProps({
  containers: { type: Array as PropType<Container[]>, required: true },
  size: {
    type: String as PropType<'small' | 'default' | 'large'>,
    default: 'default',
  },
})
const emit = defineEmits<{ (e: 'close'): void }>()
const autoBookOn = computed(() => {
  return props.containers.every((c) => c.auto_book_on)
})
const tooltip = computed(() => {
  if (autoBookOn.value) {
    return 'Click to view/edit/turn off'
  } else {
    return 'Auto booking watches for the appointments you want and books for you'
  }
})
const dialogOpen = ref(false)
const attrs = useAttrs()
function open() {
  if (props.containers.length === 0) {
    ElMessage({
      message: 'No containers selected',
      type: 'warning',
    })
  } else {
    dialogOpen.value = true
  }
}
function close() {
  dialogOpen.value = false
  emit('close')
}
</script>

<template>
  <span v-bind="attrs" @click="open">
    <slot>
      <el-button
        v-tooltip="tooltip"
        :type="autoBookOn ? 'success' : 'primary'"
        :size="props.size"
        plain
      >
        <i-icon-park-outline:robot-one
          class="align-text-bottom text-xs mr-1 mb-2px"
        />
        <template v-if="autoBookOn"> Auto Book ON </template>
        <template v-else> Enable Auto Book </template>
      </el-button>
    </slot>
  </span>
  <AutoBookDialog
    v-if="dialogOpen"
    :containers="props.containers"
    @close="close"
  />
</template>
