// need a place to store global notifications and have an action to load current global notifications

import { DateTime } from 'luxon'
import { defineStore } from 'pinia'
import type { GlobalNotification } from '~/services/apiClient'

export const useGlobalNotificationsStore = defineStore(
  'globalNotifications',
  () => {
    const notifications = ref<Map<number, GlobalNotification>>(new Map())

    function update(notification: GlobalNotification): void {
      notifications.value.set(notification.id, notification)
    }

    const activeNotifications = computed((): GlobalNotification[] => {
      return Array.from(notifications.value.values()).filter(
        (n: GlobalNotification) => {
          if (DateTime.fromISO(n.active_from) > DateTime.now()) return false
          if (!n.active_to) return true
          return DateTime.fromISO(n.active_to) > DateTime.now()
        }
      )
    })

    return {
      notifications,
      update,
      activeNotifications,
    }
  }
)
