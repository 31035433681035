import type {
  ContainerType,
  ShippingLine,
  TerminalName,
} from '~/services/apiClient'
import { useEmptyAppointmentSlotAllocationMethodStore } from '~/stores/emptyAppointmentSlotAllocationMethods'
const APPOINTMENT_SLOTS_CHANNEL_PREFIX = 'private-empty-appointment-slots-'

export function useAppointmentSlotEvents() {
  const emptySlotAllocationMethodStore =
    useEmptyAppointmentSlotAllocationMethodStore()

  const prerequisitesLoaded = computed(
    (): boolean => emptySlotAllocationMethodStore.loaded
  )
  async function waitForPreRequisitesToLoadIfNeeded() {
    if (!prerequisitesLoaded.value) {
      await emptySlotAllocationMethodStore.loadingPromise
    }
  }
  function getAppointmentSlotChannelName(
    terminal: TerminalName,
    shippingLine: ShippingLine | undefined,
    containerType: ContainerType | undefined
  ): string {
    const allocationInfo =
      emptySlotAllocationMethodStore.getAllocation(terminal)
    let name = `${APPOINTMENT_SLOTS_CHANNEL_PREFIX}${terminal}`
    if (allocationInfo.by_shipping_line) {
      if (!shippingLine) throw new Error('Shipping line is required')
      name += `-${shippingLine}`.replace(/\s+/g, '-')
    }
    if (allocationInfo.by_container_type) {
      if (!containerType) throw new Error('Container type is required')
      name += `-${containerType}`.replace(/\s+/g, '-')
    }
    return name
  }
  function loadPreRequisites() {
    return emptySlotAllocationMethodStore.loadIfNeeded()
  }
  return {
    getAppointmentSlotChannelName,
    loadPreRequisites,
    prerequisitesLoaded,
    waitForPreRequisitesToLoadIfNeeded,
  }
}
