import { useShippingLine } from './useShippingLines'
import type { ContainerType, ShippingLine } from '~/services/apiClient'
import { shortenContainerType } from '~/utils'

interface HasShippingLineAndType {
  shipping_line?: ShippingLine | null
  container_type?: ContainerType | null
}

export function useMakeLineAndTypeDescription() {
  const { getShippingLine } = useShippingLine()
  function makeLineAndTypeDescription(obj: HasShippingLineAndType): string {
    let lineDesc: string | undefined
    let typeDesc: string | undefined
    if (obj.shipping_line) {
      const shippingLineInfo = getShippingLine(obj.shipping_line)
      if (shippingLineInfo) {
        lineDesc = shippingLineInfo.short_desc
      }
    }
    if (obj.container_type) {
      typeDesc = shortenContainerType(obj.container_type)
    }
    if (lineDesc && typeDesc) {
      return `${lineDesc}/${typeDesc}`
    } else if (lineDesc) {
      return lineDesc
    } else if (typeDesc) {
      return typeDesc
    } else {
      return ''
    }
  }
  return { makeLineAndTypeDescription }
}
