<script setup lang="ts">
import type { IconType } from '../emptyReturnRules/types'
import type { ReturnRule } from '~/models/returnRules'

const props = defineProps<{
  returnRule: ReturnRule | null
}>()

const emit = defineEmits({
  viewReturnRulesScreenshot: () => true,
})

/** Function to return only the state of the returnRule i.e. open | closed | exempt | duals_only to pass it on to the icons component */
const returnRuleIcons: ComputedRef<IconType | null | undefined> = computed(
  () => {
    if (!props.returnRule) return null

    if (props.returnRule.open) {
      return 'open'
    }

    if (props.returnRule.closed) {
      return 'closed'
    }

    if (props.returnRule.exempt) {
      return 'exempt'
    }

    if (props.returnRule.duals_only) {
      return 'duals_only'
    }
  }
)
</script>

<template>
  <div v-if="returnRule" class="flex px-3 pt-3 pb-0">
    <!-- Side rectangle deco -->
    <div class="w-full">
      <!-- Dialog Header & screenshot button -->
      <div class="flex w-full justify-between">
        <div class="flex items-center">
          <ReturnRulesIcons :type="returnRuleIcons" />
          <ReturnRulesHeader :return-rule="returnRule" />
        </div>

        <!-- Rule screenshot -->
        <el-button
          plain
          type="primary"
          class="mr-2"
          @click="emit('viewReturnRulesScreenshot')"
        >
          <i-mdi:eye class="mr-1" />
          View screenshot
        </el-button>
      </div>

      <!-- Rules checked -->
      <div class="mt-1">
        <ObjectChangeTimesDisplay
          v-if="returnRule"
          :object="returnRule"
          last-checked-label="Rules last checked"
          class="text-gray-400"
        />
      </div>

      <!-- Warnings -->
      <div class="w-full" :class="{ 'mt-6': returnRule.duals_only }">
        <el-alert
          v-if="!returnRule"
          show-icon
          title="The terminal might be closed"
          type="warning"
          effect="dark"
          :closable="false"
        />
        <el-alert
          v-else-if="returnRule.duals_only"
          show-icon
          title="You can only return if you have another container to pickup"
          type="warning"
          effect="dark"
          :closable="false"
        />
      </div>
    </div>
  </div>

  <!-- IF no returnRule  -->
  <div v-else class="flex p-3 flex flex-col custom-border-gray">
    <div class="flex w-full justify-between mb-6">
      <div class="flex items-center">
        <ReturnRulesIcons :type="returnRuleIcons" />
        <ReturnRulesHeader :return-rule="returnRule" />
      </div>
    </div>

    <el-alert
      show-icon
      title="The terminal might be closed"
      type="info"
      effect="dark"
      :closable="false"
    />
  </div>
</template>
