<script lang="ts" setup>
import type { ElAutocomplete } from 'element-plus'
import { ElMessageBox } from 'element-plus'
import TruckSelectorOption from './TruckSelectorOption.vue'
import type { GETTruck, TerminalName } from '~/services/apiClient'
import type { Truck, TruckWithStats } from '~/models/trucks'
import { truckWithStatsFromTruck } from '~/models/trucks'
import { useTrucksStore } from '~/stores/trucks'
import { useTruckStatsStore } from '~/stores/truckStats'
import AddTruckDialog from '~/components/dialogs/AddTruckDialog.vue'

const props = withDefaults(
  defineProps<{
    licensePlate: string
    terminal?: TerminalName
    autofocus?: boolean
    autoSelectValue?: boolean
    disabled?: boolean
    showIcon?: boolean
    loadUsageCounts?: boolean
    narrowWidth?: boolean
  }>(),
  {
    autofocus: false,
    autoSelectValue: false,
    disabled: false,
    showIcon: true,
    loadUsageCounts: true,
    narrowWidth: true,
  }
)

const emit = defineEmits<{
  (event: 'update:modelValue', value: Truck | null): void
}>()

const trucksStore = useTrucksStore()
const truckStatsStore = useTruckStatsStore()

const selectedPlate = ref<string>(props.licensePlate)
const addingNewTruck = ref(false)
const autocompleteRef = ref<typeof ElAutocomplete | null>(null)
const truckOptions = ref<TruckWithStats[]>([])
const autoSelectedValue = ref(null as null | string)

const loading = computed(() => trucksStore.loading)
const isModifiedFromPrefill = computed(() => {
  return selectedPlate.value !== props.licensePlate
})

const terminalOrNull = computed(() => props.terminal || null)

// If we receive a change from the "outside", update
const inputLicensePlate = computed(() => props.licensePlate)
watch(inputLicensePlate, (newPlate) => {
  const activated = autocompleteRef.value?.activated
  // If the input is active, then the user caused the change and we don't want to listen
  // to our own change as it would clear the input
  if (activated) return
  selectedPlate.value = newPlate
})
onMounted(() => {
  computeTruckOptions()
  trucksStore.loadIfNotLoadedSinceReconnect().then(computeTruckOptions)
  if (props.loadUsageCounts) {
    truckStatsStore.loadIfNecessary(terminalOrNull.value).then(() => {
      computeTruckOptions()
      if (props.autofocus && autocompleteRef.value) {
        autocompleteRef.value.focus()
      }
      if (
        !selectedPlate.value &&
        props.autoSelectValue &&
        truckOptions.value.length > 0
      ) {
        // Select random truck
        const randomIx = Math.floor(
          Math.random() * Math.min(truckOptions.value.length - 1, 50)
        )
        const truck = truckOptions.value[randomIx]
        autoSelectedValue.value = truck.truck.licensePlate
        selectedPlate.value = truck.truck.licensePlate
      }
    })
  }
})

function computeTruckOptions(): void {
  const trucksByID = new Map<number, TruckWithStats>()
  function addTruck(truck: TruckWithStats) {
    trucksByID.set(truck.truck.id, truck)
  }
  // From trucks store (includes trucks with no stats)
  trucksStore.trucks.forEach((truck) => {
    addTruck(truckWithStatsFromTruck(truck))
  })
  // From truck stats
  truckStatsStore.getStatusForTerminal(terminalOrNull.value).forEach(addTruck)
  const trucks = Array.from(trucksByID.values())
  // Overwrite any truck values using the trucks store, which gets synced
  trucks.forEach((truck) => {
    const truckFromStore = trucksStore.getTruck(
      truck.truck.id,
      truck.truck.licensePlate
    )
    if (truckFromStore) {
      truck.truck = truckFromStore
    }
  })
  // Sort by num completed descending
  trucks.sort((a, b) => (b.numAssigned || 0) - (a.numAssigned || 0))
  truckOptions.value = trucks
}

const selectedTruck = computed(() => {
  const truck = truckOptions.value.find(
    (truck) => truck.truck.licensePlate === selectedPlate.value
  )
  return truck
})

watch(selectedTruck, (newValue) => {
  emit('update:modelValue', newValue ? newValue.truck : null)
})

// Options combine:
//    a) any passed truck
//    b) trucks in trucks store
//    c) truck states for this terminal
function fetchSuggestions(
  query: string,
  callback: (trucks: TruckWithStats[]) => void
): void {
  const queryUpper = query.toUpperCase()
  if (isModifiedFromPrefill.value) {
    const filteredTrucks = truckOptions.value.filter(
      (truck) =>
        truck.truck.licensePlate.includes(queryUpper) ||
        truck.truck.name?.toUpperCase()?.includes(queryUpper)
    )
    callback(filteredTrucks)
  } else {
    callback(truckOptions.value)
  }
}
/*
## Search cases:
  - filter by plate
  - filter by truck name
## SelectCases:
  - Select loaded truck
  - Paste license plate for truck loaded
  - Paste license plate for truck not yet loaded, but created
  - Paste license plate for truck not in system
## Strategy:
  - Display truck info on options and outside selector
    - Display only license plate in selector, truck can go outside
*/
function updateTruckName() {
  let title = 'Name Truck'
  if (selectedTruck.value?.truck.name) {
    title = `Rename Truck '${selectedTruck.value?.truck.name}'`
  }
  ElMessageBox.prompt(
    `Enter name for truck with license plate '${selectedTruck.value?.truck.licensePlate}'`,
    title,
    {
      confirmButtonText: 'Update Truck Name',
      cancelButtonText: 'Cancel',
      autofocus: true,
      confirmButtonClass: 'el-button--success',
      inputValue: selectedTruck.value?.truck.name || '',
    }
  )
    .then((messageData) => {
      if (messageData.action !== 'confirm')
        throw new Error(`Unexpected action: ${messageData.action}`)
      if (!selectedTruck.value) throw new Error('No selected truck')
      trucksStore
        .updateExistingTruck([
          {
            id: selectedTruck.value.truck.id,
            name: messageData.value,
            license_plate_number: selectedTruck.value.truck.licensePlate,
            archived: selectedTruck.value.truck.archived,
          },
        ])
        .then(() => {
          computeTruckOptions()
        })
    })
    .catch((error) => {
      // Just avoids a console error which isn't really an issue
      if (error === 'cancel') return
      throw new Error(error)
    })
}
function clearSelection() {
  selectedPlate.value = ''
}
function handleTruckAdded(savedTruck: GETTruck) {
  selectedPlate.value = savedTruck.license_plate_number
  addingNewTruck.value = false
  computeTruckOptions()
}
</script>

<template>
  <ElAutocomplete
    ref="autocompleteRef"
    v-model="selectedPlate"
    :loading="loading"
    :fetch-suggestions="fetchSuggestions"
    value-key="plate"
    :clearable="false"
    :select-when-unmatched="true"
    :highlight-first-item="!autoSelectedValue"
    :debounce="50"
    :disabled="disabled"
    :placeholder="loading ? 'Loading trucks...' : 'Enter plate or truck'"
    class="truck-selector"
    :class="{ 'narrow-width': narrowWidth }"
    v-bind="$attrs"
  >
    <template v-if="showIcon" #prefix>
      <i-clarity:truck-solid />
    </template>
    <template #default="{ item: truckAndStats }">
      <TruckSelectorOption
        v-if="truckAndStats"
        :truck-and-stats="truckAndStats as TruckWithStats"
        :terminal="terminalOrNull"
      />
    </template>
    <template #suffix>
      <!-- Not found/create new truck button -->
      <div
        v-if="selectedPlate && !selectedTruck"
        v-tooltip="`You need to create a new truck to use this license plate`"
        class="flex items-center"
      >
        Not found
        <el-button
          type="primary"
          size="small"
          plain
          round
          class="ml-2"
          @click.prevent.stop="addingNewTruck = true"
        >
          Create New Truck
        </el-button>
      </div>
      <template v-else-if="selectedTruck">
        <span v-if="selectedTruck.truck.name" class="text-gray-600 mr-2"
          >({{ selectedTruck.truck.name }})</span
        >
        <!-- Name truck button -->
        <el-button
          v-tooltip="
            selectedTruck.truck.name
              ? `Change truck name from '${selectedTruck.truck.name}' to something else`
              : 'Make identifying this truck easier with a name'
          "
          :type="selectedTruck.truck.name ? 'info' : 'primary'"
          size="small"
          plain
          round
          @click.prevent.stop="updateTruckName"
        >
          <template v-if="selectedTruck.truck.name">Rename</template>
          <template v-else>Name Truck</template>
        </el-button>
        <!-- Clear button -->
        <el-button
          v-tooltip="`Clear selection`"
          type="danger"
          size="small"
          circle
          plain
          aria-label="Clear selection"
          class="clear-button"
          @click.prevent.stop="clearSelection"
        >
          <i-mdi:close />
        </el-button>
      </template>
      <template v-else>
        <i-mdi:chevron-down />
      </template>
    </template>
  </ElAutocomplete>
  <AddTruckDialog
    v-if="addingNewTruck"
    v-model:visible="addingNewTruck"
    :license-plate-prefill="selectedPlate"
    @truck-added="handleTruckAdded"
    @update:visible="addingNewTruck = $event"
  />
</template>

<style lang="scss">
$buttonSpacing: 5px;

.truck-selector {
  .el-input__wrapper {
    padding-right: $buttonSpacing;
  }
  .clear-button {
    margin-left: $buttonSpacing;
  }
  // This is the area for the actual plate, which should not be more than 8 characters
  // so no we can limit the width a good amount
  &.narrow-width input.el-input__inner {
    width: 9ch;
  }
}
</style>
