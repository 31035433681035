<script lang="ts" setup>
import type { GateScheduleReading } from '~/models/gateSchedules'
import { useGateScheduleReadingsHistory } from '~/stores/gateScheduleReadingHistory'

const props = defineProps<{
  selectedReading: GateScheduleReading | null
}>()
const emit = defineEmits<{
  'reading-clicked': [reading: GateScheduleReading]
}>()
const gateSchedulesReadingHistoryStore = useGateScheduleReadingsHistory()
const readingsHistory = toRef(gateSchedulesReadingHistoryStore, 'readings')
const loadingHistory = toRef(gateSchedulesReadingHistoryStore, 'loading')
</script>

<template>
  <BaseReadingHistory
    :selected-reading="props.selectedReading"
    :readings-history="readingsHistory"
    :loading-history="loadingHistory"
    @reading-clicked="emit('reading-clicked', $event)"
  ></BaseReadingHistory>
</template>
