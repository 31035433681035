import { createGETAppointment, createGETContainer } from '@test-factories'
import { DateTime } from 'luxon'
import type { AppointmentSlot } from '~/models/appointmentSlot'
import type {
  AppointmentActionResponse,
  BookingRequest,
  BookingVerificationNotification,
  Notification as SoketiNotification,
  TerminalName,
} from '~/services/apiClient'
import {
  ActionStatus,
  ActionType,
  AppointmentBookingApi,
  NotificationType,
  PortalName,
} from '~/services/apiClient'
import { useAppointmentWithContainerInfoStore } from '~/stores/appointmentsWithContainerInfo'
import { useUserStore } from '~/stores/user'
import type { BookingRequestState } from '~~/stores/mutationRequests'
import { useMutationRequestsStore } from '~~/stores/mutationRequests'

export function useBookAppointment() {
  const userStore = useUserStore()
  const mutationRequestsStore = useMutationRequestsStore()
  const bookingRequestID = ref<number | null>(null)

  function startBooking({
    slot,
    containerNumber,
    terminal,
    masterBL,
    licensePlate,
    ownChassis,
    existingAppointmentId,
  }: {
    slot: AppointmentSlot
    containerNumber: string
    terminal: TerminalName
    masterBL: string | undefined
    licensePlate: string | undefined
    ownChassis: boolean | undefined
    existingAppointmentId: number | undefined
  }): Promise<BookingRequestState> {
    const args: BookingArgs = {
      slot,
      containerNumber,
      terminal,
      masterBL,
      licensePlate,
      ownChassis,
      existingAppointmentId,
    }
    let result: BookingResponse
    if (userStore.testMode) {
      result = startMockBooking(args)
    } else {
      result = startLiveBooking(args)
    }
    return result.then((resp) => {
      const state: BookingRequestState = {
        requestID: resp.data.request_id,
        actionType: resp.data.action,
        status: 'pending',
        latestMessage: resp.data.message,
        selectedSlot: slot,
        originalAppointmentID: existingAppointmentId,
        // These will get filled out as we receive booking updates
        appointmentID: undefined,
        bookingRequest: undefined,
      }
      mutationRequestsStore.handleNewBookingRequest(state)
      return state
    })
  }

  function startLiveBooking({
    slot,
    containerNumber,
    terminal,
    masterBL,
    licensePlate,
    ownChassis,
    existingAppointmentId,
  }: BookingArgs): BookingResponse {
    const bookingAPI = new AppointmentBookingApi()
    return bookingAPI
      .bookAppointmentAppointmentsBookingBookPost({
        slot: {
          window_start: slot.window_start.toUTC().toISO(),
          terminal,
          container_number: containerNumber,
          from_terminal_block_availability_key:
            slot.from_terminal_block_availability_key,
        },
        master_bill_of_lading: masterBL,
        truck_license_plate: licensePlate,
        own_chassis: ownChassis,
        existing_appointment_id_to_cancel: existingAppointmentId,
      })
      .then((resp) => {
        bookingRequestID.value = resp.data.request_id
        return resp
      })
  }

  function startMockBooking({
    terminal,
    containerNumber,
    licensePlate,
    slot,
    masterBL,
  }: BookingArgs): BookingResponse {
    const requestID = 123
    const mockResponse: AppointmentActionResponse = {
      message: 'Booking begun',
      request_id: requestID,
      action: ActionType.Book,
    }
    const bookingStarted = DateTime.now()
    // Schedule the successfully completed booking request update notification that
    // would normally be delivered over websockets
    const userInfo = userStore.userInfo
    const bookingRequest: BookingRequest = {
      id: requestID,
      terminal,
      container_number: containerNumber,
      status: ActionStatus.Completed,
      created: bookingStarted.toISO(),
      requested_by: userInfo
        ? {
            id: userInfo.id,
            first_name: userStore.userInfo?.first_name,
          }
        : undefined,
      organization_id: userInfo ? userInfo.current_organization.id : 23,
      truck_license_plate: licensePlate || '',
      connection_id: 1, // No way to get this sadly
      portal_name: PortalName.Apm, // Again, no way to get this, just pick a value
      window_start: slot.window_start.toISO(),
      updated: DateTime.now().toISO(),
      master_bill_of_lading: masterBL || '',
    }
    setTimeout(() => {
      const notification: SoketiNotification = {
        event_type: NotificationType.BookingRequestUpdated,
        object: bookingRequest as any,
        level: 'success',
        title: 'Booking Completed',
        message: '',
      }
      mutationRequestsStore.handleBookingRequestUpdatedEvent(notification)
    }, 2000)
    // Schedule verification update
    const appointmentID = 123
    setTimeout(() => {
      bookingRequest.appointment_id = appointmentID
      const verificationNotification: BookingVerificationNotification = {
        action_request: bookingRequest as any,
        appointment_id: appointmentID,
        appointment_issues: [],
      }
      const notification: SoketiNotification = {
        event_type: NotificationType.BookingVerification,
        object: verificationNotification as any,
        level: 'success',
        title: 'Booking verification',
        message: 'Booking verification',
      }
      mutationRequestsStore.handleAppointmentVerificationEvent(notification)
    }, 3000)
    // Schedule the updated appointment notification, which is just an updated
    // container notification
    setTimeout(() => {
      const container = createGETContainer({
        container_number: containerNumber,
        latest_appointment: createGETAppointment({
          container_number: containerNumber,
          id: appointmentID,
        }),
      })
      const { updateFromContainer } = useAppointmentWithContainerInfoStore()
      updateFromContainer(container)
    }, 4000)
    return new Promise((resolve) => {
      setTimeout(() => {
        bookingRequestID.value = mockResponse.request_id
        resolve({ data: mockResponse })
      }, 500) // Simulate a short delay
    })
  }

  return { startBooking }
}

export interface BookAppointmentsPayload {
  containerNumber: string
  terminal: TerminalName
  masterBL: string
  licensePlate: string
  ownChassis: boolean | undefined
}
interface BookingArgs {
  slot: AppointmentSlot
  containerNumber: string
  terminal: TerminalName
  masterBL: string | undefined
  licensePlate: string | undefined
  ownChassis: boolean | undefined
  existingAppointmentId: number | undefined
}

type BookingResponse = Promise<{ data: AppointmentActionResponse }>
