<script lang="ts" setup>
import { Icon } from '@iconify/vue'
const props = defineProps<{
  icon: string
  label: string
  tooltip: string
  countsDesc: string
}>()
</script>

<template>
  <el-radio-button v-tooltip="props.tooltip" :value="props.label" size="large">
    <Icon :icon="props.icon" class="filter-icon ml-2 -my-5px" />
    <span class="mr-2">{{ props.label }} </span>
    <span class="status-count">
      {{ props.countsDesc }}
    </span>
  </el-radio-button>
</template>

<style scoped>
.filter-icon {
  font-size: 20px;
}
.status-count {
  font-size: 16px;
  font-weight: 700;
}
</style>
