import { DateTime } from 'luxon'
import type {
  GETCurrentTerminalGateScheduleResponse,
  GETGateScheduleEntryResponse,
  GETGateScheduleReading,
  GateStatus,
  Shift,
} from '~/services/apiClient'
import { TerminalName } from '~/services/apiClient'

import { getTerminalLabel } from '~/constants'
import { formatDTShort } from '~/utils'

export class TerminalGateSchedule {
  entries: GateScheduleEntry[]
  terminal: TerminalName
  screenshot_url: string

  constructor(raw: GETCurrentTerminalGateScheduleResponse) {
    this.terminal = raw.terminal
    this.screenshot_url = raw.screenshot_url
    this.entries = raw.entries
      .map((entry) => new GateScheduleEntry(entry))
      .sort(
        // sort by date
        (a, b) => {
          if (a.date < b.date) return -1
          if (a.date > b.date) return 1
          return 0
        }
      )
  }

  get terminalLabel(): string {
    switch (this.terminal) {
      case TerminalName.StAlam:
        return 'Shippers A'
      case TerminalName.StLb:
        return 'Shippers L'
      default:
        return getTerminalLabel(this.terminal)
    }
  }

  get screenshot_download_link(): string {
    return makeDownloadLink(
      this.screenshot_url,
      this.terminal,
      this.entries[0].date
    )
  }
}

export class GateScheduleEntry {
  date: DateTime
  shift: Shift
  status: GateStatus
  notes: string[]
  start_time: DateTime | null
  end_time: DateTime | null
  key: string
  constructor(raw: GETGateScheduleEntryResponse) {
    this.date = DateTime.fromISO(raw.date)
    this.shift = raw.shift
    this.status = raw.status
    this.notes = raw.notes
    this.start_time = raw.start_time ? DateTime.fromISO(raw.start_time) : null
    this.end_time = raw.end_time ? DateTime.fromISO(raw.end_time) : null
    this.key = gateScheduleEntryKey(this.date, this.shift)
  }
}

export class GateScheduleReading {
  terminal: TerminalName
  first_observed: DateTime
  last_observed: DateTime
  last_parsed_time: DateTime | null
  screenshot_url: string
  first_observed_raw: string
  constructor(reading: GETGateScheduleReading) {
    this.terminal = reading.terminal
    this.first_observed = DateTime.fromISO(reading.first_observed)
    this.last_observed = DateTime.fromISO(reading.last_observed)
    this.last_parsed_time = reading.last_parsed_time
      ? DateTime.fromISO(reading.last_parsed_time)
      : null
    this.first_observed_raw = reading.first_observed
    this.screenshot_url = reading.screenshot_url
  }

  get last_changed_desc(): string {
    return formatDTShort(this.first_observed)
  }

  get last_changed_human_desc(): string {
    return this.first_observed.toRelative()!
  }

  get last_observed_desc(): string {
    return formatDTShort(this.last_observed)
  }

  get last_observed_human_desc(): string {
    return this.last_observed.toRelative()!
  }

  get observed_for_desc(): string {
    return this.first_observed
      .toRelative({ base: this.last_observed })!
      .replace(' ago', '')
      .replace('in ', '')
  }

  get terminal_desc(): string {
    return getTerminalLabel(this.terminal)
  }

  get screenshot_download_link(): string {
    return makeDownloadLink(
      this.screenshot_url,
      this.terminal,
      this.first_observed
    )
  }
}

export function gateScheduleEntryKey(date: DateTime, shift: Shift) {
  return date.toISODate() + '-' + shift
}
function makeDownloadLink(
  url: string,
  terminal: TerminalName,
  date: DateTime
): string {
  const fileName = encodeURIComponent(
    `${getTerminalLabel(terminal)} Gate Schedule for ` +
      `week(s) starting ${formatDTForFilename(date)}.png`
  )
  return `${url}?download=true&filename=${fileName}`
}

function formatDTForFilename(dt: DateTime): string {
  return dt.startOf('week').toFormat('yyyy-MM-dd')
}
