<script setup lang="ts">
// TODO: Consider consolidating with AppointmentSlotButton?
import type { SlotSelectedEvent } from '~/components/appointmentSlots/events'
import type { BaseAppointmentSlot } from '~/models/appointmentSlot'
import CancelAppointmentButton from '~/components/CancelAppointmentButton.vue'
import type { Appointment } from '~/models/appointments'

const props = defineProps<{
  appointmentSlot: BaseAppointmentSlot
  existingAppointment: Appointment | undefined
}>()
const emit = defineEmits({
  book: (event: SlotSelectedEvent) => true,
})
const slot = toRef(props, 'appointmentSlot')

const buttonType = computed(
  (): 'danger' | 'warning' | 'info' | 'primary' | 'success' => {
    if (slot.value.booked_appointment) {
      return 'danger'
    } else if (slot.value.num_appointments_available === 0) {
      return 'info'
    } else {
      return 'primary'
    }
  }
)
const buttonText = computed(() => {
  let verb = 'Book'
  if (slot.value.booked_appointment) {
    verb = 'Cancel'
  } else if (props.existingAppointment) {
    verb = 'Reschedule to'
  }
  const time = slot.value.window_start.toFormat('HH:mm')
  const endTime = slot.value.window_end?.toFormat('HH:mm')
  const timeText = endTime ? `${time} - ${endTime}` : time
  let availCountDesc: string
  if (
    slot.value.num_appointments_available === undefined ||
    slot.value.num_appointments_available === null
  ) {
    availCountDesc = '1+'
  } else {
    availCountDesc = slot.value.num_appointments_available.toString()
  }
  return `${verb} ${timeText} (${availCountDesc} available)`
})

function onClick() {
  emit('book', { slot: props.appointmentSlot })
}
</script>

<template>
  <div>
    <CancelAppointmentButton
      v-if="slot.booked_appointment"
      :appointment="slot.booked_appointment"
      class="w-full"
    >
      <el-button class="w-full" :type="buttonType" plain round>
        {{ buttonText }}
      </el-button>
    </CancelAppointmentButton>
    <el-button
      v-else
      class="w-full"
      :type="buttonType"
      plain
      round
      @click="onClick"
    >
      {{ buttonText }}
    </el-button>
  </div>
</template>
