<script lang="ts" setup>
import type { GETConnection } from '~/services/apiClient'

const props = defineProps<{ connection: GETConnection }>()
</script>

<template>
  <SFTPConnection
    :connection="props.connection"
    tms-name="Trinium"
    documentation-link="https://help.draydog.com/en/article/trinium-integration-15cocra/"
  />
</template>
