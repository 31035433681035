<script lang="ts" setup>
import type { Appointment } from '~/models/appointments'
import { formatDTMilitary } from '~/utils'

const props = defineProps<{
  appointment: Appointment
}>()

const textToCopy = computed(() => {
  return props.appointment.window_start.toFormat('MM/dd/yyyy HH:mm')
})
</script>

<template>
  <ClickToCopy :text-to-copy="textToCopy">
    <slot>
      {{ formatDTMilitary(appointment.window_start) }}
    </slot>
  </ClickToCopy>
</template>
