<script lang="ts" setup>
import { marked } from 'marked'
import type { GlobalNotification } from '~/services/apiClient'

const props = defineProps({
  notification: {
    type: Object as PropType<GlobalNotification>,
    required: true,
  },
})

const showingAlertMessage = ref(false)

const emoji = computed(() => {
  switch (props.notification.level) {
    case 'success':
      return '🎉'
    case 'info':
      return 'ℹ️'
    case 'warning':
      return '❗️'
    case 'error':
      return '🚨'
  }
})
function renderMarkdown(text: string) {
  // We don't want the outer paragraph tags
  return marked(text, { mangle: false, headerIds: false }).replace(
    /^\s*<p>(.*)<\/p>\s*$/,
    '$1'
  )
}
const titleHtml = computed(() => {
  return renderMarkdown(props.notification.title)
})
const messageHtml = computed(() => {
  if (!props.notification.message) return ''
  return renderMarkdown(props.notification.message)
})

const color_class = computed(() => {
  switch (props.notification.level) {
    case 'success':
      return 'bg-green-300'
    case 'info':
      return 'bg-blue-300'
    case 'warning':
      return 'bg-yellow-300'
    case 'error':
      return 'bg-red-300'
  }
})
</script>

<template>
  <MessageBanner class="mb-2 notification-banner" :class="[color_class]">
    {{ emoji }}
    <span v-html="titleHtml" />
    <span
      v-if="notification.message"
      plain
      class="ml-4 underline text-gray-600 cursor-pointer"
      @click="showingAlertMessage = true"
      >learn more</span
    >
  </MessageBanner>
  <el-dialog v-model="showingAlertMessage" title="Tips" width="30%">
    <template #header>
      <div class="text-lg text-gray-700 py-2 px-4 rounded">
        {{ emoji }}
        <span v-html="titleHtml" />
      </div>
    </template>
    <template #default>
      <div class="text-sm text-gray-700 py-2 px-4 rounded notification-message">
        <span v-html="messageHtml" />
      </div>
    </template>
    <template #footer>
      <el-button
        type="primary"
        class="w-full"
        @click="showingAlertMessage = false"
      >
        Close
      </el-button>
    </template>
  </el-dialog>
</template>

<style lang="scss">
.notification-message,
.notification-banner {
  // Properly style lists
  ul {
    list-style: disc;
    margin-left: 2em;
  }
  ol {
    list-style: decimal;
    margin-left: 2em;
  }
  // Properly style headers
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: 0.5em;
  }
  h1 {
    font-size: 1.5em;
  }
  h2 {
    font-size: 1.25em;
  }
  h3 {
    font-size: 1.1em;
  }
  h4 {
    font-size: 1em;
  }
  h5 {
    font-size: 0.9em;
  }
  h6 {
    font-size: 0.8em;
  }
  // Properly style links
  a {
    text-decoration: underline;
    @apply text-gray-600;
  }
}
</style>
