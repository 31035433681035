<script lang="ts" setup>
import type { Truck } from '~/models/trucks'
import type { GETTruck } from '~/services/apiClient'
import { useTrucksStore } from '~/stores/trucks'

const props = defineProps<{
  truck: Truck
  hovered: boolean
}>()
const trucksStore = useTrucksStore()

const editing = ref(false)
const saving = ref(false)
const inputRef = ref<HTMLInputElement | null>(null)
const editNameValue = ref(props.truck.name)

function saveTruck(truck: GETTruck) {
  saving.value = true
  trucksStore.updateExistingTruck([truck]).finally(() => {
    editing.value = false
    saving.value = false
  })
}
function saveTruckName() {
  saveTruck({
    id: props.truck.id,
    license_plate_number: props.truck.licensePlate,
    name: editNameValue.value || undefined,
  })
}

function archiveTruck() {
  saveTruck({
    id: props.truck.id,
    license_plate_number: props.truck.licensePlate,
    name: props.truck.name || undefined,
    archived: true,
  })
}
function unarchiveTruck() {
  saveTruck({
    id: props.truck.id,
    license_plate_number: props.truck.licensePlate,
    name: props.truck.name || undefined,
    archived: false,
  })
}

function startEditing() {
  editing.value = true
  nextTick(() => {
    inputRef.value?.select()
  })
}

function cancelEditing() {
  editing.value = false
}

function handleKeyDown(event: KeyboardEvent | Event) {
  if (event instanceof KeyboardEvent && event.key === 'Enter') {
    saveTruckName()
  }
}
</script>

<template>
  <div
    v-loading="saving"
    style="min-height: 32px"
    class="flex items-center justify-between"
  >
    <template v-if="editing">
      <el-input
        ref="inputRef"
        v-model="editNameValue"
        placeholder="Enter truck name"
        @keydown="handleKeyDown"
      >
      </el-input>
      <el-button type="success" class="ml-3" @click="saveTruckName">
        Save
      </el-button>
      <el-button @click="cancelEditing">Cancel</el-button>
    </template>
    <template v-else>
      <span>
        <template v-if="props.truck.name">{{ props.truck.name }}</template>
        <!-- TODO Make visually more clear a row is archived, maybe add an icon -->
        <template v-if="props.truck.archived"> (Archived)</template>
      </span>
      <!-- Action buttons -->
      <div v-if="props.hovered">
        <el-button
          v-tooltip="
            `Names make it easier to set license plates and manage trucks`
          "
          class="mx-3"
          plain
          type="primary"
          @click="startEditing"
        >
          <template v-if="props.truck.name">
            <i-mdi:pencil-outline class="mr-2" />
            Edit Name
          </template>
          <template v-else>
            <i-mdi:plus-circle-outline class="mr-2" />
            Add Name
          </template>
        </el-button>
        <el-button
          v-if="!props.truck.archived"
          v-tooltip="
            `Will hide truck from table and in selectors. You can always unarchive`
          "
          plain
          type="warning"
          @click="archiveTruck"
        >
          <i-mdi:archive-outline class="mr-2" />
          Archive
        </el-button>
        <el-button
          v-else
          v-tooltip="
            `Restore this truck so that it will be shown throughout the app`
          "
          plain
          type="warning"
          @click="unarchiveTruck"
        >
          <i-mdi:archive-off-outline class="mr-2" />
          Unarchive
        </el-button>
      </div>
    </template>
  </div>
</template>
