<script lang="ts" setup>
import useTruckDescription from '~/compositions/useTruckDescription'
import { TERMINALS_WHERE_LICENSE_PLATE_CAN_BE_EDITED } from '~/constants'
import type { Container } from '~/models/containers'
const props = defineProps<{
  container: Container
}>()

const latest_appointment = computed(() => {
  return props.container.latest_appointment
})
const terminal = computed(() => {
  return props.container.terminal
})
const is_in_booked_state = computed(() => {
  return props.container.is_in_booked_state
})
const truckID = computed(() => {
  return latest_appointment.value?.truck_id
})
const licensePlate = computed(() => {
  return latest_appointment.value?.truck_license_plate_number
})
const truckDescription = useTruckDescription(truckID, licensePlate)

const editingLicensePlateDialogOpen = ref(false)
const canEditLicensePlate = computed(() => {
  return (
    terminal.value &&
    TERMINALS_WHERE_LICENSE_PLATE_CAN_BE_EDITED.has(terminal.value)
  )
})
function editLicensePlate() {
  if (!canEditLicensePlate.value) return
  editingLicensePlateDialogOpen.value = true
}

const licensePlateMessage = computed(() => {
  if (is_in_booked_state.value && latest_appointment.value) {
    let editMsg
    if (canEditLicensePlate.value) {
      editMsg = 'Click to edit'
    } else {
      editMsg = 'License plate cannot be edited for this terminal'
    }
    if (latest_appointment.value.truck_license_plate_number) {
      return {
        message: 'Plate: ' + truckDescription.value,
        type: 'info' as 'info' | 'warning',
        tooltip: 'License Plate Number set on appointment. ' + editMsg,
      }
    } else {
      return {
        message: 'No Plate',
        type: 'warning' as 'info' | 'warning',
        tooltip: 'No License Plate Number set on appointment. ' + editMsg,
      }
    }
  }
  return null
})
</script>

<template>
  <ColoredBadge
    v-if="licensePlateMessage"
    v-tooltip="licensePlateMessage.tooltip"
    :type="licensePlateMessage.type"
    @click.stop="canEditLicensePlate && editLicensePlate()"
  >
    {{ licensePlateMessage.message }}
    <i-mdi:pencil
      v-if="canEditLicensePlate"
      class="align-text-top h-13px text-gray-400 -mr-1"
    />
    <EditLicensePlateDialog
      v-if="editingLicensePlateDialogOpen && latest_appointment"
      v-model="editingLicensePlateDialogOpen"
      append-to-body
      :appointment="latest_appointment"
    />
  </ColoredBadge>
</template>
