<script lang="ts" setup>
import type { PropType } from 'vue'
import { useCreateCustomer } from '~/compositions/createCustomer'

const props = defineProps({
  modelValue: { type: Number as PropType<number | undefined> },
  optional: { type: Boolean, default: true },
})
const emit = defineEmits<{ (e: 'update:modelValue', value: number): void }>()
const { createCustomer } = useCreateCustomer()

const customerID = useVModel(props, 'modelValue', emit)
function createAndSetCustomer() {
  createCustomer().then((customer) => {
    if (customer) {
      customerID.value = customer.id
    }
  })
}
const label = computed(() => {
  if (props.optional) {
    return 'Customer (optional):'
  } else {
    return 'Customer:'
  }
})
</script>

<template>
  <el-form-item>
    <div class="flex flex-col w-full">
      <p class="font-medium text-md">{{ label }}</p>

      <div class="w-full justify-between flex">
        <CustomerSelector
          v-model="customerID"
          :allow-create="true"
          class="w-full"
        />

        <el-button
          v-tooltip="'Add a new customer'"
          plain
          size="large"
          type="primary"
          class="ml-2"
          @click="createAndSetCustomer"
        >
          <i-mdi:plus />
          New
        </el-button>
      </div>
    </div>
  </el-form-item>
</template>
