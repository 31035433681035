<script lang="ts" setup>
const props = defineProps({ text: { type: String, required: true } })
</script>

<template>
  <div
    class="mx-auto text-lg text-gray-500 text-center h-full flex justify-center items-center"
  >
    <span class="text-center h-20">
      <i-mdi-cursor-default-click />
      {{ props.text }}
    </span>
  </div>
</template>
