import { defineStore } from 'pinia'
import type { AxiosError } from 'axios'
import { DateTime } from 'luxon'
import axios from 'axios'
import { AppointmentBookingApi } from '~/services/apiClient'
import { AppointmentSlot } from '~/models/appointmentSlot'
import { handleAPIError } from '~/apiErrorHandling'

export const useApptAvailabilityStore = defineStore('appointmentSlots', {
  state: () => {
    return {
      slots: [] as AppointmentSlot[],
      slotsObserved: null as DateTime | null,
      loading: false,
      availabilityFound: false,
      failed: false,
      abortController: null as AbortController | null,
    }
  },
  actions: {
    load(
      container_number: string,
      { forceRefresh = false, background = false }
    ): Promise<string | void> {
      this.loading = true
      this.failed = false
      if (!background) {
        this.slots = []
        this.slotsObserved = null
      }
      const newAbortController = this.cancelExistingRequests()
      const axiosWithNoInterceptors = axios.create()
      const api = new AppointmentBookingApi(
        undefined,
        undefined,
        axiosWithNoInterceptors
      )
      // TODO: Consider passing these to shave off a SQL query in the API usage
      const terminal = undefined
      const terminalBlockAvailabilityKey = undefined
      return api
        .getAvailableSlotsForContainerAppointmentsAvailabilityContainerNumberGet(
          container_number,
          forceRefresh,
          terminal,
          terminalBlockAvailabilityKey,
          {
            signal: newAbortController.signal,
          }
        )
        .then((resp) => {
          const availability = resp.data
          this.slotsObserved = DateTime.fromISO(availability.observed)
          this.slots = resp.data.slots.map(
            (slot) => new AppointmentSlot(availability, slot)
          )
          this.availabilityFound = true
        })
        .catch((err: AxiosError) => {
          if (err.response?.status === 404) {
            this.availabilityFound = false
          } else {
            if (this.abortController === newAbortController) {
              // This failure doesn't matter if we have a newer abortController
              // from a newer request
              this.failed = true
            }
            if (err.response?.status === 500) {
              // this failure means some underlying portal error occurred
              // we don't want to display a bunch of crap error messages to the user
              this.failed = true
              // If there is JSON with a "message" field, resolve promise with message
              // value
              if (err.response.data) {
                if (Object.hasOwn(err.response.data, 'message')) {
                  // @ts-expect-error
                  return Promise.resolve(err.response.data.message)
                }
              }
            } else {
              handleAPIError(err)
            }
          }
        })
        .finally(() => {
          if (this.abortController === newAbortController) {
            this.abortController = null
            // If we have a newer abortController, this would be set to false by the
            // request that created that abortController
            this.loading = false
          }
        })
    },
    cancelExistingRequests(): AbortController {
      if (this.abortController) {
        this.abortController.abort()
      }
      const abortController = new AbortController()
      this.abortController = abortController
      return abortController
    },
  },
})
