<script lang="ts" setup>
import type { PropType } from 'vue'
import type { SimpleUser } from '~/services/apiClient'
import { useUsersStore } from '~/stores/users'
import { useUserStore } from '~/stores/user'
import { useSimpleUserLabel } from '~/compositions/useSimpleUserLabel'
const props = defineProps({
  modelValue: { type: Array as PropType<number[]> },
  placeholder: { type: String, default: 'Select users' },
  includeUnassignedOption: { type: Boolean, default: false },
})
const emit = defineEmits<{ (e: 'update:modelValue', value: number[]): void }>()
const selectedUserIDs = useVModel(props, 'modelValue', emit)

const usersStore = useUsersStore()
const userStore = useUserStore()
const userOptions = ref([] as SimpleUser[])
const simpleUserLabel = useSimpleUserLabel()
onMounted(() => {
  if (usersStore.users.length === 0) {
    usersStore.load().then(() => {
      userOptions.value = usersStore.users
    })
  } else {
    userOptions.value = usersStore.users
  }
})
const sortedUserOptions = computed(() => {
  const meUserGroup = [] as SimpleUser[]
  const otherUsersGroup = [] as SimpleUser[]
  for (const user of userOptions.value) {
    if (user.id === userStore.loggedInUser?.id) {
      meUserGroup.push(user)
    } else {
      otherUsersGroup.push(user)
    }
  }
  const unassignedUser = { id: -1, first_name: 'Unassigned', last_name: '' }
  const options = [...meUserGroup]
  if (props.includeUnassignedOption) {
    options.push(unassignedUser)
  }
  options.push(...otherUsersGroup)
  return options
})
</script>

<template>
  <el-select
    v-model="selectedUserIDs"
    :loading="usersStore.loading"
    multiple
    filterable
    default-first-option
    collapse-tags
    collapse-tags-tooltip
    clearable
    :placeholder="placeholder"
    size="large"
  >
    <template #prefix>
      <i-ant-design:user-outlined />
    </template>
    <el-option
      v-for="user of sortedUserOptions"
      :key="user.id"
      :label="simpleUserLabel(user)"
      :value="user.id"
    />
  </el-select>
</template>
