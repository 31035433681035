<script lang="ts" setup>
import { ElMessageBox } from 'element-plus'
import type { PropType } from 'vue'
const props = defineProps({
  modelValue: { type: Number, required: true },
  size: {
    type: String as PropType<'default' | 'small' | 'large'>,
    default: 'default',
  },
})
const emit = defineEmits<{ (e: 'update:modelValue', value: number): void }>()
const bucketMinuteOptions = [60, 120, 180, 240, 360, 480, 720, 1440]

function bucketLabel(bucketMinutes: number) {
  return `${bucketMinutes / 60} hours`
}

function handleBucketSizeChange(newValue: number) {
  ElMessageBox.confirm(
    'Changing bucket size will clear your existing capacity settings. Are you sure you want to do this?',
    'Confirm change bucket size?',
    {
      confirmButtonText: 'Change and clear',
      cancelButtonText: 'Cancel',
      type: 'warning',
    }
  )
    .then(() => {
      emit('update:modelValue', newValue)
    })
    .catch(() => {
      // do nothing
    })
}
</script>

<template>
  <el-select
    :model-value="props.modelValue"
    :size="size"
    v-bind="$attrs"
    @update:model-value="handleBucketSizeChange"
  >
    <el-option
      v-for="bucketMinutes in bucketMinuteOptions"
      :key="bucketMinutes"
      :label="bucketLabel(bucketMinutes)"
      :value="bucketMinutes"
    />
  </el-select>
</template>
