<script lang="ts" setup>
import startCase from 'lodash-es/startCase'
import { useMakeDualTooltip } from '~/compositions/createDualToolTip'
import {
  AppointmentsApi,
  GETFileExportFileTypeEnum,
  type TerminalName,
  type TransactionDirection,
} from '~/services/apiClient'
import { useAppointmentStore } from '~/stores/appointments'
import { appointmentStatusColor } from '~/utils'
import type { BucketFilter } from '~/models/bucketFilters'

const props = withDefaults(
  defineProps<{
    bucketFilter: BucketFilter
    numAppointmentsInBucket: number
    terminal?: TerminalName
    loaded?: boolean | null
    direction?: TransactionDirection | null
  }>(),
  {
    terminal: undefined,
    loaded: null,
    direction: null,
  }
)
const bucketFilter = toRef(props, 'bucketFilter')
const appointmentsStore = useAppointmentStore()
const loading = toRef(appointmentsStore, 'loading')
const { makeDualTooltip } = useMakeDualTooltip()
const page = ref(1)
const pageSize = ref(200)
const pageSizeOptions = ref([50, 100, 200, 500])
function handlePageSizeChange(newPageSize: number) {
  pageSize.value = newPageSize
}
function handlePageChange(newPage: number) {
  page.value = newPage
}
const downloadingCSV = ref(false)
const downloadingExcel = ref(false)
const selectedStatus = computed(() => {
  return props.bucketFilter.status
})

const selectedTimeDesc = computed(() => {
  const start = bucketFilter.value.bucket_start
  const end = bucketFilter.value.bucket_end
  const durationHours = end.diff(start).as('hours')
  const longDTFormat = 'ccc MM-dd-yyyy HH:mm'
  const shortDTFormat = 'ccc MM-dd-yyyy'
  if (durationHours === 1) {
    return 'hour of ' + start.toFormat(longDTFormat)
  } else if (durationHours > 1 && durationHours < 24) {
    return `${durationHours} hours starting at ${start.toFormat(longDTFormat)}`
  } else if (durationHours === 24) {
    return 'day of ' + start.toFormat(shortDTFormat)
  } else if (durationHours === 168) {
    return 'week of ' + start.toFormat(shortDTFormat)
  }
  // Fallback
  const durationDesc = end
    .toRelative({
      base: start,
    })
    .replace('in ', '')
  let dtFormat = shortDTFormat
  if (durationHours > 24) {
    dtFormat = longDTFormat
  }
  return `${start.toFormat(dtFormat)}  to ${end.toFormat(
    dtFormat
  )} (${durationDesc})`
})

function loadAppointmentsTable() {
  const bucketFilters = [props.bucketFilter]
  const timeStart = bucketFilters[0].bucket_start
  const timeEnd = bucketFilters[0].bucket_end
  //
  appointmentsStore.load_with_options({
    terminal: props.terminal,
    after: timeStart,
    before: timeEnd,
    direction: props.direction || undefined,
    loaded: props.loaded || undefined,
    page: page.value,
    pageSize: pageSize.value,
    statuses: bucketFilters.map((filter) => filter.status),
  })
}
onMounted(loadAppointmentsTable)
watch([pageSize, page, bucketFilter], loadAppointmentsTable)
// if we change the page size set the page to 1
watch(pageSize, () => {
  page.value = 1
})
const router = useRouter()
function searchInContainers() {
  const link = router.resolve({
    name: 'containers',
    query: {
      containers: appointmentsStore.appointments
        .map((appt) => appt.container_number)
        .join(','),
    },
  })
  window.open(link.href, '_blank')
}

function download(fileType: GETFileExportFileTypeEnum) {
  if (fileType === 'csv') {
    downloadingCSV.value = true
  } else {
    downloadingExcel.value = true
  }
  const fileName = `${
    selectedStatus.value
  } Appointments ${props.bucketFilter.bucket_start.toFormat(
    'yyyy-MM-dd HH_mm_ss'
  )}.${fileType}`
  const ids = appointmentsStore.appointments.map((appt) => appt.id)
  const api = new AppointmentsApi()
  api
    .fileExportAppointmentsFileExportPost(
      {
        file_type: fileType,
        to_trinium: false,
        appointment_ids: ids,
      },
      { responseType: 'blob' }
    )
    .then((resp) => {
      if (resp.status === 200) {
        const blob = new Blob([resp.data], {
          type: resp.headers['content-type'],
        })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      }
    })
    .finally(() => {
      downloadingCSV.value = false
      downloadingExcel.value = false
    })
}
const buttonSize: 'small' | 'default' = 'default'
</script>

<template>
  <div v-if="loading" v-loading="true" class="text-xl min-h-40"></div>
  <div v-else>
    <!-- Controls/header -->
    <div class="flex justify-between ml-3 appointments-controls">
      <!-- Left -->
      <div class="flex justify-start">
        <!-- Description -->
        <div class="text-xs text-gray-600 mr-3">
          <div>
            Viewing
            <strong>
              {{ appointmentsStore.appointments.length }}
              {{ selectedStatus }}
            </strong>
            appointments
          </div>
          <div>
            for <strong>{{ selectedTimeDesc }}</strong>
          </div>
        </div>
        <div class="inline-block">
          <el-pagination
            :current-page="page"
            :page-size="pageSize"
            :page-sizes="pageSizeOptions"
            background
            layout="prev, pager, next, sizes"
            :total="numAppointmentsInBucket"
            class="mr-2"
            @size-change="handlePageSizeChange"
            @current-change="handlePageChange"
          />
        </div>
      </div>
      <!-- Right -->
      <div>
        <el-button
          class="mr-2"
          :disabled="appointmentsStore.appointments.length === 0"
          :loading="downloadingCSV"
          :size="buttonSize"
          @click="download(GETFileExportFileTypeEnum.Csv)"
        >
          <i-mdi:download />
          Download as CSV
        </el-button>
        <el-button
          class="mr-2"
          style="margin-left: 0"
          :disabled="appointmentsStore.appointments.length === 0"
          :loading="downloadingExcel"
          :size="buttonSize"
          @click="download(GETFileExportFileTypeEnum.Xlsx)"
        >
          <i-mdi:download />
          Download as Excel
          <i-file-icons:microsoft-excel class="ml-1" />
        </el-button>
        <el-button
          v-tooltip="
            `Search for these container numbers in the &quot;Containers&quot; page`
          "
          class="mr-2"
          style="margin-left: 0"
          :disabled="appointmentsStore.appointments.length === 0"
          :size="buttonSize"
          @click="searchInContainers"
        >
          <i-mdi:magnify />
          View in Containers
          <i-mdi:open-in-new class="ml-1" />
        </el-button>
      </div>
    </div>

    <div class="ml-3 mb-3"></div>
    <el-table
      v-loading="appointmentsStore.loading"
      :data="appointmentsStore.appointments"
      stripe
      class="w-full"
      empty-text="No appointments"
    >
      <el-table-column prop="container_number" label="Container" :width="205">
        <template #default="{ row }">
          <ClickToCopy :text-to-copy="row.container_number" />
          <router-link
            v-if="row.container_number"
            v-tooltip="
              `Search for this container number in the &quot;Containers&quot; page`
            "
            :to="{
              name: 'containers',
              query: { containers: row.container_number },
            }"
            target="_blank"
          >
            <el-button size="small" class="float-right">
              View <i-mdi:open-in-new class="ml-1 mt-1px text-xs" />
            </el-button>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column prop="terminal_reference" label="Appointment #">
        <template #default="{ row }">
          <ClickToCopy :text-to-copy="row.terminal_reference" />
        </template>
      </el-table-column>
      <el-table-column prop="window_date" label="Date • Time">
        <template #default="{ row }">
          {{ row.window_date }}
          •
          {{ row.window_description }}
        </template>
      </el-table-column>
      <el-table-column prop="status" label="Terminal • Status">
        <template #default="{ row }">
          {{ row.terminal_name }} •
          <span
            :style="{ color: appointmentStatusColor(row.status) }"
            class="text-white"
          >
            {{ startCase(row.status) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="type" label="Type" width="110">
        <template #default="{ row }">
          <span
            v-if="row.linked_appointments.length"
            v-tooltip="makeDualTooltip(row.linked_appointments)"
          >
            {{ row.type }}
            <i-tabler:arrows-right-left class="align-middle" />
          </span>
          <template v-else>{{ row.type }}</template>
        </template>
      </el-table-column>
      <el-table-column prop="created_at_terminal_fmtd" label="Created">
        <template #default="{ row }">
          <div class="text-gray-400">
            {{ row.created_at_terminal_fmtd }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="booked_by" label="Booked By">
        <template #default="{ row }">
          <div v-if="row.booked_by" class="text-gray-400">
            <ClickToCopy :text-to-copy="row.booked_by" />
          </div>
          <div
            v-else
            v-tooltip="
              `Booking user is unknown because appointment was not booked on Dray Dog`
            "
          >
            Unknown
          </div>
        </template></el-table-column
      >
    </el-table>
    <el-pagination
      :current-page="page"
      :page-size="pageSize"
      :page-sizes="pageSizeOptions"
      small
      background
      layout="prev, pager, next, sizes"
      :total="numAppointmentsInBucket"
      class="mt-2 ml-3"
      @size-change="handlePageSizeChange"
      @current-change="handlePageChange"
    />
  </div>
</template>

<style lang="scss">
.appointments-controls {
  button {
    svg {
      @apply mt-3px;
    }
  }
}
</style>
