<script lang="ts" setup>
import { useRouteQuery } from '@vueuse/router'
import startCase from 'lodash-es/startCase'
import type { TerminalName } from '~/services/apiClient'
import { useLiveCameraStreams } from '~/compositions/useLiveCameraStreams'
import { getTerminalLabel } from '~/constants'

const route = useRoute()
const terminalName = route.params.terminalName as TerminalName
const streamNameQuery = useRouteQuery('streamName')
const streamName = computed(() => streamNameQuery.value as string)
const { images: currentImages, imagesList } = useLiveCameraStreams(terminalName)
const selectedImage = computed(() => {
  for (const currentImage of currentImages.values()) {
    if (currentImage.streamName === streamName.value) {
      return currentImage
    }
  }
  return null
})

function scrollToElement(selector: string) {
  const el = document.querySelector(selector)
  if (el) {
    const rectangle = el.getBoundingClientRect()
    window.scrollTo({ top: rectangle.top + window.scrollY, behavior: 'smooth' })
  }
}
onMounted(() => {
  if (route.hash) {
    setTimeout(() => {
      scrollToElement(route.hash)
    }, 1000)
  }
})
</script>

<template>
  <div>
    <div>
      <h1 id="title" class="text-4xl font-semibold">
        {{ getTerminalLabel(terminalName) }}
        Gate Cameras
      </h1>
      <div class="mb-3 mt-2">
        <AllCamerasLink class="mr-1" />
        <el-button
          type="primary"
          class="cursor-pointer"
          @click="scrollToElement('#camera-history')"
        >
          <i-mdi:history class="mr-1" />
          History
        </el-button>
      </div>
      <h2 class="text-3xl font-semibold mt-4">
        <i-ic:baseline-live-tv class="mr-1 align-text-bottom" />
        {{ startCase(streamName) }} (Live)
      </h2>
      <!-- Current cameras -->
      <div class="flex">
        <div v-if="selectedImage !== null" class="flex flex-col">
          <div>
            <a
              :href="selectedImage.source_url"
              target="_blank"
              class="text-sm text-gray-500 float-right"
            >
              Source
            </a>
          </div>
          <CameraImage
            :img="selectedImage"
            :large="true"
            class="min-w-1/2 min-h-300px"
          />
        </div>
        <!-- Thumbnails -->
        <div
          v-if="imagesList.length > 1"
          class="flex flex-wrap -m-2 ml-2 items-start content-start"
        >
          <div
            v-for="img in currentImages.values()"
            :key="img.streamName"
            class="m-2 py-1 px-3 rounded-lg cursor-pointer max-w-200px"
            :class="{ 'selected-thumbnail': img.streamName === streamName }"
            @click="streamNameQuery = img.streamName"
          >
            <div class="flex justify-between">
              <span class="text-sm">{{ img.streamNameDesc }} </span>

              <a
                :href="img.source_url"
                target="_blank"
                class="text-xs text-gray-500 float-right"
              >
                Source
              </a>
            </div>
            <CameraImage :img="img" class="w-160px min-h-60px" />
          </div>
        </div>
      </div>
      <CamerasHistory
        id="camera-history"
        :terminal-name="terminalName"
        :stream-name="streamName"
        class="mt-8"
        style="min-height: 1500px"
      >
        <AllCamerasLink>
          <el-button type="primary" class="ml-2">
            <i-healthicons:ui-menu-grid class="align-bottom mr-1 mh-2" />
            All Terminals
          </el-button>
        </AllCamerasLink>
      </CamerasHistory>
    </div>
  </div>
</template>

<route lang="yaml">
name: specific-terminal
meta:
  layout: no_sidebar
</route>

<style scoped lang="scss">
.selected-thumbnail {
  background-color: #328feb;
}
</style>
