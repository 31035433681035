<script lang="ts" setup>
import { useWindowSize } from '@vueuse/core'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  minWidth: {
    type: Number,
    default: 600,
  },
  maxWidth: {
    type: Number,
    default: 800,
  },
  proportionalWidth: {
    type: Number,
    default: 0.5,
  },
  xMargin: {
    type: Number,
    default: 50,
  },
  cancelButtonText: {
    type: String,
    default: 'Cancel',
  },
  closeOnClickModal: {
    type: Boolean,
    default: false,
  },
  appendToBody: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: '',
  },
  top: {
    type: String,
  },
})
const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
  (e: 'close'): void
}>()
const dialogOpen = useVModel(props, 'modelValue', emit)
function closeDialog() {
  dialogOpen.value = false
  emit('close')
}
const windowSize = useWindowSize()
const dialogWidth = computed(() => {
  const proportionalWidthPixels =
    windowSize.width.value * props.proportionalWidth
  if (windowSize.width.value - props.xMargin * 2 < props.minWidth) {
    return windowSize.width.value - props.xMargin * 2
  } else if (proportionalWidthPixels > props.maxWidth) {
    return props.maxWidth
  } else if (proportionalWidthPixels < props.minWidth) {
    return props.minWidth
  } else {
    return proportionalWidthPixels
  }
})
</script>

<template>
  <el-dialog
    v-model="dialogOpen"
    :width="`${dialogWidth}px`"
    :close-on-click-modal="closeOnClickModal"
    :append-to-body="appendToBody"
    :title="title"
    :top="top"
    class="px-2"
    @close="emit('close')"
  >
    <template #header>
      <slot name="header" />
    </template>

    <slot />

    <template #footer>
      <slot name="footer">
        <div class="flex dialog-footer">
          <el-button size="large" @click="closeDialog">
            {{ cancelButtonText }}
          </el-button>
          <slot name="action-button" />
        </div>
      </slot>
    </template>
  </el-dialog>
</template>

<style lang="scss">
.dialog-footer {
  @apply flex;
  button {
    @apply flex-1;
  }
}
</style>
