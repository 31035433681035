import { defineStore } from 'pinia'
import type { DateTime } from 'luxon'
import { useUserStore } from './user'
import { Appointment } from '~/models/appointments'
import type {
  GETAppointment,
  GETAppointmentStatusCount,
  TransactionDirection,
} from '~/services/apiClient'
import {
  AppointmentStatus,
  AppointmentsApi,
  TerminalName,
} from '~/services/apiClient'

export const useAppointmentStore = defineStore('appointments', {
  state: () => {
    return {
      counts: [] as GETAppointmentStatusCount[],
      loading: false,
      appointments: [] as Appointment[],
    }
  },
  actions: {
    load({
      container_numbers,
      after,
    }: {
      container_numbers: string[]
      after?: DateTime
    }) {
      const userStore = useUserStore()
      this.appointments = []
      this.loading = true
      const api = new AppointmentsApi()
      const terminal = undefined
      const ids = undefined
      const before = undefined
      const direction = undefined
      const loaded = undefined
      const statuses = undefined
      const page = 1
      const pageSize = 25
      api
        .listAppointmentsAppointmentsGet(
          terminal,
          ids,
          after ? after.toISO() : undefined,
          before,
          direction,
          loaded,
          statuses,
          page,
          pageSize,
          container_numbers.join(',')
        )
        .then((resp) => {
          const getAppointments = resp.data
          const appointments = parseAppointments(
            getAppointments,
            userStore.demo_mode
          )
          //
          appointments.sort((a, b) => {
            const windowDiff =
              b.window_start.toMillis() - a.window_start.toMillis()
            if (windowDiff === 0) {
              return (
                (b.created_at_terminal || b.last_observed_changed).toMillis() -
                (a.created_at_terminal || a.last_observed_changed).toMillis()
              )
            }
            return windowDiff
          })
          this.appointments = appointments
        })
        .finally(() => {
          this.loading = false
        })
    },
    load_with_options(options: {
      terminal?: TerminalName
      after?: DateTime
      before?: DateTime
      loaded?: boolean
      direction?: TransactionDirection
      page?: number
      pageSize?: number
      statuses?: AppointmentStatus[]
    }) {
      const userStore = useUserStore()
      this.appointments = []
      this.loading = true
      const api = new AppointmentsApi()
      api
        .listAppointmentsAppointmentsGet(
          options.terminal,
          undefined,
          options.after?.toISO(),
          options.before?.toISO(),
          options.direction,
          options.loaded,
          options.statuses,
          options.page,
          options.pageSize
        )
        .then((resp) => {
          const appointments = resp.data.map((rawAppt) => {
            return new Appointment(rawAppt, userStore.demo_mode)
          })
          appointments.sort((a, b) => {
            const windowDiff =
              b.window_start.toMillis() - a.window_start.toMillis()
            if (windowDiff === 0) {
              return (
                (b.created_at_terminal || b.last_observed_changed).toMillis() -
                (a.created_at_terminal || a.last_observed_changed).toMillis()
              )
            }
            return windowDiff
          })
          this.appointments = appointments
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
})

export function parseAppointments(
  rawAppointments: GETAppointment[],
  demo_mode: boolean
) {
  const appointments = rawAppointments
    .filter(
      // if its apm and scheduling error, ignore it
      (rawAppt) => {
        return !(
          rawAppt.terminal === TerminalName.Apm &&
          rawAppt.status === AppointmentStatus.SchedulingError
        )
      }
    )
    .map((rawAppt) => {
      const appt = new Appointment(rawAppt, demo_mode)
      return appt
    })
  return appointments
}
