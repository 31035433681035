<script lang="ts" setup>
import { useCreateCustomer } from '~/compositions/createCustomer'
import { useCustomerStore } from '~/stores/customers'
const customersStore = useCustomerStore()
const { createCustomer } = useCreateCustomer()
const mergingCustomers = ref(false)
const someCustomersSelected = computed(
  () => customersStore.numSelectedCustomers > 1
)
const mergeTooltip = computed(() => {
  if (someCustomersSelected.value) {
    return 'Merge selected customers into one'
  }
  return 'Select 2 or more customers to merge'
})
onMounted(customersStore.load)
</script>

<template>
  <div class="mb-2">
    <el-button type="primary" @click="createCustomer">
      <i-icon-park-outline:add />
      <div class="ml-1">Add Customer</div>
    </el-button>
    <!-- Tooltip won't trigger on disabled button -->
    <div v-tooltip="mergeTooltip" class="inline-block ml-4">
      <el-button
        type="warning"
        :disabled="!someCustomersSelected"
        @click="mergingCustomers = true"
      >
        <i-material-symbols:merge />
        <div class="ml-1">Merge Customers</div>
      </el-button>
    </div>
  </div>
  <CustomerSettingsTable
    v-loading="customersStore.loading"
    :customers="customersStore.customers"
  />
  <MergeCustomersDialog
    v-if="mergingCustomers"
    @close="mergingCustomers = false"
  />
</template>
