import { defineStore } from 'pinia'
import { useUserStore } from './user'
import { isInternalUser } from '~/constants'
import type { GETUser } from '~/services/apiClient'
import { UserRoleType, UsersApi } from '~/services/apiClient'
import { User } from '~/models/user'

export const useUsersStore = defineStore('users', {
  state: () => {
    return {
      users: [] as User[],
      loading: false,
    }
  },
  actions: {
    load(): Promise<void> {
      this.loading = true
      const api = new UsersApi()
      return api
        .getUsersUsersGet()
        .then((resp) => {
          this.users = []
          this._addUsers(resp.data)
        })
        .finally(() => {
          this.loading = false
        })
    },
    _addUsers(users: GETUser[]) {
      const userStore = useUserStore()
      this.users.push(
        ...users
          // Filter out users that are not active as these have been "deleted." Maybe
          // in the future we can add a way to show deleted users
          .filter((user) => user.is_active_user)
          .map((user) => new User(user, userStore.demo_mode))
      )
      this.users.sort((a, b) => {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })
    },
    inviteUsers(emails: string[], role?: UserRoleType): Promise<void> {
      const api = new UsersApi()
      return api
        .inviteUsersUsersInvitePost(
          emails.map((email) => {
            return { email, role }
          })
        )
        .then((resp) => {
          this._addUsers(resp.data)
        })
    },
  },
  getters: {
    nonInternalUsers: (state) => {
      return state.users.filter(
        (user) =>
          !isInternalUser(user.email) && user.role !== UserRoleType.SubCarrier
      )
    },
    subCarriers: (state) => {
      return state.users.filter((user) => user.role === UserRoleType.SubCarrier)
    },
  },
})
