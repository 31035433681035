<script lang="ts" setup>
import Box from '~/components/dispatch_table/Box.vue'
import DispatchTableAndControls from '~/components/dispatch_table/DispatchTableAndControls.vue'
useHead({
  title: 'Dray Dog - Dispatch',
})
</script>

<template>
  <Box class="py-2 px-4 h-full w-full">
    <DispatchTableAndControls :show-customer-column="true" />
  </Box>
</template>

<route lang="yaml">
meta:
  auth: true
name: dispatch
</route>
