<script lang="ts" setup>
const props = defineProps<{
  assignedUserIDs: number[]
  customerID: number | undefined
  tagsToAdd: string[]
}>()
const emit = defineEmits<{
  (e: 'update:assignedUserIDs', value: number[]): void
  (e: 'update:customerID', value: number | undefined): void
  (e: 'update:tagsToAdd', value: string[]): void
}>()

const assignedUserIDs = useVModel(props, 'assignedUserIDs', emit)
const customerID = useVModel(props, 'customerID', emit)
const tagsToAdd = useVModel(props, 'tagsToAdd', emit)
</script>

<template>
  <el-form class="align-middle">
    <AssignedUserSelection v-model="assignedUserIDs" />
    <CustomerSelectOrCreate v-model="customerID" />

    <div>
      <p class="font-medium text-md">Tags</p>

      <TagsSelector
        v-model="tagsToAdd"
        class="w-full"
        placeholder="Select tags to add"
      />
    </div>
  </el-form>
</template>
