<script lang="ts" setup>
import { useCancelAppointments } from '~/compositions/cancelAppointments'
import { Appointment } from '~/models/appointments'
const props = defineProps({
  appointment: {
    type: Appointment,
    required: true,
  },
  plain: {
    type: Boolean,
    default: true,
  },
})
const { cancelAppointment } = useCancelAppointments()
</script>

<template>
  <div class="inline-block" @click="cancelAppointment(props.appointment)">
    <slot>
      <el-button type="primary" :plain="plain" size="small">
        <i-mdi:calendar-remove class="mr-1" />
        Cancel
      </el-button>
    </slot>
  </div>
</template>
