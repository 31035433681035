<!-- Groups together a container row along with optional shift or hour headers for
the cases where this row is the first row in an hour or shift group -->
<script lang="ts" setup>
import { useIntersectionObserver } from '@vueuse/core'
import type { AppointmentTransactionGroup } from './types'

const props = defineProps<{ row: AppointmentTransactionGroup }>()

const emits = defineEmits<{
  (e: 'hidden', container: AppointmentTransactionGroup): void
  (e: 'visible', container: AppointmentTransactionGroup): void
}>()
const transactionGroup = toRef(props, 'row')

let isIntersecting = false
const rowRef = ref<HTMLElement>()
const scrollAreaElement = document.getElementById('containers-list-scroll-area')
if (!scrollAreaElement) {
  throw new Error('Could not find scroll area element')
}
useIntersectionObserver(
  rowRef,
  (entries) => {
    // Get the latest value of isIntersecting based on the entry time
    let latestTime = 0
    for (const entry of entries) {
      if (entry.time >= latestTime) {
        latestTime = entry.time
        isIntersecting = entry.isIntersecting
      }
    }
    if (isIntersecting) {
      emits('visible', props.row)
    } else {
      emits('hidden', props.row)
    }
  },
  {
    rootMargin: '-50px',
    threshold: 0.0,
    root: scrollAreaElement,
  }
)
onUnmounted(() => {
  if (isIntersecting) emits('hidden', props.row)
})
watch(transactionGroup, (_, oldValue) => {
  emits('hidden', oldValue)
})
</script>

<template>
  <div :style="{ height: `${transactionGroup.rowHeight}px` }">
    <ShiftGroupHeader
      v-if="transactionGroup.isFirstRowInShift"
      :shift-group="props.row.shiftGroup"
    />
    <HourGroupHeader
      v-if="transactionGroup.isFirstRowInHour"
      :hour-group="props.row.hourGroup"
    />
    <ContainerRow ref="rowRef" :container="transactionGroup.primaryContainer" />
  </div>
</template>
