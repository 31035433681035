<script lang="ts" setup>
import { RecycleScroller } from 'vue-virtual-scroller'
import { useBookingRequestsStore } from '~/stores/bookingRequests'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

const props = defineProps({ modelValue: { type: Boolean, required: true } })
const emit = defineEmits(['update:modelValue'])
const drawerOpen = toRef(props, 'modelValue')
const bookingRequestsStore = useBookingRequestsStore()
const bookingRequests = toRef(bookingRequestsStore, 'bookingRequests')
const containerSearchText = ref('')

watch(
  drawerOpen,
  (val) => {
    if (val) {
      loadActionRequests()
    }
  },
  { immediate: true }
)
watch(containerSearchText, () => {
  loadActionRequests()
})

function loadActionRequests() {
  bookingRequestsStore.load({
    containerSearchText: containerSearchText.value || undefined,
    selectedUserIDs: undefined,
  })
}

const router = useRouter()
function viewContainer(container_number: string) {
  router.push({ name: 'containers', query: { containers: container_number } })
  emit('update:modelValue', false)
}
</script>

<template>
  <div class="text-black mt-0 mb-0">
    <h2 class="text-lg font-semibold">
      <i-mdi:history class="align-bottom" width="28" height="auto" />
      Action History
      <el-button
        class="absolute top-4 right-4"
        type="text"
        @click="emit('update:modelValue', false)"
      >
        <i-mdi:close class="text-xl text-gray-600" />
      </el-button>
    </h2>
    <div class="text-sm font-normal">
      <div class="flex justify-between items-end mt-2">
        Only the last 5 days are displayed
        <el-button @click="loadActionRequests">
          <i-mdi:refresh class="mr-1" />
          Refresh
        </el-button>
      </div>
      <el-input
        v-model="containerSearchText"
        placeholder="Search by container number(s)"
        clearable
        class="mt-3"
      />
    </div>
  </div>
  <div
    v-if="bookingRequestsStore.loading"
    v-loading="true"
    class="min-h-20"
  ></div>
  <div>num actions{{ bookingRequests.length }}</div>

  <div style="height: 500px; overflow: auto">
    <RecycleScroller
      v-slot="{ item: bookingRequest }"
      :items="bookingRequests"
      item-size="100"
      :buffer="1000"
      key-field="id"
    >
      <ActionHistoryRow
        :booking-request="bookingRequest"
        style="height: 100px"
        @view-container="viewContainer"
      />
    </RecycleScroller>
  </div>

  <!-- </div> -->
</template>

<style lang="scss">
.action-history-drawer {
  .el-drawer__header {
    margin-bottom: 0px !important;
  }
}
</style>
