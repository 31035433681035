<script setup lang="ts">
import DayOption from './DayOptions.vue'
import {
  DayGroup,
  type ShiftGroup,
  makeDayGroupKey,
} from '~/models/groupedAppointments'
const props = defineProps<{
  appointmentShiftGroups: ShiftGroup[]
  firstVisibleShift: ShiftGroup | undefined
}>()

const selectedShift = defineModel<ShiftGroup | undefined>()

const dayGroups = computed(() => {
  const dayGroups: DayGroup[] = []
  let latestDateGroup: DayGroup | null = null
  // We are relying on appointmentShiftGroups being sorted
  for (const shiftGroup of props.appointmentShiftGroups) {
    const dayGroupKey = makeDayGroupKey(shiftGroup.date)
    // New day case
    if (latestDateGroup === null || latestDateGroup.key !== dayGroupKey) {
      latestDateGroup = new DayGroup(shiftGroup.date)
      dayGroups.push(latestDateGroup)
    }
    latestDateGroup.addShiftGroup(shiftGroup)
  }
  return dayGroups
})
</script>

<template>
  <div class="flex gap-2">
    <DayOption
      v-for="dayGroup in dayGroups"
      :key="dayGroup.key"
      :day-group="dayGroup"
      :selected-shift="selectedShift"
      :first-visible-shift="selectedShift ? undefined : firstVisibleShift"
      @shift-clicked="selectedShift = $event"
    />
  </div>
</template>
