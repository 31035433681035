<script lang="ts" setup>
import { ElNotification } from 'element-plus'
import {
  TERMINALS_TO_ALLOW_AUTOMATIC_PLATE_SETTING,
  TERMINALS_WHERE_LICENSE_PLATE_IS_OPTIONAL_USED_WHEN_BOOKING,
  getTerminalLabel,
} from '~/constants'
import type { OrgConfig } from '~/models/organization'
import type { UserInfo } from '~/models/userInfo'
import type { TerminalName, UserPreferences } from '~/services/apiClient'
import { OrganizationsApi, UsersApi } from '~/services/apiClient'
import { useUserStore } from '~/stores/user'

const userStore = useUserStore()
const savedUserPreferences = ref('')
const userPreferences = ref(null as UserPreferences | null)
const savedOrgPreferences = ref('')
const orgPreferences = ref(null as OrgConfig | null)

function markUserPreferencesAsSaved(userInfo: UserInfo) {
  userPreferences.value = userInfo.preferences
  savedUserPreferences.value = JSON.stringify(userPreferences.value)
}
function markOrgPreferencesAsSaved(orgInfo: OrgConfig) {
  orgPreferences.value = orgInfo
  savedOrgPreferences.value = JSON.stringify(orgPreferences.value.toAPIFormat())
}
onMounted(() => {
  userStore.loadInfo().then(async (userInfo) => {
    markUserPreferencesAsSaved(userInfo)
    markOrgPreferencesAsSaved(userInfo.current_organization.config)
  })
})

const userPreferencesModified = computed(() => {
  return JSON.stringify(userPreferences.value) !== savedUserPreferences.value
})
const orgPreferencesModified = computed(() => {
  return (
    JSON.stringify(orgPreferences.value?.toAPIFormat()) !==
    savedOrgPreferences.value
  )
})

function saveUserPreferences() {
  const api = new UsersApi()
  if (!userPreferences.value) throw new Error('This should not happen')
  api
    .updateCurrentUserPreferencesUsersCurrentPreferencesPut(
      userPreferences.value
    )
    .then(() => {
      userStore.loadInfo()
      ElNotification({
        title: 'Preferences saved',
        type: 'success',
      })
      userStore.loadInfo().then(markUserPreferencesAsSaved)
    })
    .catch(() => {
      ElNotification({
        message: 'Error saving preferences',
        type: 'error',
      })
    })
}

function saveOrganizationPreferences() {
  const api = new OrganizationsApi()
  if (!orgPreferences.value) throw new Error('This should not happen')
  api
    .updateOrganizationPreferencesOrganizationsPreferencesPut(
      orgPreferences.value.toAPIFormat()
    )
    .then(() => {
      ElNotification({
        title: 'Preferences saved',
        type: 'success',
      })
      userStore.loadInfo().then((userInfo) => {
        markOrgPreferencesAsSaved(userInfo.current_organization.config)
      })
    })
    .catch(() => {
      ElNotification({
        message: 'Error saving preferences',
        type: 'error',
      })
    })
}
function toggleTerminalLicensePlatePrefill(terminal: TerminalName) {
  orgPreferences.value?.toggleShouldPrefillLicensePlate(terminal)
}

function toggleTerminalSetPlateIfNotSet(terminal: TerminalName) {
  orgPreferences.value?.toggleShouldSetPlateIfNotSet(terminal)
}
const autoBookNotificationOption = [
  {
    minutes: 1,
    description: 'Every minute',
  },
  {
    minutes: 5,
    description: 'Every 5 minutes',
  },
  {
    minutes: 15,
    description: 'Every 15 minutes',
  },
  {
    minutes: 30,
    description: 'Every 30 minutes',
  },
  {
    minutes: 60,
    description: 'Every hour',
  },
  {
    minutes: 120,
    description: 'Every 2 hours',
  },
  {
    minutes: 180,
    description: 'Every 3 hours',
  },
  {
    minutes: 240,
    description: 'Every 4 hours',
  },
  {
    minutes: 360,
    description: 'Every 6 hours',
  },
]
</script>

<template>
  <div>
    <!-- User preferences -->
    <h2 class="header">User Preferences</h2>
    <el-form v-if="userPreferences" label-position="top">
      <el-form-item label="Auto Book notification frequency:">
        <el-select
          v-model="userPreferences.auto_book_notification_interval_minutes"
        >
          <el-option
            v-for="option in autoBookNotificationOption"
            :key="option.minutes"
            :value="option.minutes"
            :label="option.description"
          >
            {{ option.description }}
          </el-option>
        </el-select>
        <Help title="Auto Book Notification Frequency" class="help">
          <p>
            Auto Book email notifications for successful bookings are grouped
            into emails sent at this interval. Notifications for auto booked
            appointments within 12 hours are always sent immediately.
          </p>
          <p>
            It may take up to one "cycle" for modifications of this setting to
            take affect.
          </p>
        </Help>
      </el-form-item>

      <el-button
        type="success"
        :disabled="!userPreferencesModified"
        @click="saveUserPreferences"
      >
        Save
      </el-button>
    </el-form>

    <!-- Organization preferences -->
    <h2 class="header">Organization Preferences</h2>
    <el-form v-if="orgPreferences" label-position="right">
      <el-form-item>
        <template #label>
          Automatically Set License Plate if Not Set (2 hours before)
          <Help title="License plate pre-filling" class="help inline-block">
            <p>
              This is an alternative to setting a "dummy" license plate when
              bookings an appointment. At some terminals, like APM, if the dummy
              plate is not updated and conflicts with another appointment,
              issues can arise at the gate. This system is a fallback to allow
              not setting a plate when booking, knowing that if dispatch does
              not update the plate in time, Dray Dog will set a dummy plate two
              hours before the appointment to avoid cancellation.
            </p>
            <p>
              In general across all terminals, we <em>recommend</em> this system
              because it increases that chance your truck will auto in-gate.
              This is for two reasons:
            </p>
            <ul>
              <li>Dispatchers are more likely to set the correct plate</li>
              <li>
                Dray Dog will only set a plate to avoid cancellation, reducing
                the chances that multiple appointments will have the same plate.
              </li>
            </ul>
          </Help>
        </template>
      </el-form-item>
      <el-form-item
        v-for="terminal of TERMINALS_TO_ALLOW_AUTOMATIC_PLATE_SETTING"
        :key="terminal"
      >
        <template #label>
          <span class="min-w-16 ml-8">
            {{ getTerminalLabel(terminal) }}
          </span>
        </template>
        <el-switch
          :model-value="orgPreferences.shouldSetPlateIfNotSet(terminal)"
          @click="toggleTerminalSetPlateIfNotSet(terminal)"
        />
      </el-form-item>
      <el-form-item>
        <template #label>
          Pre-fill license plates at:
          <Help title="License plate pre-filling" class="help inline-block">
            <p>
              APM will cancel appointments without a license plate set a couple
              hours before the appointment time starts. If you want to make
              absolutely sure your appointments don't get cancelled, you can set
              a "dummy" license plate. This option sets a dummy license plate by
              default, although users can still "clear" it.
            </p>
            <p>
              In general across all terminals, ee do not
              <em>recommend</em> using dummy license plates because it reduces
              that chance your truck will auto in-gate. This is for two reasons:
            </p>
            <ul>
              <li>Dispatchers are more likely to set the correct plate</li>
              <li>
                At APM, the gate gets confused if there are multiple scheduled
                appointments that have the same plate
              </li>
            </ul>
          </Help>
        </template>
      </el-form-item>
      <el-form-item
        v-for="terminal of TERMINALS_WHERE_LICENSE_PLATE_IS_OPTIONAL_USED_WHEN_BOOKING"
        :key="terminal"
      >
        <template #label>
          <span class="min-w-16 ml-8">
            {{ getTerminalLabel(terminal) }}
          </span>
        </template>
        <el-switch
          :model-value="orgPreferences.shouldPrefillLicensePlate(terminal)"
          @click="toggleTerminalLicensePlatePrefill(terminal)"
        />
      </el-form-item>

      <el-button
        type="success"
        :disabled="!orgPreferencesModified"
        @click="saveOrganizationPreferences"
      >
        Save
      </el-button>
    </el-form>
  </div>
</template>

<style scoped lang="scss">
.header {
  @apply text-xl mb-2 mt-10 font-semibold;
}
.header:first-child {
  margin-top: 0;
}
.help {
  @apply ml-3;
}
ol > li {
  @apply list-decimal;
}
</style>
