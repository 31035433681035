<script setup lang="ts">
import Box from '../Box.vue'
import ShiftOption from './ShiftOption.vue'
import { type DayGroup, type ShiftGroup } from '~/models/groupedAppointments'
const props = defineProps<{
  dayGroup: DayGroup
  selectedShift: ShiftGroup | undefined
  firstVisibleShift: ShiftGroup | undefined
}>()

const emit = defineEmits({
  shiftClicked: (shiftGroup: ShiftGroup) => true,
})

const hasAppointments = computed(() => {
  return props.dayGroup.numAppointments > 0
})

const shiftsWithAppointments = computed(() => {
  return props.dayGroup.shiftGroups.filter(
    (shiftGroup) => shiftGroup.countTotal > 0
  )
})
function calcXOffset(numAppointments: number): number {
  // Basically anchors the badge on it's right rather than left
  const numDigits = numAppointments.toString().length
  return numDigits * -7
}
</script>

<template>
  <el-badge
    :value="props.dayGroup.numAppointments"
    :show-zero="false"
    type="primary"
    :offset="[calcXOffset(props.dayGroup.numAppointments), 3]"
    :max="999"
  >
    <Box
      class="px-1 pb-1 pt-2px h-full flex flex-col"
      :class="{
        'has-no-appointments': !hasAppointments,
      }"
      style="min-width: 77px"
      data-testid="day-option"
    >
      <!-- Title -->
      <div class="font-normal text-xl py-2px">
        {{ props.dayGroup.date.toFormat('ccc') }}
        <span class="font-semibold">
          {{ props.dayGroup.date.toFormat('d') }}
        </span>
      </div>
      <!-- Shifts -->
      <div v-if="shiftsWithAppointments.length" class="flex gap-1">
        <ShiftOption
          v-for="shiftGroup in shiftsWithAppointments"
          :key="shiftGroup.key"
          :shift-group="shiftGroup"
          :selected="shiftGroup.key === selectedShift?.key"
          :is-first-visible-shift="shiftGroup.key === firstVisibleShift?.key"
          class="flex-grow"
          @shift-clicked="emit('shiftClicked', $event)"
        />
      </div>
      <div v-else class="flex-grow flex items-center justify-center">
        <div class="text-gray-500 text-sm">No appts</div>
      </div>
    </Box>
  </el-badge>
</template>

<style scoped lang="scss">
.has-no-appointments {
  @apply bg-gray-100 text-gray-500 cursor-not-allowed;
  opacity: 0.8;
}
</style>
