import { DateTime } from 'luxon'
import Customer from './customers'
import { convertMinuteOfWeekToTime } from '~/components/capacity/helpers'
import type {
  GETCapacityBucket,
  GETCapacitySet,
  SavedCustomer,
} from '~/services/apiClient'

export function makeCapacitySetLabel(
  containerTag: string | null,
  customer: SavedCustomer | null,
  dedicated: boolean
): string {
  if (containerTag) {
    return containerTag
  } else if (customer) {
    return `${customer.name} ${dedicated ? 'Dedicated' : 'Receiving'}`
  } else {
    return 'Fleet'
  }
}
export interface CapacitySetIdentifiers {
  customer: Customer | null
  containerTag: string | null
}
export class CapacitySet implements CapacitySetIdentifiers {
  buckets: CapacityBucket[]
  bucketMinutes: number
  customer: SavedCustomer | null
  customerID: number | null
  containerTag: string | null
  bucketByMinuteOfWeek: Map<number, CapacityBucket>
  dedicated: boolean
  description: string
  key: string

  constructor(
    buckets: CapacityBucket[],
    customer: SavedCustomer | null,
    container_tag: string | null,
    dedicated: boolean
  ) {
    this.customerID = customer?.id ?? null
    this.customer = customer
    this.containerTag = container_tag
    this.dedicated = dedicated
    this.description = makeCapacitySetLabel(container_tag, customer, dedicated)
    this.buckets = buckets.sort(
      (a, b) => a.startMinuteOfWeek - b.startMinuteOfWeek
    )
    this.bucketMinutes =
      this.buckets[0].endMinuteOfWeek - this.buckets[0].startMinuteOfWeek
    this.bucketByMinuteOfWeek = new Map()
    for (const bucket of this.buckets) {
      this.bucketByMinuteOfWeek.set(bucket.startMinuteOfWeek, bucket)
    }
    this.key = makeCapacitySetKey(this)
  }

  get maxCapacities(): number[] {
    return this.buckets.map((bucket) => bucket.maxAppointments)
  }

  getNearestBucketMinuteOfWeek(time: DateTime) {
    const minuteOfWeek = getMinuteOfWeek(time)
    return Math.floor(minuteOfWeek / this.bucketMinutes) * this.bucketMinutes
  }

  getBucket(time: DateTime): CapacityBucket {
    const nearestBucketMinuteOfWeek = this.getNearestBucketMinuteOfWeek(time)
    const bucket = this.bucketByMinuteOfWeek.get(nearestBucketMinuteOfWeek)
    if (!bucket) {
      const minuteOfWeeks = Array.from(this.bucketByMinuteOfWeek.keys())
      const minuteOfWeek = getMinuteOfWeek(time)
      throw new Error(
        `No bucket found for time ${time.toISO()} (minute of week ${minuteOfWeek})` +
          ` (${
            this.buckets.length
          } buckets defined, last bucket minute of week is ${
            minuteOfWeeks[minuteOfWeeks.length - 1]
          })`
      )
    }
    return bucket
  }
}
function getBucketMinuteOfWeek(bucket: GETCapacityBucket): number {
  return bucket.day_of_week * 24 * 60 + bucket.hour_of_day * 60
}

export function makeCapacitySetFromAPI(
  set: GETCapacitySet,
  demoMode: boolean
): CapacitySet {
  const buckets = set.buckets
  const mappedBuckets: CapacityBucket[] = []
  if (buckets.length === 0) {
    throw new Error('No buckets defined for capacity set')
  }
  buckets.sort((a, b) => getBucketMinuteOfWeek(a) - getBucketMinuteOfWeek(b))
  const bucketMinutes =
    getBucketMinuteOfWeek(buckets[1]) - getBucketMinuteOfWeek(buckets[0])
  const isDedicatedSet = set.independent_capacity || false
  buckets.forEach((bucket) => {
    const currentBucket = new CapacityBucket(
      {
        startMinuteOfWeek: getBucketMinuteOfWeek(bucket),
        bucketMinutes,
        maxAppointments: bucket.max_appointments,
        customer: set.customer ?? null,
        containerTag: set.container_tag ?? null,
        dedicated: isDedicatedSet,
      },
      demoMode
    )
    mappedBuckets.push(currentBucket)
  })
  return new CapacitySet(
    mappedBuckets,
    set.customer ?? null,
    set.container_tag ?? null,
    isDedicatedSet
  )
}

export function makeCapacitySetKeyFromArgs(
  customerID: number | null,
  containerTag: string | null
): string {
  if (customerID) {
    return `customer-${customerID}`
  } else if (containerTag) {
    return `tag-${containerTag}`
  } else {
    return 'global'
  }
}

export function makeCapacitySetKey(set: CapacitySetIdentifiers): string {
  return makeCapacitySetKeyFromArgs(set.customer?.id ?? null, set.containerTag)
}

export type DayOfWeek =
  | 'Saturday'
  | 'Sunday'
  | 'Monday'
  | 'Tuesday'
  | 'Wednesday'
  | 'Thursday'
  | 'Friday'

export function minuteOfWeekToDayOfWeek(minuteOfWeek: number): DayOfWeek {
  const dayOfWeek = Math.floor(minuteOfWeek / 1440)
  switch (dayOfWeek) {
    case 0:
      return 'Sunday'
    case 1:
      return 'Monday'
    case 2:
      return 'Tuesday'
    case 3:
      return 'Wednesday'
    case 4:
      return 'Thursday'
    case 5:
      return 'Friday'
    case 6:
      return 'Saturday'
    default:
      throw new Error('Invalid minute of week')
  }
}
export class CapacitySetInfo implements CapacitySetIdentifiers {
  customer: Customer | null
  customerID: number | null
  containerTag: string | null
  dedicated: boolean
  description: string
  setDescription: string
  key: string

  constructor(
    {
      customer,
      containerTag,
      dedicated,
    }: {
      customer: SavedCustomer | null
      containerTag: string | null
      dedicated: boolean
    },
    demoMode: boolean
  ) {
    this.customer = customer ? new Customer(customer, demoMode) : null
    this.customerID = customer?.id ?? null
    this.containerTag = containerTag
    this.dedicated = dedicated
    this.description = makeCapacitySetLabel(containerTag, customer, dedicated)
    this.key = makeCapacitySetKey(this)
    this.setDescription = this.description
  }
}
export class CapacityBucket extends CapacitySetInfo {
  maxAppointments: number
  startMinuteOfWeek: number
  endMinuteOfWeek: number
  bucketMinutes: number
  key: string
  constructor(
    {
      startMinuteOfWeek,
      bucketMinutes,
      maxAppointments,
      customer,
      containerTag,
      dedicated,
    }: {
      startMinuteOfWeek: number
      bucketMinutes: number
      maxAppointments?: number
      customer: Customer | null
      containerTag: string | null
      dedicated: boolean
    },
    demoMode: boolean
  ) {
    super({ customer, containerTag, dedicated }, demoMode)
    this.maxAppointments = maxAppointments ?? 0
    this.startMinuteOfWeek = startMinuteOfWeek
    this.endMinuteOfWeek = startMinuteOfWeek + bucketMinutes
    this.bucketMinutes = bucketMinutes
    this.key = `${startMinuteOfWeek}` + makeCapacitySetKey(this)
  }

  get dayOfWeek() {
    let day_of_week: number = Math.floor(this.startMinuteOfWeek / (24 * 60))
    if (day_of_week === 7) {
      day_of_week = 0
    }
    return day_of_week
  }

  get dayOfWeekDescription(): DayOfWeek {
    return minuteOfWeekToDayOfWeek(this.startMinuteOfWeek)
  }

  get hourOfDay() {
    return Math.floor((this.startMinuteOfWeek % (24 * 60)) / 60)
  }

  get timeDescription(): string {
    const exampleStart = convertMinuteOfWeekToTime(
      this.startMinuteOfWeek,
      DateTime.now()
    )
    const exampleEnd = exampleStart.plus({ minutes: this.bucketMinutes })
    return (
      exampleStart.toFormat('ccc H:mm') + ' - ' + exampleEnd.toFormat('H:mm')
    )
  }

  addCapacity(change: number) {
    this.maxAppointments = Math.max(this.maxAppointments + change, 0)
  }

  getStart(timeForWeek: DateTime): DateTime {
    return convertMinuteOfWeekToTime(this.startMinuteOfWeek, timeForWeek)
  }

  getEnd(timeForWeek: DateTime): DateTime {
    return convertMinuteOfWeekToTime(this.endMinuteOfWeek, timeForWeek)
  }

  getStartFromTimeInBucket(timeWithinBucket: DateTime): DateTime {
    const minuteOfWeek = getMinuteOfWeek(timeWithinBucket)
    if (minuteOfWeek < this.startMinuteOfWeek) {
      throw new Error(
        `Absolute time is before the start of the bucket. ` +
          `Absolute time: ${timeWithinBucket.toISO()}, bucket start: ${
            this.startMinuteOfWeek
          }`
      )
    } else if (minuteOfWeek > this.endMinuteOfWeek) {
      throw new Error(
        `Absolute time is after the end of the bucket. ` +
          `Absolute time: ${timeWithinBucket.toISO()} (minuteOfWeek=${minuteOfWeek}). Bucket end: minuteOfWeek=${
            this.endMinuteOfWeek
          }`
      )
    }
    const diff = this.startMinuteOfWeek - minuteOfWeek
    return timeWithinBucket.minus({ minutes: diff })
  }

  getEndOfBucket(absoluteTime: DateTime): DateTime {
    return this.getStartFromTimeInBucket(absoluteTime).plus({
      minutes: this.bucketMinutes,
    })
  }

  getAbsoluteBucketWindow(absoluteTime: DateTime): [DateTime, DateTime] {
    return [
      this.getStartFromTimeInBucket(absoluteTime),
      this.getEndOfBucket(absoluteTime),
    ]
  }
}

export function getMinuteOfWeek(time: DateTime): number {
  let weekDay: number = time.weekday // Monday is 1, Sunday is 7
  if (weekDay === 7) {
    weekDay = 0
  }
  if (weekDay > 6) {
    throw new Error(
      `Weekday is greater than 6 (${weekDay}). This is probably a bug in luxon. ` +
        `Time: ${time.toISO()}`
    )
  }
  return (weekDay * 24 + time.hour) * 60 + time.minute
}
