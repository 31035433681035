<script setup lang="ts">
import { Crisp } from 'crisp-sdk-web'

import { useUserStore } from './stores/user'
import { setAuthorizationHeader } from './services/apiHelpers'
import { useReturnRulesLatest } from '~/stores/returnRulesLatest'
import { useNotificationsForLoggedInUser } from '~/compositions/configureNotifications'

useHead({
  title: 'Dray Dog',
})

const userStore = useUserStore()

if (userStore.authHeader) {
  // Make sure our Authorization header is set before we do ANYTHING because if we make
  // a request without it we will get a 401 which will cause us to log out the user
  setAuthorizationHeader(userStore.authHeader)
}
useNotificationsForLoggedInUser()

const userIsLoggedIn = toRef(userStore, 'loggedIn')
const CRISP_WEBSITE_ID = 'b24d4b45-754d-4848-91a1-948b441a4da6'
Crisp.configure(CRISP_WEBSITE_ID, {})
watch(
  userIsLoggedIn,
  (isLoggedIn) => {
    if (isLoggedIn) {
      useReturnRulesLatest() // Always loaded and connected
      Crisp.chat.show()
    } else {
      Crisp.chat.hide()
    }
  },
  { immediate: true }
)
</script>

<template>
  <router-view />
</template>
