<script lang="ts" setup>
import { useConnectionsStore } from '~/stores/connections'
import { useUserStore } from '~/stores/user'
import { CRITICAL_CONNECTION_TYPES } from '~/constants'
const connectionsStore = useConnectionsStore()
const userStore = useUserStore()

onMounted(() => {
  if (!userStore.isTerminalOrg) {
    connectionsStore.loadConnectionsIfNotLoaded()
  }
})
const { invalidConnections, connections } = toRefs(connectionsStore)

const neededConnections = computed(() => {
  if (userStore.isTerminalOrg) {
    return ''
  }
  if (connectionsStore.loading) {
    return ''
  }
  // any CRITICAL_CONNECTION_TYPES that are not in connections
  return CRITICAL_CONNECTION_TYPES.filter(
    (type) => !connections.value.some((c) => c.type === type)
  )
    .map((c) => c.toUpperCase().replace('_', ' '))
    .join(', ')
})
</script>

<template>
  <div
    v-if="invalidConnections"
    class="text-sm text-gray-700 py-2 px-4 rounded"
    style="background-color: rgb(246, 203, 99)"
  >
    <slot style="text-align: center">
      <el-icon><i-ic:outline-notifications-active /></el-icon> Invalid Terminal
      Credentials for: <strong>{{ invalidConnections }}. </strong>
      <router-link
        :to="{
          name: 'settings',
          query: { tab: 'credentials' },
        }"
        ><u>Update Now</u>
        <el-icon><i-material-symbols:arrow-outward /></el-icon></router-link
    ></slot>
  </div>
  <div
    v-if="neededConnections"
    class="text-sm text-gray-700 py-2 px-4 rounded mb-1"
    style="background-color: rgb(252, 165, 165)"
  >
    <slot style="text-align: center">
      <el-icon><i-ic:outline-notifications-active /></el-icon> Need credentials
      for: <strong>{{ neededConnections }}. </strong>
      <router-link
        :to="{
          name: 'settings',
          query: { tab: 'credentials' },
        }"
        ><u>Update Now</u>
        <el-icon><i-material-symbols:arrow-outward /></el-icon></router-link
    ></slot>
  </div>
</template>
