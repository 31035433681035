<script setup lang="ts">
import type { FormInstance, FormRules } from 'element-plus'
import { TERMS_AND_CONDITIONS_LINK } from '~/constants'
import { OrganizationsApi } from '~/services/apiClient'
useHead({
  title: 'Dray Dog - Company Setup',
})
const route = useRoute()
const organizationsAPI = new OrganizationsApi()
const ruleFormRef = ref<FormInstance>()
const form = reactive({
  company_name: '',
  scac: '',
  email: '',
  phone: '',
  address_line_1: '',
  address_line_2: '',
  city: '',
  state: '',
  zip_code: '',
  first_name: '',
  last_name: '',
  token: '',
  role: '',
})
const allStates = [
  'California',
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
]
const termsChecked = ref(false)
const rules = reactive<FormRules>({
  first_name: [{ required: true, message: 'First name is required' }],
  last_name: [{ required: true, message: 'Last name is required' }],
  address_line_1: [{ required: true, message: 'Address is required' }],
  address_line_2: [{ required: false }],
  city: [{ required: true, message: 'City is required' }],
  state: [{ required: true, message: 'State is required' }],
  zip_code: [{ required: true, message: 'Zip code is required' }],
  role: [{ required: true, message: 'Title is required' }],
  phone: [{ required: true, message: 'Phone number is required' }],
})
onMounted(() => {
  form.email = route.query.email as string
  form.company_name = route.query.company_name as string
  form.scac = route.query.scac as string
  form.token = route.query.token as string
})
const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return
  await formEl.validate((valid) => {
    if (valid) {
      organizationsAPI
        .acceptOrganizationInviteOrganizationsActivatePost({
          token: form.token,
          company_address_line_1: form.address_line_1,
          company_address_line_2: form.address_line_2,
          company_city: form.city,
          company_state: form.state,
          company_zip_code: form.zip_code,
          signer_first_name: form.first_name,
          signer_last_name: form.last_name,
          signer_phone: form.phone,
          signer_role: form.role,
          signer_email: form.email,
          terms_of_service_checked: termsChecked.value,
        })
        .then((resp) => {
          if (resp.status === 200) {
            const user_activation_link = resp.data.user_activation_link
            window.location.href = user_activation_link
          }
        })
    }
  })
}
</script>

<template>
  <el-card
    shadow="always"
    :body-style="{ padding: '15px' }"
    class="my-auto relative max-w-420px mx-auto"
  >
    <template #header>
      <h1 class="text-center text-xl">Company Setup and Agreement</h1>
    </template>
    <el-form ref="ruleFormRef" label-width="125px" :rules="rules" :model="form">
      <el-form-item label="Company:" prop="company">
        <el-input v-model="form.company_name" name="company" disabled />
      </el-form-item>
      <el-form-item label="SCAC:" prop="scac">
        <el-input v-model="form.scac" name="scac" disabled />
      </el-form-item>
      <el-form-item label="Address Line 1:" prop="address_line_1">
        <el-input v-model="form.address_line_1" name="address_line_1" />
      </el-form-item>
      <el-form-item label="Address Line 2:" prop="address_line_2">
        <el-input v-model="form.address_line_2" name="address_line_2" />
      </el-form-item>
      <el-form-item label="City/Town:" prop="city">
        <el-input v-model="form.city" name="city" />
      </el-form-item>
      <el-form-item label="State" prop="state">
        <el-select
          v-model="form.state"
          placeholder="Select State"
          class="w-full"
        >
          <el-option v-for="state in allStates" :key="state" :value="state" />
        </el-select>
      </el-form-item>
      <el-form-item label="Zip Code:" prop="zip_code">
        <el-input v-model="form.zip_code" name="zip_code" />
      </el-form-item>
      <el-form-item label="First Name:" prop="first_name">
        <el-input v-model="form.first_name" name="first_name" />
      </el-form-item>
      <el-form-item label="Last Name:" prop="last_name">
        <el-input v-model="form.last_name" name="last_name" />
      </el-form-item>
      <el-form-item label="Email:" prop="email">
        <el-input v-model="form.email" name="email" disabled />
      </el-form-item>
      <el-form-item label="Phone:" prop="phone">
        <el-input v-model="form.phone" name="phone" />
      </el-form-item>
      <el-form-item label="Title:" prop="role">
        <el-input v-model="form.role" name="title" />
      </el-form-item>
      <el-checkbox
        v-model="termsChecked"
        label="I have read and agree to the terms and conditions:"
      />
      <div class="w-full text-center mb-4 mt-1">
        <a
          :href="TERMS_AND_CONDITIONS_LINK"
          type="primary"
          target="_blank"
          class="w-full text-center text-xl underline text-blue-700"
        >
          Terms and Conditions
        </a>
      </div>
      <el-button
        v-if="termsChecked"
        type="primary"
        class="w-full"
        @click="submitForm(ruleFormRef)"
      >
        Sign Up
      </el-button>
      <el-button v-else type="primary" class="w-full" disabled>
        Sign Up
      </el-button>
    </el-form>
  </el-card>
</template>

<route lang="yaml">
meta:
  layout: no_sidebar
name: set_up_company
</route>

<style scoped>
/* .el-link {
  margin-right: 8px;
} */
/* .el-link .el-icon--right.el-icon {
  vertical-align: text-bottom;
} */
</style>
