<script lang="ts" setup>
import { useRouteQuery } from '@vueuse/router'
import { ElMessage, ElNotification } from 'element-plus'
import type { Ref } from 'vue'
import { UsersApi } from '~/services/apiClient'

const email = useRouteQuery('email') as Ref<string | null>
const emailSent = ref(false)
function onTrigger() {
  if (!email.value) {
    ElMessage.warning('Please provide an email address')
    return
  }
  const api = new UsersApi()
  api
    .sendResetPasswordEmailUsersSendResetPasswordEmailPost({
      email: email.value,
    })
    .then((resp) => {
      ElNotification.success(resp.data.message)
      emailSent.value = true
    })
}
</script>

<template>
  <CenteredCard>
    <template #title> Reset Password </template>
    <div v-if="emailSent" class="text-lg">
      Check your email for a reset password link
    </div>
    <div v-else>
      <el-form-item label="Email:">
        <el-input v-model="email" name="email"></el-input>
      </el-form-item>
      <el-button type="primary" class="w-full mt-2" @click="onTrigger">
        Send Reset Password Email
      </el-button>
    </div>
  </CenteredCard>
</template>

<route lang="yaml">
meta:
  layout: no_sidebar
name: trigger_reset_password
</route>
