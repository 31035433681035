<script lang="ts" setup>
import { useElementHover } from '@vueuse/core'
import { pointer } from 'd3-selection'
import { DateTime } from 'luxon'
import { useScales } from './useScales'
import { useWeekAppointmentCounts } from './useAppointmentCounts'
import { convertMinuteOfWeekToTime } from './helpers'
import type { CapacityBucket } from '~/models/capacitySets'
const props = defineProps<{
  buckets: CapacityBucket[]
  bucketMinutes: number
  customerId: number | undefined
  containerTag: string | undefined
  dedicatedCapacityCustomerIds: number[] | undefined
  dedicatedCapacityTags: string[] | undefined
}>()

const SVGHeight = ref(200)
const SVGWidth = ref(800)
const leftMargin = ref(30)
const rightMargin = ref(20)
const bottomMargin = ref(30)
const topMargin = ref(10)
const start = ref(convertMinuteOfWeekToTime(0, DateTime.now()))

const bucketMinutesRef = toRef(props, 'bucketMinutes')
const isFleetCapacity = computed(() => !props.containerTag && !props.customerId)
const dedicatedCapacityCustomerIdsToIgnore = computed(() => {
  if (props.dedicatedCapacityCustomerIds && isFleetCapacity.value) {
    return props.dedicatedCapacityCustomerIds
  }
})
const dedicatedCapacityTagsToIgnore = computed(() => {
  if (props.dedicatedCapacityTags && isFleetCapacity.value) {
    return props.dedicatedCapacityTags
  }
})
const { getAppointmentCount, maxCount: maxBookedCount } =
  useWeekAppointmentCounts({
    weekStart: start.value,
    time_bucket_minutes: bucketMinutesRef,
  })
const maxNumAppointments = computed(() =>
  Math.max(...props.buckets.map((b) => b.maxAppointments), maxBookedCount.value)
)

const apptsScaleMax = computed(() => Math.ceil(maxNumAppointments.value * 1.05))
const graphWidth = computed(
  () => SVGWidth.value - leftMargin.value - rightMargin.value
)
const graphHeight = computed(
  () => SVGHeight.value - topMargin.value - bottomMargin.value
)

const selectedViewSize = ref(168)
const end = computed(() => start.value.plus({ hours: selectedViewSize.value }))
const { getXForTime, getYForAppointments, apptsScale } = useScales({
  start,
  end,
  width: graphWidth,
  apptsScaleMax,
  height: graphHeight,
})

const bucketWidth = computed(
  () =>
    getXForTime(start.value.plus({ minutes: props.bucketMinutes })) -
    getXForTime(start.value)
)

// watch([props.buckets], () => {
//   const maxNumAppointments = Math.max(
//     ...props.buckets.map((b) => b.maxAppointments)
//   )
//   apptsScaleMax.value = Math.ceil(maxNumAppointments * 1.3)
// })

function drawCapacityLineCoordinates(): string {
  const coordinates: [number, number][] = []
  let point: [number, number]
  let lastPoint: [number, number] | undefined
  for (const bucket of props.buckets) {
    const x = getBucketStartX(bucket)
    if (lastPoint) {
      coordinates.push([x, lastPoint[1]])
    }
    point = [x, getYForAppointments(bucket.maxAppointments)]
    coordinates.push(point)
    lastPoint = point
  }
  if (lastPoint) {
    coordinates.push([getXForTime(end.value), lastPoint[1]])
  }
  return coordinates.map((c) => c.join(',')).join(' ')
}
const capacityLineCoordinates = computed(drawCapacityLineCoordinates)
const activeBucket = ref<CapacityBucket | undefined>(undefined)
const buttonsRef = ref()
const areButtonsHovered = useElementHover(buttonsRef)
function onMouseLeaveBucketStripe(event: MouseEvent) {
  setTimeout(() => {
    if (!areButtonsHovered.value) {
      activeBucket.value = undefined
    }
  }, 10)
}
function onMouseEnterBucketStripe(bucket: CapacityBucket) {
  setTimeout(() => (activeBucket.value = bucket), 10)
}
function handleClickStripe(event: MouseEvent, bucket: CapacityBucket) {
  const [_, y] = pointer(event)
  const currentCapacityPositionY =
    getYForAppointments(bucket.maxAppointments) + topMargin.value
  const aboveCapacityLine = y < currentCapacityPositionY
  if (!event.shiftKey) {
    const clickYNumAppointmentsValue = Math.round(
      apptsScale.value.invert(y - topMargin.value)
    )
    bucket.maxAppointments = clickYNumAppointmentsValue
  } else {
    if (aboveCapacityLine) {
      bucket.addCapacity(1)
    } else {
      bucket.addCapacity(-1)
    }
  }
}

const marginTransform = computed(
  () => `translate(${leftMargin.value}, ${topMargin.value})`
)
function getBucketStartX(bucket: CapacityBucket) {
  const bucketStart = bucket.getStart(end.value)
  return getXForTime(bucketStart)
}

const activeAppointmentCount = computed(() => {
  if (activeBucket.value) {
    const bucketStart = activeBucket.value.getStart(end.value)
    const count = getAppointmentCount(bucketStart)
    return count || 0
  }
  return 0
})
const tooltipTransform = computed(() => {
  if (activeBucket.value && activeBucket.value.dayOfWeek !== 0) {
    return `translate(-70 10)`
  } else if (activeBucket.value && activeBucket.value.dayOfWeek === 0) {
    return `translate(70 10)`
  } else {
    return `translate(0 0)`
  }
})
</script>

<template>
  <svg class="dispatch-chart" :viewBox="`0 0 ${SVGWidth} ${SVGHeight}`">
    <!-- Bucket Stripes -->
    <g :key="props.buckets.length">
      <rect
        v-for="(bucket, index) in props.buckets"
        :key="bucket.startMinuteOfWeek"
        :x="getBucketStartX(bucket) + leftMargin"
        :y="topMargin"
        :width="bucketWidth"
        :height="SVGHeight - bottomMargin - topMargin"
        class="bucket-stripe"
        :class="{
          even: index % 2 === 0,
          active: activeBucket === bucket,
        }"
        @mouseover.self="() => onMouseEnterBucketStripe(bucket)"
        @mouseleave.self="onMouseLeaveBucketStripe"
        @click="(event: any) => handleClickStripe(event, bucket)"
      />
    </g>
    <Axis
      :appts-scale-max="apptsScaleMax"
      :start="start"
      :end="end"
      :height="graphHeight"
      :width="graphWidth"
      :transform="marginTransform"
    />
    <g :transform="marginTransform">
      <AppointmentCountBars
        :start-time="start"
        :time-bucket-minutes="bucketMinutes"
        :get-x-for-time="getXForTime"
        :get-y-for-appointments="getYForAppointments"
        :customer-id="customerId"
        :container-tag="props.containerTag"
        :exclude-customer-ids="dedicatedCapacityCustomerIdsToIgnore"
        :exclude-container-tags="dedicatedCapacityTagsToIgnore"
      />
      <!-- Capacity -->
      <polyline
        :points="capacityLineCoordinates"
        fill="none"
        class="capacity-line stroke-width-3 stroke-red-700 stroke-join-round stroke-cap-round"
      />
      <!-- "Now" bar -->
      <line
        :x1="getXForTime(DateTime.now())"
        :x2="getXForTime(DateTime.now())"
        :y1="0"
        :y2="SVGHeight - bottomMargin - topMargin"
        color="black"
        class="stroke-current stroke-gray-400 stroke-width-1"
      />
      <text
        :x="getXForTime(DateTime.now())"
        :y="-2"
        text-anchor="middle"
        class="fill-current text-gray-500"
        style="font-size: 6px"
      >
        now
      </text>
      <!-- Tooltip -->
      <g v-if="activeBucket" :transform="tooltipTransform">
        <CapacityTooltip
          ref="buttonsRef"
          :x="getBucketStartX(activeBucket)"
          :y="0"
          :bucket-width="bucketWidth"
          :bucket="activeBucket"
          :bucket-width-minutes="props.bucketMinutes"
          :appointment-count="activeAppointmentCount"
          @change="
            (change: any) =>
              activeBucket ? activeBucket.addCapacity(change) : undefined
          "
        />
      </g>
    </g>
  </svg>
</template>

<style lang="scss" scoped>
rect.bucket-stripe {
  fill: transparent;
  cursor: pointer;
}
rect.bucket-stripe.even {
  fill: rgba(149, 149, 149, 0.067);
}
rect.bucket-stripe.active {
  fill: rgba(105, 75, 169, 0.09);
}
polyline.capacity-line {
  pointer-events: none;
}
</style>
