import { DateTime } from 'luxon'
import type { Ref } from 'vue'
import { useUserStore } from '~/stores/user'
import {
  TERMINALS_REQUIRING_LICENSE_PLATE_FOR_EMPTY_APPOINTMENT,
  TERMINALS_REQUIRING_LICENSE_PLATE as TERMINALS_REQUIRING_LICENSE_PLATE_FOR_IMPORT_APPOINTMENT,
  TERMINALS_WHERE_LICENSE_PLATE_IS_NOT_USED_WHEN_BOOKING_EMPTY_APPOINTMENTS,
  TERMINALS_WHERE_LICENSE_PLATE_IS_NOT_USED_WHEN_BOOKING as TERMINALS_WHERE_LICENSE_PLATE_IS_NOT_USED_WHEN_BOOKING_IMPORTS,
} from '~/constants'
import type { TerminalName } from '~/services/apiClient'
import type { Appointment } from '~/models/appointments'

export function useLicensePlateRules({
  terminal,
  appointmentWindowStart,
  forEmptyBooking,
  existingAppointment,
}: {
  terminal: Ref<TerminalName>
  appointmentWindowStart: Ref<DateTime | undefined>
  forEmptyBooking: boolean
  existingAppointment: Ref<Appointment | null>
}) {
  const userStore = useUserStore()
  let terminalsWhereLicensePlateIsRequired: Set<TerminalName>
  let terminalsWhereLicensePlateIsNotUsedWhenBooking: Set<TerminalName>
  if (forEmptyBooking) {
    terminalsWhereLicensePlateIsRequired =
      TERMINALS_REQUIRING_LICENSE_PLATE_FOR_EMPTY_APPOINTMENT
    terminalsWhereLicensePlateIsNotUsedWhenBooking =
      TERMINALS_WHERE_LICENSE_PLATE_IS_NOT_USED_WHEN_BOOKING_EMPTY_APPOINTMENTS
  } else {
    terminalsWhereLicensePlateIsRequired =
      TERMINALS_REQUIRING_LICENSE_PLATE_FOR_IMPORT_APPOINTMENT
    terminalsWhereLicensePlateIsNotUsedWhenBooking =
      TERMINALS_WHERE_LICENSE_PLATE_IS_NOT_USED_WHEN_BOOKING_IMPORTS
  }

  const usesLicensePlate = computed(() => {
    return !terminalsWhereLicensePlateIsNotUsedWhenBooking.has(terminal.value)
  })
  const requiresLicensePlate = computed(() => {
    if (
      appointmentWindowStart.value &&
      appointmentWindowStart.value < DateTime.now().plus({ hours: 2 })
    ) {
      // If the appointment time is in the next two hours they should set a
      // license plate
      // right away (as long as it's an option) even if it is not required
      // however, if they have an existing appointment and there is no plate set we
      // should return false as they can't set a plate on reschedule.
      // if there is no plate set and plate is required, we won't allow them to
      // reschedule
      if (existingAppointment.value) {
        return Boolean(existingAppointment.value.truck_license_plate_number)
      }
      return !terminalsWhereLicensePlateIsNotUsedWhenBooking.has(terminal.value)
    } else {
      return terminalsWhereLicensePlateIsRequired.has(terminal.value)
    }
  })
  const shouldPrefillLicensePlate = computed(() => {
    if (requiresLicensePlate.value) {
      return true
    }
    if (!usesLicensePlate) {
      return false
    }
    if (
      userStore.currentOrg?.config &&
      userStore.currentOrg.config.shouldPrefillLicensePlate(terminal.value) ===
        false
    ) {
      return false
    }
    return true
  })
  return { usesLicensePlate, requiresLicensePlate, shouldPrefillLicensePlate }
}
