import { DateTime } from 'luxon'
import { getPortalLabel } from '~/constants'
import type {
  Change,
  ContainerEventType,
  ContainerWatchState,
  HistoryEvent,
  HistoryPhaseGroup,
  PortalName,
  TerminalName,
} from '~/services/apiClient'
import { formatDTMilitaryWDateAndYear } from '~/utils'

export class ContainerChangeGroup {
  observed: DateTime
  changes: Change[]
  type: ContainerEventType
  source?: PortalName

  constructor(event: HistoryEvent) {
    this.observed = DateTime.fromISO(event.time)
    this.changes = event.changes
    this.type = event.type
    this.source = event.source
  }

  get observed_date(): string {
    return formatDTMilitaryWDateAndYear(this.observed)
  }

  get observed_and_source_desc(): string {
    let desc = 'Observed ' + this.observed_date
    if (this.source) {
      desc += ` at ${getPortalLabel(this.source)}`
    }
    return desc
  }

  get has_non_raw_changes(): boolean {
    return this.changes.some((change) => !change.from_raw_data)
  }
}

export class ContainerPhaseGroup {
  cycle_state: ContainerWatchState
  terminal: TerminalName | undefined
  entered_phase: DateTime
  left_phase?: DateTime
  changes: ContainerChangeGroup[]

  constructor(group: HistoryPhaseGroup) {
    this.cycle_state = group.cycle_state
    this.terminal = group.terminal
    this.entered_phase = DateTime.fromISO(group.entered_phase)
    this.changes = group.events.map(
      (change) => new ContainerChangeGroup(change)
    )
  }

  get durationDescription(): string {
    if (this.left_phase) {
      return this.entered_phase
        .toRelative({ base: this.left_phase })!
        .replace(' ago', '')
    } else {
      return this.entered_phase.toRelative()!.replace(' ago', '')
    }
  }
}
