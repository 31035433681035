<script lang="ts" setup>
import type { PropType } from 'vue'
import type { Container } from '~/models/containers'

const props = defineProps({
  containers: { type: Array as PropType<Container[]>, required: true },
})
const container = ref<Container>(props.containers[0])
</script>

<template>
  History for
  <el-select v-model="container" class="mb-6">
    <el-option
      v-for="option in containers"
      :key="option.number"
      :label="option.number"
      :value="option"
    />
  </el-select>
  <div class="ml-4">
    <ContainerHistory
      :key="container.number"
      :container-number="container.number"
      :include-appointment-changes="true"
      :include-import-status-changes="false"
      :include-gate-transactions="false"
      :include-watch-changes="true"
      :include-add-empty-requests="false"
      :include-edit-license-plate-requests="false"
      :show-raw-data-changes="false"
    />
  </div>
</template>
