<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    fill?: string
    background?: string
  }>(),
  {
    fill: 'white',
    background: 'bg-gray-300',
  }
)
</script>

<template>
  <svg
    class="rounded-full mr-2"
    :class="[props.background]"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.0397 19.6567C21.4312 20.0487 21.4312 20.6583 21.0397 21.0503C20.8439 21.2462 20.6046 21.3333 20.3436 21.3333C20.0825 21.3333 19.8433 21.2462 19.6475 21.0503L15.6667 17.0656L11.6859 21.0503C11.4901 21.2462 11.2508 21.3333 10.9898 21.3333C10.7287 21.3333 10.4894 21.2462 10.2937 21.0503C9.90211 20.6583 9.90211 20.0487 10.2937 19.6567L14.2745 15.6721L10.2937 11.6875C9.90211 11.2955 9.90211 10.6859 10.2937 10.2939C10.6852 9.90202 11.2943 9.90202 11.6859 10.2939L15.6667 14.2786L19.6475 10.2939C20.039 9.90202 20.6481 9.90202 21.0397 10.2939C21.4312 10.6859 21.4312 11.2955 21.0397 11.6875L17.0589 15.6721L21.0397 19.6567Z"
      :fill="props.fill"
    />
  </svg>
</template>

<style scoped></style>
