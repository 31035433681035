<script lang="ts" setup>
import type { PropType } from 'vue'
import { ElMessageBox, ElNotification } from 'element-plus'
import type { User } from '~/models/user'
import { useUserStore } from '~/stores/user'
import { useUsersStore } from '~/stores/users'
import { UsersApi } from '~/services/apiClient'
const props = defineProps({
  users: { type: Array as PropType<User[]>, required: true },
})
const userStore = useUserStore()
const usersStore = useUsersStore()
const api = new UsersApi()
const editingUser = ref(null as null | User)
function resendUserInviteLink(email: string) {
  const api = new UsersApi()
  api
    .resendUserSignupEmailUsersResendSignupEmailPost({
      email,
    })
    .then(() => {
      ElNotification.success({
        title: 'User Invite Resent',
        message: 'User was resent invite link to their email.',
      })
    })
}

function deactivateUser(userId: number) {
  ElMessageBox.confirm(
    'User will be deactivated for your organization. Continue?',
    'Warning',
    {
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
      type: 'warning',
    }
  )
    .then(() =>
      api.deactivateUserUsersDeactivateUserUserIdPatch(userId).then(() => {
        usersStore.load()
        ElNotification({
          type: 'success',
          message: 'User removed from Dray Dog',
        })
      })
    )
    .catch(() => {
      ElNotification({
        type: 'info',
        message: 'Removal canceled',
      })
    })
}
</script>

<template>
  <div>
    <el-table :data="props.users">
      <el-table-column prop="email" label="Email">
        <template #default="{ row }">
          {{ row.email }}
          <ColoredBadge
            v-if="row.pending"
            type="info"
            size="default"
            class="ml-2"
          >
            Pending
          </ColoredBadge>
        </template>
      </el-table-column>
      <el-table-column prop="first_name" label="First Name" />
      <el-table-column prop="last_name" label="Last Name" />
      <el-table-column prop="role" label="Role">
        <template #default="{ row }">
          <UserRoleDisplay :role="row.role" />
        </template>
      </el-table-column>
      <el-table-column label="Actions">
        <template #default="{ row }">
          <ElButton
            v-tooltip="'Edit User Info'"
            :disabled="
              row.pending ||
              !(
                row.email === userStore.userEmail ||
                userStore.hasManagementPermissions
              )
            "
            type="success"
            size="small"
            data-testid="edit-button"
            @click="editingUser = row"
          >
            <el-icon size="medium">
              <i-material-symbols:edit-document />
            </el-icon>
          </ElButton>
          <ElButton
            v-tooltip="'Remove User'"
            :disabled="
              !userStore.hasManagementPermissions ||
              userStore.userEmail === row.email
            "
            type="danger"
            size="small"
            class="ml-1"
            data-testid="delete-user-button"
            @click="deactivateUser(row.id)"
          >
            <el-icon size="medium">
              <i-material-symbols:delete-forever-outline />
            </el-icon>
          </ElButton>
          <ElButton
            v-if="row.pending"
            v-tooltip="'Resend Invite'"
            type="warning"
            size="small"
            class="ml-1"
            data-testid="resend-invite"
            @click="resendUserInviteLink(row.email)"
          >
            <el-icon size="medium">
              <i-mdi:email-resend-outline />
            </el-icon>
          </ElButton>
        </template>
      </el-table-column>
    </el-table>
    <EditUserDialog
      v-if="editingUser"
      :user="editingUser"
      @close="editingUser = null"
    />
  </div>
</template>
