<script setup lang="ts">
import type { HasAppointmentWindow } from './miscTypes'
import type { TerminalName } from '~/services/apiClient'
import { ImportLocation } from '~/services/apiClient'
import type { ImportStatusBase } from '~~/models/importStatuses'
import { getContainerTypeDescription, getTerminalLabel } from '~/constants'
import ContainerBadge from '~/components/container_row/ContainerBadge.vue'
import LFDDisplay from '~/components/LFDDisplay.vue'
import { useShippingLine } from '~/compositions/useShippingLines'
const props = defineProps<{
  importStatus: ImportStatusBase | undefined
  containerNumber: string
  terminal: TerminalName | undefined
  appointmentWindow: HasAppointmentWindow | null
  colspan: number
}>()

const terminalLabel = computed(() => {
  if (!props.terminal) {
    return ''
  }
  return getTerminalLabel(props.terminal)
})

const { getShippingLine } = useShippingLine()
const shippingLine = computed(() => {
  return getShippingLine(props.importStatus?.shipping_line || undefined)
})

const locationDesc = computed(() => {
  if (!props.importStatus) {
    return ''
  }
  // match the location description with the location code
  const parsedLocation = props.importStatus?.parsed_location
  if (parsedLocation === ImportLocation.InTerminal) {
    return 'Grounded'
  }
  if (parsedLocation === ImportLocation.OnVessel) {
    return 'On Vessel'
  }
  if (parsedLocation === ImportLocation.OutGated) {
    return 'Out Gated'
  }
  return parsedLocation
})
</script>

<template>
  <td :colspan="colspan">
    <template v-if="terminalLabel"> {{ terminalLabel }} • </template>
    {{ props.containerNumber }}

    <span v-if="shippingLine || props.importStatus?.container_type">
      <span> • </span>
      <span class="text-gray-400">
        <span
          v-if="shippingLine"
          v-tooltip="
            `${shippingLine.long_desc} (raw: ${props.importStatus?.raw_shipping_line})`
          "
          class="text-gray-400"
          >{{ shippingLine.short_desc }}</span
        ><span
          v-else-if="props.importStatus?.raw_shipping_line"
          v-tooltip="`This is not a shipping line recognized...yet!`"
          class="text-red-900"
          >{{ props.importStatus?.raw_shipping_line }}</span
        >/<span
          v-if="
            props.importStatus?.container_type &&
            props.importStatus?.container_type_short
          "
          v-tooltip="
            `${getContainerTypeDescription(props.importStatus?.container_type)} (raw: ${
              props.importStatus?.raw_container_type
            })`
          "
          class="text-gray-400"
          >{{ props.importStatus.container_type_short }}</span
        ><span
          v-else-if="props.importStatus?.raw_container_type"
          v-tooltip="`This is not a container type recognized...yet!`"
          class="text-red-900"
          >{{ props.importStatus?.raw_container_type }}</span
        >
      </span>
    </span>
    <span v-if="props.importStatus"> • {{ locationDesc }} </span>
    <template v-if="appointmentWindow">
      <div>
        <span class="font-semibold">
          {{ appointmentWindow.window_start.toFormat('MMM dd HH:mm') }} -
          {{
            appointmentWindow.window_end
              ? appointmentWindow.window_end.toFormat('HH:mm')
              : appointmentWindow.window_start
                  .plus({ hours: 1 })
                  .toFormat('HH:mm')
          }}
        </span>
      </div>
    </template>
    <div
      v-if="
        props.importStatus &&
        props.importStatus.parsed_location === ImportLocation.InTerminal
      "
    >
      LFD:
      <LFDDisplay :last-free-date="props.importStatus?.last_free_day" />
    </div>
    <div v-if="props.importStatus">
      <ContainerBadge
        v-for="badge of props.importStatus?.badges"
        :key="badge.message"
        :badge="badge"
      />
    </div>
  </td>
</template>
