import { DateTime } from 'luxon'
import { getTerminalLabel } from '~/constants'
import type {
  ContainerType,
  GETEmptyReturnRule,
  GETEmptyReturnRulesReading,
  ShippingLine,
  TerminalName,
} from '~/services/apiClient'
import { ReceivingRule, Shift } from '~/services/apiClient'
import { formatDTShort } from '~/utils'

export interface ReturnOption {
  shiftDate: DateTime
  shift: Shift
  terminal: TerminalName
}
export function returnOptionKey(option: ReturnOption): string {
  return `${option.terminal}-${option.shiftDate.toISODate()}-${option.shift}`
}
export interface ReturnCategory {
  shippingLine: ShippingLine
  containerType: ContainerType
  terminal: TerminalName
}

export class ReturnRulesReading {
  terminal: TerminalName
  first_observed: DateTime
  last_observed: DateTime
  last_parsed_time: DateTime | null
  screenshot_url: string
  first_observed_raw: string
  constructor(reading: GETEmptyReturnRulesReading) {
    this.terminal = reading.terminal
    this.first_observed = DateTime.fromISO(reading.first_observed)
    this.last_observed = DateTime.fromISO(reading.last_observed)
    this.last_parsed_time = reading.last_parsed_time
      ? DateTime.fromISO(reading.last_parsed_time)
      : null
    this.first_observed_raw = reading.first_observed
    this.screenshot_url = reading.screenshot_url
  }

  get last_changed_desc(): string {
    return formatDTShort(this.first_observed)
  }

  get last_changed_human_desc(): string {
    return this.first_observed.toRelative()!
  }

  get last_observed_desc(): string {
    return formatDTShort(this.last_observed)
  }

  get last_observed_human_desc(): string {
    return this.last_observed.toRelative()!
  }

  get observed_for_desc(): string {
    return this.first_observed
      .toRelative({ base: this.last_observed })!
      .replace(' ago', '')
      .replace('in ', '')
  }

  get terminal_desc(): string {
    return getTerminalLabel(this.terminal)
  }

  get screenshot_download_link(): string {
    return makeDownloadLink(
      this.screenshot_url,
      this.terminal,
      this.first_observed,
      this.last_observed
    )
  }
}
function makeDownloadLink(
  url: string,
  terminal: TerminalName,
  firstObserved: DateTime,
  lastObserved: DateTime
): string {
  const fileName = encodeURIComponent(
    `${getTerminalLabel(terminal)} Empty Return Rules ` +
      `${formatDTForFilename(firstObserved)} to ${formatDTForFilename(
        lastObserved
      )}.png`
  )
  return `${url}?download=true&filename=${fileName}`
}

function formatDTForFilename(dt: DateTime): string {
  return dt.toFormat('yyyy-MM-dd HHmm')
}

export function makeShiftKey(
  date: DateTime | null,
  shift: Shift | null
): string {
  if (date && shift) {
    return `${date.toISODate()}_${shift}_shift`
  } else if (shift) {
    return shift + '_shift'
  } else if (date) {
    return date.toISODate()
  } else {
    return 'all'
  }
}

function ruleDescription(rule: ReceivingRule): string {
  switch (rule) {
    case ReceivingRule.Open:
      return 'Open'
    case ReceivingRule.Closed:
      return 'Closed'
    case ReceivingRule.DualsOnly:
      return 'Duals'
    case ReceivingRule.Exempt:
      return 'Exempt'
  }
}

export class SpecificReturnRule {
  // Represents the rule for a specific date+shift. Normal `ReturnRule`s can have more
  // generic representations
  terminal: TerminalName
  shiftDate: DateTime
  shift: Shift
  shipping_line: ShippingLine
  container_type: ContainerType
  rule: ReturnRule | null
  constructor(
    date: DateTime,
    shift: Shift,
    terminal: TerminalName,
    shippingLine: ShippingLine,
    containerType: ContainerType,
    rule: ReturnRule | null
  ) {
    this.shiftDate = date
    this.shift = shift
    this.terminal = terminal
    this.shipping_line = shippingLine
    this.container_type = containerType
    this.rule = rule
  }
}

export class ReturnRule {
  terminal: TerminalName
  date: DateTime | null
  shift: Shift | null
  shift_key: string
  shipping_line: ShippingLine
  raw_shipping_line: string
  container_type: ContainerType
  raw_container_type: string
  rule: ReceivingRule
  rule_description: string
  notes: string[]
  first_observed: DateTime
  last_observed: DateTime
  screenshot_url: string
  exempt: boolean
  open: boolean
  closed: boolean
  duals_only: boolean
  can_dual: boolean
  categoryKey: string
  key: string

  constructor(rule: GETEmptyReturnRule) {
    if (!rule.parsed_shipping_line) {
      throw new Error('Shipping line is required')
    }
    if (!rule.parsed_container_type) {
      throw new Error('Container type is required')
    }
    this.terminal = rule.terminal
    this.rule = rule.rule
    this.rule_description = ruleDescription(rule.rule)
    this.date = rule.date ? DateTime.fromISO(rule.date) : null
    this.shift = rule.shift || null
    this.shift_key = makeShiftKey(this.date, this.shift)
    this.shipping_line = rule.parsed_shipping_line
    this.raw_shipping_line = rule.shipping_line
    this.container_type = rule.parsed_container_type
    this.raw_container_type = rule.container_type
    this.notes = rule.notes || []
    this.first_observed = DateTime.fromISO(rule.group_first_observed)
    this.last_observed = DateTime.fromISO(rule.group_last_observed)
    this.screenshot_url = rule.screenshot_url
    this.open = rule.rule === ReceivingRule.Open
    this.exempt = rule.rule === ReceivingRule.Exempt
    this.closed = rule.rule === ReceivingRule.Closed
    this.duals_only = rule.rule === ReceivingRule.DualsOnly
    this.can_dual = !this.closed
    this.categoryKey = `${this.shipping_line}-${this.terminal}-${this.container_type}`
    this.key = `${this.categoryKey}-${this.shift_key}`
  }

  get category(): ReturnCategory {
    return {
      containerType: this.container_type!,
      shippingLine: this.shipping_line!,
      terminal: this.terminal,
    }
  }

  get terminal_label(): string {
    return getTerminalLabel(this.terminal)
  }

  get screenshot_download_link(): string {
    return makeDownloadLink(
      this.screenshot_url,
      this.terminal,
      this.first_observed,
      this.last_observed
    )
  }

  get last_observed_changed(): DateTime {
    return this.first_observed
  }

  compare(other: ReturnRule): number {
    const dateCompare = compareDates(this.date, other.date)
    if (dateCompare === 0) {
      return compareShifts(this.shift, other.shift)
    } else {
      return dateCompare
    }
  }
}

export interface CachedTerminalReturnRulesInterface {
  // This is a separate interface because it's stored in localStorage and as such
  // should not be changed in a backwards incompatible way, at least without a
  // transition period where values in the old format get mostly cleared out from
  // clients
  terminal: TerminalName
  reading_first_observed: string
  reading_last_parsed: string
  reading_last_observed: string
  cache_valid: boolean
  rules: Array<GETEmptyReturnRule>
}

function compareDates(a: DateTime | null, b: DateTime | null): number {
  if (!a || !b) {
    return 0
  }
  if (a > b) {
    return 1
  } else if (a < b) {
    return -1
  } else {
    return 0
  }
}

function shiftToNumber(shift: Shift): number {
  switch (shift) {
    case Shift.First:
      return 1
    case Shift.Second:
      return 2
    case Shift.Third:
      return 3
  }
}

function compareShifts(a: Shift | null, b: Shift | null): number {
  if (!a || !b) {
    return 0
  }
  return shiftToNumber(a) - shiftToNumber(b)
}
