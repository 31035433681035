<script lang="ts" setup>
const dialogOpen = ref(false)
</script>

<template>
  <el-button
    v-bind="$attrs"
    type="primary"
    class="fs-open-upload-containers-via-spreadsheet adjust-button-height"
    @click="dialogOpen = true"
  >
    <i-mdi:upload class="align-text-bottom mr-1" />
    Add Containers
  </el-button>
  <AddContainersDialog v-if="dialogOpen" v-model="dialogOpen" />
</template>

<style scoped lang="scss">
.method-header {
  @apply text-center my-3 text-lg font-semibold;
}

.adjust-button-height {
  height: 40px;
}
</style>
