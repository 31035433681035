<script setup lang="ts">
const numDots = ref(0)
let interval: number

const updateDots = () => {
  numDots.value = (numDots.value + 1) % 4
}

onMounted(() => {
  interval = setInterval(updateDots, 300) as unknown as number
})

onUnmounted(() => {
  if (interval !== null) {
    clearInterval(interval)
  }
})
</script>

<template>{{ '.'.repeat(numDots) }}</template>
