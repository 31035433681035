<script lang="ts" setup>
import { computed, ref } from 'vue'
import type { UploadInstance, UploadUserFile } from 'element-plus'
import { ElMessage, ElNotification } from 'element-plus'
import type { Truck } from '~/models/trucks'
import { useTrucksStore } from '~/stores/trucks'
import { useUserStore } from '~/stores/user'

const props = defineProps<{
  modelValue: boolean
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
  (e: 'close'): void
}>()

const dialogOpen = useVModel(props, 'modelValue', emit)
const trucksStore = useTrucksStore()
const userStore = useUserStore()

const fileList = ref<UploadUserFile[]>([])
const fileUploadRef = ref<UploadInstance>()

const readyForUpload = computed(() => fileList.value.length > 0)

function onError(error: Error) {
  ElNotification.error('Trucks upload failed: ' + error.toString())
}

function onExceed() {
  ElMessage.error(
    'You can only upload one file at a time. Remove your existing file to add a new one'
  )
}

function closeDialog() {
  dialogOpen.value = false
}

function onSuccess(response: Truck[]) {
  ElNotification.success({
    title: `Uploaded ${response.length} trucks`,
  })
  closeDialog()
}

function upload() {
  if (!readyForUpload.value) {
    ElMessage.error('Please select a file to upload')
    return
  }

  fileUploadRef.value!.submit()
}
</script>

<template>
  <BaseDialog
    v-if="dialogOpen"
    v-model="dialogOpen"
    class="px-2"
    @close="emit('close')"
  >
    <template #header>
      <h1 class="text-xl font-bold">Upload Trucks via Spreadsheet</h1>
    </template>

    <template #default>
      <ElUpload
        ref="fileUploadRef"
        v-model:file-list="fileList"
        accept=".xlsx,.csv"
        action="https://api.draydog.com/trucks/upload/"
        drag
        :multiple="false"
        :limit="1"
        :on-error="onError"
        :on-success="onSuccess"
        :on-exceed="onExceed"
        :headers="{ Authorization: userStore.authHeader }"
        :auto-upload="false"
      >
        <el-icon class="el-icon--upload"><i-mdi:upload /></el-icon>
        <div class="el-upload__text">
          Drop .xlsx or .csv file here or <em>click to select a file</em>
          <br />
          <br />
          Spreadsheet must have columns with headers
          <strong>License Plate</strong> and <strong>Truck Name</strong>.
          License plates not already registered will create new trucks, those
          that exist will have truck names updated.
          <br />
          This means
          <span class="italic">license plates will never be duplicated</span>.
        </div>
      </ElUpload>
    </template>

    <template #action-button>
      <el-button
        v-tooltip="{
          content: 'Please select a file to upload',
          disabled: readyForUpload,
        }"
        :loading="trucksStore.loading"
        type="primary"
        size="large"
        :disabled="!readyForUpload"
        class="flex-1 fs-upload-trucks"
        @click="upload"
      >
        Upload file
      </el-button>
    </template>
  </BaseDialog>
</template>

<style scoped lang="scss">
li {
  @apply break-normal text-xs mt-2 ml-2;
}
</style>
