<script lang="ts" setup>
import type { FormRules } from 'element-plus'
import { ElNotification } from 'element-plus'
import type { FormInstance } from 'element-plus/lib/components/form'
import Customer from '~/models/customers'
import type { SavedCustomer } from '~/services/apiClient'
import { useCustomerStore } from '~/stores/customers'

const props = defineProps({
  customer: { type: Customer, required: true },
})
const emit = defineEmits<{
  (e: 'close'): void
}>()
const customersStore = useCustomerStore()
const ruleFormRef = ref<FormInstance>()
const loading = ref(false)
const form = reactive({
  name: props.customer.name,
})
const rules = reactive<FormRules>({
  name: [{ required: true, message: 'Customer name is required' }],
})
function closeDialog() {
  emit('close')
}
function handleClose() {
  closeDialog()
}
function submitForm() {
  ruleFormRef.value?.validate((valid) => {
    if (valid) {
      loading.value = true
      const customer: SavedCustomer = {
        name: form.name as string,
        id: props.customer.id,
      }

      customersStore
        .update(customer)
        .then(() => {
          ElNotification.success('Customer Updated')
          closeDialog()
        })
        .finally(() => {
          loading.value = false
        })
    }
  })
}
</script>

<template>
  <el-dialog
    :model-value="true"
    title="Edit Customer"
    :before-close="handleClose"
    width="20%"
  >
    <el-form ref="ruleFormRef" label-width="auto" :model="form" :rules="rules">
      <el-form-item label="Customer Name:" prop="name">
        <el-input
          v-model="form.name"
          name="name"
          placeholder="Enter Name"
          class="float-left"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeDialog">Cancel</el-button>
        <el-button type="success" :loading="loading" @click="submitForm">
          Save
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<style scoped>
.el-button--text {
  margin-right: 15px;
}
.el-select {
  width: 300px;
  margin-left: auto;
}
.el-input {
  width: 300px;
  margin-left: auto;
}
.dialog-footer button:first-child {
  margin-right: 10px;
}
</style>
