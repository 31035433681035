import type { AxiosError, AxiosResponse } from 'axios'
import { DateTime } from 'luxon'
import { AppointmentSlot } from '~/models/appointmentSlot'
import type {
  GETBaseAppointmentSlot,
  GETContainerAvailability,
} from '~/services/apiClient'
import { AppointmentBookingApi, TerminalName } from '~/services/apiClient'
import { useUserStore } from '~/stores/user'

export const useGlobalSlotsDisplayConfig = createGlobalState(() => {
  const datesToShow = ref<DateTime[]>([])
  updateDatesToShow(datesToShow)
  return {
    datesToShow,
  }
})

export function useLoadSlots(
  containerNumber: string,
  terminal: TerminalName,
  terminalBlockAvailabilityKey: string | null
) {
  const userStore = useUserStore()
  const loading = ref(false)
  const abortController = ref<AbortController | null>(null)
  const slotsObserved = ref<DateTime | null>(null)
  const failed = ref(false)
  const { datesToShow } = useGlobalSlotsDisplayConfig()
  updateDatesToShow(datesToShow)
  const slots = ref<AppointmentSlot[]>([])
  const availabilityFound = ref(false)

  function cancelExistingRequests(): AbortController {
    if (abortController.value) {
      abortController.value.abort()
    }
    const newController = new AbortController()
    abortController.value = newController
    return newController
  }
  function load(): Promise<any> {
    loading.value = true
    failed.value = false
    slots.value = []
    const newAbortController = cancelExistingRequests()
    let promise: LoadDataReturnType
    if (userStore.testMode) {
      promise = loadMockData(containerNumber)
    } else {
      promise = loadLiveData(
        containerNumber,
        terminal,
        terminalBlockAvailabilityKey,
        newAbortController
      )
    }
    promise
      .then((resp) => {
        const availability = resp.data
        slotsObserved.value = DateTime.fromISO(availability.observed)
        slots.value = resp.data.slots.map(
          (slot) => new AppointmentSlot(availability, slot)
        )
        availabilityFound.value = true
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 404) {
          availabilityFound.value = false
        } else {
          if (abortController.value === newAbortController) {
            // This failure doesn't matter if we have a newer abortController
            // from a newer request
            failed.value = true
          }
          if (err.response?.status === 500) {
            // this failure means some underlying portal error occurred
            // we don't want to display a bunch of crap error messages to the user
            failed.value = true
            // If there is JSON with a "message" field, resolve promise with message
            // value
            if (err.response.data && typeof err.response.data === 'object') {
              const responseData = err.response.data as Record<string, unknown>
              if (
                'message' in responseData &&
                typeof responseData.message === 'string'
              ) {
                return Promise.resolve(responseData.message)
              }
            }
          } else {
            // TODO? Maybe just use common handling
            // handleAPIError(err)
          }
        }
      })
      .finally(() => {
        if (abortController.value === newAbortController) {
          abortController.value = null
          // If we have a newer abortController, this would be set to false by the
          // request that created that abortController
          loading.value = false
        }
      })
    return promise
  }
  return { load, datesToShow, slots, loading, cancelExistingRequests }
}

type LoadDataReturnType = Promise<AxiosResponse<GETContainerAvailability, any>>

function loadLiveData(
  containerNumber: string,
  terminal: TerminalName,
  terminalBlockAvailabilityKey: string | null,
  abortController: AbortController
): LoadDataReturnType {
  const api = new AppointmentBookingApi()
  const forceRefresh = false
  return api.getAvailableSlotsForContainerAppointmentsAvailabilityContainerNumberGet(
    containerNumber,
    forceRefresh,
    terminal,
    terminalBlockAvailabilityKey || undefined,
    {
      signal: abortController.signal,
    }
  )
}

function randomInt(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}
function loadMockData(containerNumber: string): LoadDataReturnType {
  const slots: GETBaseAppointmentSlot[] = []
  const now = DateTime.now()
  for (let i = 0; i < 10; i++) {
    const windowStart = now.plus({ hours: randomInt(0, 5 * 24) })
    slots.push({
      window_start: windowStart.toISO(),
      window_end: windowStart.plus({ hours: 1 }).toISO(),
      num_appointments_available: randomInt(0, 30),
    })
  }
  const availability: GETContainerAvailability = {
    container_number: containerNumber,
    terminal: TerminalName.Apm,
    has_availability: true,
    slots,
    observed: new Date().toISOString(),
  }

  return new Promise((resolve) => {
    // Random delay between 400 and 3000 ms
    const delay = Math.floor(Math.random() * (3000 - 400 + 1)) + 400
    setTimeout(() => {
      resolve({
        data: availability,
        status: 200,
        statusText: 'OK',
        headers: {},
        config: {},
      } as AxiosResponse<GETContainerAvailability>)
    }, delay)
  })
}

export function updateDatesToShow(datesToShow: Ref<DateTime[]>) {
  const today = DateTime.now().startOf('day')
  if (
    // If we have not dates...
    datesToShow.value.length === 0 ||
    // ...or we have dates in the past...
    datesToShow.value[datesToShow.value.length - 1] < today
  ) {
    // TODO: Make number of days dependent on screen size?
    // ...we show 5 days from today
    datesToShow.value = Array.from({ length: 5 }, (_, i) =>
      today.plus({ days: i })
    )
  }
}
