<script lang="ts" setup>
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'
import { debounce } from 'lodash-es'
import UserSelector from '../inputs/UserSelector.vue'
import ActionHistoryRow from './ActionHistoryRow.vue'
import { useBookingRequestsStore } from '~/stores/bookingRequests'
import type BookingRequest from '~/models/bookingRequest'

const props = defineProps({ modelValue: { type: Boolean, required: true } })
const emit = defineEmits(['update:modelValue'])
const drawerOpen = toRef(props, 'modelValue')
const bookingRequestsStore = useBookingRequestsStore()
const bookingRequests = toRef(bookingRequestsStore, 'bookingRequests')
const containerSearchText = ref('')
const selectedUserIDs = ref<number[]>([])

watch(
  drawerOpen,
  (val) => {
    if (val) {
      loadActionRequests()
    }
  },
  { immediate: true }
)

const debouncedLoadActionRequests = debounce(() => {
  loadActionRequests()
}, 300)

watch([containerSearchText, selectedUserIDs], () => {
  debouncedLoadActionRequests()
})

function loadActionRequests() {
  bookingRequestsStore.load({
    containerSearchText: containerSearchText.value || undefined,
    selectedUserIDs:
      selectedUserIDs.value.length > 0 ? selectedUserIDs.value : undefined,
  })
}

function close() {
  emit('update:modelValue', false)
}

const router = useRouter()
function viewContainer(container_number: string) {
  router.push({ name: 'containers', query: { containers: container_number } })
  emit('update:modelValue', false)
}
</script>

<template>
  <el-drawer
    :model-value="props.modelValue"
    direction="ltr"
    class="action-history-drawer"
    :show-close="false"
    size="550px"
    @update:model-value="(val: boolean) => emit('update:modelValue', val)"
  >
    <template #header>
      <div class="text-black mt-0 mb-3">
        <h2 class="text-lg font-semibold">
          <i-mdi:history class="align-bottom" width="28" height="auto" />
          Action History
          <el-button class="absolute top-4 right-4" @click="close">
            <i-mdi:close class="text-xl text-gray-600" />
          </el-button>
        </h2>
        <div class="text-sm font-normal">
          <div class="flex justify-between items-end mt-2">
            Only the last 5 days are displayed
            <el-button @click="loadActionRequests">
              <i-mdi:refresh class="mr-1" />
              Refresh
            </el-button>
          </div>
          <div class="flex space-x-3 mt-3">
            <el-input
              v-model="containerSearchText"
              placeholder="Search by container number(s)"
              clearable
              class="flex-1"
            />
            <UserSelector
              v-model="selectedUserIDs"
              placeholder="Filter by booked by"
              class="flex-1"
            />
          </div>
        </div>
      </div>
    </template>
    <div
      v-if="bookingRequestsStore.loading"
      v-loading="true"
      class="min-h-20"
    ></div>
    <div class="h-full overflow-auto -ml-2">
      <DynamicScroller
        :items="bookingRequests"
        :min-item-size="54"
        key-field="id"
        :page-mode="true"
      >
        <template
          #default="{
            item: bookingRequest,
            index,
            active,
          }: {
            item: BookingRequest
            index: number
            active: boolean
          }"
        >
          <DynamicScrollerItem
            :item="bookingRequest"
            :active="active"
            :size-dependencies="[bookingRequest.error_message]"
            :data-index="index"
          >
            <div class="py-3px mx-2">
              <ActionHistoryRow
                :booking-request="bookingRequest"
                @view-container="viewContainer"
              />
            </div>
          </DynamicScrollerItem>
        </template>
      </DynamicScroller>
    </div>
  </el-drawer>
</template>

<style lang="scss">
.action-history-drawer {
  .el-drawer__header {
    margin-bottom: 0px !important;
  }
  .el-drawer__body {
    padding-top: 5px !important;
    padding-right: 0px !important;
  }
}
</style>
