<script setup lang="ts">
import { ElTag } from 'element-plus'
import type { AppointmentWithContainerInfo } from '~/models/appointmentWithContainerInfo'

const props = defineProps<{
  appointmentWithInfo: AppointmentWithContainerInfo | undefined
}>()

const daysToLFD = computed(() => {
  if (!props.appointmentWithInfo) return undefined
  const lfd =
    props.appointmentWithInfo.watch.import_last_free_date?.startOf('day')
  if (!lfd) return undefined
  const appointmentShiftDate =
    props.appointmentWithInfo.appointment.shiftDate.startOf('day')
  return lfd.diff(appointmentShiftDate, 'days').days
})
const lfdDescription = computed(() => {
  if (props.appointmentWithInfo?.watch.import_last_free_date) {
    return props.appointmentWithInfo?.watch.import_last_free_date.toFormat(
      'M/d'
    )
  }
})
const tagType = computed(() => {
  if (daysToLFD.value === undefined) return 'info'
  if (daysToLFD.value > 0) return 'success'
  if (daysToLFD.value === 0) return 'warning'
  return 'danger'
})
const tooltip = computed(() => {
  if (daysToLFD.value === undefined) return undefined
  let dayNoun = 'days'
  const absDaysToLFD = Math.abs(daysToLFD.value)
  if (absDaysToLFD === 1) dayNoun = 'day'
  if (daysToLFD.value > 0)
    return `Appointment is ${absDaysToLFD} ${dayNoun} before LFD`
  if (daysToLFD.value === 0) return 'Appointment is on LFD'
  return `Appointment is ${absDaysToLFD} ${dayNoun} after LFD`
})
</script>

<template>
  <span v-if="lfdDescription" v-tooltip="tooltip">
    {{ lfdDescription }}
    <ElTag
      :type="tagType"
      size="small"
      class="ml-1 -mt-2px"
      round
      :disable-transitions="true"
    >
      {{ daysToLFD }}
    </ElTag>
  </span>
</template>
