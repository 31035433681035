<script lang="ts" setup>
import { makeTimeLabel } from './helpers'
import { useContainerFilters } from '~/compositions/containerFilters'

const {
  autoBookOnFilter,
  autoBookOffFilter,
  shippingLineFilter,
  containerTypeFilter,
  tagsFilter,
  vesselsFilter,
  updatedInLastMinutesFilter,
  setAutoBookOnFilter,
  setAutoBookOffFilter,
} = useContainerFilters()

const updatedInLastMinutesRef = ref<string | undefined>(undefined)
const minuteOptions: number[] = [15, 30, 60, 120, 240, 480, 720, 1440]
const updatedInLastOptions = minuteOptions.map((minutes) => ({
  value: minutes,
  label: makeTimeLabel(minutes),
  key: minutes.toString(),
}))
watch(updatedInLastMinutesRef, (value) => {
  if (value === undefined) {
    updatedInLastMinutesFilter.value = undefined
    return
  }
  const option = updatedInLastOptions.find((option) => option.label === value)
  updatedInLastMinutesFilter.value = option?.value
})
</script>

<template>
  <div class="other-filters-popover">
    <h3 class="font-semibold mb-4 text-base">
      <i-mdi:filter class="align-text-top" /> Other Filters
      <hr class="mt-2" />
    </h3>
    <el-form
      label-position="top"
      style="display: grid; grid-template-columns: 1fr 1fr; gap: 10px"
    >
      <el-form-item style="margin-bottom: 5px">
        <template #label>
          <i-icon-park-outline:robot-one
            class="align-text-bottom mb-1px mr-1"
          />Auto Book is:
        </template>
        <div class="flex w-full justify-between">
          <el-checkbox
            :model-value="autoBookOnFilter"
            label="ON"
            border
            class="flex-1"
            size="large"
            style="margin-right: 10px"
            @change="setAutoBookOnFilter($event as boolean)"
          >
            On
          </el-checkbox>
          <el-checkbox
            :model-value="autoBookOffFilter"
            label="OFF"
            border
            class="flex-1"
            size="large"
            @change="setAutoBookOffFilter($event as boolean)"
          />
        </div>
      </el-form-item>
      <el-form-item label=":" style="margin-bottom: 5px">
        <template #label>
          <i-icon-park-outline:timer
            class="align-text-bottom mb-1px mr-1"
          />Updated in last:
        </template>
        <el-select
          v-model="updatedInLastMinutesRef"
          :clearable="true"
          placeholder="ex: 15 minutes"
          class="dropdown-filter"
          size="large"
          border
        >
          <el-option
            v-for="option in updatedInLastOptions"
            :key="option.key"
            :value="option.label"
            :label="option.label"
          />
        </el-select>
      </el-form-item>
      <el-form-item style="margin-bottom: 5px">
        <template #label>
          <i-mdi:flag class="align-bottom mb-2px mr-1" />Shipping Line:
        </template>
        <ShippingLineSelector v-model="shippingLineFilter" :clearable="true" />
      </el-form-item>
      <el-form-item label="Container type:" style="margin-bottom: 5px">
        <template #label>
          <EmptyContainerIcon class="align-text-top" />
          Container type:
        </template>
        <ContainerTypeSelector
          v-model="containerTypeFilter"
          :clearable="true"
        />
      </el-form-item>
      <el-form-item label="Tags:" style="margin-bottom: 5px">
        <template #label>
          <i-mdi:tag class="align-middle" height="20" />
          Tags:
        </template>
        <TagsSelector v-model="tagsFilter" :clearable="true" />
      </el-form-item>
      <el-form-item label="Vessel:" style="margin-bottom: 5px">
        <template #label>
          <VesselIcon class="align-text-bottom mr-1" />
          Vessels:
        </template>
        <VesselSelector v-model="vesselsFilter" :clearable="true" />
      </el-form-item>
    </el-form>
  </div>
</template>

<style>
.other-filters-popover .el-select {
  width: 100% !important;
}
</style>
