<script lang="ts" setup>
import type { Container } from '~/models/containers'
const props = defineProps<{
  container: Container
}>()
</script>

<template>
  <div class="text-xs">
    <span class="text-gray-700">
      <AssignedCustomer :container="props.container" /></span
    ><span class="text-gray-500"
      >•<AssignedUsers :container="container" />
    </span>
  </div>
</template>
