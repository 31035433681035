import { DateTime } from 'luxon'
import { faker } from '@faker-js/faker/locale/en'
import type {
  GETBookingRequestOld as GETBookingRequest,
  SimpleUser,
  TerminalName,
} from '~/services/apiClient'
import {
  ActionStatus as APIActionStatus,
  OldBookingStatus,
} from '~/services/apiClient'

export enum ActionStatus {
  InProgress = 'in_progress',
  Completed = 'completed',
  Failed = 'failed',
  Rejected = 'rejected',
}

export function statusFromBookingRequestStatus(
  status: OldBookingStatus | APIActionStatus
): ActionStatus {
  // This is a compatibility layer between old statuses and new, consolidated statuses
  switch (status) {
    case OldBookingStatus.InProgress:
      return ActionStatus.InProgress
    case OldBookingStatus.Booked:
      return ActionStatus.Completed
    case OldBookingStatus.Rejected:
      return ActionStatus.Rejected
    case OldBookingStatus.Failed:
      return ActionStatus.Failed
    case APIActionStatus.Completed:
      return ActionStatus.Completed
    case APIActionStatus.Failed:
      return ActionStatus.Failed
    case APIActionStatus.InProgress:
      return ActionStatus.InProgress
    case APIActionStatus.Rejected:
      return ActionStatus.Rejected
  }
}

export class ActionRequest {
  id: number
  created: DateTime
  window_start: DateTime
  status: OldBookingStatus
  terminal: TerminalName
  container_number: string
  error_message?: string
  requested_by?: SimpleUser
  entered_in_tms: boolean
  entered_in_tms_by?: SimpleUser
  entered_in_tms_time?: DateTime
  auto_booked: boolean

  constructor(raw: GETBookingRequest, demoMode: boolean) {
    this.id = raw.id
    this.created = DateTime.fromISO(raw.created)
    this.terminal = raw.terminal
    this.window_start = DateTime.fromISO(raw.window_start)
    this.status = raw.status
    this.container_number = raw.container_number
    this.error_message = raw.error_message
    if (demoMode) {
      this.requested_by = {
        id: 1,
        first_name: faker.person.firstName(),
        last_name: faker.person.lastName(),
      }
    } else {
      this.requested_by = raw.requested_by
    }
    this.entered_in_tms_time = raw.entered_in_tms_time
      ? DateTime.fromISO(raw.entered_in_tms_time)
      : undefined
    this.entered_in_tms = raw.entered_in_tms_time !== null
    this.entered_in_tms_by = raw.entered_in_tms_by
    this.auto_booked = Boolean(raw.auto_book_request_id)
  }
}
