<script lang="ts" setup>
import type { FormRules } from 'element-plus'
import Help from '../display/Help.vue'
import { type GETConnection, type SFTPConfig } from '~/services/apiClient'

const props = defineProps<{
  connection: GETConnection
  tmsName: string
  documentationLink: string | undefined
}>()
const conn = toReactive(props.connection)
const showPassword = ref(false)
const config = reactive(conn.config as SFTPConfig)
const formRules = reactive<FormRules<typeof config>>({
  adjust_over_midnight_to_previous_day_before_hour: [
    {
      validator: (rule: any, value: any, callback: any) => {
        if (!value) {
          return callback(new Error('Required'))
        }
        if (value % 1 !== 0) {
          return callback(new Error('Please input digits (whole numbers) only'))
        }
        if (value > 7) {
          return callback(new Error('Value must be 7 or less'))
        }
        if (value <= 0) {
          return callback(new Error('Value must be greater than zero'))
        }
        return callback()
      },
      trigger: 'change',
    },
  ],
})
</script>

<template>
  <IntegrationConnection
    :connection="props.connection"
    :tms-name="props.tmsName"
    :documentation-link="props.documentationLink"
    :form-rules="formRules"
  >
    <el-form-item label="Host:">
      <el-input :model-value="config.host" :readonly="true" />
    </el-form-item>
    <el-form-item label="Port:">
      <el-input :model-value="config.port" :readonly="true" />
    </el-form-item>
    <el-form-item label="User name:">
      <el-input :model-value="config.user" :readonly="true" />
    </el-form-item>
    <el-form-item label="Password:" class="flex">
      <el-input
        :model-value="config.password"
        :type="showPassword ? 'text' : 'password'"
        :readonly="true"
        class="flex-1"
      />
      <el-button class="ml-2" @click="showPassword = !showPassword">
        <template v-if="showPassword">
          <i-mdi:eye-off />
        </template>
        <template v-else> <i-mdi:eye /> </template>
      </el-button>
    </el-form-item>

    <el-form-item label="Auto enter appointments:">
      <el-checkbox v-model="config.auto_enter_appointments_in_tms" />
      <Help title="Auto Enter Appointments" class="ml-2"
        >When you book or reschedule on Dray Dog, we automatically enter the
        appointment time into {{ tmsName }}. However, you can turn this on and
        off.
        <a v-if="documentationLink" :href="documentationLink" target="_blank">
          See our help doc on this.
        </a>
      </Help>
    </el-form-item>
    <el-form-item label="Auto enter empty appointments:">
      <el-checkbox v-model="config.auto_enter_empty_appointments_in_tms" />
      <Help title="Auto Enter Appointments" class="ml-2"
        >When you book an empty appointment on Dray Dog, we automatically enter
        the appointment time into {{ tmsName }}. However, you can turn this on
        and off.
        <a v-if="documentationLink" :href="documentationLink" target="_blank">
          See our help doc on this.
        </a>
      </Help>
    </el-form-item>
    <el-form-item label="Send Pickup Number:">
      <el-checkbox v-model="config.send_pickup_number" />
      <Help title="Send Pickup Number" class="ml-2"
        >This option allows you to send the pickup number, the number required
        to pick up the container, instead of the standard appointment number.
        <a v-if="documentationLink" :href="documentationLink" target="_blank">
          See our help doc on this.
        </a>
      </Help>
    </el-form-item>
    <el-form-item
      label="Shift early morning appointment starts to previous day:"
    >
      <el-checkbox v-model="config.adjust_over_midnight_to_previous_day" />
      <Help
        title="Shift early morning appointment starts to previous day"
        class="ml-2"
        >To show after midnight appointments on the previous day in
        {{ tmsName }}, this setting will show the start time as 23:59 of the
        previous day while leaving the end time the same. This will help show
        them on the day screen in {{ tmsName }}.
        <a v-if="documentationLink" :href="documentationLink" target="_blank"
          >See our help doc on this.</a
        >
      </Help>
    </el-form-item>
    <el-form-item
      v-show="config.adjust_over_midnight_to_previous_day"
      label="Shift at or before this hour:"
      prop="adjust_over_midnight_to_previous_day_before_hour"
    >
      <el-input
        v-model="config.adjust_over_midnight_to_previous_day_before_hour"
        type="number"
        :min="0"
        :max="7"
        style="width: 100px"
      />
      <Help title="Shift early morning appointment starts hour" class="ml-2">
        <p>
          Specify what early morning appointment start times should be shifted
          to the previous day. We will shift all appointments with a window
          start at or before this hour of the day.
        </p>
        <p>
          For example, if you have this set to 3 (the default), appointments
          starting between midnight to 3AM (including 3AM) will have their start
          time set to 23:59 on the previous day
        </p>
      </Help>
    </el-form-item>
    <TmsTerminalCodeMapping
      v-if="tmsName === 'Trinium'"
      :model-value="config.tms_terminal_mapping"
      :tms-name="tmsName"
      :documentation-link="documentationLink"
    />
  </IntegrationConnection>
</template>

<style scoped lang="scss">
.el-input {
  max-width: 400px !important;
}
</style>
