<script lang="ts" setup>
import type { Selection } from 'd3-selection'
import { select } from 'd3-selection'
import { axisBottom, axisLeft } from 'd3-axis'
import * as d3Time from 'd3-time'
import type { DateTime } from 'luxon'
import type { PropType } from 'vue'
import { useScales } from './useScales'

const props = defineProps({
  start: { type: Object as PropType<DateTime>, required: true },
  end: { type: Object as PropType<DateTime>, required: true },
  height: { type: Number, required: true },
  width: { type: Number, required: true },
  apptsScaleMax: { type: Number, required: true },
})
const { start, end, height, width, apptsScaleMax } = toRefs(props)

const { timeScale, getXForTime, apptsScale } = useScales({
  start,
  end,
  apptsScaleMax,
  width,
  height,
})
// Time format: https://github.com/d3/d3-time-format#locale_format
const xAxis = computed(() =>
  axisBottom(timeScale.value).ticks(d3Time.timeHour.every(24), '%A')
)
const xAxisTimes = computed(() =>
  axisBottom(timeScale.value).ticks(d3Time.timeHour.every(6), '%H:%M')
)
const xTickPlacementHours = ref(24)
const verticalGridLines = computed(() =>
  axisBottom(timeScale.value)
    .ticks(d3Time.timeHour.every(xTickPlacementHours.value), '')
    .tickSize(-height.value)
)
const yAxis = computed(() => axisLeft(apptsScale.value))

function addAxis() {
  const xAxisEl = select('#x-axis') as Selection<SVGSVGElement, any, any, any>
  const yAxisEl = select('#y-axis') as Selection<SVGSVGElement, any, any, any>
  const xGridLinesEl = select('#x-axis-grid-lines') as Selection<
    SVGSVGElement,
    any,
    any,
    any
  >
  const xAxisTimesEl = select('#x-axis-times') as Selection<
    SVGSVGElement,
    any,
    any,
    any
  >
  xAxis.value(xAxisEl)
  xAxisTimes.value(xAxisTimesEl)
  const xLabelTranslation =
    getXForTime(start.value.plus({ hours: xTickPlacementHours.value / 2 })) -
    getXForTime(start.value)

  xAxisEl
    .selectAll('.tick text')
    .attr('transform', `translate(${xLabelTranslation}, 10)`)
  verticalGridLines.value(xGridLinesEl)
  yAxis.value(yAxisEl)
}
watch([apptsScaleMax, start, end], addAxis)

onMounted(addAxis)
</script>

<template>
  <!-- Axes -->
  <g id="axis">
    <g :transform="`translate(0, ${height})`">
      <g id="x-axis-grid-lines" />
      <g id="x-axis" />
      <g id="x-axis-times" />
    </g>
    <g id="y-axis"></g>
    <g id="y-axis-label">
      <text
        :transform="`rotate(-90) translate(-${height / 2}, -34)`"
        y="6"
        dy="0.71em"
        text-anchor="middle"
        fill="currentColor"
      >
        # of Appointments
      </text>
      <image />
    </g>
  </g>
</template>

<style lang="scss">
#axis {
  text {
    font: 0.45rem sans-serif;
  }
  #x-axis-grid-lines line {
    stroke: rgba(149, 149, 149, 0.3);
    // stroke-dasharray: 8 2;
  }
  #x-axis-times text {
    font-size: 0.4rem;
  }
}
</style>
