import { DateTime } from 'luxon'
import { defineStore } from 'pinia'
import type {
  GETTerminalAllocationMethod,
  TerminalName,
} from '~/services/apiClient'
import { AppointmentBookingApi } from '~/services/apiClient'

interface EmptySlotAllocationInfo {
  // "Empty Allocation" refers to the groups that a terminal uses when allocating
  // single empty appointments. Some terminals allocate globally (one big pool,
  // by_shipping_line = false, by_container_type = false), some allocate by shipping
  // line (by_shipping_line = true, by_container_type = false), and some allocate by
  // container type AND shipping line (by_shipping_line = true, by_container_type = true).
  terminal: TerminalName
  by_shipping_line: boolean
  by_container_type: boolean
}

export const useEmptyAppointmentSlotAllocationMethodStore = defineStore(
  'empty-appointment-slot-allocation-methods',
  () => {
    const api = new AppointmentBookingApi()
    const methodsKey = 'empty-appointment-slot-allocation-methods'
    const allocationInfoByTerminal = useLocalStorage<
      Map<TerminalName, EmptySlotAllocationInfo>
    >(methodsKey, new Map(), {
      listenToStorageChanges: true,
      deep: true,
      onError: (e) => {
        console.error(`Error loading ${methodsKey} from local storage`, e)
        localStorage.removeItem(methodsKey)
      },
    })
    const lastLoadedKey =
      'empty-appointment-slot-allocation-methods-last-loaded'
    const lastLoaded = useLocalStorage<DateTime | undefined>(
      lastLoadedKey,
      undefined,
      {
        serializer: {
          read: (v: string | undefined): DateTime | undefined => {
            if (!v) {
              return undefined
            }
            return DateTime.fromISO(v)
          },
          write: (v: DateTime | undefined): string => {
            if (!v) {
              return ''
            }
            return v.toISO()
          },
        },
        listenToStorageChanges: true,
        onError: (e) => {
          console.error(`Error loading ${lastLoadedKey} from local storage`, e)
          localStorage.removeItem(lastLoadedKey)
        },
      }
    )
    const loadingPromise = ref<Promise<void> | undefined>(undefined)
    const loaded = computed(
      (): boolean =>
        lastLoaded.value !== undefined &&
        allocationInfoByTerminal.value.size > 1 &&
        !loadingPromise.value
    )
    function setAllocation(
      terminalAllocationMethod: GETTerminalAllocationMethod
    ) {
      allocationInfoByTerminal.value.set(terminalAllocationMethod.terminal, {
        terminal: terminalAllocationMethod.terminal,
        by_shipping_line: terminalAllocationMethod.by_shipping_line,
        by_container_type: terminalAllocationMethod.by_container_type,
      })
    }
    function load() {
      const promise = api
        .getEmptyAppointmentSlotAllocationMethodsAppointmentsAvailabilityEmptyAllocationMethodsGet()
        .then((resp) => {
          resp.data.forEach((row) => {
            setAllocation(row)
          })
          lastLoaded.value = DateTime.now()
        })
        .finally(() => {
          loadingPromise.value = undefined
        })
      loadingPromise.value = promise
      return promise
    }
    async function loadIfNeeded() {
      if (
        !lastLoaded.value ||
        lastLoaded.value.plus({ hours: 1 }) < DateTime.now() ||
        allocationInfoByTerminal.value.size <= 1
      ) {
        await load()
      }
    }
    function getAllocation(terminal: TerminalName): EmptySlotAllocationInfo {
      const info = allocationInfoByTerminal.value.get(terminal)
      if (!info) {
        throw new Error(
          `No allocation info for terminal ${terminal} found in cache`
        )
      }
      return info
    }
    return {
      loadIfNeeded,
      getAllocation,
      loaded,
      allocationInfoByTerminal,
      setAllocation, // For testing purposes
      loadingPromise,
    }
  }
)
