<script lang="ts" setup>
import { useEditChassis } from '~/compositions/editChassis'
import type { Container } from '~/models/containers'

const props = defineProps<{
  container: Container
}>()

const { editChassis } = useEditChassis()
</script>

<template>
  <ColoredBadge
    v-tooltip="`Click to change chassis ownership`"
    type="info"
    class="ml-1"
    @click="editChassis(props.container)"
  >
    {{ container.latest_appointment?.own_chassis_description }}
    <i-mdi:pencil class="align-text-top h-13px text-gray-400 -mr-1" />
  </ColoredBadge>
</template>
