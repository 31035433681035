<script setup lang="ts">
import SearchContainersHintRow from './SearchContainersHintRow.vue'
import NotFoundContainerRow from './NotFoundContainerRow.vue'
import type { ImportStatusBase } from '~~/models/importStatuses'
import BookingRow from '~~/components/BookingRow.vue'
import { useGlobalSlotsDisplayConfig } from '~~/compositions/loadSlots'
import type { Appointment } from '~/models/appointments'
import type { BookingRequestState } from '~~/stores/mutationRequests'
import type { TerminalName } from '~/services/apiClient'

const props = withDefaults(
  defineProps<{
    importStatuses: ImportStatusBase[]
    appointments: Appointment[]
    searchedContainers: string[]
    rounded?: boolean
    showContainersColumn?: boolean
    supportedTerminals?: TerminalName[]
  }>(),
  {
    rounded: true,
    showContainersColumn: true,
  }
)

const emit = defineEmits<{
  (e: 'bookingBegun', event: BookingRequestState): void
}>()

const { datesToShow } = useGlobalSlotsDisplayConfig()
interface ContainerToBook {
  importStatus: ImportStatusBase | undefined
  appointment: Appointment | undefined
  containerNumber: string
  loadSlotsImmediately: boolean
}
const searchedContainersSet = computed(() => new Set(props.searchedContainers))
const containersToBook = computed((): ContainerToBook[] => {
  const containerNumbers: Set<string> = new Set()
  const importStatusesByContainerNumber = new Map<string, ImportStatusBase>()
  const appointmentsByContainerNumber = new Map<string, Appointment>()
  for (const appointment of props.appointments) {
    if (searchedContainersSet.value.has(appointment.container_number)) {
      appointmentsByContainerNumber.set(
        appointment.container_number,
        appointment
      )
      containerNumbers.add(appointment.container_number)
    }
  }
  for (const importStatus of props.importStatuses) {
    if (searchedContainersSet.value.has(importStatus.number)) {
      importStatusesByContainerNumber.set(importStatus.number, importStatus)
      containerNumbers.add(importStatus.number)
    }
  }
  const results: ContainerToBook[] = []
  let count = 0
  for (const containerNumber of containerNumbers) {
    count++
    const importStatus = importStatusesByContainerNumber.get(containerNumber)
    const appointment = appointmentsByContainerNumber.get(containerNumber)
    // only load slots immediately for the first 5 containers
    if (count > 5) {
      results.push({
        importStatus,
        appointment,
        containerNumber,
        loadSlotsImmediately: false,
      })
    } else
      results.push({
        importStatus,
        appointment,
        containerNumber,
        loadSlotsImmediately: true,
      })
  }
  return results
})

const notFoundContainers = computed(() =>
  props.searchedContainers.filter(
    (container) =>
      !containersToBook.value.some(
        (containerToBook) => containerToBook.containerNumber === container
      )
  )
)
</script>

<template>
  <table
    class="booking-table"
    :class="{ rounded }"
    v-bind="$attrs"
    aria-label="Book Appointments"
  >
    <thead>
      <tr>
        <th v-if="showContainersColumn" class="container-info-column" />
        <th
          v-for="date of datesToShow"
          :key="date.toISO()"
          colspan="2"
          class="date-column"
        >
          {{ date.toFormat('ccc, MMM d') }}
        </th>
      </tr>
    </thead>
    <tbody>
      <NotFoundContainerRow
        v-for="container of notFoundContainers"
        :key="container"
        :container="container"
      />
      <BookingRow
        v-for="{
          importStatus,
          appointment,
          containerNumber,
          loadSlotsImmediately,
        } of containersToBook"
        :key="containerNumber"
        :import-status="importStatus"
        :existing-appointment="appointment"
        :show-containers-column="showContainersColumn"
        :load-slots-immediately="loadSlotsImmediately"
        :supported-terminals="supportedTerminals"
        @booking-begun="emit('bookingBegun', $event)"
      />
      <SearchContainersHintRow
        v-if="props.importStatuses.length === 0 && !props.searchedContainers"
        :colspan="datesToShow.length * 2"
      />
    </tbody>
  </table>
</template>

<style lang="scss">
table.booking-table {
  table-layout: fixed;
  th.container-info-column {
    width: 200px;
  }
  th.date-column {
    width: 210px;
  }
  th,
  td {
    min-width: 100px;
    padding: 8px;
  }

  th {
    @apply text-gray-700 !text-center !py-2;
    font-weight: 500;
  }
  & {
    border-collapse: separate;
    border-spacing: 0;
  }
  tr th,
  tr td {
    border-right: 1px solid #bbb;
    border-bottom: 1px solid #bbb;
    padding: 5px;
  }
  tr th:first-child,
  tr td:first-child {
    border-left: 1px solid #bbb;
  }
  tr th {
    background: #eee;
    border-top: 1px solid #bbb;
    text-align: left;
  }
  &.rounded {
    // Round corners
    $border-radius: 3px;
    & {
      border-radius: $border-radius;
    }

    /* top-left border-radius */
    tr:first-child th:first-child {
      border-top-left-radius: $border-radius;
    }

    /* top-right border-radius */
    tr:first-child th:last-child {
      border-top-right-radius: $border-radius;
    }

    /* bottom-left border-radius */
    tr:last-child td:first-child {
      border-bottom-left-radius: $border-radius;
    }

    /* bottom-right border-radius */
    tr:last-child td:last-child {
      border-bottom-right-radius: $border-radius;
    }
  }
}
</style>
