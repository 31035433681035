<script setup lang="ts">
const props = defineProps<{
  colspan: number
}>()
</script>

<template>
  <tr>
    <td></td>
    <td :colspan="props.colspan" class="text-center">
      <div class="py-15 text-gray-500 text-xl">
        <i-mdi:search class="align-text-bottom -mb-1px" />
        Search container numbers to view appointments
      </div>
    </td>
  </tr>
</template>
