<script lang="ts" setup>
import type {
  GateScheduleEntry,
  TerminalGateSchedule,
} from '~/models/gateSchedules'
import { GateStatus } from '~/services/apiClient/api'

const props = defineProps<{
  entry: GateScheduleEntry | undefined
  schedule: TerminalGateSchedule
}>()

// Get tag type based on status
function getTagType(status: GateStatus) {
  if (status === GateStatus.Open) return 'success'
  if (status === GateStatus.Closed) return 'danger'
  return 'warning' // For other statuses like 'tbd', etc.
}
const itemWidth = ref('56px')
const gateScheduleScreenshotDialogOpen = ref(false)
</script>

<template>
  <td>
    <el-popover
      v-if="props.entry"
      placement="top-start"
      title="Notes"
      trigger="hover"
      :content="props.entry.notes.join(', ')"
      :disabled="props.entry.notes.length === 0"
      width="fit-content"
    >
      <template #reference>
        <el-button
          :style="{ width: itemWidth, margin: '0px' }"
          size="small"
          :type="getTagType(props.entry.status)"
          plain
          @click="gateScheduleScreenshotDialogOpen = true"
        >
          {{ props.entry.status.toUpperCase() }}
          <div v-if="props.entry.notes.length > 0">
            <i-material-symbols:info class="ml-1 align-middle" />
          </div>
        </el-button>
      </template>
    </el-popover>
    <div
      v-else
      :style="{ width: itemWidth }"
      class="text-center text-gray-200 text-sm align-middle flex flex-col"
    >
      -
    </div>
  </td>
  <GateScheduleInfoModal
    v-if="gateScheduleScreenshotDialogOpen"
    :model-value="gateScheduleScreenshotDialogOpen"
    :gate-schedule="schedule"
    @close="gateScheduleScreenshotDialogOpen = false"
  />
</template>

<style lang="scss" scoped>
td {
  padding: 0.15rem;
  text-align: center;
}
</style>
