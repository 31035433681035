<script lang="ts" setup>
import {
  type ReadingSource,
  TerminalReadingType,
} from '../emptyReturnRules/terminals'
import type { ReturnRulesReading } from '~/models/returnRules'
import type { GateScheduleReading } from '~/models/gateSchedules'

const props = defineProps<{
  rulesSource: ReadingSource
  reading: ReturnRulesReading | GateScheduleReading
  readingIsHistorical: boolean
}>()
</script>

<template>
  <BaseReadingComponent
    :reading="props.reading"
    :reading-is-historical="props.readingIsHistorical"
    :rules-source="props.rulesSource"
    :reading-type="TerminalReadingType.EmptyReturnRules"
  />
</template>
