<script setup lang="ts">
import SlotsBucketButton from './SlotsBucketButton.vue'
import type { ShiftBucket } from '~~/logic/slotBucketing'
import { Shift } from '~/services/apiClient'
import type { SlotSelectedEvent } from '~/components/appointmentSlots/events'
import type { Appointment } from '~/models/appointments'

const props = defineProps<{
  shiftBucket: ShiftBucket | undefined
  existingAppointment: Appointment | undefined
}>()
const emit = defineEmits({
  book: (event: SlotSelectedEvent) => true,
})

const shouldShow = computed(() => {
  if (props.shiftBucket === undefined) {
    return false
  }
  if (
    props.shiftBucket.shift === Shift.Third &&
    props.shiftBucket.numSlotsAvailable === 0
  ) {
    return false
  }
  return true
})

function shiftName(shift: Shift): string {
  switch (shift) {
    case Shift.First:
      return '1st'
    case Shift.Second:
      return '2nd'
    case Shift.Third:
      return '3rd'
  }
}
</script>

<template>
  <div v-if="shouldShow && props.shiftBucket">
    <div class="shift-label">
      {{ shiftName(props.shiftBucket.shift) }}
    </div>
    <SlotsBucketButton
      v-for="bucket of props.shiftBucket.buckets"
      :key="bucket.hourStart"
      :bucket="bucket"
      :existing-appointment="props.existingAppointment"
      @book="emit('book', $event)"
    />
  </div>
</template>

<style lang="scss">
.shift-label {
  @apply text-xs text-gray-500;
}
</style>
