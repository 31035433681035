import { faker } from '@faker-js/faker/locale/en'
import type { GETTruck, GETTruckWithStats } from '~/services/apiClient'

export class Truck {
  id: number
  licensePlate: string
  name: string | null
  archived: boolean
  demoMode: boolean

  constructor(raw: GETTruck, demoMode: boolean) {
    this.id = raw.id
    if (demoMode) {
      this.licensePlate = faker.vehicle.vrm()
    } else {
      this.licensePlate = raw.license_plate_number
    }
    this.name = raw.name ?? null
    if (this.name && demoMode) {
      this.name = faker.person.firstName()
    }
    this.archived = raw.archived ?? false
    this.demoMode = demoMode
  }

  toGETTruck(): GETTruck {
    return {
      id: this.id,
      license_plate_number: this.licensePlate,
      name: this.name || undefined,
      archived: this.archived,
    }
  }
}

export class TruckWithStats {
  plate: string
  truck: Truck
  numCompleted: number | undefined
  numMissed: number | undefined
  numScheduled: number | undefined

  constructor(
    rawTruck: GETTruck,
    demoMode: boolean,
    rawStats?: GETTruckWithStats
  ) {
    this.plate = rawTruck.license_plate_number
    this.truck = new Truck(rawTruck, demoMode)
    this.numCompleted = rawStats?.num_completed
    this.numMissed = rawStats?.num_missed
    this.numScheduled = rawStats?.num_scheduled
  }

  get missPercentage(): number | undefined {
    if (this.numCompleted === undefined || this.numMissed === undefined)
      return undefined
    return computeMissedPercentage(this.numCompleted, this.numMissed)
  }

  get numAssigned(): number | undefined {
    if (
      this.numCompleted === undefined ||
      this.numMissed === undefined ||
      this.numScheduled === undefined
    )
      return undefined
    return this.numCompleted + this.numMissed + this.numScheduled
  }

  setStatsToZeroIfUndefined() {
    this.numCompleted = this.numCompleted ?? 0
    this.numMissed = this.numMissed ?? 0
    this.numScheduled = this.numScheduled ?? 0
  }

  toGETTruckWithStats(): GETTruckWithStats {
    return {
      truck: this.truck.toGETTruck(),
      num_completed: this.numCompleted ?? 0,
      num_missed: this.numMissed ?? 0,
      num_scheduled: this.numScheduled ?? 0,
    }
  }
}
function computeMissedPercentage(numCompleted: number, numMissed: number) {
  const total = numCompleted + numMissed
  if (total === 0) return 0
  return Math.round((numMissed / total) * 100)
}

export function truckWithStatsFromTruck(
  truck: Truck,
  stats?: GETTruckWithStats
): TruckWithStats {
  return new TruckWithStats(
    {
      id: truck.id,
      license_plate_number: truck.licensePlate,
      name: truck.name || undefined,
      archived: truck.archived,
    },
    truck.demoMode,
    stats
  )
}
