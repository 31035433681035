<script lang="ts" setup>
import type { AppointmentWithContainerInfo } from '~/models/appointmentWithContainerInfo'

const props = withDefaults(
  defineProps<{
    appointmentsWithContainers: AppointmentWithContainerInfo[]
    showTerminal?: boolean
    showCancelButton?: boolean
  }>(),
  {
    showTerminal: false,
    showCancelButton: true,
  }
)
</script>

<template>
  <table>
    <thead>
      <tr>
        <th>Container</th>
        <th>Appt #</th>
        <th>Customer</th>
        <th v-if="props.showTerminal">Terminal</th>
        <!-- Effectively sets to minimum width -->
        <th v-if="props.showCancelButton" style="width: 1%"></th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="appointmentInfo of props.appointmentsWithContainers"
        :key="appointmentInfo.appointment.id"
      >
        <td>
          <ClickToCopy :text-to-copy="appointmentInfo.container_number" />
        </td>
        <td>
          <ClickToCopy
            :text-to-copy="
              appointmentInfo.appointment.display_terminal_reference
            "
          />
        </td>
        <td>
          {{ appointmentInfo.watch.customer_name ?? '???' }}
        </td>
        <td v-if="props.showTerminal">
          {{ appointmentInfo.appointment.terminal_label }}
        </td>
        <td v-if="props.showCancelButton">
          <CancelAppointmentButton :appointment="appointmentInfo.appointment">
            <el-button type="danger" style="padding: 8px 16px">
              Cancel
            </el-button>
          </CancelAppointmentButton>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<style scoped lang="scss">
table {
  width: 100%;

  th,
  td {
    padding: 2px 5px;
    text-align: left;
  }
}
</style>
