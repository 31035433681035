<script setup lang="ts">
import TerminalSelector from './TerminalSelector.vue'
import type { AppointmentFilters, MoveTypeFilter } from './types'
import MoveTypeFilterSelector from './MoveTypeFilterSelector.vue'
import TruckSelector from '~/components/inputs/TruckSelector.vue'
import ContainersSearch from '~/components/inputs/ContainersSearch.vue'
import CustomerSelector from '~/components/inputs/CustomerSelector.vue'
import type { TerminalName } from '~/services/apiClient'
import type { Truck } from '~/models/trucks'
import { useUserStore } from '~/stores/user'

const props = withDefaults(
  defineProps<{
    modelValue: AppointmentFilters
    showOnlyLaTerminals?: boolean
    showCustomerFilter?: boolean
  }>(),
  { showCustomerFilter: false }
)

const emit = defineEmits<{
  (event: 'update:modelValue', filters: AppointmentFilters): void
}>()

const userStore = useUserStore()

function onTerminalChanged(terminals: TerminalName[]) {
  emit('update:modelValue', { ...props.modelValue, terminals })
}
function onTruckChanged(truck: Truck | null) {
  emit('update:modelValue', {
    ...props.modelValue,
    licensePlate: truck?.licensePlate || undefined,
  })
}

function onMoveTypeChanged(moveType: MoveTypeFilter | null) {
  emit('update:modelValue', {
    ...props.modelValue,
    moveType: moveType || undefined,
  })
}

function onContainersChanged(containers: string[]) {
  emit('update:modelValue', {
    ...props.modelValue,
    containers: new Set(containers),
  })
}

function onCustomerChanged(customerId: number | undefined) {
  emit('update:modelValue', {
    ...props.modelValue,
    customerId: customerId || undefined,
  })
}
</script>

<template>
  <div class="appointment-filters">
    <el-form label-position="top" :inline="true" size="default">
      <i-mdi:filter-variant
        v-tooltip="'Filter appointments'"
        class="text-3xl text-gray-500 -mt-3px -ml-4px mr-2"
      />
      <el-form-item>
        <ContainersSearch
          :model-value="Array.from(modelValue.containers)"
          @update:model-value="onContainersChanged"
        />
      </el-form-item>
      <el-form-item>
        <MoveTypeFilterSelector
          :model-value="modelValue.moveType"
          @update:model-value="onMoveTypeChanged"
        />
      </el-form-item>
      <el-form-item>
        <TerminalSelector
          :model-value="modelValue.terminals"
          :show-only-la-terminals="props.showOnlyLaTerminals"
          style="width: 120px"
          @update:model-value="onTerminalChanged"
        />
      </el-form-item>
      <el-form-item class="-mt-1px">
        <TruckSelector
          :license-plate="modelValue.licensePlate || ''"
          :load-usage-counts="false"
          :narrow-width="false"
          @update:model-value="onTruckChanged"
        />
      </el-form-item>
      <el-form-item v-if="!userStore.isSubCarrier">
        <CustomerSelector
          v-if="props.showCustomerFilter"
          v-tooltip="'View appointments for a specific customer'"
          :model-value="modelValue.customerId"
          style="width: 150px"
          class="dropdown-filter"
          size="default"
          @update:model-value="onCustomerChanged"
        />
      </el-form-item>
    </el-form>
  </div>
</template>

<style lang="scss">
.appointment-filters {
  .el-form-item {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
</style>
