<script setup lang="ts">
import SlotButton from './SlotButton.vue'
import type { SlotSelectedEvent } from '~/components/appointmentSlots/events'
import type { Bucket } from '~/logic/appointmentBucketing'
import type { Appointment } from '~/models/appointments'

const props = defineProps<{
  bucket: Bucket
  existingAppointment: Appointment | undefined
}>()
const emit = defineEmits({
  book: (event: SlotSelectedEvent) => true,
})
</script>

<template>
  <div>
    <div class="font-bold text-center mb-3">
      <span v-if="props.bucket.slots.length === 0" class="text-red-800">
        No Available Appointment Times
      </span>
      <template v-else> Available Appointment Times </template>
    </div>
    <SlotButton
      v-for="slot in props.bucket.slots"
      :key="slot.window_start.toISO()"
      :appointment-slot="slot"
      :existing-appointment="props.existingAppointment"
      class="w-full my-1"
      @book="emit('book', $event)"
    />
  </div>
</template>
