<script lang="ts" setup>
import { useContainerFilters } from '~/compositions/containerFilters'
import { useUserStore } from '~/stores/user'

const userStore = useUserStore()

const {
  statusFilterLabel,
  terminalFilterLabel,
  customerFilter,
  containerSearch,
  autoBookOnFilter,
  assignedToFilter,
} = useContainerFilters()
</script>

<template>
  <div style="margin-top: 95px" class="text-center text-lg">
    <i-ant-design:warning-filled class="align-text-bottom" />
    No containers
    <span v-if="statusFilterLabel" class="font-semibold">{{
      statusFilterLabel
    }}</span>
    <template v-if="containerSearch">
      with numbers
      <span class="font-semibold">{{ containerSearch }}</span>
    </template>
    <template v-if="!containerSearch">
      <template v-if="terminalFilterLabel">
        at
        <span class="font-semibold">{{ terminalFilterLabel }}</span>
      </template>
      <template v-if="customerFilter">
        for
        <span class="font-semibold">{{ customerFilter.name }}</span>
      </template>
      <template v-if="autoBookOnFilter">
        with <span class="font-semibold">auto-book on</span>
      </template>
      <template v-if="assignedToFilter.length">
        assigned to
        <span class="font-semibold"
          >{{ assignedToFilter.length }} selected user<template
            v-if="assignedToFilter.length > 1"
            >s</template
          ></span
        >
      </template>
    </template>
    found
    <div v-if="userStore.isSubCarrier" class="mx-20 text-sm text-gray-500 mt-2">
      <template v-if="containerSearch">
        Sub-carriers may not add new containers. Please ask your carrier to add
        containers for you
      </template>
      <template v-else>
        You are logged in as a sub-carrier. Sub-carriers can only see containers
        assigned to them. Search for containers above to find containers not yet
        assigned to you
      </template>
    </div>
  </div>
</template>
