import { DateTime } from 'luxon'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { useUserStore } from './user'
import { CapacitySetInfo } from '~/models/capacitySets'
import type {
  CapacitySetRemainingCapacities,
  GETCapacitySetAttributes,
  POSTRemainingCapacityResponse,
  RemainingCapacityPeriod,
} from '~/services/apiClient'
import { AppointmentCapacitiesApi } from '~/services/apiClient'

export const useRemainingCapacitiesStore = defineStore(
  'remaining-capacities',
  () => {
    const loading = ref(false)
    const loaded = ref(false)
    const capacitySets = ref(undefined as RemainingCapacities[] | undefined)
    const userStore = useUserStore()

    // Functions
    function load(
      customerID: number | null,
      containerTags: string[],
      daysToLoad: number
    ) {
      const timePeriodStart = DateTime.now().minus({ hours: 3 }).toUTC()
      const timePeriodEnd = timePeriodStart.plus({ days: daysToLoad })
      // Load
      const api = new AppointmentCapacitiesApi()
      loading.value = true
      loaded.value = false
      capacitySets.value = undefined
      return api
        .getRemainingCapacityAppointmentsCapacitiesRemainingPost({
          customer_id: customerID || undefined,
          container_tags: containerTags,
          time_period_start: timePeriodStart.toISO(),
          time_period_end: timePeriodEnd.toISO(),
        })
        .then((response) => {
          const data: POSTRemainingCapacityResponse = response.data
          const sets = [] as RemainingCapacities[]
          data.applicable_capacity_sets.forEach((capacitySet) => {
            sets.push(new RemainingCapacities(capacitySet, userStore.demo_mode))
          })
          capacitySets.value = sets
        })
        .finally(() => {
          loading.value = false
          loaded.value = true
        })
    }
    function getRemainingCapacityForTime(time: DateTime): number | null {
      const applicablePeriods = getRemainingCapacities(time)
      if (applicablePeriods.length === 0) return null
      const minRemainingCapacity = applicablePeriods.reduce((min, period) => {
        return period.num_remaining < min ? period.num_remaining : min
      }, Infinity)
      return minRemainingCapacity
    }
    function getRemainingCapacities(
      time: DateTime
    ): RemainingCapacityPeriodInfo[] {
      if (!capacitySets.value) throw new Error('No capacity sets loaded')
      const applicablePeriods: RemainingCapacityPeriodInfo[] = []
      capacitySets.value.forEach((set) => {
        const period = set.getRemainingCapacity(time)
        if (period) applicablePeriods.push(period)
      })
      return applicablePeriods
    }
    return {
      loading,
      loaded,
      load,
      getRemainingCapacityForTime,
      getRemainingCapacities,
    }
  }
)

export class RemainingCapacityPeriodInfo {
  capacity: number
  num_booked: number
  num_remaining: number
  time_period_start: DateTime
  time_period_end: DateTime
  set: CapacitySetInfo
  constructor(
    raw: RemainingCapacityPeriod,
    set: GETCapacitySetAttributes,
    demoMode: boolean
  ) {
    this.capacity = raw.capacity
    this.num_booked = raw.num_booked
    this.time_period_start = DateTime.fromISO(raw.start)
    this.time_period_end = DateTime.fromISO(raw.end)
    this.num_remaining = this.capacity - this.num_booked
    this.set = new CapacitySetInfo(
      {
        ...set,
        // Paper over some field naming inconsistency
        containerTag: set.container_tag ?? null,
        dedicated: set.independent_capacity ?? false,
        customer: set.customer ?? null,
      },
      demoMode
    )
  }

  get timeDescription(): string {
    return (
      this.time_period_start.toFormat('ccc H:mm') +
      ' - ' +
      this.time_period_end.toFormat('H:mm')
    )
  }
}

export class RemainingCapacities {
  dedicated: boolean
  container_tag: string | null
  capacityPeriods: RemainingCapacityPeriodInfo[]
  set: GETCapacitySetAttributes

  constructor(raw: CapacitySetRemainingCapacities, demoMode: boolean) {
    this.dedicated = raw.capacity_set.independent_capacity ?? false
    this.container_tag = raw.capacity_set.container_tag ?? null
    this.capacityPeriods = raw.remaining_capacity_periods.map(
      (period) =>
        new RemainingCapacityPeriodInfo(period, raw.capacity_set, demoMode)
    )
    this.capacityPeriods.sort(
      (a, b) => a.time_period_start.diff(b.time_period_start).milliseconds
    )
    this.set = raw.capacity_set
  }

  getRemainingCapacity(time: DateTime): RemainingCapacityPeriodInfo | null {
    let left: number = 0
    let right: number = this.capacityPeriods.length - 1

    while (left <= right) {
      const mid: number = Math.floor((left + right) / 2)
      const midPeriod: RemainingCapacityPeriodInfo = this.capacityPeriods[mid]

      if (
        time >= midPeriod.time_period_start &&
        time < midPeriod.time_period_end
      ) {
        return midPeriod
      } else if (time < midPeriod.time_period_start) {
        right = mid - 1
      } else {
        left = mid + 1
      }
    }

    return null
  }
}

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useRemainingCapacitiesStore, import.meta.hot)
  )
}
