<script lang="ts" setup>
import type { PropType } from 'vue'
import { DateTime } from 'luxon'
import VueApexChart from 'vue3-apexcharts'
import type { ApexOptions } from 'apexcharts'

import type {
  GETTimeBucketAppointmentStatusCount,
  TerminalName,
  TransactionDirection,
} from '../../services/apiClient'
import { AppointmentStatus } from '../../services/apiClient'

import { appointmentStatusColor, getDiffToSystemTime, toJSDate } from '~/utils'
import { useAppointmentCountStoreForGraph } from '~/stores/appointmentCounts'
import { BucketFilter } from '~/models/bucketFilters'

const props = defineProps({
  start: {
    type: Object as PropType<DateTime>,
    required: true,
  },
  end: {
    type: Object as PropType<DateTime | undefined>,
  },
  terminal: {
    type: String as PropType<TerminalName | undefined>,
  },
  loaded: {
    type: Boolean as PropType<boolean | null>,
    default: null,
  },
  direction: {
    type: String as PropType<TransactionDirection | null>,
    default: null,
  },
  timeBucketHours: {
    type: Number,
    required: true,
  },
  title: {
    type: String,
  },
  tooltipDateFormat: {
    type: String as PropType<string | undefined>,
  },
  xAxisDateFormat: {
    type: String as PropType<string | undefined>,
  },
  height: {
    type: Number,
    default: 500,
  },
})
const { terminal, loaded, direction, start } = toRefs(props)
const apptCountsStore = useAppointmentCountStoreForGraph()
const selectedBucketFilter = ref(undefined as BucketFilter | undefined)
function load() {
  selectedBucketFilter.value = undefined
  apptCountsStore.load({
    time_bucket_hours: props.timeBucketHours,
    after: props.start,
    terminal_name: props.terminal,
    loaded: loaded.value ?? undefined,
    direction: direction.value ?? undefined,
  })
}
onMounted(load)
watch([terminal, direction, loaded], () => load())
watch([start], () => load())
const x_series = computed(() => {
  if (apptCountsStore.counts.length === 0) {
    return []
  }
  const alignmentDT = DateTime.fromISO(
    apptCountsStore.counts[0].time_bucket_start
  )
  let seriesStart = alignmentDT
  while (seriesStart > props.start) {
    seriesStart = seriesStart.minus({ hours: props.timeBucketHours })
  }
  const seriesEnd =
    props.end ||
    DateTime.fromISO(
      apptCountsStore.counts[apptCountsStore.counts.length - 1]
        .time_bucket_start
    ).plus({ hours: props.timeBucketHours })
  const series = [] as number[]
  let time = seriesStart
  while (time < seriesEnd) {
    series.push(toChartTime(time))
    time = time.plus({ hours: props.timeBucketHours })
  }
  return series
})

const nowXValue = ref(toChartTime(DateTime.now().toLocal()))
onMounted(() => {
  const oneMinute = 60 * 1000
  setTimeout(() => {
    nowXValue.value = toChartTime(DateTime.now().toLocal())
  }, oneMinute)
})

const statuses = [
  AppointmentStatus.Scheduled,
  AppointmentStatus.Completed,
  AppointmentStatus.Missed,
  AppointmentStatus.Cancelled,
  AppointmentStatus.Aborted,
  AppointmentStatus.InProgress,
]
const statusOptions: { status: AppointmentStatus; color: string }[] =
  statuses.map((status) => ({
    status,
    color: appointmentStatusColor(status),
  }))
const options = computed((): ApexOptions => {
  const tooltip: ApexTooltip = {
    shared: true,
    intersect: false,
  }
  if (props.xAxisDateFormat) {
    tooltip.x = {
      format: props.tooltipDateFormat,
    }
  }
  const xaxis: ApexXAxis = {
    type: 'datetime',
    categories: x_series.value,
    labels: { datetimeUTC: false, format: props.xAxisDateFormat },
  }
  const options: ApexOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: false,
      },
      selection: {
        enabled: false,
      },
      animations: {
        enabled: false,
      },
      events: {},
    },
    states: {
      active: {
        allowMultipleDataPointsSelection: false,
      },
    },
    colors: [
      function ({ seriesIndex }: { seriesIndex: number }) {
        return statusOptions[seriesIndex].color
      },
    ],
    tooltip: {
      shared: false,
      onDatasetHover: {
        highlightDataSeries: false,
      },
      followCursor: false,
      x: {
        show: true,
        format: 'ddd dd MMM HH:mm',
        formatter: undefined,
      },
    },
    title: {
      text: props.title,
      style: {
        fontSize: '28px',
      },
      align: 'center',
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    xaxis,
    yaxis: {
      title: {
        text: '# of Appointments',
      },
    },
    annotations: {
      xaxis: [
        {
          x: new Date(nowXValue.value).getTime(),
          borderColor: '#00E396',
          label: {
            borderColor: '#00E396',
            orientation: 'horizontal',
            text: 'Now',
          },
        },
      ],
    },
  }
  return options
})

function toChartTime(dt: DateTime) {
  return toJSDate(dt.minus({ hours: 0 })).getTime()
}

function fromChartTime(millis: number) {
  return DateTime.fromMillis(millis).plus(getDiffToSystemTime())
}

const series = computed(() => {
  const byStatus: Map<
    AppointmentStatus,
    GETTimeBucketAppointmentStatusCount[]
  > = new Map()
  for (const count of apptCountsStore.counts) {
    if (!byStatus.has(count.status)) byStatus.set(count.status, [])

    byStatus.get(count.status)!.push(count)
  }
  let allValues = []
  for (const status of statuses) {
    const counts = byStatus.get(status) || []
    let count_ix = 0
    const values = []
    for (const time of x_series.value) {
      if (
        count_ix < counts.length &&
        time ===
          toChartTime(DateTime.fromISO(counts[count_ix].time_bucket_start))
      ) {
        values.push(counts[count_ix].count)

        count_ix++
      } else {
        values.push(0)
      }
    }
    allValues.push({ name: status, data: values })
  }
  allValues = allValues.filter(({ name }) => statuses.includes(name))
  allValues.sort((a, b) => {
    return statuses.indexOf(a.name) - statuses.indexOf(b.name)
  })
  return allValues
})
const loading = toRef(apptCountsStore, 'loading')
const selectedBucketFilterStatusCount = ref(0)

function handleSelection(
  event: MouseEvent,
  _: any,
  {
    selectedDataPoints,
  }: {
    dataPointIndex: number
    seriesIndex: number
    selectedDataPoints: number[][]
  }
) {
  selectedBucketFilter.value = undefined
  selectedBucketFilterStatusCount.value = 0
  for (const [seriesIndex, dataPointIndices] of selectedDataPoints.entries()) {
    if (!dataPointIndices) {
      continue
    }
    const status = statuses[seriesIndex]
    const dataPointIndex = dataPointIndices[0]
    const time = fromChartTime(x_series.value[dataPointIndex])
    const filter = new BucketFilter(
      time,
      time.plus({ hours: props.timeBucketHours }),
      status,
      dataPointIndex
    )
    selectedBucketFilter.value = filter
    selectedBucketFilterStatusCount.value =
      series.value[seriesIndex].data[dataPointIndex]
    return
  }
}

function handleLegendClick(event: any, seriesIndex: number, option: any) {
  const status = statuses[seriesIndex]
  // TODO: Not implemented yet
  return status
}
</script>

<template>
  <div v-if="loading" v-loading="true" :style="{ height: `${height}px` }" />
  <VueApexChart
    v-else
    type="bar"
    :height="height"
    :options="options"
    :series="series"
    @data-point-selection="handleSelection"
    @legend-click="handleLegendClick"
  />
  <AppointmentsGraphDetailsTable
    v-if="selectedBucketFilter"
    :bucket-filter="selectedBucketFilter"
    :num-appointments-in-bucket="selectedBucketFilterStatusCount"
    :terminal="terminal"
    :loaded="loaded"
    :direction="direction"
  />
  <div v-else class="text-center my-4">
    <MessageBanner
      class="bg-blue-300 px-6 text-center inline-block"
      style="font-size: 1em"
    >
      <i-mdi:information-variant-circle class="align-text-bottom mr-1" />
      Click <i-mdi:cursor-default-click class="align-text-bottom" /> on a bar to
      view appointments with that time and status
    </MessageBanner>
  </div>
</template>

<style lang="scss">
.bottom-align {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 5px;
  margin-top: 5px;
}
</style>
