<script lang="ts" setup>
import ConnectionSettings from '~/components/sections/ConnectionSettings.vue'
import { useUserStore } from '~/stores/user'
import { updateParam } from '~/utils'
useHead({
  title: 'Dray Dog - Settings',
})
const router = useRouter()
const route = useRoute()
const userStore = useUserStore()

const activeTab = ref((route.query.tab as string) || 'users')
function updateTabQueryParam() {
  updateParam(router, route, 'tab', activeTab.value)
}
updateTabQueryParam()
watch(activeTab, updateTabQueryParam)
</script>

<template>
  <Card class="py-6 px-8">
    <h1 class="text-2xl font-bold text-right mb-7 max-w-39">Settings</h1>
    <el-tabs v-model="activeTab" tab-position="left">
      <el-tab-pane
        v-if="!userStore.isTerminalOrg"
        name="preferences"
        lazy
        label="Preferences"
        class="settings-section"
      >
        <Preferences />
      </el-tab-pane>
      <el-tab-pane name="users" lazy label="Users" class="settings-section">
        <UsersSettings />
      </el-tab-pane>
      <el-tab-pane
        v-if="!userStore.isTerminalOrg"
        name="credentials"
        lazy
        label="Terminal + Line Credentials"
        class="settings-section"
      >
        <ConnectionSettings :uas-connections-only="false" />
      </el-tab-pane>
      <el-tab-pane
        v-if="!userStore.isTerminalOrg"
        name="sub_carriers"
        lazy
        label="Sub-Carriers"
        class="settings-section"
      >
        <SubCarrierSettings />
      </el-tab-pane>
      <el-tab-pane
        v-if="!userStore.isTerminalOrg"
        name="customers"
        lazy
        label="Customers"
        class="settings-section"
      >
        <CustomerSettings />
      </el-tab-pane>
      <el-tab-pane
        v-if="!userStore.isTerminalOrg"
        name="Integrations"
        lazy
        label="Integrations"
        class="settings-section"
      >
        <ExternalIntegrationSettings />
      </el-tab-pane>
    </el-tabs>
  </Card>
</template>

<route lang="yaml">
meta:
  auth: true
name: settings
</route>

<style lang="scss" scoped>
.settings-section {
  margin-left: 50px;
}
</style>
