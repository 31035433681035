<script lang="ts" setup>
import { ElNotification } from 'element-plus'
import type { Appointment } from '~/models/appointments'
import { AppointmentBookingApi } from '~/services/apiClient'
import LicensePlateSelector from '~/components/inputs/LicensePlateSelector.vue'
import BaseDialog from '~/components/dialogs/BaseDialog.vue'

const props = defineProps<{
  modelValue: boolean
  appointment: Appointment
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
  (e: 'close'): void
}>()
const dialogOpen = useVModel(props, 'modelValue', emit)
const originalLicensePlate = ref(props.appointment.truck_license_plate_number)
const licensePlate = ref(props.appointment.truck_license_plate_number || '')
// a license plate is required if the original license plate is empty
const licensePlateRequired = computed(() => !originalLicensePlate.value)
const editPlate = ref(false)
// if the appointment has no plate set edit plate to true and require it
onMounted(() => {
  if (!props.appointment.truck_license_plate_number) {
    editPlate.value = true
  }
})
const platedChanged = computed(() => {
  if (licensePlate.value === '') {
    return false
  }
  return licensePlate.value !== originalLicensePlate.value
})
function checkInAppointment() {
  const api = new AppointmentBookingApi()
  let plateToSet
  if (editPlate.value) {
    plateToSet = licensePlate.value
  }
  api
    .checkInAppointmentsBookingCheckInPost({
      appointment_id: props.appointment.id,
      license_plate: plateToSet,
    })
    .finally(() => {
      ElNotification.success({ title: 'Check in begun' })
      dialogOpen.value = false
    })
}
</script>

<template>
  <BaseDialog
    v-if="dialogOpen"
    v-model="dialogOpen"
    :title="`Check In Appointment for ${appointment.container_number}`"
    :width="400"
    @close="emit('close')"
  >
    <el-form class="check-in-appointment-dialog-form">
      <div class="mb-8">
        <el-form-item label="Terminal:">
          {{ appointment.terminal_label }}
        </el-form-item>
        <ExistingLicensePlateFormDisplay
          :license-plate="originalLicensePlate"
        />
      </div>
      <el-form-item label="Edit License Plate?">
        <el-checkbox v-model="editPlate" />
        <Help title="Edit Plate on Check In" class="text-gray-600 ml-1 mb-2px">
          Select this if you want to edit the license plate. Otherwise, we won't
          change it.
        </Help>
      </el-form-item>
      <!-- Edit license plate dropdown, select when open -->
      <el-form-item
        v-if="editPlate"
        label="License Plate:"
        data-testid="license-plate-form-item"
      >
        <LicensePlateSelector
          v-model="licensePlate"
          :terminal="appointment.terminal"
          :autofocus="true"
          :show-icon="false"
          class="w-full"
        />
      </el-form-item>
    </el-form>
    <!-- Give the user a hint/tip that they can offer any value, not just those in the dropdown -->
    <el-alert v-if="editPlate" type="info" show-icon :closable="false">
      You can enter any license plate number, not just those in the dropdown.
    </el-alert>
    <template #action-button>
      <el-button
        v-tooltip="{
          content: 'Selected a different license plate to update',
          disabled: platedChanged || !editPlate,
        }"
        size="large"
        type="primary"
        :disabled="licensePlateRequired && !licensePlate"
        @click="checkInAppointment"
      >
        Check In Appointment
      </el-button>
    </template>
  </BaseDialog>
</template>

<style lang="scss">
.check-in-appointment-dialog-for .el-form-item__label {
  font-weight: bold;
}
.check-in-appointment-dialog-for .el-form-item {
  margin-right: 15px;
}
</style>
