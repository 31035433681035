<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    fill?: string
    background?: string
  }>(),
  {
    fill: 'white',
    background: 'bg-blue-300',
  }
)
</script>

<template>
  <div
    class="rounded-full grid place-items-center mr-2 icon-size"
    :class="props.background"
  >
    <svg viewBox="0 0 512 512" class="w-5 h-5" :fill="props.fill">
      <path
        d="M480 128c0 8.188-3.125 16.38-9.375 22.62l-256 256C208.4 412.9 200.2 416 192 416s-16.38-3.125-22.62-9.375l-128-128C35.13 272.4 32 264.2 32 256c0-18.28 14.95-32 32-32c8.188 0 16.38 3.125 22.62 9.375L192 338.8l233.4-233.4C431.6 99.13 439.8 96 448 96C465.1 96 480 109.7 480 128z"
      />
    </svg>
  </div>
</template>

<style scoped>
.icon-size {
  width: 32px;
  height: 32px;
}
</style>
