import { ref } from 'vue'
import { ElNotification } from 'element-plus'
import type { NotificationHandle } from 'element-plus/lib/components/notification'
import type { UserModule } from '~/types'

const ONE_MINUTE = 60 * 1000

// https://github.com/antfu/vite-plugin-pwa#automatic-reload-when-new-content-available
export const install: UserModule = ({ isClient, router }) => {
  if (!isClient) return
  const notification = ref<NotificationHandle | null>(null)
  router
    .isReady()
    .then(async () => {
      const { registerSW } = await import('virtual:pwa-register')
      // Jitter so that all clients don't update at the same time. Trying to prevent
      // 503's due to a "thundering herd"
      const randomJitter = Math.floor(Math.random() * 10)
      const updateSW = registerSW({
        immediate: true,
        onRegistered(registration) {
          if (registration) {
            setInterval(() => {
              registration.update()
            }, ONE_MINUTE + randomJitter)
          }
        },
        onNeedRefresh() {
          if (!notification.value) {
            let countdown = 10
            const countdownMessage =
              'Click here to update Dray Dog. Auto-update will occur in 10 minutes.'
            const countdownInterval = setInterval(() => {
              if (countdown <= 0) {
                clearInterval(countdownInterval)
                // Trigger the update after the countdown reaches zero
                updateSW()
                if (notification.value) {
                  notification.value.close()
                  notification.value = null
                }
              }
              countdown -= 1 // Decrement the countdown
            }, ONE_MINUTE)

            notification.value = ElNotification({
              title: 'Update Available',
              message: countdownMessage,
              type: 'warning',
              duration: 0,
              onClick: () => {
                updateSW()
                if (notification.value) {
                  notification.value.close()
                  notification.value = null
                }
              },
              showClose: false,
              customClass: 'cursor-pointer',
            })
          }
        },
      })
    })
    // Not sure why this is needed, but it was added in:
    // https://github.com/antfu/vitesse/commit/b0323be19cbd921ec5e5de35c2d8476a7e28a6ef
    .catch(() => {})
}
