import { DateTime } from 'luxon'
import { AppointmentStatus, TransactionDirection } from '~/services/apiClient'
import { useAppointmentCountStoreForGraph } from '~/stores/appointmentCounts'

function formatUTCTime(date: DateTime) {
  return date.toUTC().toMillis()
}
export function useWeekAppointmentCounts({
  weekStart,
  time_bucket_minutes,
}: {
  weekStart: DateTime
  time_bucket_minutes: Ref<number>
}) {
  const time_bucket_hours = computed(() =>
    Math.ceil(time_bucket_minutes.value / 60)
  )

  const ACTIVE_APPOINTMENT_STATUSES = new Set<AppointmentStatus>([
    AppointmentStatus.Scheduled,
    AppointmentStatus.InProgress,
    AppointmentStatus.Completed,
  ])

  const appointmentCountsStore = useAppointmentCountStoreForGraph()
  const countsByTime = computed(() => {
    const weekEnd = weekStart.plus({ days: 7 })
    const countsByTime = new Map<number, number>()
    for (const entry of appointmentCountsStore.counts) {
      const bucketTime = DateTime.fromISO(entry.time_bucket_start)
      if (!ACTIVE_APPOINTMENT_STATUSES.has(entry.status)) {
        continue // Ignore cancelled, aborted etc. appointments
      }
      if (bucketTime < weekStart || bucketTime > weekEnd) {
        continue // Ignore appointments outside of the current week
      }
      const key = formatUTCTime(bucketTime)
      const existingCount = countsByTime.get(key) ?? 0
      countsByTime.set(key, existingCount + entry.count)
    }
    return countsByTime
  })

  const counts = computed(() => {
    return Array.from(countsByTime.value.entries()).map(([time, count]) => ({
      time,
      count,
    }))
  })

  const maxCount = computed(() => {
    return Math.max(...counts.value.map((c) => c.count))
  })

  const loading = ref(false)
  function load({
    customerId,
    excludeCustomerIDs,
    excludeTags,
    containerTag,
  }: {
    customerId: number | undefined
    excludeCustomerIDs: string | undefined
    excludeTags: string[] | undefined
    containerTag: string | undefined
  }) {
    loading.value = true
    appointmentCountsStore.load({
      time_bucket_hours: time_bucket_hours.value,
      after: weekStart,
      // 7 days + 1 day buffer. Not sure if the buffer is necessary, but just to be safe.
      // I filter out appointments outside of the current week anyway.
      before: weekStart.plus({ days: 8 }),
      direction: TransactionDirection.Outbound,
      loaded: true,
      customer_id: customerId || undefined,
      exclude_customer_ids: excludeCustomerIDs,
      excludeContainerTags: excludeTags,
      containerTags: containerTag ? [containerTag] : undefined,
    })
  }
  function getAppointmentCount(time: DateTime) {
    const key = formatUTCTime(time)
    return countsByTime.value.get(key) ?? 0
  }
  return {
    counts,
    load,
    loading: appointmentCountsStore.loading,
    getAppointmentCount,
    maxCount,
  }
}
