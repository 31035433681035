import type { DateTime } from 'luxon'
import type { EmptyInAppointmentSlot } from '~/models/appointmentSlot'
import type { AppointmentWithContainerInfo } from '~/models/appointmentWithContainerInfo'

export interface TimeSlot {
  window_start: DateTime
  window_end: DateTime | undefined
  slot: EmptyInAppointmentSlot | undefined
  existingSingleEmptyAppointments: AppointmentWithContainerInfo[]
  dualOpportunities: AppointmentWithContainerInfo[]
  existingDualEmptyAppointments: AppointmentWithContainerInfo[]
}

// Refer to ReceivingRule
export enum EIconType {
  Closed = 'closed',
  Open = 'open',
  Exempt = 'exempt',
  DualsOnly = 'duals_only',
  Error = 'null',
}

export type IconType = 'open' | 'closed' | 'duals_only' | 'exempt'
