<script setup lang="ts"></script>

<template>
  <div>
    <h1>404</h1>
    <p>Page not found</p>
  </div>
</template>

<route lang="yaml">
meta:
  layout: 404
</route>
