import { defineStore } from 'pinia'
import { getAppointmentSystemConnectionTypes } from '~/constants'
import type {
  ConnectionType,
  GETConnection,
  POSTConnection,
  TerminalName,
} from '~/services/apiClient'
import {
  ConnectionValidationStatus,
  ConnectionsApi,
} from '~/services/apiClient'

export const useConnectionsStore = defineStore('connections', () => {
  const loadingPromise = ref<Promise<void> | null>(null)
  const connections = ref<GETConnection[]>([])
  const connectionLookup = ref(new Map<ConnectionType, GETConnection>())
  const loadedFromAPI = ref(false)

  const loading = computed(() => loadingPromise.value !== null)
  const invalidConnections = computed(() => {
    return connections.value
      .filter((c) => c.validation_status === ConnectionValidationStatus.Invalid)
      .map((c) => c.type.toUpperCase().replace('_', ' '))
      .join(', ')
  })

  function load(): Promise<void> {
    const api = new ConnectionsApi()
    loadingPromise.value = api
      .listConnectionsConnectionsGet()
      .then((resp) => {
        connections.value = resp.data
        resp.data.forEach((c) => {
          connectionLookup.value.set(c.type, c)
        })
      })
      .finally(() => {
        loadingPromise.value = null
        loadedFromAPI.value = true
      })
    return loadingPromise.value
  }

  function loadConnectionsIfNotLoaded(): Promise<void> {
    if (loadedFromAPI.value) {
      // Already loaded case
      return Promise.resolve()
    } else if (loadingPromise.value) {
      // Currently loading case
      return loadingPromise.value
    } else {
      // Not yet loaded case
      return load()
    }
  }

  function save(connections: POSTConnection[]) {
    const api = new ConnectionsApi()
    return api.saveConnectionsConnectionsPost(connections).then((resp) => {
      _updateConnections(resp.data)
      return resp
    })
  }

  function saveAndValidate(connections: POSTConnection[]) {
    const api = new ConnectionsApi()
    return api
      .saveAndValidateConnectionsConnectionsSaveAndValidatePost(connections)
      .then((resp) => {
        _updateConnections(resp.data)
        return resp
      })
  }

  function update(connection: GETConnection) {
    const api = new ConnectionsApi()
    return api.updateConnectionConnectionsPut(connection)
  }

  function _updateConnections(newConnections: GETConnection[]) {
    for (const connection of newConnections) {
      connectionLookup.value.set(connection.type, connection)
    }
    connections.value = [...connectionLookup.value.values()]
    loadedFromAPI.value = true
  }
  async function isAppointmentSystemConnectionSetup(
    terminal: TerminalName
  ): Promise<boolean> {
    const suitableConnectionTypes =
      getAppointmentSystemConnectionTypes(terminal)
    // These would be terminals without an appointment system
    if (suitableConnectionTypes === null) return false
    await loadConnectionsIfNotLoaded()
    for (const suitableConnectionType of suitableConnectionTypes) {
      const connection = connectionLookup.value.get(suitableConnectionType)
      if (
        connection &&
        connection.validation_status === ConnectionValidationStatus.Validated
      ) {
        return true
      }
    }
    return false
  }

  return {
    loading,
    connections,
    connectionLookup,
    loadedFromAPI,
    invalidConnections,
    load,
    loadConnectionsIfNotLoaded,
    save,
    saveAndValidate,
    update,
    isAppointmentSystemConnectionSetup,
  }
})
