<script lang="ts" setup>
import { ElTag } from 'element-plus'

const props = withDefaults(
  defineProps<{
    type?: 'success' | 'warning' | 'danger' | 'info'
    size?: 'small' | 'default' | 'large'
    effect?: 'dark' | 'light' | 'plain'
    round?: boolean
  }>(),
  {
    type: 'success',
    size: 'small',
    effect: 'light',
    round: false,
  }
)
</script>

<template>
  <ElTag
    :type="props.type"
    :size="props.size"
    :effect="effect"
    disable-transitions
    :round="props.round"
  >
    <slot />
  </ElTag>
</template>
