<script lang="ts" setup>
import { ElMessage, ElNotification } from 'element-plus'
import type { AppointmentSlot } from '~/models/appointmentSlot'
import { AppointmentBookingApi } from '~/services/apiClient/api'
import BookingForm from '~/components/inputs/BookingForm.vue'

import type { Appointment } from '~/models/appointments'
import type { Container } from '~/models/containers'
import type { RemainingCapacityPeriodInfo } from '~/stores/remainingCapacities'
import { useRemainingCapacitiesStore } from '~/stores/remainingCapacities'
const props = defineProps<{
  appointmentSlot: AppointmentSlot
  existingAppointment: Appointment | null
  container: Container
}>()

const emit = defineEmits<{ (e: 'close'): void }>()
const slot = toRef(props, 'appointmentSlot')
const remainingCapacitiesStore = useRemainingCapacitiesStore()
const bucketsWithRemainingCapacity = computed(
  (): RemainingCapacityPeriodInfo[] => {
    if (!remainingCapacitiesStore.loaded) return []
    return remainingCapacitiesStore.getRemainingCapacities(
      slot.value.window_start
    )
  }
)
const remainingCapacity = computed((): number | null => {
  if (!remainingCapacitiesStore.loaded) return null
  return remainingCapacitiesStore.getRemainingCapacityForTime(
    slot.value.window_start
  )
})
const dialogOpen = ref(true)
type BookingFormType = InstanceType<typeof BookingForm>
const bookingFormRef = ref<BookingFormType>()

const submitForm = async (formEl: BookingFormType | undefined) => {
  if (!formEl) return
  formEl.validate((valid: boolean) => {
    if (valid) {
      const form = formEl.getValues()
      if (props.existingAppointment) {
        let errorMsg = null as null | string
        if (
          props.existingAppointment.terminal !== props.appointmentSlot.terminal
        ) {
          errorMsg =
            'Existing appointment terminal does not match appointment slot terminal'
        } else if (
          props.existingAppointment.container_number !==
          props.appointmentSlot.container_number
        ) {
          errorMsg = `Appointment slot container number does not match existing appointment`
        }
        if (errorMsg) {
          ElMessage.warning(errorMsg)
          throw new Error(errorMsg)
        }
      }
      const bookingAPI = new AppointmentBookingApi()
      bookingAPI
        .bookAppointmentAppointmentsBookingBookPost({
          slot: {
            window_start: slot.value.window_start.toUTC().toISO(),
            terminal: slot.value.terminal,
            container_number: props.container.number,
            from_terminal_block_availability_key:
              slot.value.from_terminal_block_availability_key,
          },
          master_bill_of_lading: form.masterBL,
          truck_license_plate: form.licensePlate,
          existing_appointment_id_to_cancel: props.existingAppointment?.id,
          own_chassis: form.ownChassis,
        })
        .then((resp) => {
          // Is there a better way to check for error?
          if (resp) {
            let title
            if (props.existingAppointment) {
              title = 'Appointment Reschedule begun'
            } else {
              title = 'Appointment Booking begun'
            }
            ElNotification({
              title,
              message: 'You will get a notification on success or failure',
              type: 'success',
            })
          }
          closeDialog()
        })
    }
  })
}
function closeDialog() {
  dialogOpen.value = false
  emit('close')
}
const title = computed(() => {
  if (props.existingAppointment) {
    return 'Reschedule Appointment'
  } else {
    return 'Book Appointment'
  }
})
const bookButtonType = computed((): 'success' | 'warning' | 'danger' => {
  if (props.existingAppointment) {
    return 'warning'
  } else if (remainingCapacity.value !== null && remainingCapacity.value <= 0) {
    return 'danger'
  } else {
    return 'success'
  }
})
</script>

<template>
  <el-dialog
    :model-value="dialogOpen"
    :title="title"
    width="30%"
    center
    :append-to-body="true"
    @close="emit('close')"
  >
    <div>
      <BookingForm
        ref="bookingFormRef"
        :container="props.container"
        :appointment-slot="slot"
        :inline="false"
        size="default"
      />
      <el-alert
        v-if="props.container.import_status?.available_for_pickup === false"
        :closable="false"
        type="warning"
        show-icon
        class="shadow mt-4"
        style="margin-bottom: 8px"
        title="Container not ready for pickup"
      >
        You can book an still book an appointment, but something else must
        happen prior to pickup (e.g. clear hold)
      </el-alert>
      <CapacityAlert
        v-for="bucketWithCapacity in bucketsWithRemainingCapacity"
        :key="bucketWithCapacity.set.key"
        :remaining-capacity="bucketWithCapacity"
      />
    </div>
    <template #footer>
      <div class="flex">
        <el-button
          size="large"
          class="flex-1 fs-close-booking-dialog"
          @click="closeDialog"
        >
          Cancel
        </el-button>
        <el-button
          :type="bookButtonType"
          size="large"
          class="flex-1"
          :class="{
            'fs-book-appointment': !props.existingAppointment,
            'fs-reschedule-appointment': props.existingAppointment,
          }"
          @click="submitForm(bookingFormRef)"
        >
          <template v-if="props.existingAppointment">
            Reschedule Appointment
          </template>
          <template
            v-else-if="remainingCapacity !== null && remainingCapacity <= 0"
          >
            Book Anyway
          </template>
          <template v-else> Book </template>
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>
