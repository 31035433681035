<script lang="ts" setup>
import { useUsersStore } from '~/stores/users'
const usersStore = useUsersStore()

onMounted(usersStore.load)
const inviteUsersDialogOpen = ref(false)
</script>

<template>
  <el-button type="primary" class="mb-2" @click="inviteUsersDialogOpen = true">
    Invite Users
  </el-button>
  <UsersTable
    v-loading="usersStore.loading"
    :users="usersStore.nonInternalUsers"
  />
  <InviteUsersDialog v-model="inviteUsersDialogOpen" />
</template>
