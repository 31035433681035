<script lang="ts" setup>
import { UserRoleType } from '~/services/apiClient'
import { NORMAL_USER_ROLES } from '~/constants'

const props = withDefaults(
  defineProps<{
    modelValue?: UserRoleType
    disabled?: boolean
    required: boolean
    allowManager: boolean
    placeholder?: string
  }>(),
  {
    disabled: false,
    placeholder: 'Select Role',
  }
)

const emit = defineEmits<{
  (e: 'update:modelValue', value: UserRoleType): void
}>()

const roleOptions = computed(() => {
  const roles = props.allowManager
    ? NORMAL_USER_ROLES
    : NORMAL_USER_ROLES.filter((role) => role !== UserRoleType.Management)
  return roles.map((role) => ({ label: role }))
})
</script>

<template>
  <el-select
    :model-value="modelValue"
    :placeholder="placeholder"
    class="w-full"
    :disabled="disabled"
    :required="required"
    @update:model-value="emit('update:modelValue', $event)"
  >
    <el-option
      v-for="roleOption in roleOptions"
      :key="roleOption.label"
      :label="roleOption.label"
      :value="roleOption.label"
    />
  </el-select>
</template>
