import type { DateTime } from 'luxon'
import type { Bucket } from '~/logic/appointmentBucketing'
import { bucketAppointmentsForRange } from '~/logic/appointmentBucketing'
import type { AppointmentSlot } from '~/models/appointmentSlot'
import type { Shift } from '~/services/apiClient'

const BUCKETS = [0, 3, 7, 10, 13, 15, 18, 21]

export class ShiftBucket {
  shift: Shift
  shiftDate: DateTime
  buckets: Bucket[]
  key: string
  numSlotsAvailable: number
  constructor(shift: Shift, shiftDate: DateTime) {
    this.shift = shift
    this.shiftDate = shiftDate
    this.buckets = []
    this.key = makeShiftKey(shiftDate, shift)
    this.numSlotsAvailable = 0
  }

  addBucket(bucket: Bucket) {
    this.buckets.push(bucket)
    this.numSlotsAvailable += bucket.numSlots
  }
}

export function groupSlotsIntoShiftBuckets(
  slots: AppointmentSlot[],
  start: DateTime,
  end: DateTime
): Map<string, ShiftBucket> {
  const buckets = bucketAppointmentsForRange(slots, start, end, BUCKETS)
  const shiftBucketsByKey: Map<string, ShiftBucket> = new Map()
  for (const bucket of buckets) {
    const key = makeShiftKey(bucket.shiftDate, bucket.shift)
    let shiftBucket = shiftBucketsByKey.get(key)
    if (!shiftBucket) {
      shiftBucket = new ShiftBucket(bucket.shift, bucket.shiftDate)
      shiftBucketsByKey.set(key, shiftBucket)
    }
    shiftBucket.addBucket(bucket)
  }
  return shiftBucketsByKey
}

export function makeShiftKey(shiftDate: DateTime, shift: Shift): string {
  return `${shiftDate.toFormat('yyyy-MM-dd')}-${shift}`
}
