import type { GETShippingLine, ShippingLine } from '~/services/apiClient'
import { useShippingLinesStore } from '~/stores/shippingLines'

export function useShippingLine() {
  const shippingLineStore = useShippingLinesStore()
  onMounted(shippingLineStore.loadShippingLinesCached)
  function getShippingLine(
    shippingLine: ShippingLine | undefined
  ): GETShippingLine | undefined {
    if (!shippingLine) return undefined
    return shippingLineStore.shipping_line_lookup.get(shippingLine)
  }
  return { getShippingLine }
}
