import { Crisp } from 'crisp-sdk-web'
import type { UserInfo } from '~/models/userInfo'
import { getOpenReplaySessionURL } from '~/observability/openReplay'

// Crisp.session.setSegments(['bug', 'ios'], false)

// Crisp.session.setData({
//   user_id: '123456',
//   plan: 'free',
// })

// // Or with custom parameters
// Crisp.session.pushEvent('purchase', {
//   price: 66.66,
//   item: 'XXXX_XXXX',
// })

// Crisp.trigger.run('growth_hack') // will run the `growth_hack` trigger.
// Crisp.session.setData({
//   user_id: '123456',
//   plan: 'free',
// })

export function configureUserWithCrispChat(userInfo: UserInfo) {
  const openReplayURL = getOpenReplaySessionURL()
  Crisp.user.setEmail(userInfo.email)
  Crisp.user.setNickname(userInfo.full_name)
  const userRole = userInfo.current_organization.role
  Crisp.user.setCompany(userInfo.current_organization.name, {
    description: userInfo.current_organization.description,
    employment: userRole ? [userRole] : [],
  })
  const userData: any = {
    SCAC: userInfo.current_organization.scac,
  }
  if (openReplayURL) {
    setOpenReplaySessionURLForCrispChat(openReplayURL)
  }
  Crisp.session.setData(userData)
}
export function setOpenReplaySessionURLForCrispChat(url: string) {
  Crisp.session.setData({
    'Open Replay URL': url,
  })
}

export function clearChatData() {
  Crisp.session.reset()
}
