<script lang="ts" setup>
import { ConnectionType } from '~/services/apiClient'
import { useConnectionsStore } from '~/stores/connections'

const connectionsStore = useConnectionsStore()
onMounted(connectionsStore.load)

const triniumConnection = computed(() => {
  return connectionsStore.connectionLookup.get(ConnectionType.TriniumSftp)
})
const tranDataConnection = computed(() => {
  return connectionsStore.connectionLookup.get(ConnectionType.TranDataSftp)
})
const portProAPIConnection = computed(() => {
  return connectionsStore.connectionLookup.get(ConnectionType.PortProApi)
})
const profitToolsCoreAPIConnection = computed(() => {
  return connectionsStore.connectionLookup.get(
    ConnectionType.ProfitToolsCoreApi
  )
})
const compcareCoreAPIConnection = computed(() => {
  return connectionsStore.connectionLookup.get(ConnectionType.CompcareCoreApi)
})
</script>

<template>
  <div
    v-if="
      !triniumConnection &&
      !tranDataConnection &&
      !portProAPIConnection &&
      !profitToolsCoreAPIConnection &&
      !compcareCoreAPIConnection
    "
    class="mb-4 max-w-800px"
  >
    <el-alert title="Directions" type="info" show-icon :closable="false">
      Contact us to set up an integration with your TMS.
    </el-alert>
  </div>
  <div v-loading="connectionsStore.loading">
    <TriniumSFTPConnection
      v-if="triniumConnection"
      :connection="triniumConnection"
    />
    <TranDataSFTPConnection
      v-if="tranDataConnection"
      :connection="tranDataConnection"
    />
    <PortProAPIConnection
      v-if="portProAPIConnection"
      :connection="portProAPIConnection"
    />
    <ProfitToolsCoreAPIConnection
      v-if="profitToolsCoreAPIConnection"
      :connection="profitToolsCoreAPIConnection"
    />
    <CompcareCoreAPIConnection
      v-if="compcareCoreAPIConnection"
      :connection="compcareCoreAPIConnection"
    />
  </div>
</template>
