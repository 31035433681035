import { DateTime } from 'luxon'
import { acceptHMRUpdate, defineStore } from 'pinia'

import { ContainerType, ContainersApi } from '~/services/apiClient'

const MOST_COMMON_TYPES: ContainerType[] = [
  ContainerType._40Hc,
  ContainerType._40Sd,
  ContainerType._20Sd,
  ContainerType._45Hc,
]
export interface ContainerTypeInfo {
  type: ContainerType
  description: string
}

const CACHE_TIME_MINUTES = 60

export const useContainerTypesStore = defineStore('containerTypes', () => {
  const container_types = useLocalStorage(
    'container-types',
    [] as ContainerTypeInfo[]
  )
  const container_types_lookup = computed(() => {
    return new Map(container_types.value.map((ct) => [ct.type, ct.description]))
  })
  const containerTypesLastLoaded = useLocalStorage(
    'container-types-last-loaded',
    undefined as DateTime | undefined
  )
  const loading = ref(false)
  function loadContainerTypes() {
    let lastLoaded: DateTime | undefined
    if (
      containerTypesLastLoaded.value &&
      typeof containerTypesLastLoaded.value === 'string'
    ) {
      lastLoaded = DateTime.fromISO(containerTypesLastLoaded.value)
    } else {
      lastLoaded = containerTypesLastLoaded.value
    }
    if (
      container_types.value.length === 0 ||
      !lastLoaded ||
      lastLoaded.plus({ minutes: CACHE_TIME_MINUTES }) < DateTime.now()
    ) {
      _loadContainerTypes()
    }
  }
  function _loadContainerTypes() {
    loading.value = true
    const api = new ContainersApi()
    api.getContainerTypesContainersTypesGet().then((resp) => {
      container_types.value = resp.data.map((row) => {
        return {
          type: row.type,
          description: row.type
            .replace('SD', 'Standard')
            .replace('HC', 'High Cube'),
        }
      })
      // Sort with most common types first
      container_types.value.sort((a, b) => {
        const aIndex = MOST_COMMON_TYPES.indexOf(a.type)
        const bIndex = MOST_COMMON_TYPES.indexOf(b.type)
        if (aIndex === -1 && bIndex === -1) {
          return 0
        } else if (aIndex === -1) {
          return 1
        } else if (bIndex === -1) {
          return -1
        } else {
          return aIndex - bIndex
        }
      })
      loading.value = false
      containerTypesLastLoaded.value = DateTime.now()
    })
  }

  function getContainerTypeDescription(type: ContainerType): string {
    const ct = container_types_lookup.value.get(type)
    if (ct) {
      return ct
    }
    return type
  }
  return {
    container_types,
    loading,
    loadContainerTypes,
    getContainerTypeDescription,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useContainerTypesStore, import.meta.hot)
  )
}
