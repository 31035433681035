<script lang="ts" setup>
import type { AppointmentTransaction } from '~/models/groupedAppointments'
import CheckInAppointmentButton from '~/components/container_row/CheckInAppointmentButton.vue'

const props = defineProps<{
  transaction: AppointmentTransaction | null
}>()

// Extract the appointment from the transaction
const appointment = computed(
  () =>
    props.transaction?.inbound_appointment?.appointment ||
    props.transaction?.outbound_appointment?.appointment
)
</script>

<template>
  <CheckInAppointmentButton v-if="appointment" :appointment="appointment" />
</template>
