<script lang="ts" setup>
const props = defineProps({
  loading: {
    type: Boolean,
    required: true,
  },
})
</script>

<template>
  <el-button
    v-if="!props.loading"
    type="primary"
    plain
    size="small"
    class="fs-refresh-import-status-button"
  >
    <i-mdi:refresh class="mr-1" />Refresh
  </el-button>
  <el-button
    v-else
    :loading="props.loading"
    type="danger"
    plain
    size="small"
    class="fs-refresh-import-status-button"
  >
    Refreshing...
  </el-button>
</template>
