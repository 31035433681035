import * as Sentry from '@sentry/vue'
import 'virtual:vite-plugin-sentry/sentry-config'

import type { UserModule } from '~/types'
import {
  getOpenReplaySessionURL,
  openReplaySessionURLKeyName,
} from '~/observability/openReplay'

const dist = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.dist
const release = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.release

export const install: UserModule = ({ router, app }) => {
  if (process.env.NODE_ENV === 'development') {
    return
  }
  // https://docs.sentry.io/platforms/javascript/guides/vue/
  Sentry.init({
    app,
    dist,
    release,
    dsn: 'https://671d76b9dcd8443794e771edbc8c2881@o946257.ingest.sentry.io/6274045',
    replaysSessionSampleRate: 0.0,
    enableTracing: true,
    tracesSampleRate: 0.2,
    attachStacktrace: true,
    logErrors: true,
    // See:
    // https://docs.sentry.io/platforms/javascript/guides/vue/features/component-tracking/#trackcomponents
    // I'm not enabling this because I think it might destroy performance, but it might
    // be interesting to try. Maybe activate only on internal users to start
    trackComponents: false,
    denyUrls: [
      /hubspot\.com/i,
      /sentry\.io/i,
      /openreplay\.com/i,
      /crisp\.chat/i,
    ],
    tracePropagationTargets: [/.*api\.draydog\.com.*/],
    integrations: [
      Sentry.browserTracingIntegration({
        router,
      }),
      Sentry.extraErrorDataIntegration(),
      Sentry.reportingObserverIntegration(),
    ],
    beforeSend(event) {
      if (event.exception?.values) {
        for (const exception of event.exception.values) {
          if (
            exception.type === 'CanceledError' &&
            exception.value === 'canceled'
          ) {
            // These are raised by API requests we cancelled ourselves, so there is no
            // reason to send them to Sentry. IDK if there's a better way to do this.
            return null
          }
          if (exception.value === 'ResizeObserver loop limit exceeded') {
            // Not an issue per:
            // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
            return null
          }
        }
      }
      // Setting this here so that it will have an appropriate "jumpto" parameter
      const openReplaySessionURL = getOpenReplaySessionURL()
      if (openReplaySessionURL) {
        event.tags = event.tags || {}
        event.tags[openReplaySessionURLKeyName] = openReplaySessionURL
      }
      return event
    },
  })
}
