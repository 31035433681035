<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    fill?: string
    background?: string
  }>(),
  {
    fill: 'white',
    background: 'bg-orange-300',
  }
)
</script>

<template>
  <svg
    class="rounded-full mr-2"
    :class="[props.background]"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M17.8238 8.51074L20.5664 11.3818C20.7305 11.5404 20.8125 11.7564 20.8125 11.9943C20.8125 12.2322 20.7305 12.4455 20.5664 12.6068L17.8238 15.4889C17.4984 15.8307 16.968 15.8307 16.6426 15.4889C16.3172 15.1471 16.3172 14.5947 16.6426 14.2529L17.9605 12.8748H12.027C11.5621 12.8748 11.1875 12.4838 11.1875 11.9998C11.1875 11.5158 11.5621 11.1248 12.0242 11.1248H17.9578L16.6398 9.74668C16.3145 9.40488 16.3145 8.85254 16.6398 8.51074C16.968 8.16895 17.4957 8.16895 17.8238 8.51074Z"
      :fill="props.fill"
    />
    <path
      d="M13.1762 24.4893L10.4336 21.6182C10.2695 21.4596 10.1875 21.2436 10.1875 21.0057C10.1875 20.7678 10.2695 20.5545 10.4336 20.3932L13.1762 17.5111C13.5016 17.1693 14.032 17.1693 14.3574 17.5111C14.6828 17.8529 14.6828 18.4053 14.3574 18.7471L13.0395 20.1252L18.973 20.1252C19.4379 20.1252 19.8125 20.5162 19.8125 21.0002C19.8125 21.4842 19.4379 21.8752 18.9758 21.8752L13.0422 21.8752L14.3602 23.2533C14.6855 23.5951 14.6855 24.1475 14.3602 24.4893C14.032 24.8311 13.5043 24.8311 13.1762 24.4893Z"
      :fill="props.fill"
    />
  </svg>
</template>

<style scoped></style>
