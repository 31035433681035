<script lang="ts" setup>
import type { PropType } from 'vue'
import type { Container } from '~/models/containers'

const props = defineProps({
  container: { type: Object as PropType<Container>, required: true },
})
</script>

<template>
  <DetailHeader>
    <NeedsAppointmentIcon class="align-middle" />
    Booking
    <CancelAppointmentButton
      v-if="container.latest_appointment?.status === 'scheduled'"
      :appointment="container.latest_appointment"
      class="ml-2 mb-2px"
      size="small"
    />

    <el-popover
      v-if="
        props.container.has_active_appointment &&
        props.container.import_booking_applies
      "
      :width="190"
      trigger="click"
      class="break-normal"
    >
      <template #reference>
        <el-button type="primary" size="small" plain class="ml-3">
          <i-mdi:calendar-arrow-right class="mr-1" />
          Reschedule
        </el-button>
      </template>
      <span class="break-normal"> To reschedule, select a new time below </span>
    </el-popover>
    <AutoBookButton
      v-if="props.container.import_booking_applies"
      :containers="[container]"
      size="small"
      class="ml-3"
    />
  </DetailHeader>
  <ImportAppointmentSlots
    v-if="container.import_booking_applies"
    :container="container"
  />
</template>
