<script setup lang="ts">
useHead({
  title: 'Dray Dog - Accept Invite',
})

const router = useRouter()
const route = useRoute()

router.push({ name: 'sign_up', query: route.query })
</script>

<template>
  <OnboardingCard> Redirecting...</OnboardingCard>
</template>

<route lang="yaml">
meta:
  layout: no_sidebar
name: accept_invite
</route>
