<script lang="ts" setup>
import type {
  GETConnection,
  ProfitToolsCoreAPIConfig,
} from '~/services/apiClient'

const props = defineProps<{
  connection: GETConnection
}>()
const conn = toReactive(props.connection)
const tmsName = 'Profit Tools'
const documentationLink =
  'https://help.draydog.com/en/article/profit-tools-integration-5s16i6/'
const config = reactive(conn.config as ProfitToolsCoreAPIConfig)
const formRules = undefined
</script>

<template>
  <IntegrationConnection
    :connection="props.connection"
    :tms-name="tmsName"
    :documentation-link="documentationLink"
    :form-rules="formRules"
  >
    <BaseCoreAPIConnection
      :config="config"
      :documentation-link="documentationLink"
      :tms-name="tmsName"
  /></IntegrationConnection>
</template>

<style scoped lang="scss">
.el-input {
  max-width: 165px !important;
}
</style>
