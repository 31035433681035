<script lang="ts" setup>
import type { FormRules } from 'element-plus'
import { ElNotification } from 'element-plus'
import { CAPACITY_SET_DIALOG_MAX_WIDTH } from './constants'
import { useCapacitiesStore } from '~/stores/capacities'
import { useCustomerStore } from '~/stores/customers'
import type { SavedCustomer } from '~/services/apiClient'
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
})
const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
  (e: 'close'): void
}>()
const dialogOpen = useVModel(props, 'modelValue', emit)
const customerStore = useCustomerStore()
const capacitiesStore = useCapacitiesStore()
const loading = ref(false)
const form = reactive({
  customer: undefined as SavedCustomer | undefined,
  tag: undefined as unknown as string | undefined,
  dedicated: false,
})
const rules = reactive<FormRules>({
  customer: [{ required: true, message: 'Please Select a Customer' }],
})

const customerOptions = computed(() => {
  const options: { name: string; id: number }[] = []
  const keys = Array.from(capacitiesStore.customerSets.keys())
  for (const customer of customerStore.customers) {
    if (keys.includes(customer.id)) {
      continue
    } else {
      options.push({ name: customer.name, id: customer.id })
    }
  }
  return options
})
function submitForm() {
  if (!form.customer) {
    return
  }
  const customer = form.customer
  const customerName = customerOptions.value.find(
    (c) => c.id === customer.id
  )?.name
  capacitiesStore.regenerateBuckets(
    120,
    { customer, containerTag: null },
    form.dedicated
  )
  ElNotification.success({ title: 'Capacities Generated for ' + customerName })
  dialogOpen.value = false
}
onMounted(customerStore.loadIfNotLoaded)
</script>

<template>
  <BaseDialog
    v-if="dialogOpen"
    v-model="dialogOpen"
    title="Add Customer Capacity Set"
    :max-width="CAPACITY_SET_DIALOG_MAX_WIDTH"
    @close="emit('close')"
  >
    <el-form
      ref="ruleFormRef"
      :model="form"
      :rules="rules"
      label-position="top"
    >
      <el-form-item label="Customer:" prop="customer">
        <el-select
          v-model="form.customer"
          placeholder="Select a Customer"
          style="margin-left: 0px"
          :loading="loading"
          clearable
          value-key="id"
        >
          <el-option
            v-for="customer in customerOptions"
            :key="customer.id"
            :label="customer.name"
            :value="customer"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Capacity type:" prop="dedicated">
        <el-switch
          v-model="form.dedicated"
          active-text="Dedicated"
          inactive-text="Receiving"
        />
      </el-form-item>
    </el-form>
    <el-alert title="" type="info" effect="dark" show-icon>
      <p>
        A customer capacity set limits the number of appointments booked for
        containers assigned to a specific customer. This is useful if you have a
        customer who can only accept loads at specific times or only so many
        loads per day.
      </p>
      <br />
      <p>
        "Dedicated" customer capacity sets are
        <strong>separate from</strong> Fleet Capacity, while "Receiving"
        customer capacity sets are <strong>in addition to</strong> Fleet
        Capacity.
      </p>
      <br />
      <p>
        Appointments made for customers with Dedicated capacity sets
        <em>do not</em> "use" your Fleet Capacity while appointments made for
        customers with Receiving capacity sets (or no capacity definitions at
        all) <em>do</em> "use" your Fleet Capacity.
      </p>
    </el-alert>
    <template #action-button>
      <el-button
        size="large"
        type="success"
        :disabled="!form.customer"
        @click="submitForm()"
      >
        Add Customer Capacity
      </el-button>
    </template>
  </BaseDialog>
</template>

<style scoped>
.el-button--text {
  margin-right: 15px;
}
.el-select {
  width: 300px;
  margin-left: auto;
}
.el-input {
  width: 300px;
  margin-left: auto;
}
.dialog-footer button:first-child {
  margin-right: 10px;
}
</style>
