import { DateTime } from 'luxon'
import { defineStore } from 'pinia'
import type { GETTimeBucketAppointmentStatusCount } from '~/services/apiClient'
import type { LoadAppointmentCountsParams } from '~/services/apiHelpers'
import { loadAppointmentCounts } from '~/services/apiHelpers'

function makeAppointmentStoreFactory(key: string) {
  return defineStore(key, () => {
    // State
    const counts = ref<GETTimeBucketAppointmentStatusCount[]>([])
    const loading = ref(false)
    const countByBucketStartTime = ref(new Map<string, number>())
    const time_bucket_hours = ref(1) // Just initial value, gets set on load

    // Actions
    async function load(params: LoadAppointmentCountsParams) {
      time_bucket_hours.value = params.time_bucket_hours
      counts.value = []
      loading.value = true

      try {
        counts.value = await loadAppointmentCounts(params)
        countByBucketStartTime.value = makeScheduledCountLookup(counts.value)
      } finally {
        loading.value = false
      }
    }
    function getCountForRange(start: DateTime, end: DateTime): number {
      let time = start.startOf('hour')
      let count = 0
      while (time < end) {
        const key = time.toUTC().toLocaleString(DateTime.DATETIME_SHORT)
        const bucketCount = countByBucketStartTime.value.get(key) ?? 0
        count += bucketCount
        time = time.plus({ hours: time_bucket_hours.value })
      }
      return count
    }

    return {
      counts,
      loading,
      countByBucketStartTime,
      time_bucket_hours,
      load,
      getCountForRange,
    }
  })
}

export const useAppointmentCountStoreForAvailability =
  makeAppointmentStoreFactory('appointment-counts-availability')

export const useAppointmentCountStoreForGraph = makeAppointmentStoreFactory(
  'appointment-counts-graph'
)

export function makeTimeKey(time: DateTime) {
  return time.toUTC().toLocaleString(DateTime.DATETIME_SHORT)
}

export function makeScheduledCountLookup(
  counts: GETTimeBucketAppointmentStatusCount[]
) {
  return counts.reduce((acc, curr) => {
    if (curr.status === 'scheduled') {
      const start = DateTime.fromISO(curr.time_bucket_start)
      const key = makeTimeKey(start)
      acc.set(key, curr.count)
    }
    return acc
  }, new Map())
}
