<script lang="ts" setup>
import type { PropType } from 'vue'
import { ElNotification } from 'element-plus'
import { ContainersApi } from '~/services/apiClient'
import type { ImportStatus } from '~/models/importStatus'

const props = defineProps({
  importStatus: {
    type: Object as PropType<ImportStatus>,
    required: true,
  },
})
const loading = ref(false)
const lastObserved = toRef(props.importStatus, 'last_observed')
const loadTimeout = ref<NodeJS.Timeout | undefined>()
function clearLoadTimeout() {
  if (loadTimeout.value) {
    clearTimeout(loadTimeout.value)
    loading.value = false
  }
}
watch(lastObserved, clearLoadTimeout)
onBeforeUnmount(clearLoadTimeout)

function refreshImportStatus(importStatus: ImportStatus) {
  loading.value = true
  loadTimeout.value = setTimeout(() => {
    ElNotification.warning({
      title:
        'Failed to refresh import status for ' + importStatus.container_number,
      message:
        'Refresh import status took too long on ' +
        importStatus.source +
        '. Try again in a few minutes.',
    })
    loading.value = false
  }, 45000)
  const api = new ContainersApi()
  api.manualRefreshImportStatusesContainersImportStatusesRefreshPost([
    {
      container_number: importStatus.container_number,
      terminal: importStatus.terminal,
      portal: importStatus.source,
    },
  ])
}
</script>

<template>
  <RefreshButton
    :loading="loading"
    class="fs-refresh-import-status-button"
    @click="refreshImportStatus(props.importStatus)"
  />
</template>
