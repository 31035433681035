import { DateTime } from 'luxon'
import { defineStore } from 'pinia'
import { useReturnRulesHistory } from './returnRuleReadingsHistory'
import { ReturnRulesReading } from '~/models/returnRules'
import type {
  EmptyReturnRulesCheckedEvent,
  EmptyReturnRulesCheckedNotification,
  GETEmptyReturnRulesReading,
  TerminalName,
} from '~/services/apiClient'
import { EmptyReturnRulesApi } from '~/services/apiClient'

export const useReturnRulesReadingsLatest = defineStore(
  'empty-return-rules-readings-latest',
  {
    state: () => {
      return {
        readings_by_terminal: new Map() as Map<
          TerminalName,
          ReturnRulesReading
        >,
        loading: false,
      }
    },
    actions: {
      load() {
        this.readings_by_terminal = new Map()
        this.loading = true
        const api = new EmptyReturnRulesApi()
        return api
          .getLatestReadingsEmptyReturnRulesReadingsLatestGet()
          .then((resp) =>
            resp.data.forEach((reading) => this.setReading(reading))
          )
          .finally(() => {
            this.loading = false
          })
      },
      setReading(reading: GETEmptyReturnRulesReading) {
        const mappedReading = new ReturnRulesReading(reading)
        this.readings_by_terminal.set(mappedReading.terminal, mappedReading)
        const emptyReturnRulesHistory = useReturnRulesHistory()
        if (
          emptyReturnRulesHistory.readings.length > 0 &&
          mappedReading.terminal ===
            emptyReturnRulesHistory.readings[0].terminal &&
          mappedReading.first_observed >
            emptyReturnRulesHistory.readings[0].first_observed
        ) {
          emptyReturnRulesHistory.readings.unshift(mappedReading)
        }
      },
      updateObservedTimes(notification: EmptyReturnRulesCheckedNotification) {
        const emptyReturnRulesHistory = useReturnRulesHistory()
        for (const check of notification.checks) {
          const lastObserved = DateTime.fromISO(check.last_observed)
          const firstObserved = DateTime.fromISO(check.first_observed)
          const existingReading = this.readings_by_terminal.get(check.terminal)
          if (existingReading) {
            existingReading.last_observed = lastObserved
          }
          if (
            emptyReturnRulesHistory.readings.length > 0 &&
            check.terminal === emptyReturnRulesHistory.readings[0].terminal &&
            firstObserved.equals(
              emptyReturnRulesHistory.readings[0].first_observed
            )
          ) {
            emptyReturnRulesHistory.readings[0].last_observed = lastObserved
          }
        }
      },
      updateObservedTimesFromSoketi(checkEvent: EmptyReturnRulesCheckedEvent) {
        const emptyReturnRulesHistory = useReturnRulesHistory()
        const lastObserved = DateTime.fromISO(checkEvent.check_time)
        const firstObserved = DateTime.fromISO(checkEvent.first_observed)
        const existingReading = this.readings_by_terminal.get(
          checkEvent.terminal
        )
        if (existingReading) {
          existingReading.last_observed = lastObserved
        }
        if (
          emptyReturnRulesHistory.readings.length > 0 &&
          checkEvent.terminal ===
            emptyReturnRulesHistory.readings[0].terminal &&
          firstObserved.equals(
            emptyReturnRulesHistory.readings[0].first_observed
          )
        ) {
          emptyReturnRulesHistory.readings[0].last_observed = lastObserved
        }
      },
    },
  }
)
