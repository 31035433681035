import type { AppointmentWithContainerInfo } from '~/models/appointmentWithContainerInfo'
import { type SpecificReturnRule, returnOptionKey } from '~/models/returnRules'
import {
  type ContainerType,
  type ShippingLine,
  type TerminalName,
} from '~/services/apiClient'
import type { CategoryKey } from '~/stores/returnRulesLatest'
import { useReturnRulesLatest } from '~/stores/returnRulesLatest'

const useReturnRulesAndFilters = createSharedComposable(
  useReturnRulesAndFiltersInner
)
export { useReturnRulesAndFilters }
function useReturnRulesAndFiltersInner() {
  // Stores
  const latestReturnRulesStore = useReturnRulesLatest()

  // Refs
  const shippingLine = ref(undefined as undefined | ShippingLine)
  const containerType = ref(undefined as undefined | ContainerType)
  const terminal = ref(undefined as undefined | TerminalName)
  const allReturnRuleCategoryKeys = toRef(
    latestReturnRulesStore,
    'categoryKeys'
  )
  const allNotClosedReturnRuleCategoryKeys = toRef(
    latestReturnRulesStore,
    'notClosedCategoryKeys'
  )

  // Computed properties
  const applicableReturnRuleCategoryKeys = computed(() => {
    return allReturnRuleCategoryKeys.value.filter(isCategoryApplicable)
  })
  const applicableNotClosedCategoryKeys = computed((): CategoryKey[] => {
    const keys =
      allNotClosedReturnRuleCategoryKeys.value.filter(isCategoryApplicable)
    return keys.map((key) => {
      return {
        terminal: key.terminal,
        shippingLine: key.shippingLine,
        containerType: key.containerType,
      }
    })
  })
  const applicableTerminals = computed((): Set<TerminalName> => {
    return new Set(
      applicableReturnRuleCategoryKeys.value.map((key) => key.terminal)
    )
  })

  const dualReturnOptions = computed((): Map<string, SpecificReturnRule> => {
    // Indicates areas where duals *could* be formed. Further matching based on
    // compatibility rules with specific appointments must be applied later
    const lookup = new Map<string, SpecificReturnRule>()
    if (!shippingLine.value || !containerType.value) return lookup
    const rulesByTerminal = latestReturnRulesStore.expandedRules
      .get(shippingLine.value)
      ?.get(containerType.value)
    if (!rulesByTerminal) return lookup
    for (const rules of rulesByTerminal.values()) {
      for (const rule of rules) {
        const key = returnOptionKey(rule)
        if (lookup.has(key))
          throw new Error('Duplicate return rule key: ' + key)
        lookup.set(key, rule)
      }
    }
    return lookup
  })

  // Functions
  function isAppointmentDualEligible(
    appointment: AppointmentWithContainerInfo
  ) {
    const key = returnOptionKey(appointment.appointment)
    const rule = dualReturnOptions.value.get(key)
    if (!rule?.rule) return false
    if (!rule.rule.can_dual) return false
    // Check for length match on a best-effort basis
    if (
      appointment.watch.container_type &&
      !areLengthsCompatibleForDual(
        appointment.watch.container_type,
        rule.rule.container_type
      )
    ) {
      return false
    }
    return true
  }
  function isCategoryApplicable(category: CategoryKey) {
    if (shippingLine.value && category.shippingLine !== shippingLine.value) {
      return false
    }
    if (containerType.value && category.containerType !== containerType.value) {
      return false
    }
    if (terminal.value && category.terminal !== terminal.value) {
      return false
    }
    return true
  }
  return {
    shippingLine,
    containerType,
    terminal,
    applicableTerminals,
    applicableNotClosedCategoryKeys,
    applicableReturnRuleCategoryKeys,
    isAppointmentDualEligible,
  }
}

function areLengthsCompatibleForDual(
  inboundType: ContainerType,
  outboundType: ContainerType
) {
  // Some terminals REQUIRE that the container lengths for a dual match (LBCT, APM) and
  // others do not. However, I'll just start with lengths having to match and go from
  // there

  // For the other terminals, you really need a bunch of chassis context to determine
  // the options for a dual when the lengths don't match. You need either:
  // - Your own "super" adjustable chassis
  // - Have the empty on a pool chassis that you can terminate, and be able to pickup
  //   a different, appropriate length pool chassis
  const inboundLength = extractContainerLength(inboundType)
  const outboundLength = extractContainerLength(outboundType)
  return inboundLength === outboundLength
}

function extractContainerLength(containerType: ContainerType) {
  return parseInt(containerType.match(/\d+/)![0])
}
