<script lang="ts" setup>
import { ArrowDown } from '@element-plus/icons-vue'
import { makeTimeLabel } from './helpers'
import StatusFilterRadioButton from './StatusFilterRadioButton.vue'
import { TERMINALS } from '~/constants'
import { useContainerStore } from '~/stores/containers'
import type { ContainerWatchState, TerminalName } from '~/services/apiClient'
import { useContainerFilters } from '~/compositions/containerFilters'
import { useUserStore } from '~/stores/user'
import { useShippingLinesStore } from '~/stores/shippingLines'
import { useContainerTypesStore } from '~/stores/containerTypes'
const filters = useContainerFilters()
const {
  assignedToFilter,
  autoBookOnFilter,
  autoBookOffFilter,
  containerSearch,
  customerFilter,
  customerIDFilter,
  shippingLineFilter,
  containerTypeFilter,
  tagsFilter,
  vesselsFilter,
  setAssignedToFilter,
  setAutoBookOnFilter,
  setAutoBookOffFilter,
  setCustomerIDFilter,
  statusFilterLabel,
  statusFilterOptions,
  terminalFilter,
  terminalFilterLabel,
  updatedInLastMinutesFilter,
  // myContainersOnly,
} = filters
const containerStore = useContainerStore()
const shippingLinesStore = useShippingLinesStore()
const containerTypesStore = useContainerTypesStore()
const userStore = useUserStore()

const terminals = ref(TERMINALS)
function getStateCountDesc(states: ContainerWatchState[]): string {
  let count = 0
  let countFound = false
  for (const state of states) {
    const stateCount = containerStore.statusCounts.get(state)
    if (stateCount != null) {
      count += stateCount
      countFound = true
    }
  }
  if (countFound) return count.toString()
  else return '-'
}

const numContainersAllStates = computed(() => {
  let count = 0
  containerStore.statusCounts.forEach((value, key) => {
    if (value !== null) {
      count += value
    }
  })
  return count
})

function getCountByTerminalDesc(terminal: TerminalName): string {
  const count = containerStore.countsByTerminal.get(terminal)
  if (count !== undefined) return count.toString()
  else return '-'
}
</script>

<template>
  <Card class="py-1 py-3 px-4">
    <!-- Search Containers -->
    <div class="flex flex-wrap justify-start filter-row">
      <div class="max-w-500px flex flex-1 mr-2" style="min-width: 180px">
        <el-input
          v-model="containerSearch"
          v-tooltip="
            `Search for one or more containers. Option to add if containers not yet in system.`
          "
          placeholder="KKFU9159476, NYKU4064208..."
          size="default"
          clearable
        >
          <template #prepend>
            <el-icon>
              <i-mdi:magnify />
            </el-icon>
          </template>
        </el-input>
      </div>
      <div class="self-center mr-2">or</div>
      <!-- Filter Terminals -->
      <el-dropdown
        v-tooltip="'View containers at a specific terminal'"
        size="large"
        trigger="click"
      >
        <el-button
          plain
          class="mr-2 dropdown-filter el-input--color"
          size="large"
        >
          <i-mdi:pier-crane class="align-middle h-5 w-5 mr-1" />
          Terminal
          <el-icon class="el-icon--right"><ArrowDown /></el-icon>
        </el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item
              v-for="terminal in terminals"
              :key="terminal.name"
              @click="terminalFilter = terminal.name"
            >
              <span class="min-w-8">
                {{ getCountByTerminalDesc(terminal.name) }}
              </span>
              {{ terminal.label }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>

      <!-- Filter Customers -->
      <CustomerSelector
        v-if="!userStore.isSubCarrier"
        v-tooltip="'View containers assigned to a specific customer'"
        :model-value="customerIDFilter"
        style="width: 150px"
        class="dropdown-filter"
        @update:model-value="setCustomerIDFilter($event || null)"
      />
      <!-- Filter by assignment -->
      <UserSelector
        v-if="!userStore.isSubCarrier"
        v-tooltip="'View containers assigned to one or more users'"
        :model-value="assignedToFilter"
        placeholder="Assigned user"
        class="ml-2 dropdown-filter"
        style="width: 190px"
        :include-unassigned-option="true"
        @update:model-value="setAssignedToFilter"
      />
      <!-- Filter auto-book -->
      <el-popover
        :width="440"
        trigger="click"
        placement="bottom"
        :persistent="false"
        :hide-after="0"
      >
        <template #reference>
          <el-button
            :plain="!(autoBookOnFilter || autoBookOffFilter)"
            type="primary"
            class="ml-2"
            size="large"
          >
            <i-mdi:filter-outline class="align-text-top h-5 w-5 mr-1" />
            Filters
            <el-icon class="el-icon--right"><ArrowDown /></el-icon>
          </el-button>
        </template>
        <OtherFilters />
      </el-popover>
      <ClearFilterButton v-if="tagsFilter.length" @click="tagsFilter = []">
        {{ tagsFilter.join(', ') }}
      </ClearFilterButton>
      <ClearFilterButton
        v-if="vesselsFilter?.length"
        @click="vesselsFilter = undefined"
      >
        {{ vesselsFilter.join(', ') }}
      </ClearFilterButton>
      <ClearFilterButton
        v-if="terminalFilter"
        @click="terminalFilter = undefined"
      >
        {{ terminalFilterLabel }}
      </ClearFilterButton>
      <ClearFilterButton
        v-if="customerFilter"
        @click="setCustomerIDFilter(null)"
      >
        {{ customerFilter.name }}
      </ClearFilterButton>
      <ClearFilterButton
        v-if="autoBookOnFilter"
        @click="setAutoBookOnFilter(false)"
      >
        Auto Book ON
      </ClearFilterButton>
      <ClearFilterButton
        v-if="autoBookOffFilter"
        @click="setAutoBookOffFilter(false)"
      >
        Auto Book OFF
      </ClearFilterButton>
      <ClearFilterButton
        v-if="shippingLineFilter"
        @click="shippingLineFilter = undefined"
      >
        {{ shippingLinesStore.getShippingLineDescription(shippingLineFilter) }}
      </ClearFilterButton>
      <ClearFilterButton
        v-if="containerTypeFilter"
        @click="containerTypeFilter = undefined"
      >
        {{
          containerTypesStore.getContainerTypeDescription(containerTypeFilter)
        }}
      </ClearFilterButton>
      <ClearFilterButton
        v-if="updatedInLastMinutesFilter"
        @click="updatedInLastMinutesFilter = undefined"
      >
        Updated in last {{ makeTimeLabel(updatedInLastMinutesFilter) }}
      </ClearFilterButton>
    </div>
    <div class="flex flex-wrap filter-row">
      <!-- Filter Status -->
      <el-radio-group v-model="statusFilterLabel" class="mr-2">
        <StatusFilterRadioButton
          v-if="containerSearch"
          icon="mdi:magnify"
          label="Searched"
          tooltip="View only searched containers"
          :counts-desc="numContainersAllStates.toString()"
        />
        <StatusFilterRadioButton
          v-for="statusOption in statusFilterOptions"
          :key="statusOption.label"
          :icon="statusOption.icon"
          :label="statusOption.label"
          :tooltip="`View only ${statusOption.label} containers`"
          :counts-desc="getStateCountDesc(statusOption.states)"
        />
      </el-radio-group>

      <div
        v-if="containerStore.numUnwatchedContainers && !userStore.isSubCarrier"
        class="flex"
      >
        <div class="text-xs max-w-34 ml-2">
          {{ containerStore.numUnwatchedContainers }}
          <template v-if="containerStore.numUnwatchedContainers > 1">
            searched containers are not being tracked
          </template>
          <template v-else>searched container is not being tracked</template>
        </div>
        <AddContainersButton />
      </div>
      <AddContainersButtonAndDialog
        v-if="!userStore.isSubCarrier"
        class="mt-4"
      />
      <!-- Filter To My Containers -->
      <!-- <el-divider direction="vertical" style="height: 2rem" />
      <el-divider direction="vertical" style="height: 2rem" />
      <el-switch v-model="myContainersOnly" active-text="Added by Me" /> -->
    </div>
  </Card>
</template>

<style scoped>
.filter-row:not(:first-child) > * {
  margin-top: 1rem;
}

.el-tag {
  font-size: 13px;
}

.el-input--color {
  color: var(--element-input-color);
}
</style>
