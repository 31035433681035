import { useRouteQuery } from '@vueuse/router'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { useUserStore } from './user'

export const useCurrentOrgStore = defineStore('current-org', () => {
  const userStore = useUserStore()
  const router = useRouter()
  const urlScac = useRouteQuery<string | undefined>('scac', undefined, {
    mode: 'push',
  })
  const urlOrgId = useRouteQuery<string | undefined>('org_id', undefined, {
    mode: 'push',
  })
  const currentOrg = computed(() => {
    return userStore.currentOrg
  })
  async function selectOrgFromURL() {
    await nextTick()

    if (urlOrgId.value !== undefined) {
      const parsedOrgId = parseInt(urlOrgId.value, 10)

      if (!isNaN(parsedOrgId)) {
        const org = userStore.userInfo?.organizations.find(
          (org) => org.id === parsedOrgId
        )
        if (org) {
          if (org.scac) {
            // Replace the current route with the updated query parameters
            urlScac.value = org.scac
            urlOrgId.value = undefined
            const currentQuery = { ...router.currentRoute.value.query }
            delete currentQuery.org_id
            currentQuery.scac = urlScac.value
            router.replace({ query: currentQuery })
          } else {
            // If the org doesn't have a SCAC, we can't use it, delete the route scac
            urlOrgId.value = org.id.toString()
            urlScac.value = undefined
            const currentQuery = { ...router.currentRoute.value.query }
            delete currentQuery.scac
            currentQuery.org_id = urlOrgId.value
            router.replace({ query: currentQuery })
          }
        }
      }
    }

    if (urlScac.value && !(currentOrg.value?.scac === urlScac.value)) {
      await switchToOrgByScac(urlScac.value)
    } else if (
      urlOrgId.value &&
      !(currentOrg.value?.id === parseInt(urlOrgId.value))
    ) {
      await switchToOrgById(parseInt(urlOrgId.value))
    }
  }
  // When we navigate, often that would clear the SCAC, so let's just set it again if
  // that is the case
  router.beforeEach((to, from, next) => {
    if (currentOrg.value && currentOrg.value.scac && !to.query.scac) {
      to.query = { ...to.query, scac: currentOrg.value.scac }
      next(to)
      return
    } else if (
      currentOrg.value &&
      currentOrg.value.id &&
      !to.query.scac &&
      !to.query.org_id
    ) {
      // set org id in the query if we don't have scac
      to.query = { ...to.query, org_id: currentOrg.value.id.toString() }
      next(to)
      return
    }
    next()
  })

  async function switchToOrgByScac(scac: string) {
    const org = userStore.userInfo?.organizations.find(
      (org) => org.scac === scac
    )
    if (org) {
      urlScac.value = org.scac
      urlOrgId.value = undefined
      await userStore.switchToOrg(org.id)
      return true
    }
    urlScac.value = currentOrg.value?.scac || undefined
    urlOrgId.value = undefined
    return false
  }

  async function switchToOrgById(id: number) {
    const org = userStore.userInfo?.organizations.find((org) => org.id === id)
    if (org) {
      urlScac.value = undefined
      urlOrgId.value = org.id.toString()
      await userStore.switchToOrg(org.id)
      return true
    }
    urlOrgId.value = currentOrg.value?.id?.toString() || undefined
    urlScac.value = undefined
    return false
  }
  return { switchToOrgByScac, switchToOrgById, selectOrgFromURL }
})
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCurrentOrgStore, import.meta.hot))
}
