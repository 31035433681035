<script setup lang="ts">
import { useRouter } from 'vue-router'
import { useCurrentOrgStore } from '~/stores/currentOrg'
import { useUserStore } from '~/stores/user'
import Login from '~/components/navigation/Login.vue'
import type { GETToken } from '~/services/apiClient'

useHead({
  title: 'Dray Dog - Login',
})

const userStore = useUserStore()
const { selectOrgFromURL } = useCurrentOrgStore()
const router = useRouter()

function goToContainersPage() {
  router.push({ name: 'containers' })
}

function goToEmptyReturnsPage() {
  router.push({ name: 'empty-returns' })
}

onMounted(() => {
  if (userStore.loggedIn) {
    if (userStore.isTerminalOrg) {
      goToEmptyReturnsPage()
    } else {
      goToContainersPage()
    }
  }
})

async function handleLogin(token: GETToken) {
  await userStore.login(token)
  await selectOrgFromURL()
  if (!userStore.isTerminalOrg) {
    goToContainersPage()
  } else {
    goToEmptyReturnsPage()
  }
}
</script>

<template>
  <Login @login="handleLogin">
    <template #extra>
      <router-link
        :to="{ name: 'trigger_reset_password', query: { email: '' } }"
        class="underline cursor-pointer text-xs text-gray-500"
      >
        Forgot your password?
      </router-link>
    </template>
  </Login>
</template>

<route lang="yaml">
meta:
  layout: no_sidebar
name: login
</route>
