<script lang="ts" setup>
import type { PropType } from 'vue'
import type { CameraImage } from '~/models/cameraImage'

const props = defineProps({
  img: { type: Object as PropType<CameraImage>, required: true },
  anchor: { type: String },
})
const { img } = toRefs(props)
</script>

<template>
  <router-link
    :to="{
      name: 'specific-terminal',
      params: { terminalName: img.terminal },
      query: { streamName: img.streamName },
      hash: props.anchor,
    }"
  >
    <slot>
      <span class="underline ml-2">
        <i-mdi:history class="align-text-bottom -mb-1px" />
        View History
      </span>
    </slot>
  </router-link>
</template>
