<script lang="ts" setup>
import { computed, toRefs } from 'vue'
import type { ReadingSource } from '../emptyReturnRules/terminals'
import { TerminalReadingType } from '../emptyReturnRules/terminals'
import { useUserStore } from '~/stores/user'
import { useReturnReadingIsStale } from '~/compositions/returnReadingIsStale'
import { useLuxonTimeAgo } from '~/compositions/timeAgo'
import type { ReturnRulesReading } from '~/models/returnRules'
import type { GateScheduleReading } from '~/models/gateSchedules'

const props = defineProps<{
  reading: ReturnRulesReading | GateScheduleReading
  readingIsHistorical: boolean
  rulesSource?: ReadingSource
  readingType: TerminalReadingType
}>()

const { reading: readingRef } = toRefs(props)
const userStore = useUserStore()
const staleAfter = computed(() => {
  if (props.readingType === TerminalReadingType.GateSchedule) {
    return 20 // minutes
  }
  return 10 // minutes
})
const readingIsOld = useReturnReadingIsStale(readingRef, staleAfter.value)
const readingIsStale = computed(() => {
  return readingIsOld.value && !props.readingIsHistorical
})
const readingLastChecked = computed(() => {
  if (readingRef.value) {
    return readingRef.value.last_observed
  }
  return null
})

const staleMessage = computed(() => {
  if (props.readingType === TerminalReadingType.GateSchedule) {
    return 'Gate Schedule last fetched'
  }
  return 'Empty return rules last fetched'
})
const readingLastCheckedDesc = useLuxonTimeAgo(readingLastChecked)
</script>

<template>
  <div>
    <div class="mb-2">
      <ColoredBadge
        v-if="reading.first_observed && readingIsStale"
        type="danger"
      >
        <i-mdi:alert class="align-middle mr-1" />
        {{ staleMessage }} {{ readingLastCheckedDesc }}
      </ColoredBadge>
      <ColoredBadge v-else type="success" effect="dark" class="shadow">
        Last checked {{ readingLastCheckedDesc }}
      </ColoredBadge>
      <ColoredBadge
        v-if="reading.last_parsed_time === null"
        type="warning"
        effect="dark"
        class="shadow ml-2"
      >
        Not Parsed
      </ColoredBadge>
      <ColoredBadge v-else type="success" effect="dark" class="shadow ml-2">
        Parsed
      </ColoredBadge>
      <a v-if="rulesSource" :href="rulesSource.source" target="_blank">
        <ColoredBadge type="info" class="ml-2">
          View Source
          <template v-if="rulesSource.requiresLogin">
            (requires login)
          </template>
          <i-mdi:open-in-new class="align-text-bottom text-xs" />
        </ColoredBadge>
      </a>
    </div>

    <div class="flex h-full">
      <!-- Left (Screenshot) -->
      <div class="flex-1 overflow-y-scroll">
        <h2 class="section-header">Screenshot</h2>
        <el-image
          :key="reading.screenshot_url"
          :src="reading.screenshot_url"
          class="shadow-xl border p-3 rounded-3px max-w-full min-w-30vw min-h-20vh pr-6 pb-6"
        >
          <template #placeholder>
            <el-skeleton :loading="true" animated>
              <template #template>
                <el-skeleton-item variant="image" class="min-h-20vh" />
              </template>
            </el-skeleton>
          </template>
        </el-image>
      </div>

      <!-- Right (Parsed rules, internal users) -->
      <div
        v-if="
          userStore.is_internal_user &&
          props.readingType === TerminalReadingType.EmptyReturnRules
        "
        class="flex-1 overflow-y-scroll"
      >
        <h2 class="section-header">Parsed Rules</h2>
        <TerminalEmptyReturnTable
          :key="reading.terminal"
          :reading-first-observed="reading.first_observed_raw"
          :terminal="reading.terminal"
        />
      </div>

      <div
        v-if="
          userStore.is_internal_user &&
          props.readingType === TerminalReadingType.GateSchedule
        "
        class="flex-1 overflow-y-scroll"
      >
        <h2 class="section-header">Parsed Entries</h2>
        <TerminalGateScheduleTable
          :key="reading.terminal"
          :reading-first-observed="reading.first_observed_raw"
          :terminal="reading.terminal"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.section-header {
  @apply text-center text-2xl font-bold mb-2;
}
</style>
