<script lang="ts" setup>
import type { AppointmentTableRow, RescheduleInfo } from './types'
import BookAppointmentsTable from '~~/components/BookAppointmentsTable.vue'
import type { Appointment } from '~/models/appointments'
import type { BookingRequestState } from '~~/stores/mutationRequests'

const props = defineProps<{
  appointment: Appointment
  rowData: AppointmentTableRow
  targetElement: HTMLElement
}>()

const emit = defineEmits<{
  (e: 'rescheduleBegun', event: RescheduleInfo): void
  (e: 'close'): void
}>()

function onBookingBegun(bookingState: BookingRequestState) {
  emit('rescheduleBegun', {
    state: bookingState,
    row: props.rowData,
    originalAppointment: props.appointment,
  })
}

function close() {
  emit('close')
}
</script>

<template>
  <el-popover
    :virtual-ref="targetElement"
    virtual-triggering
    clo
    :teleported="true"
    :visible="true"
    width="98%"
  >
    <div class="text-xl text-center mb-2 text-black flex justify-between">
      <div>
        <i-mdi:calendar-sync class="align-text-bottom -mb-1px" />
        Select new appointment time
      </div>
      <div>
        <i-mdi:close class="cursor-pointer" @click="close" />
      </div>
    </div>
    <BookAppointmentsTable
      :appointments="[props.appointment]"
      :searched-containers="[props.appointment.container_number]"
      :import-statuses="[]"
      :rounded="false"
      class="w-full"
      :show-containers-column="false"
      @booking-begun="onBookingBegun"
    />
  </el-popover>
</template>
