<script lang="ts" setup>
import { useVesselCounts } from '~/compositions/useVesselCounts'

const props = defineProps<{
  /** The Vessel Label  */
  modelValue: string[] | undefined
}>()
const emit = defineEmits<{
  /** Emit Vessel back to parent  */
  (e: 'update:modelValue', value: string[] | undefined): void
  (e: 'close', value: string | undefined): void
}>()
const selectedVessel = useVModel(props, 'modelValue', emit)
const { countByName } = useVesselCounts()

type SelectRefElement = HTMLElement | null
const selectRef: Ref<SelectRefElement | null> = ref(null)
</script>

<template>
  <el-select
    ref="selectRef"
    v-model="selectedVessel"
    placeholder="Select vessel"
    filterable
    :clearable="true"
    size="large"
  >
    <el-option
      v-for="[name, count] in countByName.entries()"
      :key="name"
      :value="name"
    >
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <span>{{ name }}</span>
        <span>{{ count }}</span>
      </div>
    </el-option>
  </el-select>
</template>
