import { DateTime } from 'luxon'
import { acceptHMRUpdate, defineStore } from 'pinia'
import type { SoketiEvent } from '~/compositions/configureSoketi'
import { useSoketi } from '#compositions/configureSoketi'
import { getPortalLabel, getTerminalLabel } from '~/constants'
import type {
  GETVesselETA,
  GETVesselETAs,
  PortalName,
  TerminalName,
  VesselETAsUpdatedEvent,
} from '~/services/apiClient'
import { EventName, VesselsApi, WebsocketChannel } from '~/services/apiClient'

class Vessel {
  vesselID: number
  eta: VesselETA
  otherETAs: VesselETA[] = []
  constructor(raw: GETVesselETAs) {
    this.vesselID = raw.vessel_id
    this.eta = new VesselETA(raw.eta)
    this.otherETAs = raw.other_etas.map((otherETA) => new VesselETA(otherETA))
  }

  get portalLabel(): string {
    return getPortalLabel(this.eta.portal)
  }
}
class VesselETA {
  eta: DateTime
  firstObserved: DateTime
  lastObserved: DateTime
  portal: PortalName
  terminal: TerminalName | undefined
  constructor(raw: GETVesselETA) {
    this.eta = DateTime.fromISO(raw.eta)
    this.firstObserved = DateTime.fromISO(raw.first_observed)
    this.lastObserved = DateTime.fromISO(raw.last_observed!)
    this.portal = raw.portal_name
    this.terminal = raw.terminal
  }

  get portalLabel(): string {
    return getPortalLabel(this.portal)
  }

  get terminalLabel(): string {
    if (!this.terminal) {
      return ''
    }
    return getTerminalLabel(this.terminal)
  }
}

export const useVesselETAsStore = defineStore('vessel-etas', () => {
  const etasByVesselID = ref<Map<number, Vessel>>(new Map())
  const loadingPromise = ref<Promise<void>>()
  const loading = ref(false)
  function load() {
    if (loadingPromise.value) {
      return loadingPromise.value
    }
    const api = new VesselsApi()
    loading.value = true
    // Include stuff arriving in the last week
    const after = DateTime.now().minus({ days: 7 })
    loadingPromise.value = api
      .vesselEtasVesselsEtasGet(undefined, after.toISO())
      .then((response) => {
        const vesselEtas = response.data
        vesselEtas.forEach((vesselETA) => {
          const eta = new Vessel(vesselETA)
          etasByVesselID.value.set(eta.vesselID, eta)
        })
      })
      .finally(() => {
        loading.value = false
        loadingPromise.value = undefined
      })
  }
  const soketi = useSoketi()
  soketi.bindOnReconnect(load)
  function onVesselETAUpdated(event: SoketiEvent) {
    const update_event = event as VesselETAsUpdatedEvent
    update_event.etas.forEach((rawETA) => {
      const eta = new Vessel(rawETA)
      etasByVesselID.value.set(eta.vesselID, eta)
    })
  }
  soketi.bind(
    WebsocketChannel.PrivateVesselEtas,
    EventName.VesselEtaUpdated,
    onVesselETAUpdated
  )

  async function getVesselETAAsync(
    vesselID: number,
    terminal: TerminalName | null
  ): Promise<VesselETA | null> {
    if (loadingPromise.value) {
      await loadingPromise.value
    }
    return getVesselETA(vesselID, terminal)
  }
  function getVesselETA(
    vesselID: number,
    terminal: TerminalName | null
  ): VesselETA | null {
    const vessel = etasByVesselID.value.get(vesselID) ?? null
    if (!vessel) {
      return null
    }
    // find the matching terminal if there is one
    if (terminal) {
      const terminalETA =
        vessel.otherETAs.find((eta) => eta.terminal === terminal) ?? null
      if (terminalETA) {
        return terminalETA
      }
    }
    return vessel.eta
  }
  // Start loading on setup
  load()

  return { getVesselETAAsync, loading, getVesselETA }
})
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useVesselETAsStore, import.meta.hot))
}
