<script lang="ts" setup>
import { ElNotification } from 'element-plus'
import type { PropType } from 'vue'
import type { Container } from '~/models/containers'
import { ContainersApi } from '~/services/apiClient'

const props = defineProps({
  container: { type: Object as PropType<Container>, required: true },
  modelValue: { type: Boolean, required: true },
})
const emit = defineEmits(['close', 'update:modelValue'])
const assignedUserIDs = ref(
  props.container.assigned_users.map((user) => user.id)
)
const saving = ref(false)
function save() {
  saving.value = true
  const existingAssignedUserIDs = props.container.assigned_users.map(
    (user) => user.id
  )
  const existingAssignedUserIDsSet = new Set(existingAssignedUserIDs)
  const newAssignedUserIDsSet = new Set(assignedUserIDs.value)
  const usersToAssign = assignedUserIDs.value.filter(
    (userID) => !existingAssignedUserIDsSet.has(userID)
  )
  const usersToUnassign = existingAssignedUserIDs.filter(
    (userID) => !newAssignedUserIDsSet.has(userID)
  )
  const api = new ContainersApi()
  const promises = []
  if (usersToUnassign.length > 0) {
    promises.push(
      api.unassignContainersContainersUnassignPost({
        container_numbers: [props.container.number],
        user_ids: usersToUnassign,
      })
    )
  }
  if (usersToAssign.length > 0) {
    promises.push(
      api.assignContainersContainersAssignPost({
        container_numbers: [props.container.number],
        user_ids: usersToAssign,
      })
    )
  }
  Promise.all(promises)
    .then(() => {
      ElNotification.success({ title: 'Assigned users updated' })
      close()
    })
    .finally(() => {
      saving.value = false
    })
}
function close() {
  emit('close')
  emit('update:modelValue', false)
}
</script>

<template>
  <el-dialog
    :model-value="props.modelValue"
    :title="`Edit Assigned Users for ${props.container.number}`"
    width="30%"
    :append-to-body="true"
    @update:model-value="emit('update:modelValue')"
    @close="emit('close')"
  >
    <UserSelector v-model="assignedUserIDs" class="w-full" />
    <template #footer>
      <div class="flex">
        <el-button class="flex-1" @click="close">Cancel</el-button>
        <el-button
          class="flex-1"
          type="primary"
          :loading="saving"
          @click="save"
        >
          Save
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>
