<script lang="ts" setup>
import { DateTime } from 'luxon'
import type { TerminalName } from '../../services/apiClient'
import { TransactionDirection } from '../../services/apiClient'
import { TERMINALS, getTerminalLabel } from '~/constants'
const selectedTerminal = ref('')
const selectedTerminalName = computed(() => {
  if (selectedTerminal.value) {
    return selectedTerminal.value as TerminalName
  }
})
interface MoveTypeOption {
  label: string
  loaded: boolean
  direction: TransactionDirection
}
const moveTypeOptions: MoveTypeOption[] = [
  { label: 'Load Out', loaded: true, direction: TransactionDirection.Outbound },
  { label: 'Load In', loaded: true, direction: TransactionDirection.Inbound },
  {
    label: 'Empty Out',
    loaded: false,
    direction: TransactionDirection.Outbound,
  },
  { label: 'Empty In', loaded: false, direction: TransactionDirection.Inbound },
]
const shortTermTooltipFormat = 'ddd, MMM d - htt'
const shortTermXAxisLabelFormat = 'ddd, MMM d'
const viewOptions = [
  {
    label: 'All Future (by 2 hours)',
    bucket_hours: 2,
    start: DateTime.now().toLocal().startOf('day'),
    tooltipDateFormat: shortTermTooltipFormat,
    xAxisDateFormat: shortTermXAxisLabelFormat,
  },
  {
    label: 'All Future (by 4 hours)',
    bucket_hours: 4,
    start: DateTime.now().toLocal().startOf('day'),
    tooltipDateFormat: shortTermTooltipFormat,
    xAxisDateFormat: shortTermXAxisLabelFormat,
  },
  {
    label: 'Last 14 days (by 4 hours)',
    bucket_hours: 4,
    start: DateTime.now().toLocal().minus({ days: 14 }),
    tooltipDateFormat: shortTermTooltipFormat,
    xAxisDateFormat: shortTermXAxisLabelFormat,
  },
  {
    label: 'Last 30 days (by 12 hours)',
    bucket_hours: 12,
    start: DateTime.now().toLocal().minus({ days: 30 }),
  },
  {
    label: 'Last 30 days (by day)',
    bucket_hours: 24,
    start: DateTime.now().toLocal().minus({ days: 30 }),
  },
  {
    label: 'Last 60 days (by day)',
    bucket_hours: 24,
    start: DateTime.now().toLocal().minus({ days: 60 }),
  },
  {
    label: 'Last 90 days (by day)',
    bucket_hours: 24,
    start: DateTime.now().toLocal().minus({ days: 90 }).startOf('week'),
  },
  {
    label: 'Last 90 days (by week)',
    bucket_hours: 24 * 7,
    start: DateTime.now().toLocal().minus({ days: 90 }).startOf('week'),
  },
  {
    label: 'Last 180 days (by week)',
    bucket_hours: 24 * 7,
    start: DateTime.now().toLocal().minus({ days: 180 }).startOf('week'),
  },
]
const selectedViewOption = ref(viewOptions[3])
const selectedMoveType = ref(moveTypeOptions[0])
</script>

<template>
  <el-form label-position="top" inline class="dashboard-controls">
    <el-form-item label="Period:" style="min-width: 220px">
      <el-select v-model="selectedViewOption" value-key="label">
        <el-option
          v-for="viewOption in viewOptions"
          :key="viewOption.label"
          :label="viewOption.label"
          :value="viewOption"
        />
      </el-select>
    </el-form-item>
    <el-form-item label="Terminal:">
      <el-select v-model="selectedTerminal" clearable>
        <el-option label="Terminals" value="" />
        <el-option
          v-for="terminal in TERMINALS"
          :key="terminal.name"
          :label="terminal.label"
          :value="terminal.name"
        />
      </el-select>
    </el-form-item>
    <el-form-item label="Move type:">
      <el-select v-model="selectedMoveType" value-key="label" clearable>
        <el-option label="All" value="" />
        <el-option
          v-for="moveType in moveTypeOptions"
          :key="moveType.label"
          :label="moveType.label"
          :value="moveType"
        />
      </el-select>
    </el-form-item>
  </el-form>
  <AppointmentsGraph
    :start="selectedViewOption.start"
    :terminal="selectedTerminalName"
    :loaded="selectedMoveType.loaded"
    :direction="selectedMoveType.direction"
    :time-bucket-hours="selectedViewOption.bucket_hours"
    :title="`Appointments Booked - ${selectedMoveType.label || ''} ${
      selectedTerminalName ? `at ${getTerminalLabel(selectedTerminalName)}` : ''
    }`"
    :tooltip-date-format="selectedViewOption.tooltipDateFormat"
    :x-axis-date-format="selectedViewOption.xAxisDateFormat"
  />
</template>

<style lang="scss">
.dashboard-controls .el-form-item {
  min-width: 200px;
}
</style>
