import { ElNotification } from 'element-plus'
import { acceptHMRUpdate, defineStore } from 'pinia'

import { ContainerEventsApi, ContainerTagsApi } from '~/services/apiClient'

export const useContainerTagsStore = defineStore('containerTags', () => {
  const tags = ref([] as string[])
  const tagsAPI = new ContainerTagsApi()
  const eventsAPI = new ContainerEventsApi()
  function loadTags() {
    tagsAPI.getTagsContainersTagsGet().then((response) => {
      tags.value = response.data.map((tag) => tag.name)
    })
  }
  loadTags()
  function tagContainers(containerNumbers: string[], tagsToAdd: string[]) {
    eventsAPI
      .tagContainerContainersEventsTagPost({
        container_numbers: containerNumbers,
        tags: tagsToAdd,
      })
      .then(() => {
        ElNotification.success({
          title: 'Tags Added',
          message: tagsToAdd.join(' ') + ' tags added successfully!',
        })
      })
      .catch(() => {
        ElNotification.error({
          title: 'Error Adding Tags',
          message: 'Failed to add tags:' + tagsToAdd.join(' '),
        })
      })
  }
  function untagContainers(containerNumbers: string[], tagsToRemove: string[]) {
    // remove tag from the container
    const tagsStr = tagsToRemove.join(', ')
    eventsAPI
      .untagContainerContainersEventsUntagPost({
        container_numbers: containerNumbers,
        tags: tagsToRemove,
      })
      .then(() => {
        ElNotification.success({
          title: 'Tags Removed',
          message: tagsStr + ' tags were removed',
        })
      })
      .catch(() => {
        ElNotification.error({
          title: 'Error Removing Tags',
          message: 'Failed to remove tags: ' + tagsStr,
        })
      })
  }
  return {
    tags,
    tagContainers,
    untagContainers,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useContainerTagsStore, import.meta.hot)
  )
}
